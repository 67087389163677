<div class="main-content-loader d-flex justify-content-center align-items-center position-fixed" *ngIf="showLoading">
  <label for="" class="loader"></label>
</div>

<div style="background-color:#fff; display:flex; height:47px; width:95%; box-shadow: 6px 6px 8px #ccc; margin-bottom: 16px; justify-content: space-between; align-items: center; position: fixed;
  z-index: 2;">

  <div style="display: flex; flex-direction: row; height: 100%; align-items: center;">
    <img (click)="goBack()" style="height: 20px; margin-left: 12px; width: 30px; align-self: center;"
      src="assets/images/arrow-back.svg" alt="" />
    <div   style="padding-left: 8px; font-size: 12px; font-weight: bold; color: #333; margin-right: 3px;">{{fullName}}
      ({{gender}} -
      {{ageObj.years}}y {{ageObj.months}}m {{ageObj.days}}d)</div>
      <!-- <div *ngIf="showAge" style="padding-left: 8px; font-size: 12px; font-weight: bold; color: #333; margin-right: 3px;">{{fullName}}
        ({{gender}} -
        {{ageObj.years}}y {{ageObj.months}}m {{ageObj.days}}d)</div> -->

    <div *ngFor="let element of vitalsArray; let i = index"
      style="height: 100%; display: flex; justify-content: center; align-items: center;  margin-left: -10px;">

      <img style="height: 100%;" src="assets/images/design_separator.svg" alt="" />
      <div
        style="position: absolute; display: flex; justify-content: center; align-items: center; flex-direction: column;">
        <div style="font-size: 14px; font-weight: bold; color: #333; margin-bottom: -4px;">
          {{element.value}} {{element.unit}}</div>
        <div style="color:#4687BF; font-size:12px">{{element.name}}</div>
      </div>
    </div>
    
  </div>

  <div style="display:flex; flex-direction: row;  margin-right: 30px;">
    <div (click)="open(more_select_type_modal, 'more_medicine_type_modal_new_drug'); clearAllSelection()" class="btn-type-border"
      style="margin-left:20px;">
      Select&nbsp;Fields
    </div>
    <div ngbDropdown class="d-inline-block">
      <button class="btn btn-outline-primary" id="dropdownBasic1" style="border: 0px;
        border-bottom: 1px solid #ccc;
        padding-right: 0px;
        color: #333;
        background-color: #fff;
        margin-right: 4px;
        " ngbDropdownToggle>{{templateName}} </button>

      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <button (click)="onDropdownSelectTemplate(item)" style="margin: 0px 16px 0px 0px !important"
          *ngFor="let item of selectedTemplate; let i = index" ngbDropdownItem>{{item}}</button>
      </div>
    </div>

    <div ngbDropdown class="d-inline-block">
      <button class="btn btn-outline-primary" id="dropdownBasic1" style="border: 0px;
        border-bottom: 1px solid #ccc;
        padding-right: 0px;
        color: #333;
        background-color: #fff;
        " ngbDropdownToggle>{{selectedLanguage.name}} </button>

      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <button (click)="onDropdownSelectLanguage(item)" style="margin: 0px 16px 0px 0px !important"
          *ngFor="let item of languageArray; let i = index" ngbDropdownItem>{{item.name}}</button>
      </div>
    </div>

    <div (click)="previewPrescriptionPDF()" class="btn-type-border" style="margin-left:20px;">
      Preview
    </div>


  </div>

</div>


<!-- BODY -->
<div
  style="height: 100%; width: 100%; background-color: #F3F6FA; display: flex; flex-direction: row; margin-top: 60px;">

  <div style=" width: 50%; height: 100%; display: flex; flex-direction: column;">
    <!-- Complaints Card -->
    <div name="card" class="card-container">

      <div style="display: flex; flex-direction: row; margin-left: 10px; ">
        <div *ngFor="let titles of complaintsCardArray; let i = index" style="display: flex; flex-direction: column;">
          <div (click)="switchComplaintTabs(i)"
            [ngClass]="(titles.selected) ?  'highlightedTextTab' :'unhighlightedText'" style="
            cursor: pointer;
            padding: 10px 6px 0px 6px;
            font-size: 16px;
            text-align: center;
            ">{{titles.name}}</div>
          <div [ngClass]="(titles.selected) ?  'selected-tab' :'unselected-tab'"></div>
        </div>

        <div *ngIf="selectedTabComplaints==='Chief Complaints'" (click)="open(complaints_modal, 'complaints_modal')"
          class="action-button">+Add</div>
        <div *ngIf="selectedTabComplaints==='Working Diagnosis'"
          (click)="open(working_diagnosis_modal, 'working_diagnosis_modal')" class="action-button">+Add</div>
        <!-- <img class="action-button" src="assets/images/add_button.svg" alt="" /> -->
      </div>

      <textarea *ngIf="selectedTabComplaints === 'Chief Complaints'" [(ngModel)]="chief_complaints" cols="40" rows="10"
        class="textarea-style">{{chief_complaints}}</textarea>

      <textarea *ngIf="selectedTabComplaints === 'Working Diagnosis'" [(ngModel)]="working_diagnosis" cols="40"
        rows="10" class="textarea-style">{{working_diagnosis}}</textarea>

      <!-- <textarea *ngIf="selectedTabComplaints == 'Observations'" [(ngModel)]="observations" cols="40" rows="10"
        class="textarea-style">{{observations}}</textarea> -->

      <!-- <textarea *ngIf="selectedTabComplaints == 'Notes'" [(ngModel)]="notes" cols="40" rows="10"
        class="textarea-style">{{notes}}</textarea> -->
    </div>


    <!-- Add Medicine Card -->
    <div name="card" class="card-container">

      <div style="
        display: flex; flex-direction: row; width: 97%; height: 40px; margin: 16px; justify-content: space-between;">
        <input id="typeahead-format" type="text" class="form-control" [(ngModel)]="yourtext" [ngbTypeahead]="search"
          [inputFormatter]="formatMatches" [resultFormatter]="formatter" (input)="getSearchedMeds($event.target.value)"
          placeholder="Start typing a drug name" (selectItem)="selectedItem($event)"
          [ngClass]="(unselectedDrugName) ? 'errorBorder' : 'commonBorder'" (ngModelChange)="onChangeDrugName()"
          style="
            height: 100%;
            width: 86%;
            border-radius: 30px;
            outline: none;
            background-color: red;
            padding-left: 16px;" id="fname" name="fname">

        <div (click)="open(add_new_drug_modal, 'add_new_drug_modal')" style="margin: 0px 8px 0px 8px;
          cursor: pointer;
          padding: 8px 0px 8px 0px;
          border-radius: 6px;
          background-color: #fff;
          color: #4687BF;
          border: 1px solid #4687BF;
          text-align: center;
          
          width: 100px;
          ">+New Drug</div>

      </div>


      <div>
        <div style="
           cursor: pointer;
          padding: 6px 0px 0px 16px;
          font-size: 16px;
          font-weight: bold;
        
            ">Drug Type
        </div>

        <div style="display: flex; flex-direction: row; margin: 8px; flex-wrap: wrap;">
          <div *ngFor="let item of drugTypeArray; let i = index" style="display: flex; flex-direction: row;">
            <div *ngIf="item.name!='More'" (click)="switchDrugTypes(i)"
              [ngClass]="(unselectedDrugType) ? 'errorHighlight' : (item.selected) ?  'highlightedBox' :'unhighlightedBox'"
              style="margin: 8px;
          cursor: pointer;
          padding: 10px;
          border-radius: 6px;
          text-align: center;
        
          ">{{item.name}}</div>

            <div *ngIf="item.name=='More'" (click)="open(more_medicine_type_modal, 'more_medicine_type_modal')"
              [ngClass]="(unselectedDrugType) ? 'errorHighlight' : (item.selected) ?  'highlightedBox' :'unhighlightedBox'"
              style="margin: 8px;
            cursor: pointer;
            padding: 10px;
            border-radius: 6px;
            text-align: center;
          
            ">{{item.name}}</div>
          </div>
        </div>

      </div>

      <div>
        <div style="justify-content: space-between; display: flex; flex-direction: row; align-items: center;">
          <div style="
           cursor: pointer;
          padding: 6px 0px 0px 16px;
          font-size: 16px;
          font-weight: bold;
            ">Dosage ({{doseUnitType}})
          </div>

          <div *ngIf="this.selectedDosageUnit && this.selectedDosageUnit !='Apply locally'"
            style="display: flex; flex-direction: row;">
            <div style="padding: 0px 12px 0px 12px;">
              <img (click)="decrementDose()" style="margin: 0px; height: 20px; width: 20px;"
                src="assets/images/decrement.svg" />
            </div>

            <div style="
           cursor: pointer;
          font-size: 20px;
          color: #707070;
          margin: -6px 0px 0px 0px;
          width: 40px;
          text-align: center;
            ">{{this.selectedDosageUnit}}
            </div>

            <div style="padding: 0px 12px 0px 12px;">
              <img (click)="incrementDose()" style="margin: 0px; height: 20px; width: 20px;"
                src="assets/images/increment.svg" />
            </div>

          </div>
        </div>

        <div style="display: flex; flex-direction: row; margin: 8px;">
          <div *ngFor="let item of dosageUnitArray; let i = index" style="display: flex; flex-direction: row;">

            <div (click)="switchDosageUnit(i)"
              [ngClass]="(unselectedDosageUnit) ? 'errorHighlight' : (item.selected) ?  'highlightedBox' :'unhighlightedBox'"
              style="margin: 0px 8px 0px 8px;
          cursor: pointer;
          padding: 8px 16px 8px 16px;
          border-radius: 50px;
          text-align: center;
          ">{{item.name}}</div>
          </div>
        </div>

        <div style="display: flex; flex-direction: row; margin: 0px 0px 0px 8px; flex-wrap: wrap;">
          <div (ngModelChange)="onDaysChange($event)" *ngFor="let item of dosageTypeArray; let i = index"
            style="display: flex; flex-direction: row; ">
            <div (click)="switchDosageType(i)"
              [ngClass]=" (unselectedDosageType) ? 'errorHighlight' : (item.selected) ?  'highlightedBox' :'unhighlightedBox'"
              style="margin: 8px ;
          cursor: pointer;
          padding: 8px;
          border-radius: 6px;
          text-align: center;
         
          ">{{item.name}}</div>
          </div>
        </div>
      </div>




      <!-- new section -->
      <div style=" height: 100px; width: 100%; display: flex; flex-direction: row;">
        <div style="display:flex; width: 50%; height: 100%;">
          <div style="display: flex; flex-direction: row; align-items: center;">
            <span style="  
                color: grey;
                font-size: 30px;
                padding: 8px;">×</span>
            <textarea (ngModelChange)="onDaysChange($event)" [(ngModel)]="days" maxlength="3" style="width: 60px"
              [ngClass]="(unselectedDays) ? 'errorBorder' : null"
              class="textarea-small-custom">{{selectedTimeType}}</textarea>



            <div ngbDropdown placement="top-right" class="d-inline-block">
              <button class="btn btn-outline-primary" id="dropdownBasic1" style="border: 0px;
                border-bottom: 1px solid #ccc;
                padding-right: 0px;
                color: #333;
                background-color: #fff;
                " ngbDropdownToggle>{{selectedTimeType}} </button>

              <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <button (click)="onDropdownSelectTime(item)" *ngFor="let item of timeTypeArr; let i = index"
                  ngbDropdownItem>{{item}}</button>
              </div>
            </div>
          </div>
        </div>

        <div style="display:flex;  width: 50%; height: 100%; align-items: center; justify-content: flex-end;">
          <div (click)="addDose(0)" class="btn-type-border" style="width: 140px; height: 40px; margin-right: 16px;">+Add
            Dose</div>
        </div>
      </div>
      <!-- here -->
      <div
        style="width: 100%; display: flex; flex-direction: row; align-items: center; justify-content: space-between;">
        <div [ngClass]="true?  'highlightedText' :'unhighlightedText'" style="
        cursor: pointer;
        padding: 0px 6px 0px 6px;
        margin-left: 12px;
        font-size: 16px;
        text-align: flex-start;
        ">Dose Preview</div>

        <a (click)="clearDosePreview()" style="margin-right: 16px; color: #F57F7F;">Clear</a>
      </div>


      <div style="
      resize: none;
        border: 1px dashed #ccc;
        border-radius: 6px;
        background-color: #F3F6FA;
        width: 95%;
        margin-top: 10px;
        margin-bottom: 10px;
        align-self: center;
        height: 80px;
        padding: 8px;
        outline: none;
        color: #707070 !important;
        font-size: 16px;
        overflow: scroll;
      ">
        <div *ngFor="let element of dosePreviewArray; let i = index"
          style="display: flex; flex-direction: row; justify-content: space-between; align-items: center; margin: 8px 0px 8px 0px;">
          <div>
            <!-- <div *ngIf="element.medicineName" style="color: #333; font-weight: bold;">{{i+1}}.
              {{element.medicineName}}
            </div> -->
            <div style="color: grey; font-size: 14px;"> {{element.dosageArr.join(', ')}} </div>
            <div style="color: grey; font-size: 14px;"> {{element.instructions}} </div>
          </div>

          <div style="display: flex; flex-direction: row;">
            <!-- <img style="margin: 0px 8px 0px 8px" src="assets/images/edit.svg" /> -->
            <!-- <img (click)="removeMedicineFromPreview(i)" style="margin: 0px 8px 0px 8px"
              src="assets/images/delete.svg" /> -->
          </div>

        </div>
      </div>


      <div>
        <div style="
           cursor: pointer;
          padding: 6px 0px 0px 16px;
          font-size: 16px;
          font-weight: bold;
            ">When to take
        </div>

        <div style="display: flex; flex-direction: row; margin: 0px 0px 0px 8px; flex-wrap: wrap;">
          <div *ngFor="let item of whenToTakeArray; let i = index">
            <div (click)="switchWhenToTake(i)" [ngClass]="(item.selected) ?  'highlightedBox' :'unhighlightedBox'"
              style="margin: 8px;
          cursor: pointer;
          padding: 8px;
          border-radius: 6px;
          text-align: center;
         
          ">{{item.name}}</div>
          </div>
        </div>
      </div>


      <div>
        <div style="display: flex; flex-direction: row; margin-left: 10px; ">

          <div style="
        cursor: pointer;
       padding: 6px 0px 0px 16px;
       font-size: 16px;
       font-weight: bold;
         ">Instructions
          </div>
          <div (click)="open(medicine_instructions_modal, 'medicine_instructions_modal')" class="action-button">+Add
          </div>
          <!-- here -->
        </div>

        <div style="display:flex; justify-content: center; align-items: center;">
          <textarea name="Text1" cols="40" rows="10" class="textarea-small-style"
            [(ngModel)]="medicine_instructions">{{medicine_instructions}}</textarea>
        </div>
      </div>



      <div
        style="display:flex; flex-direction: row; justify-content: space-between; margin-bottom: 16px; margin-top: 6px; align-items: center;">

        <!-- <div style="display: flex; flex-direction: row; align-items: center;">
          <span style="  
              color: grey;
              font-size: 30px;
              padding: 8px;">×</span>
          <textarea (ngModelChange)="onDaysChange($event)" [(ngModel)]="days" maxlength="3" style="width: 60px"
            [ngClass]="(unselectedDays) ? 'errorBorder' : null" class="textarea-small-custom">{{days}}</textarea>



          <div ngbDropdown placement="top-right" class="d-inline-block">
            <button class="btn btn-outline-primary" id="dropdownBasic1" style="border: 0px;
              border-bottom: 1px solid #ccc;
              padding-right: 0px;
              color: #333;
              background-color: #fff;
              " ngbDropdownToggle>{{selectedTimeType}} </button>

            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <button (click)="onDropdownSelectTime(item)" *ngFor="let item of timeTypeArr; let i = index"
                ngbDropdownItem>{{item}}</button>
            </div>
          </div>
        </div> -->

        <div
          style="flex-direction: row; display: flex; width: 100%; align-items: center; justify-content: space-between">
          <a (click)="clearAllSelection()" class="btn-type-border-red"
            style="width: 140px;color: #F57F7F; text-align: center; font-weight: bold; margin-left: 16px;">Clear</a>
          <!-- <div (click)="addDose(0)" class="btn-type-border" style="width: 140px;">+Add Dose</div> -->
          <div (click)="addToPrescription()" class="btn-type-filled" style="margin-right: 16px; width: 180px;">Add to
            Prescription</div>
        </div>
      </div>

    </div>
  </div>



  <div style=" width: 50%; height: 100%; display: flex; flex-direction: column;">

    <!-- General Instructions Tab -->
    <div name="card" class="card-container">
      <div style="display: flex; flex-direction: row; margin-left: 10px; ">
        <div *ngFor="let titles of generalCardArray; let i = index" style="display: flex; flex-direction: column;">
          <div (click)="switchGeneralTabs(i)" [ngClass]="(titles.selected) ?  'highlightedTextTab' :'unhighlightedText'"
            style="
            cursor: pointer;
            padding: 10px 6px 0px 6px;
            font-size: 16px;
            text-align: center;
            ">{{titles.name}}</div>
          <div [ngClass]="(titles.selected) ?  'selected-tab' :'unselected-tab'"></div>
        </div>

        <div *ngIf="selectedTabGeneral=='Gen. Instructions'"
          (click)="open(general_instructions_modal, 'general_instructions_modal')" class="action-button">+Add</div>

        <div *ngIf="selectedTabGeneral=='Dietary Advice'" (click)="open(dietary_advices_modal, 'dietary_advices_modal')"
          class="action-button">+Add</div>

      </div>

      <textarea *ngIf="selectedTabGeneral == 'Gen. Instructions'" [(ngModel)]="general_instructions" cols="40" rows="10"
        class="textarea-style">{{general_instructions}}</textarea>

      <textarea *ngIf="selectedTabGeneral == 'Dietary Advice'" [(ngModel)]="dietary_advices" cols="40" rows="10"
        class="textarea-style">{{dietary_advices}}</textarea>
    </div>


    <!-- Investigation Tab -->
    <!-- <div name="card" class="card-container">

      <div style="display: flex; flex-direction: row; margin-left: 10px; ">
        <div *ngFor="let titles of investigationCardArray; let i = index" style="display: flex; flex-direction: row;">
          <div (click)="switchInvestigationTabs(i)"
            [ngClass]="(titles.selected) ?  'highlightedText' :'unhighlightedText'" style="
            cursor: pointer;
            padding: 10px 6px 0px 6px;
            font-size: 16px;
            text-align: center;
            ">{{titles.name}}</div>
        </div>
        <div (click)="open(investigations_modal, 'investigations_modal')" class="action-button">+Add
        </div>

      </div>

      <textarea *ngIf="selectedTabInvestigation == 'Investigations'" [(ngModel)]="investigations" cols="40" rows="10"
        class="textarea-small-style">{{investigations}}</textarea>

      <textarea *ngIf="selectedTabInvestigation == 'Referral'" [(ngModel)]="referral" cols="40" rows="10"
        class="textarea-small-style">{{referral}}</textarea>
    </div> -->

    <!-- Prescription Preview -->
    <div name="card" class="card-container">

      <div style="display: flex; flex-direction: row; margin-left: 10px; ">
        <div style="display: flex; flex-direction: row;">
          <div [ngClass]="true?  'highlightedText' :'unhighlightedText'" style="
            cursor: pointer;
            padding: 10px 6px 0px 6px;
            font-size: 16px;
            text-align: center;
            ">Prescription Preview</div>
        </div>
        <div (click)="open(previous_medicines_fav, 'previous_medicines_fav'); closefavper()" class="action-button">
          Favourite
          Medicines</div>
        <div (click)="open(previous_medicines_modal, 'previous_medicines_modal')" class="action-button">Previous
          Medicines</div>

      </div>
      <div style="
      resize: none;
        border: 1px dashed #ccc;
        border-radius: 6px;
        background-color: #F3F6FA;
        width: 95%;
        margin-top: 10px;
        margin-bottom: 10px;
        align-self: center;
        height: 260px;
        padding: 8px;
        outline: none;
        color: #707070 !important;
        font-size: 16px;
        overflow: scroll;
      ">
        <div *ngFor="let element of prescriptionPreviewArray; let i = index"
          style="display: flex; flex-direction: row; justify-content: space-between; align-items: center; margin: 8px 0px 8px 0px;">
          <div>
            <div *ngIf="element.medicineName" style="color: #333; font-weight: bold;">{{i+1}}.
              {{element.medicineName}}
            </div>
            <div style="color: grey; font-size: 14px;"> {{element.dosage2}} </div>
            <div style="color: grey; font-size: 14px;"> {{element.instructions}} </div>
            <div style="color: grey; font-size: 14px;"> {{element.duration2}} </div>
          </div>

          <div style="display: flex; flex-direction: row;">
            <!-- <img style="margin: 0px 8px 0px 8px" src="assets/images/edit.svg" /> -->
            <img (click)="removeMedicineFromPreview(i)" style="margin: 0px 8px 0px 8px"
              src="assets/images/delete.svg" />
          </div>

        </div>


      </div>
    </div>


    <!-- Next Followup Tab -->
    <div name="card" class="card-container">

      <div style="display: flex; flex-direction: row; margin-left: 10px; ">
        <div *ngFor="let titles of nextFollowUpCardArray; let i = index" style="display: flex; flex-direction: row;">
          <div (click)="switchFollowupTab(i)" [ngClass]="(titles.selected) ?  'highlightedText' :'unhighlightedText'"
            style="
            cursor: pointer;
            padding: 10px 6px 0px 6px;
            font-size: 16px;
            text-align: center;
            ">{{titles.name}}</div>
        </div>

        <div style="flex: 1; display: flex;"></div>

        <div *ngIf="selectedTabFollowup == 'Next Followup'" style="display:flex; flex-direction: row; ">
          <input style="visibility: hidden;" type="text" ngbDatepicker #d="ngbDatepicker" placement="top"
            (dateSelect)="onDateSelect($event, 1)" />
          <div (click)="d.toggle()" class="action-button">Select Date</div>
        </div>

        <div *ngIf="selectedTabFollowup == 'Prescription Expiry'" style="display:flex; flex-direction: row; ">
          <input style="visibility: hidden;" type="text" ngbDatepicker #d="ngbDatepicker" placement="top"
            (dateSelect)="onDateSelect($event, 2)" />
          <div (click)="d.toggle()" class="action-button">Select Date</div>
        </div>

      </div>

      <textarea *ngIf="selectedTabFollowup == 'Next Followup'" [(ngModel)]="nextFollowUp" cols="40" rows="10"
        class="textarea-small-style">{{nextFollowUp}}</textarea>

      <textarea *ngIf="selectedTabFollowup == 'Prescription Expiry'" [(ngModel)]="prescriptionExpirationDate" cols="40"
        rows="10" class="textarea-small-style">{{prescriptionExpirationDate}}</textarea>

      <div style="display: flex; flex-direction: row; margin: 6px; justify-content: flex-end;">
        <div *ngFor="let item of followupArray; let i = index" style="display: flex; flex-direction: row;">
          <div (click)="switchFollowup(i)" [ngClass]="(item.selected) ?  'highlightedBox' :'unhighlightedBox'" style="margin: 0px 8px 0px 8px;
        cursor: pointer;
        padding: 8px 16px 8px 16px;
        border-radius: 6px;
        text-align: center;
       
        ">{{item.name}}</div>
        </div>
      </div>
      <!-- <div style="display: flex; flex-direction: row;">
        <div>Call</div>
        <div>Report</div>
        <div>Appointment</div>
      </div> -->
    </div>

  </div>

</div>

<!-- Complaints Modal -->
<ng-template #complaints_modal let-modal>
  <div class="modal-header" style="border-bottom: 0px;">
    <h4 class="modal-title" id="modal-basic-title">Complaints</h4>
    <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
      (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>

      <div class="form-group">
        <div class="input-group">
          <input id="searchbox" (input)="onSearchChange($event.target.value, 'complaints')" style="border-radius:6px; padding: 0px 8px 0px 8px; height: 40px; margin-top: -20px;
          font-size: 16px;" class="form-control" placeholder="Start typing to search" name="sb">
        </div>
        <div style="height: 80vh; overflow: scroll;">
          <div
            style="display: flex; flex-direction: row; flex-wrap: wrap; margin-top: 16px; margin-bottom: 16px; width: 45vw;   ">

            <div *ngFor="let item of allComplaintsArray; let i = index" (click)="selectModalValue(i, 'complaints')"
              class="searchable-component-style">
              <img *ngIf="item.selected  != true" style="margin: 0px 8px 0px 0px" src="assets/images/tick_mark_grey.svg"
                class="tick-icon" />

              <img *ngIf="item.selected" style="margin: 0px 8px 0px 0px" src="assets/images/tick_mark_color.svg"
                class="tick-icon" />
              <div>{{item.name}}</div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div (click)="saveModalValues('complaints'); modal.dismiss()" class="btn-type-filled">Add Complaints</div>
  </div>
</ng-template>

<!-- Working Diagnosis Modal -->
<ng-template #working_diagnosis_modal let-modal>
  <div class="modal-header" style="border-bottom: 0px;">
    <h4 class="modal-title" id="modal-basic-title">Working Diagnosis</h4>
    <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
      (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>

      <div class="form-group">
        <div class="input-group">
          <input id="searchbox" (input)="onSearchChange($event.target.value, 'diagnosis')" style="border-radius:6px; padding: 0px 8px 0px 8px; height: 40px; margin-top: -20px;
          font-size: 16px;" class="form-control" placeholder="Start typing to search" name="dp">
        </div>
        <div style="height: 80vh; overflow: scroll;">
          <div
            style="display: flex; flex-direction: row; flex-wrap: wrap; margin-top: 16px; margin-bottom: 16px; width: 45vw; ">

            <div *ngFor="let item of allDiagnosisArray; let i = index" (click)="selectModalValue(i, 'diagnosis')"
              class="searchable-component-style">
              <img *ngIf="item.selected  != true" style="margin: 0px 8px 0px 0px" src="assets/images/tick_mark_grey.svg"
                class="tick-icon" />

              <img *ngIf="item.selected" style="margin: 0px 8px 0px 0px" src="assets/images/tick_mark_color.svg"
                class="tick-icon" />
              <div>{{item.name}}</div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div (click)="saveModalValues('diagnosis'); modal.dismiss()" class="btn-type-filled">Add Working Diagnosis</div>
  </div>
</ng-template>

<!-- Medicine Instructions Modal -->
<ng-template #medicine_instructions_modal let-modal>
  <div class="modal-header" style="border-bottom: 0px;">
    <h4 class="modal-title" id="modal-basic-title">Medicine Instructions</h4>
    <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
      (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>

      <div class="form-group">
        <div class="input-group">
          <input id="searchbox" (input)="onSearchChange($event.target.value, 'medicine_instructions')" style="border-radius:6px; padding: 0px 8px 0px 8px; height: 40px; margin-top: -20px;
          font-size: 16px;" class="form-control" placeholder="Start typing to search" name="dp">
        </div>
        <div style="height: 80vh; overflow: scroll;">
          <div
            style="display: flex; flex-direction: row; flex-wrap: wrap; margin-top: 16px; margin-bottom: 16px; width: 45vw; ">

            <div *ngFor="let item of allMedicineInstructionsArray; let i = index"
              (click)="selectModalValue(i, 'medicine_instructions')" class="searchable-component-style">
              <img *ngIf="item.selected  != true" style="margin: 0px 8px 0px 0px" src="assets/images/tick_mark_grey.svg"
                class="tick-icon" />

              <img *ngIf="item.selected" style="margin: 0px 8px 0px 0px" src="assets/images/tick_mark_color.svg"
                class="tick-icon" />
              <div>{{item.name}}</div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div (click)="saveModalValues('medicine_instructions'); modal.dismiss()" class="btn-type-filled">Add Medicine
      Instructions</div>
  </div>
</ng-template>

<!-- General Instructions Modal -->
<ng-template #general_instructions_modal let-modal>
  <div class="modal-header" style="border-bottom: 0px;">
    <h4 class="modal-title" id="modal-basic-title">General Instructions</h4>
    <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
      (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>

      <div class="form-group">
        <div class="input-group">
          <input id="searchbox" (input)="onSearchChange($event.target.value, 'general_instructions')" style="border-radius:6px; padding: 0px 8px 0px 8px; height: 40px; margin-top: -20px;
          font-size: 16px;" class="form-control" placeholder="Start typing to search" name="dp">
        </div>
        <div style="height: 80vh; overflow: scroll;">
          <div
            style="display: flex; flex-direction: row; flex-wrap: wrap; margin-top: 16px; margin-bottom: 16px; width: 45vw; ">

            <div *ngFor="let item of allGeneralInstructionsArray; let i = index"
              (click)="selectModalValue(i, 'general_instructions')" class="searchable-component-style">
              <img *ngIf="item.selected  != true" style="margin: 0px 8px 0px 0px" src="assets/images/tick_mark_grey.svg"
                class="tick-icon" />

              <img *ngIf="item.selected" style="margin: 0px 8px 0px 0px" src="assets/images/tick_mark_color.svg"
                class="tick-icon" />
              <div>{{item.name}}</div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div (click)="saveModalValues('general_instructions'); modal.dismiss()" class="btn-type-filled">Add General
      Instructions</div>
  </div>
</ng-template>

<!-- Dietary Advice Modal -->
<ng-template #dietary_advices_modal let-modal>
  <div class="modal-header" style="border-bottom: 0px;">
    <h4 class="modal-title" id="modal-basic-title">Dietary Advice</h4>
    <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
      (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>

      <div class="form-group">
        <div class="input-group">
          <input id="searchbox" (input)="onSearchChange($event.target.value, 'dietary_advices')" style="border-radius:6px; padding: 0px 8px 0px 8px; height: 40px; margin-top: -20px;
          font-size: 16px;" class="form-control" placeholder="Start typing to search" name="dp">
        </div>
        <div style="height: 80vh; overflow: scroll;">
          <div
            style="display: flex; flex-direction: row; flex-wrap: wrap; margin-top: 16px; margin-bottom: 16px; width: 45vw; ">

            <div *ngFor="let item of allDietaryAdvicesArray; let i = index"
              (click)="selectModalValue(i, 'dietary_advices')" class="searchable-component-style">
              <img *ngIf="item.selected  != true" style="margin: 0px 8px 0px 0px" src="assets/images/tick_mark_grey.svg"
                class="tick-icon" />

              <img *ngIf="item.selected" style="margin: 0px 8px 0px 0px" src="assets/images/tick_mark_color.svg"
                class="tick-icon" />
              <div>{{item.name}}</div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div (click)="saveModalValues('dietary_advices'); modal.dismiss()" class="btn-type-filled">Add Dietary
      Advice</div>
  </div>
</ng-template>

<!-- Investigations Modal -->
<ng-template #investigations_modal let-modal>
  <div class="modal-header" style="border-bottom: 0px;">
    <h4 class="modal-title" id="modal-basic-title">Investigations</h4>
    <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
      (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>

      <div class="form-group">
        <div class="input-group">
          <input id="searchbox" (input)="onSearchChange($event.target.value, 'investigations')" style="border-radius:6px; padding: 0px 8px 0px 8px; height: 40px; margin-top: -20px;
          font-size: 16px;" class="form-control" placeholder="Start typing to search" name="dp">
        </div>
        <div style="height: 80vh; overflow: scroll;">
          <div
            style="display: flex; flex-direction: row; flex-wrap: wrap; margin-top: 16px; margin-bottom: 16px; width: 45vw; ">

            <div *ngFor="let item of allInvestigationsArray; let i = index"
              (click)="selectModalValue(i, 'investigations')" class="searchable-component-style">
              <img *ngIf="item.selected  != true" style="margin: 0px 8px 0px 0px" src="assets/images/tick_mark_grey.svg"
                class="tick-icon" />

              <img *ngIf="item.selected" style="margin: 0px 8px 0px 0px" src="assets/images/tick_mark_color.svg"
                class="tick-icon" />
              <div>{{item.name}}</div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div (click)="saveModalValues('investigations'); modal.dismiss()" class="btn-type-filled">Add Investigations</div>
  </div>
</ng-template>



<!-- More Medicine Type Modal -->
<ng-template #more_medicine_type_modal let-modal>
  <div class="modal-header" style="border-bottom: 0px;">
    <h4 class="modal-title" id="modal-basic-title">Select Drug Type</h4>
    <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
      (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>

      <div class="form-group">
        <div class="input-group">
          <input id="searchbox" (input)="onSearchChange($event.target.value, 'drugTypes')" style="border-radius:6px; padding: 0px 8px 0px 8px; height: 40px; margin-top: -20px;
          font-size: 16px;" class="form-control" placeholder="Start typing to search" name="dp">
        </div>
        <div style="height: 40vh; overflow: scroll;">
          <div
            style="display: flex; flex-direction: row; flex-wrap: wrap; margin-top: 16px; margin-bottom: 16px; width: 45vw; ">

            <div *ngFor="let item of drugTypes; let i = index" (click)="selectModalValue(i, 'drugTypes')"
              class="searchable-component-style">
              <img *ngIf="item.selected  != true" style="margin: 0px 8px 0px 0px" src="assets/images/tick_mark_grey.svg"
                class="tick-icon" />

              <img *ngIf="item.selected" style="margin: 0px 8px 0px 0px" src="assets/images/tick_mark_color.svg"
                class="tick-icon" />
              <div>{{item.name}}</div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div (click)="saveModalValues('drugTypes'); modal.dismiss()" class="btn-type-filled">Select Drug Type</div>
  </div>
</ng-template>
<ng-template #more_select_type_modal let-modal>
  <div class="modal-header" style="border-bottom: 0px;">
    <h4 class="modal-title" id="modal-basic-title">Select Fields to Show in Print</h4>
    <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
      (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>

      <div class="form-group">

        <!-- <div style="height: 40vh; overflow: scroll;"> -->
        <div
          style="display: flex; flex-direction: column; flex-wrap: wrap; margin-top: 16px; margin-bottom: 16px; width: 45vw; ">

          <div *ngFor="let item of selectFieldsArray; let i = index" (click)="selectModalFieldValue(item)"
            class="searchable-component-style">
            <img *ngIf="item.selected" style="margin: 0px 8px 0px 0px" src="assets/images/tick_mark_color.svg"
              class="tick-icon" />
            <img *ngIf="!item.selected" style="margin: 0px 8px 0px 0px" src="assets/images/tick_mark_grey.svg"
              class="tick-icon" />


            <div>{{item.name}}</div>
          </div>
        </div>
        <!-- </div> -->
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div (click)="modal.dismiss();" class="btn-type-filled">Ok</div>
  </div>
</ng-template>

<!-- Add New Drug Modal -->
<ng-template #add_new_drug_modal let-modal>
<div style="padding: 20px;">
  <div class="modal-header" style="border-bottom: 0px;">
    <h4 class="modal-title" id="modal-basic-title">Add New Drug</h4>
    <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
      (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>

      <div class="form-group">

        <div style="flex-direction: column;" class="input-group">

          <div style="margin-top: -20px;">
            <div style="color: grey;">Drug Name</div>
            <input [(ngModel)]="drugNameAddNewDrugs" id="searchbox"
              (input)="onSearchChange($event.target.value, 'drugTypes')" style="border-radius:6px; padding: 0px 8px 0px 8px; height: 40px; margin-top:0px;
              font-size: 16px;" class="form-control" placeholder="Enter Drug Name" name="drugName">
          </div>

          <div style="margin-top: 16px;">
            <div style="color: grey;">Drug Type</div>
            <div>
              <div (click)="open(more_medicine_type_modal, 'more_medicine_type_modal_new_drug')" style="position: absolute;
              z-index: 999;
              height: 40px;
              width: 40px;
              right: 0;
              justify-content: center;
              align-items: center;
              display: flex;
              ">
                <img style="height: 30px; width: 30px;" src="assets/images/plus_button_new.svg" />
              </div>

              <input [(ngModel)]="drugTypeAddNewDrugs" id="searchbox"
                (input)="onSearchChange($event.target.value, 'drugTypes')" style="border-radius:6px; padding: 0px 8px 0px 8px; height: 40px; margin-top:0px;
              font-size: 16px; z-index: 0;" class="form-control" placeholder="Enter Drug Type" name="drugType">

            </div>
          </div>

          <div style="margin-top: 16px;">
            <div style="color: grey;">Generic Name (Drug Composition)</div>
            <input [(ngModel)]="genericDrugNameAddNewDrugs" id="generic"
              (input)="onSearchChange($event.target.value, 'drugTypes')" style="border-radius:6px; padding: 0px 8px 0px 8px; height: 40px; margin-top:0px;
              font-size: 16px;" class="form-control" placeholder="Enter Drug Generic Name" name="genericName">
          </div>

          <div style="margin-top: 16px;">
            <div style="color: grey;">Drug Company</div>
            <input [(ngModel)]="drugCompanyAddNewDrugs" id="drugCompany"
              (input)="onSearchChange($event.target.value, 'drugTypes')" style="border-radius:6px; padding: 0px 8px 0px 8px; height: 40px; margin-top:0px;
              font-size: 16px;" class="form-control" placeholder="Enter name of Drug Company" name="drugCompany">
          </div>

        </div>

      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div (click)="saveModalValues('drugTypesAddNewDrug'); modal.dismiss()" class="btn-type-filled">Add</div>
  </div>
</div>
</ng-template>

<!-- Previous Medicines Modal -->
<ng-template #previous_medicines_modal let-modal>
  <div class="modal-header" style="border-bottom: 0px;">
    <h4 class="modal-title" id="modal-basic-title">Previously prescribed medicines</h4>
    <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
      (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">

        <div style="flex-direction: column;" class="input-group">

          <div style="margin-top: -20px; display: flex; flex-direction: column; align-items: flex-end;">
            <div style="color: grey;">Choose Date</div>

            <div ngbDropdown class="d-inline-block">
              <button class="btn btn-outline-primary" id="dropdownBasic1" style="border: 0px;
              border-bottom: 1px solid #ccc;
              padding-right: 0px;
              color: #333;
              background-color: #fff;
              " ngbDropdownToggle>{{selectedPreviousMedDate}} </button>

              <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <button (click)="onDropdownSelect(item)" *ngFor="let item of previousMedsArr; let i = index"
                  ngbDropdownItem>{{item.value}}</button>
              </div>
            </div>
          </div>

          <div *ngFor="let element of selectedPreviousMed; let i = index"
            style="display: flex; flex-direction: row; justify-content: space-between; align-items: center; margin: 10px 0px 10px 0px;">
            <div>
              <div style="color: #333; font-weight: bold;">{{i+1}}.
                {{element.Drug.product_name}}
              </div>
              <div style="color: grey; font-size: 14px;"> {{element.dosage2}}</div>
            </div>

            <div style="display: flex; flex-direction: row;">

            </div>

          </div>







        </div>

      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div (click)="saveModalValues('copyMedicines'); modal.dismiss()" class="btn-type-filled">Copy Medicines</div>
  </div>
</ng-template>
<ng-template #previous_medicines_fav let-modal>
  <div class="modal-header" style="border-bottom: 0px;">
    <h4 class="modal-title" id="modal-basic-title">Favourite Medicines</h4>
    <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
      (click)="modal.dismiss('Cross click');">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <form>
      <div class="form-group">

        <div style="flex-direction: column;" class="input-group">

          <div style="margin-top: -20px; display: flex; flex-direction: column; align-items: flex-end;">
            <div style="color: grey;">Choose Favourite Medicines</div>

            <div ngbDropdown class="d-inline-block">

              <button class="btn btn-outline-primary" id="dropdownBasic1" style="border: 0px;
              border-bottom: 1px solid #ccc;
              padding-right: 0px;
              color: #333;
              background-color: #fff;
              " ngbDropdownToggle [ngValue]="null">{{selectedPreviousFavMed}} </button>

              <div ngbDropdownMenu aria-labelledby="dropdownBasic1" style="margin-left: -59px;">
                <button (click)="onDropdownSelectFavMed(item)" *ngFor="let item of favArry; let i = index"
                  ngbDropdownItem>{{item.value}}</button>
              </div>
            </div>

          </div>
          <div style="height: 40vh; overflow: scroll;">
            <div *ngFor="let element of selectedFavMed; let i = index"
              style="display: flex; flex-direction: row; justify-content: space-between; align-items: center; margin: 10px 0px 10px 0px;">
              <div>
                <div style="color: #333; font-weight: bold;">{{i+1}}.
                  {{element.Drug.product_name}}
                </div>
                <div style="color: grey; font-size: 14px;"> {{element.dosage2}}</div>
              </div>

            </div>

          </div>


        </div>
        <!-- <div style="color: #333; font-weight: bold;">Chief_complaints
           
          <div style="display: flex; flex-direction: row; color: grey;">
            {{chief_complaints}}
          </div>
        </div> -->
        <!-- <div style="color: #333; font-weight: bold;">General_instructions
           
          <div style="display: flex; flex-direction: row; color: grey;">
            {{general_instructions}}
          </div>
        </div> -->

      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div style="display: flex; flex-direction: row; justify-content: space-between; flex: 1; ">
      <div (click)="saveModalValues('copyMedicinesFav'); modal.dismiss()" class="btn-type-filled">Copy Medicines</div>

      <div (click)=" open(addDeleteModal,'favArry');onClickDeleteFavMed(favArry);" style="  margin: 0px 8px 0px 8px;
    cursor: pointer;
    padding: 8px 16px 8px 16px;
    border-radius: 6px;
    background-color: #ff0000;
    color: #FFF;
    border-width: 0px;
    text-align: center;
    font-weight: bold;
    height: 40px;">Delete </div>


    </div>
  </div>
</ng-template>

<ng-template #addDeleteModal let-modal>
  <div class="modal-header" style="border-bottom: 0px;">
    <h4 class="modal-title" id="modal-basic-title">Delete Record</h4>
    <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
      (click)="modal.dismiss('Cross click');">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div style="font-size: 16px;">
      Are you sure you want to delete the selected records?
    </div>


    <!-- <div *ngIf="requisition.doctorId===currentDoctorObj.id" class="rightSideTextOnCard"(click)="onClickRequisitionDelete(requisition)" style="color: #ff0000;">Delete</div>    -->

  </div>
  <!-- <div (click)="printRequisitions('preview');" class="btn-type-filled" style="margin: 0px 8px 0px 8px;
      cursor: pointer;
      padding: 8px 16px 8px 16px;
      border-radius: 6px;
      background-color: #4687BF;
      color: #FFF;
      border-width: 0px;
      text-align: center;
      font-weight: bold;
      height: 40px">ok</div> -->


  <div class="modal-footer">

    <button type="button" style="
      border-color: #6c757d; color:#6c757d; border-radius: 4px;" class="btn btn-outline-secondary"
      (click)="modal.dismiss('addDeleteModal')">Cancel</button>
    <button type="button" style="background-color: #ff0000; color: '#fff'; border-radius: 4px;" class="btn"
      (click)=" onClickDeleteFavMed();modal.dismiss('addDeleteModal')">Delete</button>


  </div>
</ng-template>
<p-toast position="top-right" [baseZIndex]="5000">
  <ng-template let-message pTemplate="message">
    <div class=" p-flex p-flex-column"
      style="flex: 1; background-color:#BAE8FC; opacity: 0.95; display: flex; flex-direction: row; align-items: center; border-left: 8px solid #0D8CCD; border-radius: 8px;top:67px ;">
      <div>
        <!-- <img (click)="goBackpage()" style="height: 36px; margin-left: 12px; width: 36px; align-self: center;"
                  src="assets/images/exclamation-circle-blue.svg" alt="" /> -->
      </div>
      <div class=" p-text-center">
        <div style="font-size: 18px;
                  font-weight: bold;
                  padding: 12px 0px 0px 12px;
                  
                  color: #034A70;">{{message.summary}}</div>
        <div style="padding-left: 12px; padding-bottom: 12px; color: #034A70; font-size: 16px; margin-top: -6px;">
          {{message.detail}}</div>
      </div>
    </div>
  </ng-template>
</p-toast>
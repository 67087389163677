<div class="container-ms">
    <div class="headerSection">
        <div style="flex: 1.5; display: flex; flex-direction: column; width: 100%;">
            <div class="heading" style="margin: 8px 22px 0px 2px">Immunization / Vaccination Schedule</div>
            <a href="https://indianpediatrics.net/epub102020/RECOMM-00258.pdf" target="_blank" class="subheading">Tap to
                view IAP Guidelines 2020-2021</a>
        </div>

        <div
            style="display: flex; flex: 1; align-items: center; justify-content: flex-end; position: relative; padding:0px 8px;">

            <div style="display: flex; flex-direction: row;">
                <div class="info-card-container" *ngFor="let element of patientInfoArray">
                    <span class="info-title">{{element.key}}</span>
                    <span class="info-subtitle">{{element.value}}</span>
                </div>
            </div>

            <!-- <button (click)="openNewSpecialVaccine();" class="btn btn-secondary" style="margin-top: 6px;">+ Special Vaccines</button>
            <button (click)="navigateToCertificate();" class="btn" style="margin-top: 6px;">Certificate</button> -->
        </div>
    </div>
    <div style="display: flex; flex-direction: row; justify-content: flex-end; width: 100%;">
        <!-- <button (click)="openNewSpecialVaccine();" class="btn btn-secondary" style="margin-top: 6px;">+ Special
            Vaccines</button> -->
            <button (click)="openDialog(resetVaccinationModal,0);getDob();" class="btn btn-secondary"
            style="margin-top: 6px; display: flex; justify-content: center; align-items: center;">

            <mat-icon [ngClass]="'blue-icon'">
                inventory
            </mat-icon>

            <span style="margin-left: 4px;">Reset Vaccine</span>
        </button>

            <button (click)="openVaccineInventory();" class="btn btn-secondary"
            style="margin-top: 6px; display: flex; justify-content: center; align-items: center;">

            <mat-icon [ngClass]="'blue-icon'">
                inventory
            </mat-icon>

            <span style="margin-left: 4px;">Vaccine Inventory</span>
        </button>

        <button (click)="openNewSpecialVaccine();" class="btn btn-secondary"
            style="margin-top: 6px; display: flex; justify-content: center; align-items: center;">

            <mat-icon [ngClass]="'blue-icon'">
                vaccines
            </mat-icon>

            <span style="margin-left: 4px;">Special Vaccines</span>
        </button>

        <button (click)="openBulkVaccineUpdate();" class="btn btn-secondary"
            style="margin-top: 6px; display: flex; justify-content: center; align-items: center;">

            <mat-icon [ngClass]="'blue-icon'">
                cloud_upload
            </mat-icon>

            <span style="margin-left: 4px;">Bulk Update</span>
        </button>

        <button (click)="navigateToCertificate();" class="btn btn-secondary"
            style="margin-top: 6px; display: flex; justify-content: center; align-items: center;">
            <mat-icon [ngClass]="'blue-icon'">
                card_membership
            </mat-icon>

            <span style="margin-left: 4px;">Certificate</span>
        </button>
    </div>
    <mat-tab-group #tabGroup  animationDuration="0ms" class="remove-border-bottom" style="width: 100%;">
        <mat-tab>
            <ng-template mat-tab-label>
              Vaccination List
            </ng-template>

            <div style="height: 88vh; width: 100%;">
                <!-- MilestoneByType : All -->
                <div style="height: 80vh; width: 100%;">
                    <ng-container [ngTemplateOutlet]="renderImmunization"
                        [ngTemplateOutletContext]="{value:vaccineScheduleListWithVaccinedata}">
                    </ng-container>
                </div>
                <!-- MilestoneByType : All -->Î
            </div>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
           Immunization-Govt
            </ng-template>
            <div style="height: 88vh; width: 100%;">
                <!-- MilestoneByType : All -->
                <div style="height: 80vh; width: 100%;">
                    <ng-container [ngTemplateOutlet]="renderGovtImmunization"
                        [ngTemplateOutletContext]="{value:govtVaccineList}">
                    </ng-container>
                </div>
                <!-- MilestoneByType : All -->Î
            </div>
            </mat-tab>
    </mat-tab-group>

    <!-- <div style="height: 88vh; width: 100%;">
        <div style="height: 80vh; width: 100%;">
            <ng-container [ngTemplateOutlet]="renderImmunization"
                [ngTemplateOutletContext]="{value:vaccineScheduleListWithVaccinedata}">
            </ng-container>
        </div>
    </div> -->

<!-- GOVTvACC-->
<ng-template #renderGovtImmunization let-value="value">
    <table mat-table [dataSource]="value" style="width: 100%; margin-top: 8px;">
        <ng-container>
            <!-- VISIT -->
            <ng-container matColumnDef="visit">
                <th mat-header-cell *matHeaderCellDef> Visit </th>
                <td mat-cell *matCellDef="let element"
                    style="text-align: center !important; width:17%; font-weight: bold;"
                    [ngStyle]="{'background-color': (element.visitName == 'Special Vaccines') ? '#d1ecf157' : element.indexValue=='even' ? '#eee' : '#fff' }">
                    <div style="display: flex; flex-direction: row;">
                        <!-- <div *ngIf="element.vaccineVisit !=''"
                            style="height: 25px; width: 8px; background-color:  #88C71F; border-radius: 20px; margin-right: 6px;"
                            [ngStyle]="{'background-color': (!element.administeredStatus && element.isPending) ? '#FFDB41' : element.administeredStatus ? '#88C71F' : '#ccc' }"
                            ></div> -->
                        <span>{{element.vaccineVisit}}</span>
                    </div>
                </td>
            </ng-container>

            <!-- VACCINE -->
            <ng-container matColumnDef="vaccine">
                <th mat-header-cell *matHeaderCellDef> Vaccine Name </th>
                <td  mat-cell *matCellDef="let element"
                    style="text-align: center !important; width: 17.5% !important;"
                    [ngStyle]="{'background-color': (element.visitName == 'Special Vaccines') ? '#d1ecf157' :  element.indexValue=='even' ? '#eee' : '#fff' }">
                    {{element.vaccinationName}}

                </td>
            </ng-container>

            <!-- RECEIVED DATE -->
            <ng-container matColumnDef="receivedDate">
                <th mat-header-cell *matHeaderCellDef> Received Date </th>
                <td  mat-cell *matCellDef="let element" style="width: 10%; text-align: center !important;"
                    [ngStyle]="{'background-color': (element.visitName == 'Special Vaccines') ? '#d1ecf157' :  element.indexValue=='even' ? '#eee' : '#fff' }">
                    <span style="font-size: 18px; " >{{element.administeredDate |
                        date:"dd-MM-YYYY"}}</span>
                </td>
            </ng-container>

            <!-- BRAND -->
          

            <!-- ACTION   -->
          

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsGOVT; sticky:true" [ngClass]="{accent:true}"></tr>
        <tr id="{{i}}" mat-row *matRowDef="let row;  let i = index; columns: displayedColumnsGOVT;"></tr>

    </table>
</ng-template>

    <!-- RESUSABLE TABLE -->
    <ng-template #renderImmunization let-value="value">
        <table mat-table [dataSource]="value" style="width: 100%; margin-top: 8px;">
            <ng-container>
                <!-- VISIT -->
                <ng-container matColumnDef="visit">
                    <th mat-header-cell *matHeaderCellDef> Visit </th>
                    <td mat-cell *matCellDef="let element"
                        style="text-align: start !important; width:17%; font-weight: bold;"
                        [ngStyle]="{'background-color': (element.visitName == 'Special Vaccines') ? '#d1ecf157' : element.indexValue=='even' ? '#eee' : '#fff' }" style="color: #36404D;font-size: 18px; font-weight: bold;">
                        <div style="display: flex; flex-direction: row;">
                            <!-- <div *ngIf="element.vaccineVisit !=''"
                                style="height: 25px; width: 8px; background-color:  #88C71F; border-radius: 20px; margin-right: 6px;"
                                [ngStyle]="{'background-color': (!element.administeredStatus && element.isPending) ? '#FFDB41' : element.administeredStatus ? '#88C71F' : '#ccc' }"
                                ></div> -->
                            <span>{{element.vaccineVisit}}</span>
                        </div>
                    </td>
                </ng-container>

                <!-- VACCINE -->
                <ng-container matColumnDef="vaccine">
                    <th mat-header-cell *matHeaderCellDef> Vaccine Name </th>
                    <td mat-cell *matCellDef="let element"
                        style="text-align: start !important; width: 17.5% !important; color: #36404D;font-size: 18px; font-family: Mukta;"
                        [ngStyle]="{'background-color': (element.visitName == 'Special Vaccines') ? '#d1ecf157' :  element.indexValue=='even' ? '#eee' : '#fff' }" >
                        {{element.name}}&nbsp;&nbsp; <span style="color: #eda92b;" *ngIf="element.notes=='GOVT' && element.administeredStatus">[{{element.notes}}]</span>

                    </td>
                </ng-container>

                <!-- RECOMMENDED DATE -->
                <ng-container matColumnDef="recommendedDate">
                    <th mat-header-cell *matHeaderCellDef> Recommended Date </th>
                    <td mat-cell *matCellDef="let element" style="width: 10% !important;"
                        [ngStyle]="{'background-color': (element.visitName == 'Special Vaccines') ? '#d1ecf157' :  element.indexValue=='even' ? '#eee' : '#fff' }">
                        {{element.dueDate | date:"dd-MM-YYYY"}}

                    </td>
                </ng-container>

                <!-- CATCHUP DATE -->
                <ng-container matColumnDef="catchupDate">
                    <th mat-header-cell *matHeaderCellDef> Catchup Date </th>
                    <td mat-cell *matCellDef="let element" style="width: 10% !important;"
                        [ngStyle]="{'background-color': (element.visitName == 'Special Vaccines') ? '#d1ecf157' :  element.indexValue=='even' ? '#eee' : '#fff' }">
                        {{element.catchupDate}}
                    </td>
                </ng-container>

                <!-- STATUS -->
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef> Status </th>
                    <td mat-cell *matCellDef="let element" style="width: 8.5%"
                        [ngStyle]="{'background-color': (element.visitName == 'Special Vaccines') ? '#d1ecf157' :  element.indexValue=='even' ? '#eee' : '#fff' }">
                        <!-- Overdue -->
                        <div *ngIf="element.isOverdue"
                            style="background-color:#F8D7DA; color: #721C23; text-align: center; margin: 0px 12px; font-size: 17px; font-weight: bold;">
                            Overdue
                        </div>
                        <!-- Completed -->
                        <div *ngIf="element.administeredStatus"
                            style="background-color:#D4EDDA; color: #165724; text-align: center; margin: 0px 12px;  font-size: 17px; font-weight: bold;">
                            Taken
                        </div>
                        <!-- Due -->
                        <div *ngIf="!element.administeredStatus && element.isPending && !element.isOverdue"
                            style="background-color:#FFF3CD; color: #8B6A0F; text-align: center; margin: 0px 12px; font-size: 17px; font-weight: bold;">
                            Due
                        </div>
                        <!-- Upcoming -->
                        <div *ngIf="!element.isPending && !element.administeredStatus"
                            style="background-color:#E2E3E5; color: #383D42; text-align: center; margin: 0px 12px; font-size: 17px; font-weight: bold;">
                            Upcoming
                        </div>
                    </td>
                </ng-container>

                <!-- RECEIVED DATE -->
                <ng-container matColumnDef="receivedDate">
                    <th mat-header-cell *matHeaderCellDef> Received Date </th>
                    <td mat-cell *matCellDef="let element" style="width: 10%; text-align: center !important;"
                        [ngStyle]="{'background-color': (element.visitName == 'Special Vaccines') ? '#d1ecf157' :  element.indexValue=='even' ? '#eee' : '#fff' }">
                        <span style="font-size: 18px; " *ngIf="!element.edit"> {{ element.administeredDate }}</span>
                    </td>
                </ng-container>

                <!-- BRAND -->
                <ng-container matColumnDef="brand">
                    <th mat-header-cell *matHeaderCellDef> Brand </th>
                    <td mat-cell *matCellDef="let element" style="width: 14.5%; text-align: start !important;"
                        [ngStyle]="{'background-color': (element.visitName == 'Special Vaccines') ? '#d1ecf157' :  element.indexValue=='even' ? '#eee' : '#fff' }">
                        <span style="font-size: 17px;" *ngIf="!element.edit">{{element.brandvaccine ?
                            element.brandvaccine : element.administeredStatus ? 'NA' : ''}}</span>
                    </td>
                </ng-container>

                <!-- ACTION   -->
                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef> Action </th>
                    <td mat-cell *matCellDef="let element;" style="width: 10.5% !important; text-align: center;"
                        [ngStyle]="{'background-color': (element.visitName == 'Special Vaccines') ? '#d1ecf157' : element.indexValue=='even' ? '#eee' : '#fff' }">
                        <div *ngIf="element.visitName != 'Special Vaccines'" class="actionBtn"
                            (click)="setSelectedVaccinationRecord(element);">
                            {{element.administeredStatus ? "View" : "Record"}}
                        </div>

                        <div *ngIf="element.visitName == 'Special Vaccines'" class="actionBtn"
                            (click)="setSelectedSpecialVaccinationRecord(element);">
                            {{element.administeredStatus ? "View" : "Record"}}
                        </div>
                    </td>
                </ng-container>

            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true" [ngClass]="{accent:true}"></tr>
            <tr id="{{i}}" mat-row *matRowDef="let row;  let i = index; columns: displayedColumns;"></tr>

        </table>
    </ng-template>


    <ng-template #previewVaccineModal let-modal>
    <div style="padding: 20px;">
        <div style="display: flex;flex-direction: column;">
            <div style="display: flex; flex-direction: row; justify-content: space-between;">

                <div>
                    <div class="modalTitle">{{selectedVacVisit.name}}</div>
                    <div class="modalSubtitle">Recommended Date : {{selectedVacRecord.dueDate}}</div>
                </div>

                <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
                    (click)="closeDialog();">
                    <span aria-hidden="true" style="font-size: 40px;color: #000;" (click)="closeDialog()">&times;</span>
                </button>
            </div>

        </div>

        <div style="margin-top: 20px; width: 50vw; height: 90vh; display: flex; flex-direction: column;">
            <div style="font-size: 16px; color: grey;">Vaccines</div>
            <div style="display: flex; flex-direction: row; flex-wrap: wrap;">
                <div *ngFor="let record of selectedVacVisit.data; let idx = index" (click)="selectVaccine(idx, record)"
                    style="cursor: pointer; display: flex; flex-direction: row; flex-wrap: wrap; font-size: 18px; color: #333; background-color: #F9FBFF; 
                     border-radius: 4px; padding: 8px; width: fit-content; margin: 4px;"
                    [ngClass]="selectedVaccIndex == idx ? 'selected-vaccine' : 'unselected-vaccine'"
                    [ngStyle]="{'background-color': (record.covers) ? '#E5F8F5' : '#ffffff'}">
                    <mat-icon
                        [ngClass]="record.administeredStatus ? 'green-icon' : !record.administeredStatus && selectedVacIndex == idx ? 'blue-icon' : 'grey-icon'">
                        check_circle
                    </mat-icon>
                    <span style="margin-left: 6px;">{{record.name}}</span>
                </div>
            </div>
            <div style="display: flex; justify-content: space-between; margin-top: 12px;">
                <div>
                    <div style="font-size: 16px; color: grey;">Brands</div>

    <!-- Vaccine is given and brand exists -->
<div *ngIf="selectedVacVisit?.data?.[selectedVacIndex]?.brandvaccine || selectedVacVisit?.data?.[selectedVacIndex]?.administeredStatus"
style="cursor: pointer; display: flex; flex-direction: row; flex-wrap: wrap; font-size: 18px; color: #333; background-color: #F9FBFF; 
         border-radius: 4px; padding: 8px; width: fit-content; margin: 4px;">

<mat-icon class="green-icon">
    check_circle
</mat-icon>
<span style="margin-left: 6px;">{{selectedVacVisit?.data?.[selectedVacIndex]?.brandvaccine ? selectedVacVisit?.data?.[selectedVacIndex]?.brandvaccine : 'NA'}}</span>
</div>


                    <!-- Vaccine is NOT given and show select brand -->
                     <div *ngIf="!selectedVacVisit.data[selectedVacIndex].brandvaccine"
                        style="display: flex; flex-direction: row; flex-wrap: wrap;">
                        <div *ngFor="let brand of vaccineBrands; let idx =index" (click)="selectBrand(idx, brand)"
                            style="cursor: pointer; display: flex; flex-direction: row; flex-wrap: wrap; font-size: 18px; color: #333; background-color: #F9FBFF; 
                                 border-radius: 4px; padding: 8px; width: fit-content; margin: 4px;"
                            [ngClass]="selectedBrandIndex == idx ? 'selected-vaccine' : 'unselected-vaccine'"
                            [ngClass]="brand.highlighted ? 'highlighted-brand' : 'unhighlighted-brand'">
                            <mat-icon [ngClass]="selectedBrandIndex == idx ? 'blue-icon' : 'grey-icon'">
                                check_circle
                            </mat-icon>
                            <span style="margin-left: 6px;">{{brand.name}}</span>
                        </div>
                    </div> 
                </div>


                <!-- <div style="color: #333; text-align: center; width: 100px; font-weight: bold; padding: 6px; align-self: center; font-size: 16px;"
                    [ngStyle]="{'background-color': (selectedMilestone.status=='1') ? '#F8D7DA' : (selectedMilestone.status=='2') ? '#D4EDDA' : (selectedMilestone.status=='3') ? '#FFF3CD' : '#E2E3E5' }">
                    {{selectedMilestone.status == "1" ? "Overdue" : selectedMilestone.status == "2" ? "Done" :
                    selectedMilestone.status == "3" ? "Due" : "Upcoming"}}
                </div> -->
            </div>

            <div style="font-size: 16px; color: grey;">Received Date</div>
            <div style="display: flex; flex-direction: row; cursor: pointer;">

                <!-- Vaccine administered -->
                <div *ngIf="selectedVacVisit.data[selectedVacIndex].administeredStatus"
                    style="font-size: 18px; color: #000;">{{selectedDate}}</div>

                <!-- Vaccine not administered -->
                <div *ngIf="!selectedVacVisit.data[selectedVacIndex].administeredStatus">
                    <input placeholder="DD/MM/YYYY" name="dp" [(ngModel)]="receivedDate" ngbDatepicker
                        #d="ngbDatepicker" [maxDate]="maxDate" [minDate]="minDate"
                        style="font-size: 16px; color: #000; border: 0px; width: 100px; outline: none;">
                    <img (click)="d.toggle()" style="height: 30px; width: 30px;" src="assets/images/calendarIcon.svg"
                        alt="" />
                </div>
            </div>

            <div style="font-size: 16px; color: grey;">Images of Vaccine Label</div>
            <div style="display: flex; flex-direction: row;">
                <div *ngFor="let item of selectedVaccineLabels; let i = index">
                    <img style="height: 120px; width: 120px; border-radius: 8px; margin: 4px;" #myImage
                        [hidden]="myImage.isBugged" (error)="myImage.isBugged = true" src={{item.imageUrl}} alt="" />
                </div>
                <div *ngIf="!selectedVaccineLabels.length" style="font-size: 18px; color: #000;">Not Applicable</div>
            </div>

            <div style="font-size: 24px; color: #333; font-weight: bold; margin-top: 12px;">IAP Guidelines</div>

            <!-- IAP guidelines start -->
            <div style="flex: 1; overflow: scroll;">
                <div style="font-size: 16px; color: #5D86CC; font-weight: bold;">Routine</div>
                <div style="font-size: 16px; color: #000;">{{selectedVacVisit.data[selectedVacIndex].routine}}</div>

                <div style="font-size: 16px; color: #5D86CC; font-weight: bold; margin-top: 6px;">Catchup Info</div>
                <div style="font-size: 16px; color: #000;">{{selectedVacVisit.data[selectedVacIndex].catchupInfo}}</div>

                <div style="font-size: 16px; color: #5D86CC; font-weight: bold; margin-top: 6px;">Comments</div>
                <div style="font-size: 16px; color: #000;">{{selectedVacVisit.data[selectedVacIndex].comments}}</div>
            </div>
            <!-- IAP guidelines end -->

            <div style="padding-top: 16px; display: flex; justify-content: flex-end;">
                <button (click)="closeDialog()" class="cancelBtn">Cancel</button>
                <button class="submitBtn"
                    *ngIf="receivedDate!='' && selectedBrandIndex!=null &&selectedBrand !='' && selectedVacVisit.data[selectedVacIndex].dueDate"
                    (click)="markVaccineAsTaken()">Save Vaccine</button>

                <button class="submitBtnDisabled"
                    *ngIf="receivedDate=='' ||selectedBrandIndex==null || selectedBrand =='' || !selectedVacVisit.data[selectedVacIndex].dueDate">Save
                    Vaccine</button>
            </div>
        </div>
    </div>
    </ng-template>


    <ng-template #specialVaccineModal let-modal>
      <div style="padding: 20px;">
        <div style="display: flex;flex-direction: column;">
            <div style="display: flex; flex-direction: row; justify-content: space-between;">

                <div>
                    <div class="modalTitle">Special Vaccines</div>
                    <div class="modalSubtitle"></div>
                </div>

                <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
                    (click)="closeDialog();">
                    <span aria-hidden="true" style="font-size: 40px;color: #000;" (click)="closeDialog()">&times;</span>
                </button>
            </div>

        </div>

        <div style="margin-top: 20px; width: 50vw; height: 90vh; display: flex; flex-direction: column;">
            <div style="font-size: 16px; color: grey;">Vaccines</div>
            <div style="display: flex; flex-direction: row; flex-wrap: wrap;">
                <div *ngFor="let record of selectedVacVisit.data; let idx =index" (click)="selectVaccine(idx, record)"
                    style="cursor: pointer; display: flex; flex-direction: row; flex-wrap: wrap; font-size: 18px; color: #333; background-color: #F9FBFF; 
                     border-radius: 4px; padding: 8px; width: fit-content; margin: 4px;"
                    [ngClass]="selectedVacIndex == idx ? 'selected-vaccine' : 'unselected-vaccine'">
                    <mat-icon
                        [ngClass]="record.administeredStatus ? 'green-icon' : !record.administeredStatus && selectedVacIndex == idx ? 'blue-icon' : 'grey-icon'">
                        check_circle
                    </mat-icon>
                    <span style="margin-left: 6px;">{{record.name}}</span>
                </div>
            </div>

            <div style="font-size: 16px; color: grey;">Received Date</div>
            <div style="display: flex; flex-direction: row; cursor: pointer;">

                <!-- Vaccine administered -->
                <div *ngIf="selectedVacVisit.data[selectedVacIndex].administeredStatus"
                    style="font-size: 18px; color: #000;">{{selectedDate}}</div>

            </div>

            <div style="flex: 1; overflow: scroll;">
                <div style="font-size: 16px; color: grey;">Description</div>
                <div class="formatter" style="font-size: 16px; color: #000;">
                    {{selectedVacVisit.data[selectedVacIndex].description ?
                    selectedVacVisit.data[selectedVacIndex].description : 'NA'}}</div>
            </div>

            <div style="padding-top: 16px; display: flex; justify-content: flex-end;">
                <button (click)="closeDialog()" class="cancelBtn">Cancel</button>
                <button class="submitBtn" *ngIf="receivedDate!='' && selectedBrand !=''"
                    (click)="markVaccineAsTaken()">Save Vaccine</button>

                <!-- <button class="submitBtnDisabled" *ngIf="receivedDate=='' || selectedBrand ==''">Save Vaccine</button> -->
            </div>
        </div>
      </div>
    </ng-template>


    <ng-template #newSpecialVaccineModal let-modal>
     <div style="width: 100%; padding: 20px;">
        <div style="display: flex;flex-direction: column;">
            <div style="display: flex; flex-direction: row; justify-content: space-between;">

                <div>
                    <div class="modalTitle">Special Situation Vaccines</div>
                    <div class="modalSubtitle">Select a special situation vaccination administered from the given option
                        or simply select 'Other' and write in the name below.</div>
                </div>

                <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
                    (click)="closeDialog();">
                    <span aria-hidden="true" style="font-size: 40px;color: #000;" (click)="closeDialog()">&times;</span>
                </button>
            </div>

        </div>

        <div style="margin-top: 20px; width: 60vw; height: 90vh; display: flex; flex-direction: column;">
            <div style="font-size: 16px; color: grey;">Vaccines</div>
            <div style="display: flex; flex-direction: row; flex-wrap: wrap;">
                <div *ngFor="let record of specialSituationVaccines; let idx =index"
                    (click)="selectVaccine(idx, record)" style="cursor: pointer; display: flex; flex-direction: row; flex-wrap: wrap; font-size: 18px; color: #333; background-color: #F9FBFF; 
                     border-radius: 4px; padding: 8px; width: fit-content; margin: 4px;"
                    [ngClass]="selectedVacIndex == idx ? 'selected-vaccine' : 'unselected-vaccine'">
                    <mat-icon [ngClass]="selectedVacIndex == idx ? 'blue-icon' : 'grey-icon'">
                        check_circle
                    </mat-icon>
                    <span style="margin-left: 6px;">{{record.name}}</span>
                </div>
            </div>

            <div style="font-size: 16px; color: grey;">Received Date</div>
            <div style="display: flex; flex-direction: row; cursor: pointer;">



                <!-- Vaccine not administered -->
                <div>
                    <input placeholder="DD/MM/YYYY" name="dp" [(ngModel)]="receivedDate" ngbDatepicker
                        #d="ngbDatepicker" [maxDate]="maxDate" [minDate]="minDate"
                        style="font-size: 16px; color: #000; border: 0px; width: 100px; outline: none;">
                    <img (click)="d.toggle()" style="height: 30px; width: 30px;" src="assets/images/calendarIcon.svg"
                        alt="" />
                </div>
            </div>

            <div *ngIf="specialSituationVaccines[selectedVacIndex].name != 'Other'" style="flex: 1; overflow: scroll;">
                <div style="font-size: 16px; color: grey;">Description</div>
                <div class="formatter" style="font-size: 16px; color: #000;">
                    {{specialSituationVaccines[selectedVacIndex].description ?
                    specialSituationVaccines[selectedVacIndex].description : 'NA'}}</div>
            </div>

            <div *ngIf="specialSituationVaccines[selectedVacIndex].name == 'Other'" style="flex: 1;">
                <div style="font-size: 16px; color: grey;">Vaccine Name</div>
                <input type="text" placeholder="Enter Vaccine Name" #searchInput [(ngModel)]="splVaccineName" style="font-size: 16px;
                    border: 1px solid #5a73e2 !important;
                    background-color: #fff !important;
                    border-radius: 6px !important;
                    height: 45px;
                    width: 50%;
                    padding-left: 8px;" />
            </div>

            <div style="padding-top: 16px; display: flex; justify-content: flex-end;">
                <button (click)="closeDialog()" class="cancelBtn">Cancel</button>

                <button *ngIf="receivedDate!='' && specialSituationVaccines[selectedVacIndex].name != 'Other'"
                    class="submitBtn" (click)="saveSpecialVaccine();closeDialog();" [disabled]="saveSpecialVaccButton">Save Vaccine</button>
                <button
                    *ngIf="receivedDate!='' && specialSituationVaccines[selectedVacIndex].name == 'Other' && splVaccineName !=''"
                    class="submitBtn" (click)="saveSpecialVaccine()" [disabled]="saveSpecialVaccButton">Save Vaccine</button>

                <button class="submitBtnDisabled"
                    *ngIf="receivedDate=='' && specialSituationVaccines[selectedVacIndex].name != 'Other'">Save
                    Vaccine</button>
                <button class="submitBtnDisabled"
                    *ngIf=" (receivedDate=='' || splVaccineName =='')  && specialSituationVaccines[selectedVacIndex].name == 'Other'" [disabled]="saveSpecialVaccButton">Save
                    Vaccine</button>

            </div>
        </div>
     </div>
    </ng-template>

    <ng-template #bulkVaccineUpdate let-modal>
     <div style="width: 100%; padding: 20px;">
        <div style="display: flex;flex-direction: column;">
            <div style="display: flex; flex-direction: row; justify-content: space-between;">

                <div>
                    <div class="modalTitle">Bulk Update Vaccines</div>
                    <div class="modalSubtitle">Multiselect all the vaccines you wish to administer together.</div>
                </div>

                <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
                    (click)="closeDialog();">
                    <span aria-hidden="true" style="font-size: 40px;color: #000;" (click)="closeDialog()">&times;</span>
                </button>
            </div>

        </div>

        <div style="margin-top: 20px; width: 60vw; height: 90vh; display: flex; flex-direction: column;">

            <div style="font-size: 16px; color: grey;">Transcript</div>

            <div style="display: flex; flex-direction: row; height: 60%; align-items: center; ">
                <mat-icon (click)="changeTranscriptIndex(1)" style="cursor: pointer;" [ngClass]="'blue-icon'">
                    chevron_left
                </mat-icon>

                <img *ngIf="allTranscripts.length" style="height: 100%; width: 100%; object-fit: contain;"
                    src="{{allTranscripts[transcriptIndex].labReportUrl}}" alt="" />

                <div *ngIf="!allTranscripts.length" style="height: 100%; width: 100%;justify-content: center;
                align-items: center;
                display: flex;
                color: grey;
                font-size: 18px;">No Transcript Found</div>

                <mat-icon (click)="changeTranscriptIndex(2)" style="cursor: pointer;" [ngClass]="'blue-icon'">
                    chevron_right
                </mat-icon>
            </div>



            <div style="display: flex; flex: 1; flex-direction: column; overflow: scroll;">
                <div style="font-size: 16px; color: grey;">Pending Vaccines</div>
                <div style="display: flex; flex-direction: row; flex-wrap: wrap;">
                    <div *ngFor="let vaccine of pendingVaccines; let idx = index"
                        (click)="selectPendingVaccine(idx, vaccine)" style="cursor: pointer; display: flex; flex-direction: row; flex-wrap: wrap; font-size: 18px; color: #333; background-color: #F9FBFF; 
                     border-radius: 4px; padding: 8px; width: fit-content; margin: 4px;"
                        [ngClass]="vaccine.selected ? 'selected-vaccine' : 'unselected-vaccine'">
                        <mat-icon [ngClass]="vaccine.selected ? 'green-icon' : 'grey-icon'">
                            check_circle
                        </mat-icon>
                        <div style="display: flex; flex-direction: column; flex: 1;">
                            <span style="margin-left: 6px;">{{vaccine.name}}</span>
                            <span
                                style="margin-left: 6px; color: grey; font-size: 14px; line-height: 12px;">{{vaccine.dueDate
                                |
                                date:"dd-MM-YYYY"}}</span>
                        </div>
                    </div>

                    <div *ngIf="!pendingVaccines.length" style="font-size: 16px; color: #333;">No Pending Vaccines</div>

                </div>
            </div>



            <div style="padding-top: 16px; display: flex; justify-content: flex-end;">
                <button (click)="closeDialog()" class="cancelBtn">Cancel</button>

                <button class="submitBtn" (click)="uploadBulkVaccination()">Add to Schedule</button>
                <!-- <button class="submitBtnDisabled" *ngIf="true">Add to Schedule</button> -->
            </div>
        </div>
     </div>
    </ng-template>


    <ng-template #confirmVacStartDate let-modal>
  <div style="padding: 20px;">
    <div style="display: flex;flex-direction: column;">
        <div style="display: flex; flex-direction: row; justify-content: space-between;">

            <div>
                <div class="modalTitle">Confirm Vaccination Start Date</div>
                <!-- <div class="modalSubtitle">Select a special situation vaccination administered from the given option
                    or simply select 'Other' and write in the name below.</div> -->
            </div>

            <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
                (click)="closeDialog();">
                <span aria-hidden="true" style="font-size: 40px;color: #000;" (click)="closeDialog()">&times;</span>
            </button>
        </div>

    </div>

    <div style="margin-top: 20px; width: 40vw; height: 40vh; display: flex; flex-direction: column;">

        <div style="display: flex; flex-direction: row; cursor: pointer;">
        </div>

        <div style="flex: 1;">
            <div class="formatter" style="font-size: 18px; color: #000;">
                Birth date for {{patientMoreData.firstName}} {{patientMoreData.lastName}} is set as <span style="font-weight: bold; color:#5a73e2">{{patientMoreData.formattedDOB}}</span>, and vaccination start date with respect to DOB is
                set as <span style="font-weight: bold; color:#5a73e2">{{patientMoreData.formattedVacStartDate}}</span>.
                Please confirm if the DOB and vaccination start date is correct.
                In case of any discrepancy, please contact support on the below given number.
            </div>
        </div>

        <div style="font-size: 18px; color: #000; font-weight: bold;">Copious Support</div>
        <div style="font-size: 18px; color: #000;">+919689907287</div>


        <div style="padding-top: 16px; display: flex; justify-content: flex-end;">
            <!-- <button (click)="closeDialog()" class="cancelBtn">Remind me later</button> -->

            <button 
                class="submitBtn" (click)="closeDialog()">Confirm</button>
            

        </div>
    </div>
  </div>
    </ng-template>

</div>



<ng-template #resetVaccinationModal let-modal>
<div style="width: 100%; padding: 15px;">
    <h4> Reset Vaccination </h4>
    <div style="display: flex;flex-direction: row;">
      <mat-form-field appearance="outline" style="margin-top: 18px;" >
        <mat-label style="font-size: 16px;">Patient Id </mat-label>
        <input matInput  [(ngModel)]="patientMoreData._id" placeholder="Enter Patient Id" disabled >
    
     
      </mat-form-field>
    
      <mat-form-field appearance="outline" style="margin-top: 18px; margin-left: 10px;">
        <mat-label style="font-size: 16px;">DOB</mat-label>
        <input 
          matInput 
          [matDatepicker]="picker" 
          [(ngModel)]="patientDoB" 
          (input)="formatDate($event.target.value)" 
          placeholder="DD/MM/YYYY" readonly>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <!-- <div *ngIf="dateError" style="color: red; font-size: 12px; margin-left: 10px;">
        Please enter a valid date in DD/MM/YYYY format.
      </div> -->
      
      <mat-form-field appearance="outline" style="margin-left: 17px; margin-top: 18px;">
        <mat-label style="font-size: 16px;">Vaccination start date</mat-label>
        <input 
          matInput 
          [matDatepicker]="picker1" 
          [(ngModel)]="patientVaccinationDate" 
          (input)="formatVaccinationDate($event.target.value)" 
          placeholder="DD/MM/YYYY" readonly>
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </mat-form-field>

    </div>
   <div style="display: flex; flex-direction: row; justify-content: end;">
    <button (click)="closeDialog();"  mat-raised-button style="background-color: #eee; color: #444;; height: 40px;margin-left: 9px;margin-top: 3px;font-size: 16px;font-weight: bold;">Cancel</button>
    <button (click)="resetVaccination();"  mat-raised-button style="background-color: #5D86CC; color: #fff; height: 40px;margin-left: 9px;margin-top: 3px;font-size: 16px;font-weight: bold;">Reset Vaccination</button>
   </div>
</div>
   </ng-template>

<p-toast position="top-right" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class=" p-flex p-flex-column"
            style="flex: 1; background-color:#BAE8FC; opacity: 0.95; display: flex; flex-direction: row; align-items: center; border-left: 8px solid #0D8CCD; border-radius: 8px;">
            <div>
                <img (click)="goBack()" style="height: 36px; margin-left: 12px; width: 36px; align-self: center;"
                    src="assets/images/exclamation-circle-blue.svg" alt="" />
            </div>
            <div class=" p-text-center">
                <div style="font-size: 18px;
                font-weight: bold;
                padding: 12px 0px 0px 12px;
                
                color: #034A70;">{{message.summary}}</div>
                <div
                    style="padding-left: 12px; padding-bottom: 12px; color: #034A70; font-size: 16px; margin-top: -6px;">
                    {{message.detail}}</div>
            </div>
        </div>
    </ng-template>
</p-toast>

<ng-template #dateFormat_modal>
    <!-- <h4>Delete Favourite?</h4> -->
    <div style="color: red; font-size: 16px; padding: 10px 10px 10px 10px;">
        Please enter a valid date in DD/MM/YYYY format.</div>
    <div style="padding-top: 16px; display: flex; justify-content: flex-end;">
      <button (click)="closeDialog()" style="background-color: #eee;
                  padding: 8px 16px;
                  border-radius: 6px;
                  color: #fff;
                  font-weight: bold;
                  border: 0px;
                  margin: 4px;
                  color: #444;
                  outline: none;">Cancel</button>

      <!-- <button (click)="onClickDeleteFavMed()" style="background-color: #f44336;
              padding: 8px 16px;
              border-radius: 6px;
              color: #fff;
              font-weight: bold;
              border: 0px;
              margin: 4px;
            outline: none;">Yes, Delete</button> -->
    </div>
  </ng-template>
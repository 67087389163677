
<app-lab-infobar></app-lab-infobar>


<div
style="display: flex; flex: 1; align-items: center; justify-content: flex-start; position: relative; padding-right: 0px; margin-left: 20px; margin-top: 10px;">
<div
    style="height: 70%; width: 28%; border-radius: 6px; border: 0.4px solid #005CAF; display: flex; flex-direction: row; margin-right: 8px;">
    <div style="width: 90%; height: 100%;">
        <input type="text" placeholder="Search Records by Doctor/Patient Name etc ." class="form-control" #searchInput
            [(ngModel)]="searchPatientRequisition" (keyup)="onSearchPatientRequisition($event)"
            style="height: 50px !important; font-size: 16px; border: 1px solid transparent !important; background-color: #fff !important; border-radius: 8px !important;" />
    </div>
    <div style="width: 10%; height: 100%; display: flex; justify-content: center; align-items: center; margin-top: 4px;">
        <button  style="border: 0px; background-color: transparent; outline: none;"><img
                src="assets/images/search_icon_blue.svg" alt="" /></button>
    </div>
</div>
<mat-form-field appearance="outline" style="margin-top: 18px;" >
    <mat-label style="font-size: 18px;">From Date </mat-label>
    <input matInput [matDatepicker]="picker" [(ngModel)]="fromDateForRequisition">

    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
  
  <mat-form-field  appearance="outline"  style="margin-left: 17px; margin-top: 18px;">
    <mat-label style="font-size: 18px;">To Date</mat-label>
    <input [(ngModel)]="toDateForRequisition" matInput [matDatepicker]="picker1">
   
    <mat-datepicker-toggle matSuffix  [for]="picker1"></mat-datepicker-toggle>
    <mat-datepicker #picker1></mat-datepicker>
  </mat-form-field>
  
  <button (click)="getRequisitionsByLabId()"
  class="new_btn" style=" margin-top: -3px;" >Search</button>
  <button style=" margin-top: -3px; margin-left: 25px;" (click)="getRequisitionsByLabId()" ngbTooltip="Refresh Requisitions" class="new_btn">
    <span >&#x21bb; Refresh</span></button>
</div>


<div  style="height: 100vh; width: 100%; margin-top: 10px;">
    <div style="height:100vh; width: 100%;">
        <ng-container [ngTemplateOutlet]="renderImmunization" [ngTemplateOutletContext]="{value:labRequisitionsList}">
        </ng-container>
    </div>
</div>



<ng-template #renderImmunization let-value="value" >
    <table mat-table [dataSource]="value" style="width: 100%;">
        <ng-container>

            <!-- AGE -->
            <ng-container matColumnDef="sr no" style="width:10%">
                <th mat-header-cell *matHeaderCellDef> Sr No</th>
                <td mat-cell *matCellDef="let element let j = index"  style="text-align: center !important; width:5%"> 
                    {{j+1}}
                </td>
            </ng-container>
            <ng-container matColumnDef="dateTime" >
                <th mat-header-cell *matHeaderCellDef style="text-align: left !important;" > Date & Time </th>
                <td mat-cell *matCellDef="let element" style="text-align: left !important; width:5%"> 
                 <span style="color: #000;">  {{moment(element.date).format('DD/MM/YYYY')}}</span>   <br>
                    <span style="text-align: center;">  {{moment(element.date).format('h:mm a')}} </span> 
                </td>
            </ng-container>
            <ng-container matColumnDef="patient">
                <th mat-header-cell *matHeaderCellDef class="theadUi"> Patient </th>
                <td  mat-cell *matCellDef="let element" style="text-align: start !important; width:10%; "> 
                    <span (click)="openDialog(viewAllReportsModal);onclickViewAllReports(element)" style="cursor: pointer;color: navy;" title="View Reports"> {{element.patientName}} </span>
               
                    <br>{{element.mobileNumber}}
                </td>
            </ng-container>
            <!-- ACTION -->
            <ng-container matColumnDef="doctor">
                <th mat-header-cell *matHeaderCellDef class="theadUi"> Doctor</th>
                <td mat-cell *matCellDef="let element" style="text-align: start !important;width: 5% !important">
                   
                {{element.doctorName}}
                   
                </td>
            </ng-container>

            <ng-container matColumnDef="test" >
                <th mat-header-cell *matHeaderCellDef style="text-align: left !important;" > Test </th>
                <td mat-cell *matCellDef="let element" style="text-align: start  !important; width: 24% !important;">
                  <span  style="color: black;" > 
                    {{element.test}} <span style="font-weight: bold;" *ngIf="element.comments && element.comments!=''">({{element.comments}})</span>
                    &nbsp;&nbsp;<span title="View Requisition PDF" style="color: blue;cursor: pointer;" *ngIf="element.url && element.url!=''" (click)="openRequisitionUrl(element.url)">View</span>
                    &nbsp;&nbsp;<span title="View Photo" style="color: blue;cursor: pointer;" *ngIf="element.photoURL && element.photoURL!=''" (click)="openRequisitionUrl(element.photoURL)">Photo</span>
                 </span>
                 
               
                 </td>
                
            </ng-container>
            <ng-container matColumnDef="status" >
                <th mat-header-cell *matHeaderCellDef style="text-align: center!important;" > Status </th>
                <td mat-cell *matCellDef="let element" style="width: 5%;text-align: center;">
               
                 <div (click)="onClickSatusUpdate(element);" *ngIf="element.status=='Open'||element.status=='null' ||element.status==null"
                 style="cursor: pointer;background-color:#FFF3CD; color:#8B6A0F; text-align: center; margin: 0px 12px;  font-size: 16px; font-weight: bold;">
               Open
             </div>

             <div (click)="onClickSatusUpdate(element)" *ngIf="element.status=='Confirmed' "
             style="cursor: pointer;background-color:#e0ecfc;; color:#7481bc; text-align: center; margin: 0px 12px;  font-size: 16px; font-weight: bold;">
             Confirmed
         </div>
         <div (click)="onClickSatusUpdate(element)" *ngIf="element.status=='Done' "
         style="cursor: pointer;background-color:#21b844; color:#165724; text-align: center; margin: 0px 12px;  font-size: 16px; font-weight: bold;">
       Done
     </div>
         <div (click)="onClickSatusUpdate(element)" *ngIf="element.status=='Sample collected' "
         style="cursor: pointer;background-color:#D4EDDA; color:#165724; text-align: center; margin: 0px 12px;  font-size: 16px; font-weight: bold;">
         Sample taken
     </div>
                 </td>
                
            </ng-container>
            <ng-container matColumnDef="labcomments" >
                <th mat-header-cell *matHeaderCellDef style="text-align: left !important;" > Comment </th>
                <td mat-cell *matCellDef="let element" style="text-align: left !important; width: 15% !important;">
                 <span *ngIf="element.labcomments"> {{ element.labcomments.substring(0,80)}} </span> 
                 <span *ngIf="element.labcomments && element.labcomments.length > 80"> ...</span>
               
                 </td>
                
            </ng-container>
            <ng-container matColumnDef="action" style="width:10%">
                <th  mat-header-cell *matHeaderCellDef style="text-align: left !important; padding-left: 8px !important; " > Action </th>
                <td mat-cell *matCellDef="let element" style="text-align: left  !important; width: 7% !important;">
                    <div (click)="onClickUploadDocuments(element);getLabReportsByReqId()" style="margin-left: 10px;"  class="actionBtn" 
                   >  Upload 
               </div>
                 </td>
                
            </ng-container>
         
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true" style="background-color: #eee;"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</ng-template>

<ng-template #uploadDocuments let-modal>
    <div style="width: 100%; height: 100%;">
        <div class="modal-header" style="border-bottom: 0px;; width: 76vw;">
            <h4 class="modal-title" id="modal-basic-title">{{labUplaodLable}} </h4>
            <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
                (click)="closeDialog();">
                <span aria-hidden="true" style="font-size: 40px; color: #000;">&times;</span>
            </button>
        </div>
        <div class="modal-body" style="margin-top: -27px;">


            
            <div style="display: flex; flex-direction: row;  height: 90vh; width: 100%; overflow: scroll;">
                <div *ngIf="patientCommnets" style="font-size: 16px; margin-top: 15px;">Comments:</div>
            <div *ngIf="patientCommnets"  style="display: flex; justify-content: center; " >
                    <span style="border: 1px solid #6fbdbb;padding: 6px;
                    height:fit-content;  margin-top: 10px;border-radius: 0.6rem;
                       font-size: 16px;"> {{ patientCommnets}}</span>  
                        </div>
                <div *ngIf="documentPdfURL" style="display: flex; width: 50%;">
                <embed [attr.src]="documentPdfURL" type="application/pdf" width="100%" height="100%">
                </div>
                <div *ngIf="showUploadPdf" style="display: flex; width: 50%;">
                    <embed src="showUploadPdf" type="application/pdf" width="100%" height="100%">
                    </div>
                <div *ngIf="documentPhotoURL" style="display: flex; width: 50%;">
                    <img [attr.src]="documentPhotoURL" type="application/jpg" width="100%" height="100%">
                    </div>
                   
                     
                <div style="display: flex;width: 50%;  height: 90vh;  overflow: scroll; flex-direction: column; overflow: scroll;">
                    <div>
                        <h4 style="margin-left: 10px;">Previously Uploaded Reports</h4>
                        <div class="files-list">
                            <div style="display: flex;flex-direction: row; justify-content:left;font-size: 16px;"  *ngFor="let displayfilesName of uploadedReportLIst; let i = index">
                              
                                <div  style="width: 58%;margin-left: 10px; display: flex;
                                
                                padding-left: 16px;
                                
                                align-items: center;
                                margin-bottom: 1rem;">
                                    <div>
                                        <h4 class="name" style="color: #000;font-size: 16px;">
                                            <!-- {{ moment(displayfilesName?.documentDate).utcOffset('+05:30').format('DD/MM/YY hh:mm a') }} - {{ displayfilesName?.emailMessageIdAttchName.substring(14) }} -->
                                             {{ moment(displayfilesName?.documentDate).format('DD/MM/YY') }} - {{ displayfilesName?.emailMessageIdAttchName.substring(20) }}
                                        </h4>
                                        
                                    </div>
                                </div>
                              
                                <div (click)="onClickGetReport(displayfilesName.url);" style="margin-left: 0px; color: #5D86CC; text-decoration: underline; font-weight: bold; cursor: pointer;"> 
                                    View
                                </div>
                                <div (click)="openDialog(addDeleteModal); onClickDeleteReport(displayfilesName,'uploadedReport');" style="margin-left: 20px; color: red; text-decoration: underline; font-weight: bold; cursor: pointer;"> 
                                    Delete
                                </div>
                            </div>
                          
                        </div>

                        <div style="display: flex; justify-content: center;">
                            <div for="fileDropRef" style="margin-left: 5px;">
        
                                <div class="container" appDnd (fileDropped)="onFileDropped($event)"
                                    style="margin-left: 5px;width: 482px;height: 256px;">
                                    <input type="file" multiple="multiple" #fileDropRef id="fileDropRef"
                                        (change)="fileBrowseHandler($event.target.files)" 
                                        accept=".jpg, .png, .jpeg, .pdf"/>
        
                                    <img src="assets/images/new_upload.svg" alt="" width="90" height="90" />
        
                                    <div style="font-weight: 600; font-size: 16px; color: #717887;">Drag and drop pdf file
                                        here(Max,'10'mb)</div>
                                    <h3>or</h3>
                                    <h6 style="color:red">{{report_message}}</h6>
                                 
                                    <label class="btnNewStyle" for="fileDropRef">Browse files</label>
                                </div>
        
                                <div class="files-list">
                                    <div style="display: flex;flex-direction: row; justify-content:space-between;"  *ngFor="let displayfilesName of fileconvertedtobase64; let i = index">
                                        <div class="single-file file-icon" style="width: 90%;margin-left: 10px;">
                                            <mat-icon >
                                               upload
                                            </mat-icon>
                                    
                                            <div class="info" (click)="viewPdf(displayfilesName)" style="cursor: pointer;">
                                                <h4 class="name" style="color: #000;font-size: 16px;">
                                                    {{ displayfilesName?.name }}
                                                </h4>
                                                <p class="size">
                                                    <!-- {{ formatBytes(file?.size) }} -->
                                                </p>
                                                <!-- <app-progress [progress]="file?.progress"></app-progress> -->
                                            </div>
                                        </div>
                                      
        
                                        <div class="delete"  (click)="deleteFile(i)">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18">
                                                <path fill="#B1B1B1" fill-rule="nonzero"
                                                    d="M1 16c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2v10zm3.17-7.83a.996.996 0 0 1 1.41 0L7 9.59l1.42-1.42a.996.996 0 1 1 1.41 1.41L8.41 11l1.42 1.42a.996.996 0 1 1-1.41 1.41L7 12.41l-1.42 1.42a.996.996 0 1 1-1.41-1.41L5.59 11 4.17 9.58a.996.996 0 0 1 0-1.41zM10.5 1L9.79.29C9.61.11 9.35 0 9.09 0H4.91c-.26 0-.52.11-.7.29L3.5 1H1c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1h-2.5z" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
        

                               
                            </div>
        
                        </div>
                    </div>
                    
                </div>
            </div>
           
        </div>

      


        <div style="display: flex;justify-content: flex-end;">
            <button (click)="closeDialog()" class="btn btnUiForCancel"   style="margin-top: 0px; display: flex; justify-content: center; align-items: center;">
              Cancel
            </button>
            <button  class="btn btn-secondary"
            style="margin-top: 0px; display: flex; justify-content: center; align-items: center;">
    
            <mat-icon [ngClass]="'white-icon'">
                description
            </mat-icon>
    
            <span (click)="saveChangesLabReoprt()" style="margin-left: 4px; font-weight: bold;">Upload</span>
        </button>
          </div>
    </div>
</ng-template>


<ng-template #requisitionContent_modal >
    <div style="width: 45vw; padding: 20px;">
    <h4>Status Update</h4>
    <!-- <div style="color: #555; font-size: 16px;">Are you sure you want to delete reciept?</div> -->
    <div style=" display: flex; ">


        <mat-form-field style=" width: 100%; margin-left: 0px;">
            <mat-label style="font-size: 16px;">Status</mat-label>
            <mat-select style="font-size: 16px;" [value]="requisitionStatusName"
                (selectionChange)="onDropdownSelecStatus($event.value)">
                <mat-option style="font-size: 16px;" *ngFor="let item of requisitionStatus"
                    [value]="item">
                    {{item}}
                </mat-option>
            </mat-select>
        </mat-form-field>   
      
    </div>
    <h6>Comments</h6>
    <div class="text-area-style" id="labcomments" contenteditable="true" style="font-size: 18px ;"> {{labcommentsForeditBox}} </div>
        
    <div style="display: flex; flex-direction: row;justify-content: flex-end;">
        <button (click)="closeDialog();" class="btnUiForCancel">Cancel</button>
        <button style="margin-left: 10px;" (click)="updateRequisitionByLab()" class="createReceiptBtn">Update</button>
    </div>
</div>
</ng-template>
<ng-template #viewReportModal let-modal>
    <div style="width: 80vw;">
        <div class="modal-header" style="border-bottom: 0px; padding: 16px 16px 16px 0px; width: 76vw;">
            <h4 class="modal-title" id="modal-basic-title">View Report</h4>
            <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
                (click)="closeDialog();">
                <span aria-hidden="true" style="font-size: 40px; color: #000;">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div style="display: flex;  height: 80vh;">
                <embed [attr.src]="reportPdfURL" type="application/pdf" width="100%" height="100%">
            </div>
        </div>
    </div>
</ng-template>



<ng-template #addDeleteModal>
    <h4>Delete Reports?</h4>
    <div style="color: #555; font-size: 16px;"> Are you sure you want to delete the selected records?</div>
    <div style="padding-top: 16px; display: flex; justify-content: flex-end;">
        <button (click)="closeDialog()" style="background-color: #eee;
              padding: 8px 16px;
              border-radius: 6px;
              color: #fff;
              font-weight: bold;
              border: 0px;
              margin: 4px;
              color: #444;
              outline: none;">Cancel</button>

        <button (click)="deleteReport();closeDialog()" style="background-color: #f44336;
          padding: 8px 16px;
          border-radius: 6px;
          color: #fff;
          font-weight: bold;
          border: 0px;
          margin: 4px;
        outline: none;">Delete</button>
    </div>
</ng-template>


<ng-template #viewAllReportsModal>
    <div class="modal-header" style="border-bottom: 0px; padding: 16px 16px 16px 0px; width: 76vw;">
        <h4 class="modal-title" id="modal-basic-title">All Reports</h4>
        <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
            (click)="closeDialog();">
            <span aria-hidden="true" style="font-size: 40px; color: #000;">&times;</span>
        </button>
    </div>
    <!-- <mat-dialog-actions>
        <h4 style="margin-bottom: 25px;">All Reports </h4>
      
    </mat-dialog-actions> -->
    <div
style="display: flex; flex: 1; align-items: center; justify-content: flex-start; position: relative; padding-right: 0px; margin-left: 20px; margin-top: 3px;">
<div
    style="height: 70%; width: 28%; border-radius: 6px; border: 0.4px solid #005CAF; display: flex; flex-direction: row; margin-right: 8px;">
    <div style="width: 90%; height: 100%;">
        <input type="text" placeholder="Search Records Patient Name etc ." class="form-control" #searchInput
            [(ngModel)]="searchPatientAllReports" (keyup)="onSearchPatientAllReports(searchPatientAllReports)"
            style="height: 50px !important; font-size: 16px; border: 1px solid transparent !important; background-color: #fff !important; border-radius: 8px !important;" />
    </div>
    <div style="width: 10%; height: 100%; display: flex; justify-content: center; align-items: center; margin-top: 4px;">
        <button  style="border: 0px; background-color: transparent; outline: none;"><img
                src="assets/images/search_icon_blue.svg" alt="" /></button>
    </div>
</div>
<mat-form-field appearance="outline" style="margin-top: 18px;" >
    <mat-label style="font-size: 18px">From Date </mat-label>
    <input matInput [matDatepicker]="picker" [(ngModel)]="fromDateForAllReports">

    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
  
  <mat-form-field  appearance="outline"  style="margin-left: 17px; margin-top: 18px;">
    <mat-label style="font-size: 18px">To Date</mat-label>
    <input [(ngModel)]="toDateForForAllReports" matInput [matDatepicker]="picker1">
   
    <mat-datepicker-toggle matSuffix  [for]="picker1"></mat-datepicker-toggle>
    <mat-datepicker #picker1></mat-datepicker>
  </mat-form-field>
  
  <button (click)="getReportsByFromDateAndToDate()"
  class="new_btn" style=" margin-top: -3px;" >Search</button>
  
</div>
    <mat-dialog-content>
    <div style=" width: 50vw;display: flex;flex-direction: row; justify-content:left;font-size: 16px;margin-top: 15px;"  *ngFor="let displayfilesName of allUploadedReportList; let i = index">
                              
        <div  style="width: 58%;margin-left: 10px; display: flex;padding-left: 16px;
        align-items: center;
        margin-bottom: 1rem;">
            <div>
                <h4 class="name" style="color: #000;font-size: 16px;">
                    <!-- {{ moment(displayfilesName?.documentDate).utcOffset('+05:30').format('DD/MM/YY hh:mm a') }} - {{ displayfilesName?.emailMessageIdAttchName.substring(14) }} -->
                    {{ moment(displayfilesName?.documentDate).format('DD/MM/YY') }} - {{ displayfilesName?.emailMessageIdAttchName.substring(14) }}
                </h4>
                
            </div>
        </div>
      
        <div (click)="onClickGetReport(displayfilesName.url);" style="margin-left: 0px; color: #5D86CC; text-decoration: underline; font-weight: bold; cursor: pointer;"> 
            View
        </div>
        <div (click)="openDialog(addDeleteModal); onClickDeleteReport(displayfilesName,'allReport');" style="margin-left: 20px; color: red; text-decoration: underline; font-weight: bold; cursor: pointer;"> 
            Delete
        </div>
    </div>
</mat-dialog-content>


    </ng-template>
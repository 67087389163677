<div
    
    style="display: flex; width: 100%; margin-top: 0px; overflow: scroll !important; height: 100vh; overflow-y:scroll !important;">
    <div style="margin-left: 18px ;display: flex; justify-content: flex-start; width: 60vw; flex-direction: column;">
        <!-- <label style="font-weight: bold; font-size: 24px;margin-top: 15px;margin-left: 20px;">Receipt</label> -->
        <div class="heading">Receipt</div>
        <div class="subheading">This feature is used create receipt for patients.</div>
        <!-- division -  20% 30% 20% 15% 15% -->
        <div style="height: 88vh; width: 100%;">
            <div style="height: 80vh; width: 100%;">
                <ng-container [ngTemplateOutlet]="renderReceipt" [ngTemplateOutletContext]="{value:allReceiptArr}">
                </ng-container>
            </div>
        </div>
        <ng-template #renderReceipt let-value="value">
            <table mat-table [dataSource]="value" style="width: 100%;">
                <ng-container>

                    <!-- AGE -->

                    <ng-container matColumnDef="date" style="width:10%">
                        <th mat-header-cell *matHeaderCellDef class="theadUi"> Date </th>
                        <td mat-cell *matCellDef="let element" style="text-align: start !important; width:10%">
                            {{element.date}}
                        </td>
                    </ng-container>

                    <!-- ACTION -->
                    <ng-container matColumnDef="amount">
                        <th mat-header-cell *matHeaderCellDef class="theadUi"> Amount</th>
                        <td mat-cell *matCellDef="let element" style="text-align: start !important; width:10%">
                            Rs.{{element.amount}}
                        </td>
                    </ng-container>

                    <!-- STATUS -->

                    <ng-container matColumnDef="action" style="width:10%">
                        <th class="theadUi" mat-header-cell *matHeaderCellDef> Action </th>

                        <td mat-cell *matCellDef="let element" style="text-align: start !important; width:10%">

                            <div
                                style="flex-direction: row;display:flex; width: 15%;  justify-content: center; align-items: center; ">
                                <img (click)="setReceiptItem(element);openDialog(delete_modal)"
                                    style="height: 20px;cursor: pointer;" src="assets/images/delete_red.svg" />
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="view" style="width:10%">
                        <th class="theadUi" mat-header-cell *matHeaderCellDef> View </th>
                        <td mat-cell *matCellDef="let element"
                            style="text-align: start !important;width: 10% !important">

                            <div *ngIf="element.billUrl" class="actionBtn" (click)="onClickViewReceipt(element)">
                                View
                            </div>

                        </td>
                    </ng-container>

                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true" style="background-color: #eee;">
                </tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </ng-template>

    </div>

    <div
        style="display: flex; width: 45vw; height: 100vh; flex-direction: row; justify-content: center; margin-top: 16px; overflow: scroll; overflow-y: scroll;">
        <div
            style=" height:fit-content; width: 81%; background-color: #fff;  border-color: rgb(70, 135, 191); border-style: solid; border-radius: 20px; border-width: 1px;padding: 15px;">
            <div style="display: flex;justify-content: space-between;">
                <div style="margin: 0px 0px 16px 0px; font-size: 24px; color: #333; font-weight: bold;">Create Receipt
                </div>
                <div style="display: flex;flex-direction: row;justify-content: flex-end;">
                    <div ngbDropdown class="d-inline-block" style="margin-right: 22px;">
                        <button class="btn btn-outline-primary" id="dropdownBasic1" style="border: 0px; border-bottom: 1px solid #ccc;padding-right: 0px;
                color: #333;
                background-color: #fff;
                margin-right: 4px;" ngbDropdownToggle>{{templateName}} </button>

                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" style="top:-11px">
                            <button (click)="onDropdownSelectTemplate(item)"
                                *ngFor="let item of selectedTemplate; let i = index" ngbDropdownItem>{{item}}</button>
                        </div>

                    </div>
                </div>
            </div>


            <div class="main"
                style="display: flex;flex-direction: row; justify-content: space-between; width: 91%; height: 100%;">

                <mat-form-field style="width: 40%;">

                    <input matInput [matDatepicker]="picker" [readonly]="true"
                        [(ngModel)]="receiptDate">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>

                </mat-form-field>
                <mat-form-field style="width: 40%;">
                    <mat-label style="font-size: 16px;">Receipt No.</mat-label>
                    <input matInput [(ngModel)]="receiptNo" maxlength="15">
                </mat-form-field>
            </div>
            <div
                style="width: 80%; display: flex; flex-direction: row; justify-content: space-between; align-items: center; ">
                <div style="font-size: 16px; color: #aeaeae;">Reasons</div>
                <div style="font-size: 16px; color: #aeaeae;">Amount</div>
            </div>
            
            <!-- <div style="background-color: peachpuff; overflow-y:scroll; height:450px"> -->
            <div *ngFor="let element of visitReasonArr; let i = index"
                style="height: 40px; width: 85%; display: flex; flex-direction: row; justify-content: space-between; align-items: center; margin: 10px 0px 10px 0px;">

                <div (click)="selectVisitReasonValue(element,i);" *ngIf="!element.selected"
                    style="border: 1px solid #34b3b3; background-color: #fff; border-radius: 4px; margin-left: 8px; cursor: pointer; height: 26px; width: 26px;"
                    class="largerCheckbox"></div>

                <img (click)="selectVisitReasonValue(element,i);" *ngIf="element.selected"
                    style="width:26px; color:#007d7e; margin-left: 8px; cursor: pointer;"
                    src="assets/images/checkbox_material.svg" class="largerCheckbox" />

                <div style="width: 56%;">
                    <div *ngIf="element.otherReason!='other'"
                        [ngClass]="element.selected ?  'titleActive' :'titleInactive'">{{element.reason}}</div>
                    <!-- <div *ngIf="element.otherReason==''" [ngClass]="element.selected ?  'titleActive' :'titleInactive'"><input  style="width: 200px;" [(ngModel)]="element.otherReason"></div> -->
                    <div *ngIf="element.otherReason=='other'" class="searchBox"
                        style="display: flex;flex-direction: row; width: 86%; border-radius: 6px;">

                        <input
                            style="height:25px ;  border:none; outline: none; width:100%;background-color:  #fff; border-radius: 6px;"
                            placeholder="Other:" [(ngModel)]="element.reason">

                    </div>
                </div>




                <div class="searchBox" style="display: flex;flex-direction: row; width: 26%; border-radius: 6px;">

                    <input
                        style="height:24px ;  border:none; outline: none; width:100%;background-color:  #fff; border-radius: 6px;"
                        [(ngModel)]="element.reasonAmount" maxlength="7" (keypress)="validateNumber($event,'receipt')"
                        (input)="getTotal(element.reasonAmount)">

                </div>
            </div>
            <!-- </div> -->

            <div style="display: flex; justify-content: center;">
                <div (click)="addMoreRow()" class="actionBtn" style="width: 100px;">Add More</div>
            </div>

            <hr>
            <div
                style=" width: 91%; display: flex; flex-direction: row; justify-content: space-between; align-items: center; margin: 10px 0px 10px 0px;">
                <div class="titleInactive" style="font-weight: 600;">
                    Total
                </div>
                <div class="searchBox"
                    style="display: flex;flex-direction: row; width: 26%; border-radius: 6px; margin-right: 20px;">

                    <input style="border:none; outline: none; width:100%;background-color: #fff;  border-radius: 6px;"
                        [(ngModel)]="amount">
                </div>
            </div>
            <div style="height:34px; display: flex;flex-direction: row;justify-content: flex-end;width: 100%;">
                <button (click)="previewReceipt()" style="height: 40px;" class="createReceipt">Preview Receipt</button>
            </div>




        </div>
    </div>
</div>

<!--------***********************viewReceiptModal***************************-->
<ng-template #viewReceiptModal let-modal>
    <div>
        <div class="modal-header" style="border-bottom: none;">
            <h4 class="modal-title" id="modal-basic-title">View receipt</h4>
            <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
                (click)="closeDialog();closeAddReceiptModal()">
                <span aria-hidden="true" style="font-size: 40px; color:#000;">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div style="display: flex; height: 70vh; margin-top: 0px; width: 70vw;">
                <embed [attr.src]="receiptPdfURL" type="application/pdf" width="100%" height="100%">
            </div>
            <div style="display: flex;justify-content: flex-end; margin-top: 10px;">
                <!-- <button type="submit" class=" opd-primary-btn btn-md w125 newBtnStyle"
                    style="text-transform: capitalize;"
                    (click)="onClickBillShare(receiptURL);closeAddReceiptModal();closeDialog();">Share
                    Receipt</button> -->
            </div>


        </div>

    </div>
</ng-template>

<p-toast position="top-right" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class=" p-flex p-flex-column"
            style="flex: 1; background-color:#BAE8FC; opacity: 0.95; display: flex; flex-direction: row; align-items: center; border-left: 8px solid #0D8CCD; border-radius: 8px;">
            <div>
                <img (click)="goBack()" style="height: 36px; margin-left: 12px; width: 36px; align-self: center;"
                    src="assets/images/exclamation-circle-blue.svg" alt="" />
            </div>
            <div class=" p-text-center">
                <div style="font-size: 18px;
                font-weight: bold;
                padding: 12px 0px 0px 12px;
                
                color: #034A70;">{{message.summary}}</div>
                <div
                    style="padding-left: 12px; padding-bottom: 12px; color: #034A70; font-size: 16px; margin-top: -6px;">
                    {{message.detail}}</div>
            </div>
        </div>
    </ng-template>
</p-toast>
<ng-template #delete_modal>
    <div style="width: 100%; padding: 20px;">
    <h4>Delete Reciept?</h4>
    <div style="color: #555; font-size: 16px;">Are you sure you want to delete reciept?</div>
    <div style="padding-top: 16px; display: flex; justify-content: flex-end;">
        <button (click)="closeDialog()" class="cancelBtnUi">Cancel</button>
        <button (click)="onClickBillDelete()" class="deleteBtn">Delete</button>
    </div>
    </div>
</ng-template>

<ng-template #receiptContent_modal>
    <div style="width: 40vw; padding: 20px;">
        <h4>Create Receipt</h4>
        <!-- <div style="color: #555; font-size: 16px;">Are you sure you want to delete reciept?</div> -->
        <div style=" display: flex; justify-content: space-evenly; ">
            <mat-form-field style="width: 50%;">
                <mat-label style="font-size: 16px;">Payer Name</mat-label>
                <input placeholder="Enter Payer Name" matInput [(ngModel)]="payerName">
            </mat-form-field>


            <mat-form-field style="width: 50%; margin-left: 15px;">
                <mat-label style="font-size: 16px;">Payment Method</mat-label>
                <mat-select style="font-size: 16px;" [value]="paymentMethodName"
                    (selectionChange)="onDropdownSelecPayment($event.value)">
                    <mat-option style="font-size: 16px;" *ngFor="let item of paymentMethod" [value]="item">
                        {{item}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

        </div>
        <div *ngIf="isPediatric" class="text-area-style" style="font-size: 18px ;">Received with thanks from <span
                style="font-size: 18px; font-weight: bold;">{{payerName}}</span> towards treatment of <span
                style="font-size: 16px; font-weight: bold;">{{patientName}} </span><br>
            The sum of <span style="font-size: 18px;font-weight: bold;">Rs.{{amount}}/- </span> In words <span
                style="font-size: 18px; font-weight: bold;">{{amountInWords}} only </span> </div>
        <div *ngIf="!isPediatric" class="text-area-style" style="font-size: 18px ;">Received with thanks from <span
                style="font-size: 18px; font-weight: bold;">{{payerName}}</span> <span
                style="font-size: 16px; font-weight: bold;"> </span><br>
            The sum of <span style="font-size: 18px;font-weight: bold;">Rs.{{amount}}/- </span> In words <span
                style="font-size: 18px; font-weight: bold;">{{amountInWords}} only </span> </div>
        <div style="display: flex; flex-direction: row;justify-content: flex-end;">
            <button (click)="closeDialog();closeAddReceiptModal();" class="cancelBtnUi">Cancel</button>
            <button (click)="printReceipt('preview');closeDialog();" class="createReceiptBtn">Create Receipt</button>
        </div>
    </div>
</ng-template>
<!-- <app-top-info-navbar  [parentForm]="'PO'"></app-top-info-navbar> -->
<!-- <app-home-dashboard></app-home-dashboard> -->
<div style="display: flex;flex-direction: row; width: 100%; margin: 16px; ">

  <div style="display: flex;flex-direction: row; " class="patientListAfterMaxWindow">
    <!-- [@fadeInOut] -->
    <div [@slideIn]="slideInStart" *ngIf="showPatientList" style="padding: 0px 8px; ">

      <div style="display: flex; align-items: center;height: 70px; padding-top: 10px; justify-content: space-between;">
        <div style="font-size: 18px;color: #000000;font-weight: 600; ">Todays Patients List</div>

        <div (click)="toggleShowPatientList()" *ngIf="showPatientList" [class.presentPatientList]="showPatientList"
          style="cursor: pointer;">
          <img src="assets/images/delete_blue.svg" />
        </div>
      </div>

      <!-- changed 17vw to 19vw -->
      <input #searchFavourites placeholder="Search Patient" type="text" aria-label="Number" matInput
        [(ngModel)]="searchPatient" class="searchTab" [formControl]="searchPatientControl"
        style="height: 49px; width: 19vw;">
      <div style="overflow-y: auto; max-height: 72vh; padding-bottom: 20px;">
        <div *ngFor="let item of appointmentCollection | filter:searchPatient; let i=index"
          (click)="onClickPatient(item)"
          style="display: flex;flex-direction: row; width: 99.5%; cursor: pointer; justify-content: space-around; height: 48px; "
          [ngClass]="(item.PatientId==patientId) ?  'patientSelectedUI' :'patientUnSelected'">
          <div style="display: flex;flex-direction: row; width: 70%; padding: 8px;">
            <div *ngIf="item.comepleteData.Patient.User.gender=='female'" style="">
              <img style="height: 36px; width: 36px;" src="assets/images/appFemalIcon.svg" class="largerCheckbox" />
            </div>
            <div *ngIf="item.comepleteData.Patient.User.gender=='male'" style="">
              <img style="height: 36px; width: 36px;" src="assets/images/appMaleIcon.svg" class="largerCheckbox" />
            </div>

            <div style="display: flex;flex-direction: column;margin-left: 8px;">
              <div style="color: #000000; font-size: 18px;font-weight: 600;">{{item.fullName.trim().split('
                ')[0].slice(0,1)}}
                {{item.fullName.trim().split(' ')[item.fullName.trim().split(' ').length-1]}}

              </div>
              <div style="color: #7C7C7C;font-size: 14px; margin-top: -8px;">{{item.mobile}}</div>
            </div>
          </div>
          <div style="display: flex;flex-direction: column; width: 30%;">
            <div style="display: flex;flex-direction: column;align-items: flex-end;"
              *ngIf="item.arrived && !item.in &&!item.done">
              <div class="cradUid">Arrived</div>
              <div style="color: #1C59FF;font-size: 16px; margin-right: 8px;text-align: end; font-weight: bold;">
                {{item.arrived | date:"hh:mm a"}} </div>
            </div>
            <div style="display: flex;flex-direction: column;align-items: flex-end;" *ngIf="item.in && !item.done">
              <div class="cradUid">Attending</div>
              <div style="color: #1C59FF;font-size: 16px;margin-right: 8px;text-align: end;font-weight: bold;">
                {{item.in | date:"hh:mm a"}} </div>
            </div>
            <div style="display: flex;flex-direction: column;align-items: flex-end;" *ngIf="item.done ">
              <div class="doneUi">Done</div>
              <!-- <div style="color: #1C59FF;font-size: 16px;margin-right: 8px;text-align: end;font-weight: bold;">
                {{item.done | date:"hh:mm a"}} </div> -->
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="arrowContainer">

      <!-- Your existing div elements -->
      <div (click)="toggleShowPatientList()" *ngIf="!showPatientList" [class.absentPatientList]="!showPatientList"
        style="cursor: pointer;">
        <img src="assets/images/menuIcon.svg" />
      </div>
      <!-- <div (click)="toggleShowPatientList()" *ngIf="showPatientList" [class.presentPatientList]="showPatientList"
        style="cursor: pointer;">
        <img src="assets/images/delete_blue.svg" />
      </div> -->
    </div>

  </div>



  <div class="otherTabsAfterMaxWindow" [ngStyle]="{'width': showPatientList ? '80%' : '98%'}"
    style="display: flex; flex-direction: row;">



    <div style=" display: flex; flex-direction: column;"
      [ngStyle]="{'width': showPatientList ? '75%' : '76%', 'padding-left': showPatientList ? '0px' : '4px'}">
      <div>
        <app-new-rx-top-bar *ngIf="(patientInfo | json) != '{}' && (patientAppointments | json) != '{}' && (appointmentObjPurposeVisit | json) "
          [patientObj]="patientObj" [patientInfo]="patientInfo" [patientApponmentById]="patientApponmentById"
          [ageObj]="ageObj" [patientAppointments]="patientAppointments"
          (ageObjFromTopRx)=" ($event)" [fistNameObj]="fistNameObj" [patientGetPrescriptions]="patientGetPrescriptions"></app-new-rx-top-bar>
      </div>
      <!-- (ageObjFromTopRx)="ageFromTopBar($event)" -->

      <!-- this change is commented for removing error for rror: NG0100: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked. Previous value: 'true'. Current value: 'false'. Find more at https://angular.io/errors/NG0100 -->

      <mat-accordion class="example-headers-align" [multi]="false" id="testing">
        <!-- style.width]="getPanelWidth()" -->
        <mat-expansion-panel (opened)="openGroup('Complaints');" [class.show-patient-list]="showPatientList">
          <mat-expansion-panel-header [ngClass]="(finalSelectedComplaints.length) ?  'titleActive' :'titleInactive'">
            <mat-panel-title>
              <img src="assets/images/complaints_icon.svg" alt="" />
              <span class="titleUi">Concerns and Issues</span>
              <span [ngClass]="{
                  'previewPresentList': showPatientList,
                  'PreviewAbsPatientList': !showPatientList
                }">
                <ng-container *ngFor="let complaint of finalSelectedComplaints; let last = last">
                  {{ complaint }}{{ !last ? ', ' : '' }}
                </ng-container>
              </span>

              <!-- <span class="vertical_dash">  </span> -->
              <!-- <span *ngIf="finalSelectedComplaints.length;" class="complaintsPreviewDash">_</span> -->



            </mat-panel-title>

          </mat-expansion-panel-header>
          <div style=" display: flex; flex-direction: column; width: 100%; ">
            <div>
              <div class="mat-autocomplet" style="margin-top: 4px;">
                <input #searchFavourites placeholder="Look For Concerns..." type="text" aria-label="Number" matInput
                  [(ngModel)]="selectedComplinats" class="searchTab" [matAutocomplete]="auto"
                  [formControl]="complaintsControl">

                <mat-autocomplete (optionSelected)='selectcomplaintDetails($event.option.value,"dropDownValue")'
                  autoActiveFirstOption #auto="matAutocomplete">
                  <mat-option *ngFor="let option of allComplaintsName | async" [value]="option">
                    <span>
                      <span style="font-weight: bold; color: #333;">{{option.complaint}} </span>
                    </span>

                  </mat-option>
                </mat-autocomplete>
              </div>
            </div>

            <div>
              <div class="dataContainer" style="margin: 8px 0px;">
                <div *ngFor="let item of allComplaints.slice(0, 14); let i = index "
                  class="searchable-component-style selectedCard" (click)="selectcomplaintDetails(item,i);">

                  <!-- <img style="margin: 0px 8px 0px 0px;width:22px; color:#007d7e;"
                  src="assets/images/newAddButton.svg" class="largerCheckbox" /> -->
                  <div
                    style="font-size: 18px; font-weight: 500; font-family: 'Mukta', sans-serif;line-height: 16px;     color: rgb(0 0 0 / 60%); font-size: 18px; ">
                    {{item.complaint}} </div>


                </div>

              </div>
            </div>

            <div *ngIf="suggestComplaints.length" style="margin: 6px; font-size: 16px; color: #333;">More symptoms
              related to last complaint</div>
            <div class="dataContainer">
              <div *ngFor="let item of suggestComplaints; let i = index "
                class="searchable-component-style selectedCard" (click)="selectcomplaintDetails(item,i);">

                <!-- <img style="margin: 0px 8px 0px 0px;width:22px; color:#007d7e;"
                src="assets/images/newAddButton.svg" class="largerCheckbox" /> -->
                <div
                  style="font-size: 18px; font-weight: 500; font-family: 'Mukta', sans-serif;line-height: 16px;     color: rgb(0 0 0 / 60%); font-size: 18px; ">
                  {{item?.complaint}} </div>
              </div>


            </div>

            <div style="font-size: 16px; color: #333; padding: 4px; text-align: end;">
              Cannot find the Concern and Issue you are looking for?
              <span (click)="openDialog(addNewModalConcerns, 0);"
                style="font-weight: bold; color: #5A73E2; cursor: pointer;">
                Add New Concern and Issue</span>
            </div>

          </div>

          <div>

            <!-- working here -->

            <!-- <div *ngIf="allSelectedComplaintsAndMoreSym.length "
                style="width: 100%; display: flex;flex-direction: row;">
                <div
                  style="margin: 10px 0px 15px 10px; display: flex;flex-direction: row; width: 28%; justify-content: space-between; ">

                  <div style="color: #333333; font-size: 18px;">Since Day(s)</div>
                </div>
                <div style="margin: 10px 0px 15px 60px;color: #333333; font-size: 18px; width: 20%;">
                  More Symptoms
                </div>

              </div> -->


            <div *ngIf="allSelectedComplaintsAndMoreSym.length"
              style="display: flex; flex-direction: row;margin-left: 0px; margin-top: 8px;width: 80%;">
              <div style="font-size:16px; width: 40%; padding-left: 8px;">Complaint</div>
              <div style="font-size:16px; width: 15%; text-align: center;">Since Day(s)</div>
              <div style="font-size:16px; width: 35%; padding-left: 8px; text-align: center;">Details</div>
              <div style="font-size:16px; width: 10%; "></div>
            </div>

            <div
              style="width: 80%; border: 1px solid #E3E3E3; display: flex;flex-direction: column; flex-wrap: wrap; margin-left: 5px; margin-top: 8px; ">



              <div
                style="width: 100%; display: flex;flex-direction: row;  border: 1px solid #f3f3f3; background-color: #fff;"
                *ngFor="let item of allSelectedComplaintsAndMoreSym; let ind = index">

                <div
                  style="color: #181818; font-size: 18px; font-weight: 600;width: 40%; display: flex; align-items: center; margin-left: 8px;">
                  {{item.name}} </div>
                <div style="display: flex;flex-direction: row;  font-size: 18px;font-weight: 600; width: 15%; ">
                  <!-- <span style="color: #333;">Since </span> -->
                  <input *ngIf="!item.isHistory" style="text-align: center;" type="text" aria-label="Number" matInput
                    [(ngModel)]="item.sinceDay" class="sinceDayUi"
                    (input)="onchangeSinecDays($event.target.value,item.key,ind)" maxlength="2">
                  <!-- <span
                    style="color: #333;">Day(s)</span> -->
                </div>
                <div style="color: #CACACA; font-size: 18px;font-weight: 600;width: 35%;">
                  <div ngbDropdown *ngFor="let itemLIst of [moreSymptomsName]" class="d-inline-block"
                    style="width: 95%;">
                    <button class="btn btn-outline-primary" id="dropdownBasic1" style="
          border: 1px solid #ccc;
        width: 100%;
          
          background-color: #fff;
          margin: 6px; padding:6px;border-radius: 6px;
          " [ngStyle]="{'color': (item.moreSymptoms.length) ? '#333' : '#ccc'}" ngbDropdownToggle>{{item.selected ||
                      itemLIst}} </button>

                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1" style="top:-11px">
                      <button style="margin: 0px 16px 0px 0px !important"
                        *ngFor="let moreSysmtoms of item.moreSymptoms; let i = index"
                        (click)="onDropdownSelectTemplate(i, item,ind)" ngbDropdownItem>{{moreSysmtoms}}</button>
                    </div>
                  </div>

                </div>
                <div style="width: 10%; cursor: pointer; display: flex; align-items: center; justify-content: center;">
                  <img (click)="onDeleteComplaints(item,ind)" style="height: 30px; width: 30px;"
                    src="assets/images/close_red_outline.png" alt="" />
                </div>
              </div>











            </div>

            <!-- 
            <div *ngIf="backToEdit">

              <div style="font-size: 18px; color: #333; font-weight: bold; padding: 4px; margin-top: 8px;">
                Selected Concerns</div>

              <div class="dataContainer" style="width: 100%; margin: 4px 0px 4px 0px;">
                <div *ngFor="let item of finalSelectedComplaints; let i = index "
                  style="border: 1px solid #BFC1C6; background-color: #fff;"
                  class="searchable-component-style selectedCard">
                 


                  <div
                    style="font-size: 16px; font-weight: 500; font-family: 'Mukta', sans-serif;line-height: 16px; color: #181818; ">
                    {{item}} </div>
                  <div (click)="onDeleteComplaints(item, i)" style="padding: 0px 0px 0px 0px; cursor: pointer;">
                    <img style="height: 20px; width: 20px; margin-left: 6px;" src="assets/images/close_red_outline.png"
                      alt="" />
                  </div>
                </div>
              </div>
            </div> -->

          </div>

        </mat-expansion-panel>
        <mat-expansion-panel [class.show-patient-list]="showPatientList">
          <div
            style="display: flex;flex-direction: row;justify-content: end; color: #5A73E2;font-size: 18px; font-weight: bold;">
            <span (click)="goToTab('Visits');" style="cursor: pointer;border: 1px solid #5a73e2;
              padding: 8px;
              border-radius: 6px; ">View in Details</span>
          </div>
          <!-- <div style="display: flex; flex-direction: row; justify-content: end; color: #5A73E2; font-size: 16px; font-weight: bold;">
            <button type="button" (click)="goToTab('Visits')" style="cursor: pointer; border: 1px solid #5a73e2; padding: 8px; border-radius: 6px;">
              View in Details

            </button>
          </div> -->
          <mat-expansion-panel-header [ngClass]="( hasPositiveValues()) ?  'titleActive' :'titleInactive'">
            <!-- [ngClass]="(selectedVisit.weight>0 || selectedVisit.height>0 || selectedVisit.Temp>0 || selectedVisit.hv>0 || selectedVisit.cc>0) ?  'titleActive' :'titleInactive'" -->

            <mat-panel-title>
              <img src="assets/images/vital_icon.svg" alt="" />
              <span class="titleUi"> Vitals</span>
              <!-- <span *ngIf="selectedVisit.weight" class="complaintsPreviewDash">_</span> -->
              <!-- <span class="vertical_dash">  </span> -->
              <!-- <span class="complaintsPreview" style="left: 214px;">
              {{ filteredVitalSigns.join(' ') }}
            </span> -->
              <span [ngClass]="{
                  'previewPresentList': showPatientList,
                  'PreviewAbsPatientList': !showPatientList
                  
                }">


                <span *ngIf="selectedVisit.weight"> Weight-{{selectedVisit.weight}}</span>
                <ng-container *ngIf="selectedVisit.height || selectedVisit.Temp || selectedVisit.hc || selectedVisit.cc || selectedVisit.spo2 || 
                  selectedVisit.bpSys || selectedVisit.bpDia || selectedVisit.Heartrate || selectedVisit.Pulse || 
                  selectedVisit.Bloodhaemoglobin || selectedVisit.BloodSugarRandom || selectedVisit.Bloodsugar_F || 
                  selectedVisit.Bloodsugar_PP || selectedVisit.Blood_Hba1c || selectedVisit.Chol_HDL || 
                  selectedVisit.Chol_LDL || selectedVisit.TotalCholesterol || selectedVisit.Triglycerides_LFT">
                  <span *ngIf="selectedVisit.weight">,</span>
                </ng-container>
                <ng-container *ngIf="!( selectedVisit.height || selectedVisit.Temp || selectedVisit.hc || selectedVisit.cc || selectedVisit.spo2 || 
                  selectedVisit.bpSys || selectedVisit.bpDia || selectedVisit.Heartrate || selectedVisit.Pulse || 
                  selectedVisit.Bloodhaemoglobin || selectedVisit.BloodSugarRandom || selectedVisit.Bloodsugar_F || 
                  selectedVisit.Bloodsugar_PP || selectedVisit.Blood_Hba1c || selectedVisit.Chol_HDL || 
                  selectedVisit.Chol_LDL || selectedVisit.TotalCholesterol || selectedVisit.Triglycerides_LFT)">
                  <span *ngIf="selectedVisit.weight">.</span>
                </ng-container>
                <span *ngIf="selectedVisit.height"> Height-{{selectedVisit.height}}</span>
                <ng-container *ngIf="selectedVisit.height && selectedVisit.Temp || selectedVisit.hc || selectedVisit.cc || selectedVisit.spo2 ||
                  selectedVisit.bpSys || selectedVisit.bpDia || selectedVisit.Heartrate || selectedVisit.Pulse || 
                  selectedVisit.Bloodhaemoglobin || selectedVisit.BloodSugarRandom || selectedVisit.Bloodsugar_F || 
                  selectedVisit.Bloodsugar_PP || selectedVisit.Blood_Hba1c || selectedVisit.Chol_HDL || 
                  selectedVisit.Chol_LDL || selectedVisit.TotalCholesterol || selectedVisit.Triglycerides_LFT">
                  <span *ngIf="selectedVisit.height">,</span>
                </ng-container>
                <ng-container *ngIf="!( selectedVisit.Temp || selectedVisit.hc || selectedVisit.cc || selectedVisit.spo2 ||  
                  selectedVisit.bpSys || selectedVisit.bpDia || selectedVisit.Heartrate || selectedVisit.Pulse || 
                  selectedVisit.Bloodhaemoglobin || selectedVisit.BloodSugarRandom || selectedVisit.Bloodsugar_F || 
                  selectedVisit.Bloodsugar_PP || selectedVisit.Blood_Hba1c || selectedVisit.Chol_HDL || 
                  selectedVisit.Chol_LDL || selectedVisit.TotalCholesterol || selectedVisit.Triglycerides_LFT)">
                  <span *ngIf="selectedVisit.height ">.</span>

                </ng-container>
                <span *ngIf="selectedVisit.Temp"> Temp-{{selectedVisit.Temp}}</span>
                <ng-container *ngIf="selectedVisit.hc || selectedVisit.cc || selectedVisit.spo2 || 
                  selectedVisit.bpSys || selectedVisit.bpDia || selectedVisit.Heartrate || selectedVisit.Pulse || 
                  selectedVisit.Bloodhaemoglobin || selectedVisit.BloodSugarRandom || selectedVisit.Bloodsugar_F || 
                  selectedVisit.Bloodsugar_PP || selectedVisit.Blood_Hba1c || selectedVisit.Chol_HDL || 
                  selectedVisit.Chol_LDL || selectedVisit.TotalCholesterol || selectedVisit.Triglycerides_LFT">
                  <span *ngIf="selectedVisit.Temp">,</span>
                </ng-container>
                <ng-container *ngIf="!( selectedVisit.hc || selectedVisit.cc || selectedVisit.spo2 || 
                  selectedVisit.bpSys || selectedVisit.bpDia || selectedVisit.Heartrate || selectedVisit.Pulse || 
                  selectedVisit.Bloodhaemoglobin || selectedVisit.BloodSugarRandom || selectedVisit.Bloodsugar_F || 
                  selectedVisit.Bloodsugar_PP || selectedVisit.Blood_Hba1c || selectedVisit.Chol_HDL || 
                  selectedVisit.Chol_LDL || selectedVisit.TotalCholesterol || selectedVisit.Triglycerides_LFT)">
                  <span *ngIf="selectedVisit.Temp">.</span>

                </ng-container>

                <span *ngIf="selectedVisit.hc"> Head Circumference-{{selectedVisit.hc}}</span>
                <ng-container *ngIf=" selectedVisit.cc || selectedVisit.spo2 || 
                  selectedVisit.bpSys || selectedVisit.bpDia || selectedVisit.Heartrate || selectedVisit.Pulse || 
                  selectedVisit.Bloodhaemoglobin || selectedVisit.BloodSugarRandom || selectedVisit.Bloodsugar_F || 
                  selectedVisit.Bloodsugar_PP || selectedVisit.Blood_Hba1c || selectedVisit.Chol_HDL || 
                  selectedVisit.Chol_LDL || selectedVisit.TotalCholesterol || selectedVisit.Triglycerides_LFT">
                  <span *ngIf="selectedVisit.hc">,</span>
                </ng-container>
                <ng-container *ngIf="!(selectedVisit.cc || selectedVisit.spo2 || 
                  selectedVisit.bpSys || selectedVisit.bpDia || selectedVisit.Heartrate || selectedVisit.Pulse || 
                  selectedVisit.Bloodhaemoglobin || selectedVisit.BloodSugarRandom || selectedVisit.Bloodsugar_F || 
                  selectedVisit.Bloodsugar_PP || selectedVisit.Blood_Hba1c || selectedVisit.Chol_HDL || 
                  selectedVisit.Chol_LDL || selectedVisit.TotalCholesterol || selectedVisit.Triglycerides_LFT)">
                  <span *ngIf="selectedVisit.hc">.</span>

                </ng-container>

                <span *ngIf="selectedVisit.cc !== undefined && selectedVisit.cc !== 'undefined' && selectedVisit.cc">
                  Chest Circumference-{{selectedVisit.cc}}</span>
                <ng-container *ngIf=" selectedVisit.spo2 || 
                  selectedVisit.bpSys || selectedVisit.bpDia || selectedVisit.Heartrate || selectedVisit.Pulse || 
                  selectedVisit.Bloodhaemoglobin || selectedVisit.BloodSugarRandom || selectedVisit.Bloodsugar_F || 
                  selectedVisit.Bloodsugar_PP || selectedVisit.Blood_Hba1c || selectedVisit.Chol_HDL || 
                  selectedVisit.Chol_LDL || selectedVisit.TotalCholesterol || selectedVisit.Triglycerides_LFT">
                  <span *ngIf="selectedVisit.cc>0">,</span>
                </ng-container>
                <ng-container *ngIf="!(selectedVisit.spo2 || 
                  selectedVisit.bpSys || selectedVisit.bpDia || selectedVisit.Heartrate || selectedVisit.Pulse || 
                  selectedVisit.Bloodhaemoglobin || selectedVisit.BloodSugarRandom || selectedVisit.Bloodsugar_F || 
                  selectedVisit.Bloodsugar_PP || selectedVisit.Blood_Hba1c || selectedVisit.Chol_HDL || 
                  selectedVisit.Chol_LDL || selectedVisit.TotalCholesterol || selectedVisit.Triglycerides_LFT)">
                  <span *ngIf="selectedVisit.cc">.</span>

                </ng-container>

                <span *ngIf="selectedVisit.spo2"> SPO2-{{selectedVisit.spo2}}</span>
                <ng-container *ngIf=" 
                  selectedVisit.bpSys || selectedVisit.bpDia || selectedVisit.Heartrate || selectedVisit.Pulse || 
                  selectedVisit.Bloodhaemoglobin || selectedVisit.BloodSugarRandom || selectedVisit.Bloodsugar_F || 
                  selectedVisit.Bloodsugar_PP || selectedVisit.Blood_Hba1c || selectedVisit.Chol_HDL || 
                  selectedVisit.Chol_LDL || selectedVisit.TotalCholesterol || selectedVisit.Triglycerides_LFT">
                  <span *ngIf="selectedVisit.spo2 && selectedVisit.lmp !== 'null'">,</span>
                </ng-container>
                <ng-container *ngIf="!(
                  selectedVisit.bpSys || selectedVisit.bpDia || selectedVisit.Heartrate || selectedVisit.Pulse || 
                  selectedVisit.Bloodhaemoglobin || selectedVisit.BloodSugarRandom || selectedVisit.Bloodsugar_F || 
                  selectedVisit.Bloodsugar_PP || selectedVisit.Blood_Hba1c || selectedVisit.Chol_HDL || 
                  selectedVisit.Chol_LDL || selectedVisit.TotalCholesterol || selectedVisit.Triglycerides_LFT)">
                  <span *ngIf="selectedVisit.spo2">.</span>

                </ng-container>
                <span *ngIf="selectedVisit.lmp !== 'null' && showLMPAfterEight && selectedVisit.lmp ">LMP-{{
                  selectedVisit.lmp |
                  date: 'dd/MM/yyyy'
                  }}</span>

                <ng-container *ngIf="
                  selectedVisit.bpSys || selectedVisit.bpDia || selectedVisit.Heartrate || selectedVisit.Pulse || 
                  selectedVisit.Bloodhaemoglobin || selectedVisit.BloodSugarRandom || selectedVisit.Bloodsugar_F || 
                  selectedVisit.Bloodsugar_PP || selectedVisit.Blood_Hba1c || selectedVisit.Chol_HDL || 
                  selectedVisit.Chol_LDL || selectedVisit.TotalCholesterol || selectedVisit.Triglycerides_LFT">
                  <span *ngIf="showLMPAfterEight && selectedVisit.lmp !== 'null'">,</span>
                </ng-container>
                <ng-container *ngIf="!(
                  selectedVisit.bpSys || selectedVisit.bpDia || selectedVisit.Heartrate || selectedVisit.Pulse || 
                  selectedVisit.Bloodhaemoglobin || selectedVisit.BloodSugarRandom || selectedVisit.Bloodsugar_F || 
                  selectedVisit.Bloodsugar_PP || selectedVisit.Blood_Hba1c || selectedVisit.Chol_HDL || 
                  selectedVisit.Chol_LDL || selectedVisit.TotalCholesterol || selectedVisit.Triglycerides_LFT)">
                  <span *ngIf="showLMPAfterEight && selectedVisit.lmp && selectedVisit.lmp !== 'null'">.</span>

                </ng-container>



                <span *ngIf="selectedVisit.bpSys"> BP(Sys)-{{selectedVisit.bpSys}}</span>
                <ng-container *ngIf="selectedVisit.bpDia || selectedVisit.Heartrate || selectedVisit.Pulse || 
                  selectedVisit.Bloodhaemoglobin || selectedVisit.BloodSugarRandom || selectedVisit.Bloodsugar_F || 
                  selectedVisit.Bloodsugar_PP || selectedVisit.Blood_Hba1c || selectedVisit.Chol_HDL || 
                  selectedVisit.Chol_LDL || selectedVisit.TotalCholesterol || selectedVisit.Triglycerides_LFT">
                  <span *ngIf="selectedVisit.bpSys">,</span>
                </ng-container>
                <ng-container *ngIf="!( selectedVisit.bpDia || selectedVisit.Heartrate || selectedVisit.Pulse || 
                  selectedVisit.Bloodhaemoglobin || selectedVisit.BloodSugarRandom || selectedVisit.Bloodsugar_F || 
                  selectedVisit.Bloodsugar_PP || selectedVisit.Blood_Hba1c || selectedVisit.Chol_HDL || 
                  selectedVisit.Chol_LDL || selectedVisit.TotalCholesterol || selectedVisit.Triglycerides_LFT)">
                  <span *ngIf="selectedVisit.bpSys">.</span>

                </ng-container>

                <span *ngIf="selectedVisit.bpDia"> BP(Dia)-{{selectedVisit.bpDia}}</span>
                <ng-container *ngIf="
                selectedVisit.Heartrate || selectedVisit.Pulse || 
                  selectedVisit.Bloodhaemoglobin || selectedVisit.BloodSugarRandom || selectedVisit.Bloodsugar_F || 
                  selectedVisit.Bloodsugar_PP || selectedVisit.Blood_Hba1c || selectedVisit.Chol_HDL || 
                  selectedVisit.Chol_LDL || selectedVisit.TotalCholesterol || selectedVisit.Triglycerides_LFT">
                  <span *ngIf="selectedVisit.bpDia">,</span>
                </ng-container>
                <ng-container *ngIf="!(selectedVisit.Heartrate || selectedVisit.Pulse || 
                  selectedVisit.Bloodhaemoglobin || selectedVisit.BloodSugarRandom || selectedVisit.Bloodsugar_F || 
                  selectedVisit.Bloodsugar_PP || selectedVisit.Blood_Hba1c || selectedVisit.Chol_HDL || 
                  selectedVisit.Chol_LDL || selectedVisit.TotalCholesterol || selectedVisit.Triglycerides_LFT)">
                  <span *ngIf="selectedVisit.bpDia">.</span>

                </ng-container>

                <span *ngIf="selectedVisit.Heartrate"> Heart Rate-{{selectedVisit.Heartrate}}</span>
                <ng-container *ngIf="selectedVisit.Pulse || 
                    selectedVisit.Bloodhaemoglobin || selectedVisit.BloodSugarRandom || selectedVisit.Bloodsugar_F || 
                    selectedVisit.Bloodsugar_PP || selectedVisit.Blood_Hba1c || selectedVisit.Chol_HDL || 
                    selectedVisit.Chol_LDL || selectedVisit.TotalCholesterol || selectedVisit.Triglycerides_LFT">
                  <span *ngIf="selectedVisit.Heartrate">,</span>
                </ng-container>
                <ng-container *ngIf="!(selectedVisit.Pulse || 
                    selectedVisit.Bloodhaemoglobin || selectedVisit.BloodSugarRandom || selectedVisit.Bloodsugar_F || 
                    selectedVisit.Bloodsugar_PP || selectedVisit.Blood_Hba1c || selectedVisit.Chol_HDL || 
                    selectedVisit.Chol_LDL || selectedVisit.TotalCholesterol || selectedVisit.Triglycerides_LFT)">
                  <span *ngIf="selectedVisit.Heartrate">.</span>

                </ng-container>


                <span *ngIf="selectedVisit.Pulse"> Pulse-{{selectedVisit.Pulse}}</span>
                <ng-container *ngIf=" 
                  selectedVisit.Bloodhaemoglobin || selectedVisit.BloodSugarRandom || selectedVisit.Bloodsugar_F || 
                  selectedVisit.Bloodsugar_PP || selectedVisit.Blood_Hba1c || selectedVisit.Chol_HDL || 
                  selectedVisit.Chol_LDL || selectedVisit.TotalCholesterol || selectedVisit.Triglycerides_LFT">
                  <span *ngIf="selectedVisit.Pulse">,</span>
                </ng-container>
                <ng-container *ngIf="!(selectedVisit.Bloodhaemoglobin || selectedVisit.BloodSugarRandom || selectedVisit.Bloodsugar_F || 
                  selectedVisit.Bloodsugar_PP || selectedVisit.Blood_Hba1c || selectedVisit.Chol_HDL || 
                  selectedVisit.Chol_LDL || selectedVisit.TotalCholesterol || selectedVisit.Triglycerides_LFT)">
                  <span *ngIf="selectedVisit.Pulse">.</span>

                </ng-container>

                <span *ngIf="selectedVisit.Bloodhaemoglobin">Hemoglobin-{{selectedVisit.Bloodhaemoglobin}}</span>
                <ng-container *ngIf="selectedVisit.BloodSugarRandom || selectedVisit.Bloodsugar_F || 
                  selectedVisit.Bloodsugar_PP || selectedVisit.Blood_Hba1c || selectedVisit.Chol_HDL || 
                  selectedVisit.Chol_LDL || selectedVisit.TotalCholesterol || selectedVisit.Triglycerides_LFT">
                  <span *ngIf="selectedVisit.Bloodhaemoglobin">,</span>
                </ng-container>
                <ng-container *ngIf="!(selectedVisit.BloodSugarRandom || selectedVisit.Bloodsugar_F || 
                  selectedVisit.Bloodsugar_PP || selectedVisit.Blood_Hba1c || selectedVisit.Chol_HDL || 
                  selectedVisit.Chol_LDL || selectedVisit.TotalCholesterol || selectedVisit.Triglycerides_LFT)">
                  <span *ngIf="selectedVisit.Bloodhaemoglobin">.</span>

                </ng-container>


                <span *ngIf="selectedVisit.BloodSugarRandom">
                  BloodSugar(Random)-{{selectedVisit.BloodSugarRandom}}</span>
                <ng-container *ngIf="selectedVisit.Bloodsugar_F || 
                    selectedVisit.Bloodsugar_PP || selectedVisit.Blood_Hba1c || selectedVisit.Chol_HDL || 
                    selectedVisit.Chol_LDL || selectedVisit.TotalCholesterol || selectedVisit.Triglycerides_LFT">
                  <span *ngIf="selectedVisit.BloodSugarRandom">,</span>
                </ng-container>
                <ng-container *ngIf="!(selectedVisit.Bloodsugar_F || 
                    selectedVisit.Bloodsugar_PP || selectedVisit.Blood_Hba1c || selectedVisit.Chol_HDL || 
                    selectedVisit.Chol_LDL || selectedVisit.TotalCholesterol || selectedVisit.Triglycerides_LFT)">
                  <span *ngIf="selectedVisit.BloodSugarRandom">.</span>

                </ng-container>

                <span *ngIf="selectedVisit.Bloodsugar_F"> BloodSugar(F)-{{selectedVisit.Bloodsugar_F}}</span>
                <ng-container *ngIf="
                  selectedVisit.Bloodsugar_PP || selectedVisit.Blood_Hba1c || selectedVisit.Chol_HDL || 
                  selectedVisit.Chol_LDL || selectedVisit.TotalCholesterol || selectedVisit.Triglycerides_LFT">
                  <span *ngIf="selectedVisit.Bloodsugar_F">,</span>
                </ng-container>
                <ng-container *ngIf="!(
                  selectedVisit.Bloodsugar_PP || selectedVisit.Blood_Hba1c || selectedVisit.Chol_HDL || 
                  selectedVisit.Chol_LDL || selectedVisit.TotalCholesterol || selectedVisit.Triglycerides_LFT)">
                  <span *ngIf="selectedVisit.Bloodsugar_F">.</span>

                </ng-container>

                <span *ngIf="selectedVisit.Bloodsugar_PP"> BloodSugar(PP)-{{selectedVisit.Bloodsugar_PP}}</span>
                <ng-container *ngIf="selectedVisit.Blood_Hba1c || selectedVisit.Chol_HDL || 
                  selectedVisit.Chol_LDL || selectedVisit.TotalCholesterol || selectedVisit.Triglycerides_LFT">
                  <span *ngIf="selectedVisit.Bloodsugar_PP">,</span>
                </ng-container>
                <ng-container *ngIf="!( selectedVisit.Blood_Hba1c || selectedVisit.Chol_HDL || 
                  selectedVisit.Chol_LDL || selectedVisit.TotalCholesterol || selectedVisit.Triglycerides_LFT)">
                  <span *ngIf="selectedVisit.Bloodsugar_PP">.</span>

                </ng-container>

                <span *ngIf="selectedVisit.Blood_Hba1c"> Blood(HbA1c)-{{selectedVisit.Blood_Hba1c}}</span>
                <ng-container *ngIf="selectedVisit.Chol_HDL || 
                  selectedVisit.Chol_LDL || selectedVisit.TotalCholesterol || selectedVisit.Triglycerides_LFT">
                  <span *ngIf="selectedVisit.Blood_Hba1c">,</span>
                </ng-container>
                <ng-container *ngIf="!( selectedVisit.Chol_HDL || 
                  selectedVisit.Chol_LDL || selectedVisit.TotalCholesterol || selectedVisit.Triglycerides_LFT)">
                  <span *ngIf="selectedVisit.Blood_Hba1c">.</span>

                </ng-container>


                <span *ngIf="selectedVisit.Chol_HDL"> Cholesterol(HDL)-{{selectedVisit.Chol_HDL}}</span>
                <ng-container *ngIf="
                  selectedVisit.Chol_LDL || selectedVisit.TotalCholesterol || selectedVisit.Triglycerides_LFT">
                  <span *ngIf="selectedVisit.Chol_HDL">,</span>
                </ng-container>
                <ng-container *ngIf="!(
                  selectedVisit.Chol_LDL || selectedVisit.TotalCholesterol || selectedVisit.Triglycerides_LFT)">
                  <span *ngIf="selectedVisit.Chol_HDL">.</span>

                </ng-container>

                <span *ngIf="selectedVisit.Chol_LDL"> Chol LDL- {{selectedVisit.Chol_LDL}}</span>
                <ng-container *ngIf=" selectedVisit.TotalCholesterol || selectedVisit.Triglycerides_LFT">
                  <span *ngIf="selectedVisit.Chol_LDL">,</span>
                </ng-container>
                <ng-container *ngIf="!(selectedVisit.TotalCholesterol || selectedVisit.Triglycerides_LFT)">
                  <span *ngIf="selectedVisit.Chol_LDL">.</span>

                </ng-container>

                <span *ngIf="selectedVisit.TotalCholesterol"> Total
                  Cholesterol-{{selectedVisit.TotalCholesterol}}</span>
                <ng-container *ngIf="selectedVisit.Triglycerides_LFT">
                  <span *ngIf="selectedVisit.TotalCholesterol">,</span>
                </ng-container>
                <ng-container *ngIf="!(selectedVisit.Triglycerides_LFT)">
                  <span *ngIf="selectedVisit.TotalCholesterol">.</span>

                </ng-container>

                <span *ngIf="selectedVisit.Triglycerides_LFT">
                  Triglycerided-{{selectedVisit.Triglycerides_LFT}}.</span>

                <ng-container *ngIf="!(selectedVisit.Triglycerides_LFT)">
                  <span *ngIf="selectedVisit.Triglycerides_LFT">.</span>

                </ng-container>

              </span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-vertical-stepper [disableRipple]="true" labelPosition="bottom" #stepper [linear]="true">
            <mat-step [completed]="completed">

              <ng-template matStepLabel>Vitals</ng-template>
              <div style="display: flex;flex-direction: row; width: 100%;">
                <mat-form-field appearance="outline" style="width: 125px;">
                  <mat-label style="font-size: 18px; margin-top: 0px !important;">Weight (kg)</mat-label>
                  <input matInput maxlength="6" (keypress)="validateNumber($event)" style="font-size: 18px"
                    style="font-size: 18px" [(ngModel)]="selectedVisit.weight">

                </mat-form-field>
                <mat-form-field appearance="outline" style="width: 125px; margin-left:8px">
                  <mat-label style="font-size: 18px;">Height (cm)</mat-label>
                  <input matInput maxlength="6" (keypress)="validateNumber($event)" style="font-size: 18px"
                    [(ngModel)]="selectedVisit.height">
                </mat-form-field>
                <!-- Head Circumference fields & Chest Circumference -->

                <div *ngIf="!(ageObj?.years > 6 || (ageObj?.years === 6 && ageObj?.months >= 0 && ageObj?.days >= 0))">

                  <mat-form-field appearance="outline" style="width: 125px; margin-left:8px">
                    <mat-label style="font-size: 18px;">HC (cm)</mat-label>
                    <input matInput maxlength="6" (keypress)="validateNumber($event)" style="font-size: 18px"
                      [(ngModel)]="selectedVisit.hc">

                  </mat-form-field>
                  <mat-form-field appearance="outline" style="width: 125px; margin-left:8px">
                    <mat-label style="font-size: 18px;">CC (cm)</mat-label>
                    <input matInput maxlength="6" (keypress)="validateNumber($event)" style="font-size: 18px"
                      [(ngModel)]="selectedVisit.cc">
                  </mat-form-field>


                </div>
                <mat-form-field appearance="outline" style="width: 125px; margin-left:8px">
                  <mat-label style="font-size: 18px;">Temp (F)</mat-label>
                  <input matInput maxlength="6" (keypress)="validateNumber($event)" style="font-size: 18px"
                    [(ngModel)]="selectedVisit.Temp">
                </mat-form-field>
                <mat-form-field appearance="outline" style="width: 125px; margin-left:8px">
                  <mat-label style="font-size: 18px;">SPO2 (%)</mat-label>
                  <input matInput style="font-size: 18px" [(ngModel)]="selectedVisit.spo2" maxlength="6"
                    (keypress)="validateNumber($event)">
                </mat-form-field>
                <!-- <mat-form-field appearance="outline" style="width: 140px; margin-left:8px">
                    <mat-label style="font-size: 18px;">LMP</mat-label>
                    <input matInput style="font-size: 18px" [(ngModel)]="selectedVisit.lmp" maxlength="6" (keypress)="validateNumber($event)">
                  </mat-form-field> -->
                <div *ngIf="showLMPAfterEight">
                  <mat-form-field appearance="outline" style="width: 125px; margin-left:8px">
                    <mat-label>LMP</mat-label>
                    <input matInput [matDatepicker]="picker" [max]="todayDate" [(ngModel)]="lmpDate"
                      placeholder="YYYY-MM-DD" (dateChange)="onDateChangeForLmpDate($event)">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>

                  </mat-form-field>
                  <!-- <mat-form-field appearance="outline" class="vitalsInputField">
                      <mat-label style="font-size: 18px;">LMP</mat-label>
                      <input matInput type="date"  [matDatepicker]="picker" [max]="maxDate" [(ngModel)]="lmpDate"
                        (dateChange)="onDateChangeLmp($event)">
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field> -->
                </div>
              </div>

            </mat-step>
            <mat-step>
              <ng-template matStepLabel>BP & Heart</ng-template>
              <ng-template matStepperIcon="edit">

              </ng-template>

              <div style="display: flex;flex-direction: row;">
                <mat-form-field appearance="outline" style="width: 125px;">
                  <mat-label style="font-size: 18px;">BP(Sys) (mmHg)</mat-label>
                  <input matInput style="font-size: 18px" [(ngModel)]="selectedVisit.bpSys" maxlength="6"
                    (keypress)="validateNumber($event)">

                </mat-form-field>
                <mat-form-field appearance="outline" style="margin-left: 8px;width: 125px;">
                  <mat-label style="font-size: 18px;">BP(Dia) (mmHg)</mat-label>
                  <input matInput style="font-size: 18px" [(ngModel)]="selectedVisit.bpDia" maxlength="6"
                    (keypress)="validateNumber($event)">
                </mat-form-field>
                <mat-form-field appearance="outline" style="margin-left: 8px;width: 125px;">
                  <mat-label style="font-size: 18px;">Heart Rate (min)</mat-label>
                  <input matInput style="font-size: 18px" [(ngModel)]="selectedVisit.Heartrate" maxlength="6"
                    (keypress)="validateNumber($event)">
                </mat-form-field>
                <mat-form-field appearance="outline" style="margin-left: 8px;width: 125px;">
                  <mat-label style="font-size: 18px;">Pulse (min)</mat-label>
                  <input matInput style="font-size: 18px" [(ngModel)]="selectedVisit.Pulse" maxlength="6"
                    (keypress)="validateNumber($event)">
                </mat-form-field>
              </div>

            </mat-step>
            <mat-step [completed]="completed">
              <ng-template matStepLabel>Blood Analysis(Sugar)</ng-template>
              <ng-template matStepperIcon="edit">
                3
              </ng-template>
              <div style="display: flex;flex-direction: row;">
                <mat-form-field appearance="outline" style="width: 125px;">
                  <mat-label style="font-size: 18px;">Hemoglobin</mat-label>
                  <input matInput style="font-size: 18px" [(ngModel)]="selectedVisit.Bloodhaemoglobin" maxlength="6"
                    (keypress)="validateNumber($event)">

                </mat-form-field>
                <mat-form-field appearance="outline" style="margin-left: 8px;width: 125px;">
                  <mat-label style="font-size: 18px;">Random</mat-label>
                  <input matInput style="font-size: 18px" [(ngModel)]="selectedVisit.BloodSugarRandom" maxlength="6"
                    (keypress)="validateNumber($event)">

                </mat-form-field>
                <mat-form-field appearance="outline" style="margin-left: 8px;width: 125px;">
                  <mat-label style="font-size: 18px;">Fasting</mat-label>
                  <input matInput style="font-size: 18px" [(ngModel)]="selectedVisit.Bloodsugar_F" maxlength="6"
                    (keypress)="validateNumber($event)">
                </mat-form-field>
                <mat-form-field appearance="outline" style="margin-left: 8px;width: 125px;">
                  <mat-label style="font-size: 18px;">Post Prandial</mat-label>
                  <input matInput style="font-size: 18px" [(ngModel)]="selectedVisit.Bloodsugar_PP" maxlength="6"
                    (keypress)="validateNumber($event)">
                </mat-form-field>
                <mat-form-field appearance="outline" style="margin-left: 8px;width: 125px;">
                  <mat-label style="font-size: 18px;">HbA1c</mat-label>
                  <input matInput style="font-size: 18px" [(ngModel)]="selectedVisit.Blood_Hba1c" maxlength="6"
                    (keypress)="validateNumber($event)">
                </mat-form-field>
              </div>

            </mat-step>

            <mat-step>
              <ng-template matStepLabel>Lipid Profile</ng-template>
              <ng-template matStepperIcon="edit">
                3
              </ng-template>

              <div style="display: flex;flex-direction: row;">
                <mat-form-field appearance="outline" style="width: 125px;">
                  <mat-label style="font-size: 18px;">Chol(HDL)(mg/dL)</mat-label>
                  <input matInput style="font-size: 18px" [(ngModel)]="selectedVisit.Chol_HDL" maxlength="6"
                    (keypress)="validateNumber($event)">

                </mat-form-field>
                <mat-form-field appearance="outline" style="margin-left: 8px; width: 125px;">
                  <mat-label style="font-size: 18px;">Chol(LDL)(mg/dL)</mat-label>
                  <input matInput style="font-size: 18px" [(ngModel)]="selectedVisit.Chol_LDL" maxlength="6"
                    (keypress)="validateNumber($event)">
                </mat-form-field>
                <mat-form-field appearance="outline" style="margin-left: 8px; width: 125px;">
                  <mat-label style="font-size: 18px;">Total Chol(mg/dL)</mat-label>
                  <input matInput style="font-size: 18px" [(ngModel)]="selectedVisit.TotalCholesterol" maxlength="6"
                    (keypress)="validateNumber($event)">
                </mat-form-field>
                <mat-form-field appearance="outline" style="margin-left: 8px; width: 125px;">
                  <mat-label style="font-size: 18px;">Triglyc(mg/dL)</mat-label>
                  <input matInput style="font-size: 18px" [(ngModel)]="selectedVisit.Triglycerides_LFT" maxlength="6"
                    (keypress)="validateNumber($event)">
                </mat-form-field>
              </div>

            </mat-step>
          </mat-vertical-stepper>

        </mat-expansion-panel>
        <mat-expansion-panel (opened)="openGroup('Diagnosis');" [class.show-patient-list]="showPatientList">
          <mat-expansion-panel-header [ngClass]="(selectedDignosisName.length) ?  'titleActive' :'titleInactive'">
            <mat-panel-title>
              <img src="assets/images/diganosis_icon.svg" alt="" />
              <!-- <span class="titleUi"> Diagnosis</span>
            <span *ngIf="selectedDignosisName.length" class="complaintsPreviewDash">_</span>

            <span *ngFor="let item of selectedDignosisName; let last = last" class="complaintsPreview">{{item}}{{
              !last ? ', ' : ' ' }}</span> -->
              <span class="titleUi">Diagnosis</span>
              <span [ngClass]="{
                  'previewPresentList': showPatientList,
                  'PreviewAbsPatientList': !showPatientList
                }">
                <ng-container *ngFor="let diagnosis of selectedDignosisName; let last = last">
                  {{ diagnosis }}{{ !last ? ', ' : '' }}
                </ng-container>
              </span>

            </mat-panel-title>

          </mat-expansion-panel-header>
          <div style=" display: flex; flex-direction: column; width: 100%; margin-top: 6px;">
            <input #searchFavourites placeholder="Search Diagnosis" type="text" aria-label="Number" matInput
              [(ngModel)]="selectedDiagnosis" class="searchTab" [matAutocomplete]="auto1"
              [formControl]="diagnosisControl">

            <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete"
              (optionSelected)='onDropdownSelectDignosis($event.option.value)'>
              <mat-option *ngFor="let option of allDignosisName | async" [value]="option">
                <span>
                  <span style="font-weight: bold; color: #333;">{{option.diagnosis}} </span>
                </span>

              </mat-option>
            </mat-autocomplete>

          </div>
          <div class="dataContainer" style="margin: 8px 0px; max-height: 160px; overflow-y: scroll; width: 100%;">
            <div *ngFor="let item of allDignosis let i = index " class="searchable-component-style selectedCard"
              (click)="selectDignosisModalValue(item,i);">
              <!-- 
            <img style="margin: 0px 8px 0px 0px;width:22px; color:#007d7e;" src="assets/images/newAddButton.svg"
              class="largerCheckbox" /> -->
              <div
                style="font-size: 18px; font-weight: 500; font-family: 'Mukta', sans-serif;line-height: 16px;     color: rgb(0 0 0 / 60%); font-size: 18px; ">
                {{item.diagnosis}} </div>


            </div>

          </div>

          <div style="font-size: 16px; color: #333; padding: 4px; text-align: end;">
            Cannot find the diagnosis you are looking for?
            <span (click)="openDialog(addNewModalDiagnosis, 0);"
              style="font-weight: bold; color: #5A73E2; cursor: pointer;">
              Add New Diagnosis</span>
          </div>

          <div style="font-size: 18px; color: #333; font-weight: bold; padding: 4px;">
            Selected Diagnosis</div>
          <div class="dataContainer" style="width: 100%;  height: fit-content">


            <div *ngFor="let item of selectedDignosisName; let i = index "
              style="border: 1px solid #BFC1C6; background-color: #fff;"
              class="searchable-component-style selectedCard">
              <!-- (click)="selectComplaintModalValue(item,i);" -->

              <div
                style="font-size: 18px; font-weight: 500; font-family: 'Mukta', sans-serif;line-height: 16px; color: #181818; ">
                {{item}} </div>
              <div (click)="onClickDeleteData('Dignosis',item)" style="padding: 0px 0px 0px 0px; cursor: pointer;">
                <img style="width: 20px; margin-left: 6px;" src="assets/images/close_red_outline.png" alt="" />
              </div>


            </div>
          </div>


        </mat-expansion-panel>


        <mat-expansion-panel expandedHeight="100%" [class.show-patient-list]="showPatientList">
          <mat-expansion-panel-header [ngClass]="(allMedicines.length) ?  'titleActive' :'titleInactive'">
            <mat-panel-title>
              <img src="assets/images/med_icon.svg" alt="" />
              <span class="titleUi"> Medicines</span>
              <span [ngClass]="{
                  'previewPresentList': showPatientList,
                  'PreviewAbsPatientList': !showPatientList
                }" *ngIf="allMedicines.length">
                Total number of medicines: {{ allMedicines.length }}

              </span>
            </mat-panel-title>

          </mat-expansion-panel-header>

          <div style="width: 100%; display: flex;flex-direction: row; height: fit-content;">



            <div class="vitalsDivInMedicine" *ngIf="selectedVisit.weight"> Weight- {{selectedVisit.weight }} kg

            </div>



            <div class="vitalsDivInMedicine" *ngIf="selectedVisit.Temp"> Temp- {{selectedVisit.Temp}} F

            </div>



            <div class="vitalsDivInMedicine" *ngIf="selectedVisit.spo2"> SPO2- {{selectedVisit.spo2}}%


            </div>




          </div>

          <div style="width: 100%; display: flex;flex-direction: row; height: fit-content;">

            <div style="width:49%;">
              <div
                style="display: flex; flex-direction: row; border-bottom: 1px solid #eee; margin: 10px; width: 100%;">

                <div (click)="toggleMedicineSection(1)"
                  style="padding: 8px; font-weight: bold;cursor: pointer; font-size: 18px;"
                  [ngClass]="medicineSectionToggle==1 ? 'focused-tab' : 'unfocused-tab'">
                  Add New
                </div>

                <!-- (click)="dialog.open(popular_medicines); closefavper();popularMedicines()" -->
                <div (click)="toggleMedicineSection(2)"
                  style="padding: 8px; margin-left: 25px; font-weight: bold;cursor: pointer; font-size: 18px;"
                  [ngClass]="medicineSectionToggle==2 ? 'focused-tab' : 'unfocused-tab'">
                  Popular
                </div>

                <!-- (click)="dialog.open(previous_medicines_fav); closefavper()" -->
                <div (click)="toggleMedicineSection(3)"
                  style="padding: 8px;margin-left: 25px; font-weight: bold;cursor: pointer; font-size: 18px;"
                  [ngClass]="medicineSectionToggle==3 ? 'focused-tab' : 'unfocused-tab'">
                  Favourite
                </div>

                <!-- (click)="dialog.open(previous_medicines_modal);previousMed();" -->
                <div (click)="toggleMedicineSection(4)"
                  style="padding: 8px;margin-left: 25px; font-weight: bold;cursor: pointer; font-size: 18px;"
                  [ngClass]="medicineSectionToggle==4 ? 'focused-tab' : 'unfocused-tab'">
                  Previous

                </div>

              </div>

              <div *ngIf="medicineSectionToggle==1" class="row"
                style="justify-content: center;  align-content: flex-start;">

                <div onclick="event.stopPropagation();" (click)="addNewMedicine($event)"
                  style="width: 100%; height: 60px; border-radius: 8px;  display: flex; align-items: center;  margin-bottom: 10px; color: #047A7B; font-weight: bold; padding: 2px 10px 2px 10px"
                  [ngStyle]="{'height': isAddMedicine ? null : '60px'}">
                  <div
                    style="height: 100%; width: 100%; display: flex; flex-direction: column; justify-content: center">

                    <div
                      style="
              display: flex; flex-direction: row; width: 100%; height: 40px; margin: 9px 0px; justify-content: space-between; align-items: center;">
                      <div
                        style="position: relative; width: 60%; height: 100%; display: flex; align-items: center; margin-left: 6px;">
                        <div *ngIf="medicineLoader"
                          style="position: absolute; right: 8px; height: 1.5rem; width: 1.5rem;"
                          class="spinner-border text-muted">
                        </div>

                        <input #typeAhead id="typeahead-format" type="text" class="form-control"
                          placeholder="{{searchText}}" [resultTemplate]="searchDrugResultFormatterTemplate"
                          [(ngModel)]="medicineName" [ngbTypeahead]="searchDrugsByTyping"
                          [inputFormatter]="formatMatches" [resultFormatter]="formatter"
                          (input)="getSearchedMeds($event.target.value)" (selectItem)="setSelectMedicine($event)"
                          [ngClass]="(unselectedDrugName) ? 'errorBorder' : 'commonBorder'"
                          (ngModelChange)="onChangeDrugName()" style="
                  height: 100%;
                  width: 90%;
                  border-radius: 8px;
                  outline: none;    
                  font-size: 18px;
                  padding-left: 8px;" name="fname" popupClass="background-color:red">
                      </div>

                  <div style="display: flex; flex-direction: row; width: 40%; justify-content: space-between;">
                    <mat-form-field class="typeField" style="width: 111px;margin: 0px 24px 0px 0px">
                      <mat-label style="width: 100px;">Type</mat-label>
                      <mat-select placeholder="{{drugType}}" [formControl]="drugTypeField" [value]="selectedDrugType"
                        required (selectionChange)="dropdownSelect('drugType',$event.value)">
                        <mat-option *ngFor="let item of drugTypeSelection" [value]="item">{{item}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field id="fourth" style="    width: 111px;margin: 0px 24px 0px 0px;">

                      <mat-select placeholder="Unit" [formControl]="DrugUnitField" [value]="selectedDrugUnit"
                        (selectionChange)="dropdownSelect('drugUnit',$event.value)">
                        <mat-option *ngFor="let item of drugUnitTypes" [value]="item">{{item}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                    </div>

                    <!-- add drug -->

                    <div *ngFor="let dose of doseCount; let i = index"
                      style=" display: flex; flex-direction: column; width: 100%; margin-left: 7px;  ">
                      <div>

                        <div class="doseCount">
                          <div
                            *ngFor="let element of frequencyWithVal.slice(0, frequencyCollapsibleVariable); let j = index">

                            <div style="font-size: 18px; width: 54px; text-align: center; padding: 0px 8px 0px 0px;">
                              {{element.name.charAt(0)}}</div>
                            <!-- <span *ngIf="drugTypeUnit !='Fingertip' && drugTypeUnit!='Fingertip,Apply-Locally'"
                            style="padding: 6px;margin-top: 2px;font-size: 16px;width: 32px; font-weight: 500; color: #333;">{{drugTypeUnit}}</span> -->
                            <div style="display: flex; flex-direction: row; flex-wrap: wrap;"
                              *ngIf="selectedUnit !='Apply Locally'">

                              <input *ngIf="i==0" class="unitInput" type="number" min="0" step="any"
                                [(ngModel)]="element.value" (input)="onUnitChange($event.target.value, j)"
                                id="drugUnit_{{j}}" onKeyPress="if(this.value.length==5) return false;" />


                            </div>

                            <!-- <div style="font-size: 16px; width: 134px;"> x {{element.name}}</div> -->
                            <!-- <div style="font-size: 16px; width: 134px;"> x {{element.name}}</div> -->

                          </div>


                          <a (click)="toggleFrequencyCollapse()" style="color: #F57F7F;     margin-top: 20px;">Show
                            {{frequencyCollapsibleVariable
                            == 4 ? 'More' : 'Less' }} </a>




                          <div style="margin-top: 10px;" class="whenToTake">

                            <!-- WHEN TO TAKE -->
                            <mat-form-field id="fourth" style="width: 101px;margin: 0px 24px 0px 0px" *ngIf="i == 0">
                              <!-- <mat-label>When to take</mat-label> -->
                              <mat-select class="matSelectUi" placeholder="{{Whentotake}}"
                                [formControl]="WhentotakeField" [value]="dose.whenToTake"
                                (selectionChange)="dropdownSelect('whenToTake',$event.value)">
                                <mat-option *ngFor="let item of whenToTake" [value]="item">{{item}}</mat-option>
                              </mat-select>
                            </mat-form-field>


                            <!-- DURATION -->
                            <mat-form-field id="third"
                              style="    width: 84px;margin: 0px 15px 0px -1px;padding-left: 0px;">
                              <!-- <mat-label>Duration</mat-label> -->
                              <mat-select placeholder="{{Duration}}" [value]="dose.duration"
                                [formControl]="DurationField"
                                (selectionChange)="dropdownSelect('duration',$event.value)">
                                <mat-option *ngFor="let day of duration" [value]="day">
                                  {{day}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>



                        </div>
                      </div>



                    </div>




                    <div
                      style="display: flex; flex-direction: row; justify-content: space-between; width: 100%; padding-top: 0px; align-items: center;">

                      <div style="display: flex; align-items: center; justify-content: center; width: '100%'; ">
                        <!-- <app-language-select-textbox #lngMedInstr [width]="82" [stylesFor]="{'margin-left': '51px'}"
                          [stylesForRow]="{'margin-left': '7px'}" [styles]="{'padding-left': '45px'}"
                          patientSelectedLanguage="{{patientSelectedLanguage}}" [callBack]="onInputCustomComponent"
                          styleForWidth="instructions" placeholder="Instructions"
                          style="padding-left: 0px; width: 100%;">
                        </app-language-select-textbox> -->
                        <app-language-select-textbox #lngMedInstr [width]="86" value="langtextboxvalue"
                          patientSelectedLanguage="{{patientSelectedLanguage}}" placeholder="Start typing to search "
                          style="padding-left: 0px; width: 100%;">
                        </app-language-select-textbox>

                        <div style="display: flex; align-items: start;">

                          <div (click)="dialog.open(medicine_instructions_modal);" #tooltip="matTooltip"
                            style="margin-top: 10px; " matTooltip="Cannot find drugs by search? Add New."
                            matTooltipPosition="below" class="addNewDrugAndMoreInsButton">+More</div>
                        </div>
                      </div>
                    </div>
                    <div style="display: flex;flex-direction: row; width: 98%;">
                      <div (click)="addToList()" onclick="event.stopPropagation();" class="addListbutton">Add to
                        List
                      </div>
                      <div (click)="dialog.open(add_new_drug_modal);" #tooltip="matTooltip"
                        matTooltip="Cannot find drugs by search? Add New." matTooltipPosition="below"
                        class="addListbutton" style="width: 25%; border-radius: 6px;
                background-color: #fff;
                color: #047A7B;
                border: 1px solid #047A7B; margin-left: 10px; font-size: 18px;">+New Drug</div>
                    </div>


                  </div>

                  <div style="font-size: 18px; cursor: pointer;" *ngIf="!isAddMedicine">+Add Medicine</div>
                </div>




              </div>

              <!-- POPULAR SECTION START -->
              <div *ngIf="medicineSectionToggle==2" class="form-group" style="padding-left: 4px;">
                <div style="flex-direction: column;" class="input-group">
                  <div style="margin-top: 0px; display: flex; flex-direction: column; width: 100%; ">
                    <input placeholder="Search Medicines From List 1" type="text" aria-label="Number" matInput
                      [formControl]="myControlFav" [(ngModel)]="searchMed" class="searchTab" style="border-radius:6px; padding: 0px 8px 0px 8px; height: 40px; margin-top:0px; width: 97%;
                      font-size: 18px; z-index: 0;">

                  </div>
                  <div style="width: 100%; margin-top: 8px;">

                    <mat-chip-list class="chipForDig" aria-label="Fish selection">
                      <mat-chip class="price-filter" #ref="matChip"
                        *ngFor="let item of diagnosisCollectionArray | filter:searchMed;let i=index"
                        (click)="toggle(ref)" [value]="item" [selected]="item.selected==true">
                        {{item.name}}
                      </mat-chip>

                    </mat-chip-list>

                    <mat-chip-list aria-label="Fish selection">
                      <mat-chip class="price-filter" style="margin-top: 10px;" #ref="matChip"
                        *ngFor="let item of drugTypeList | filter:searchMed;let i=index" (click)="toggle(ref)"
                        [selected]="item.selected==true" [value]="item">
                        {{item.name}}
                      </mat-chip>

                    </mat-chip-list>
                  </div>


                  <div [ngClass]="{
                      'withPListPopularMedicines': showPatientList,
                      'popularMedicines': !showPatientList
                    }">
                    <div *ngFor="let item of allMedicinesForDiagnosis | filter:searchMed; let i = index"
                      (click)="selectMedicine(item, i)" style="display: flex; flex-direction: column; width: 100%;  ">

                      <div style="width: 99%; height: fit-content; padding-top: 5px; padding-bottom: 5px; border-radius: 8px;position: relative;
                       display: flex;  justify-content: space-around; margin-bottom: 6px; align-items: center;"
                        [ngStyle]="{'border': item.editing ? '1px solid #F08439' : '1px solid #DFDFDF'}">

                        <img (click)="onSelectMedicine(item.idx, 2); saveModalValues('copyPopularMedicines');"
                          style="width:26px; color:#007d7e; margin-left: 8px; cursor: pointer;"
                          src="assets/images/newAddButton.svg" class="largerCheckbox" />

                        <div style="width: 86%;">
                          <div [ngClass]="(false) ?  'titleActive' :'titleInactive'" style="margin-left: 6px;">
                            {{item.name}} </div>
                          <div id="something" name="something" *ngIf="!item.editing"
                            [ngClass]="(false) ?  'subtitleActive' :'subtitleInactive'">
                            {{item.dosage2}} {{item.duration2}}</div>
                          <div id="something" name="something" *ngIf="!item.editing"
                            [ngClass]="(false) ?  'subtitleActive' :'subtitleInactive'"
                            style="color: grey; font-size: 13px;">
                            {{item.instructions}} </div>

                        </div>
                        <div>
                          <!-- openDialog(delete_modalForPopularMed); -->
                          <img (click)="popularMedicineDelete(item)"
                            style="width:30px; margin-right: 10px; color:#007d7e; cursor: pointer;"
                            src="assets/images/delete_icon_round.svg" class="largerCheckbox" />
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div style="width:100%; display: flex; justify-content: flex-end;">

                </div>

              </div>
              <!-- POPULAR SECTION END -->


              <!-- FAVOURITE MEDICINE START -->
              <div *ngIf="medicineSectionToggle==3" style="flex-direction: column; padding-left: 16px;  height: 72vh;"
                class="input-group">

                <div
                  style="margin-top: 0px; display: flex; flex-direction: row; width: 100%; border: 1px solid #ccc; border-radius: 6px;">

                  <input #searchFavourites placeholder="Choose Favourites" id="favinputtextbox" type="text"
                    aria-label="Number" matInput [matAutocomplete]="auto4" [formControl]="myControlFavMed"
                    [(ngModel)]="selectedPreviousFavMed" style="border-radius:6px; padding: 0px 8px 0px 8px; height: 40px; margin-top:0px; width: 98%;
              font-size: 18px; z-index: 0;">
                  <mat-autocomplete (optionSelected)='onDropdownSelectFavMed($event.option.value)' autoActiveFirstOption
                    #auto4="matAutocomplete">
                    <mat-option *ngFor="let option of favArrayNames | async" [value]="option"
                      [ngClass]="(option.selectedComplaints=='New Born'||option.selectedComplaints=='Well Child') ?  'activeMat' :'UnactiveMat'">
                      <span>
                        <span style="font-weight: bold; color: #333;">{{option.value}} </span><span style="color: grey;"
                          *ngIf="option.selectedDiagnosis.length">({{option.selectedDiagnosis}})

                        </span>
                      </span>

                    </mat-option>
                  </mat-autocomplete>

                  <i class="fa fa-close" (click)="clearFavouriteText()" aria-hidden="true"
                    style="color: #bfbebf;padding: 10px; font-size: 18px; cursor: pointer;"></i>
                </div>
                <div *ngIf="selectedPreviousFavMed.length" style="height: 40vh; overflow-y: scroll;">
                  <div *ngFor="let element of selectedFavMed; let i = index"
                    style="display: flex; flex-direction: row; justify-content: space-between; align-items: center; margin: 10px 0px 10px 0px;">

                    <div class="newCardStyle"
                      [ngStyle]="{'border': element.editing ? '1px solid #F08439' : '1px solid #DFDFDF'}">



                      <img (click)="onSelectMedicine(i, 3); saveModalValues('copyMedicinesFav');"
                        style="width:26px; color:#007d7e; margin-left: 8px; cursor: pointer;"
                        src="assets/images/newAddButton.svg" class="largerCheckbox" />
                      <div style="width: 86%; " class="medicinPopular">
                        <div [ngClass]="(false) ?  'titleActive' :'titleInactive'">{{element.Drug ?
                          element.Drug.product_name ? element.Drug.product_name:'':''}}</div>
                        <div id="something" name="something" *ngIf="!element.editing"
                          [ngClass]="(false) ?  'subtitleActive' :'subtitleInactive'">
                          {{element.dosage2}} {{element.duration2}}</div>
                        <div id="something" name="something" *ngIf="!element.editing"
                          [ngClass]="(false) ?  'subtitleActive' :'subtitleInactive'"
                          style="color: grey; font-size: 13px;">
                          {{element.instructions}}</div>

                      </div>
                    </div>



                  </div>
                </div>


                <div style="display: flex; justify-content: space-between; width: 100%;">
                  <a *ngIf="selectedPreviousFavMed.length" (click)="openDialog(delete_modal); " style="
            color: #f44336;
                        padding: 8px 0px;
                        
                        font-weight: bold;
                        margin: 8px;
                        "> ⚠️ Delete this favourite?</a>

                  <button *ngIf="!selectedFavMed.length" style="background-color: #f44336; visibility: hidden;
                        padding: 8px 16px;
                        border-radius: 6px;
                        color: #fff;
                        font-weight: bold;
                        border: 0px;
                        margin: 8px;
                        outline: none;">Delete</button>
                  <div style="padding-top: 16px; display: flex; justify-content: flex-end;">

                  </div>
                </div>

              </div>
              <!-- FAVOURITE MEDICINE END -->

              <!-- PREVIOUS MEDICINE START -->
              <div *ngIf="medicineSectionToggle==4" style="flex-direction: column; width: 90%; padding-left: 16px;"
                class="input-group">

                <div style="display: flex; flex-direction: row; align-items: center;">
                  <div style="width: 80%;">
                    <mat-form-field id="fourth" style="width: 100%; margin: 0px 6px;">
                      <mat-label>Filter Previous Prescriptions</mat-label>
                      <mat-select placeholder="{{selectedPreviousMedDate}}" (selectionChange)="onDropdownSelect($event)"
                        name="ll" #select>
                        <mat-select-trigger>
                          {{select.value?.value}}
                        </mat-select-trigger>
                        <mat-option *ngFor="let item of previousMedsArr" [value]="item" style=" line-height: normal;">


                          <div>
                            <div style="font-size: 17px; color: grey;">

                              <span style="font-weight: bold; color: #333;">{{item.value}} </span>(
                              <!-- Complaints: {{item.complaints ? item.complaints : 'NA'}}, &nbsp;&nbsp;&nbsp;&nbsp; -->
                              Diagnosis: {{item.diagnosis ? item.diagnosis : 'NA'}})
                            </div>
                          </div>
                          <!-- </div> -->

                        </mat-option>
                      </mat-select>

                    </mat-form-field>
                  </div>

                  <div *ngIf="pdfUrlForViewPrescription" (click)="onClickViewPrescription()" style="width: 22%;
              background-color: #ffffff;
              font-size: 18px;
              text-align: center;
              margin-left: 16px;
              padding: 6px;
              border-radius: 6px;
              border: 2px solid #5d86cc;
              color: #5d86cc;
              font-weight: bold;
              cursor: pointer;
              ">
                    View PDF</div>

                </div>

                <div style="height: 32vh;overflow-y: scroll;">
                  <div *ngFor="let element of selectedPreviousMed; let i = index"
                    style=" display: flex; flex-direction: row; justify-content: space-between; align-items: center; margin: 10px 10px 10px 0px;">

                    <div class="newCardStyle"
                      [ngStyle]="{'border': element.editing ? '1px solid #F08439' : '1px solid #DFDFDF'}">

                      <img (click)="onSelectMedicine(i, 4); saveModalValues('copyMedicines');"
                        style="width:26px; color:#007d7e; margin-left: 8px; cursor: pointer;"
                        src="assets/images/newAddButton.svg" class="largerCheckbox" />

                      <div style="width: 86%;">
                        <div [ngClass]="(false) ?  'titleActive' :'titleInactive'">{{element?.Drug?.product_name}}
                        </div>
                        <div id="something" name="something" *ngIf="!element.editing"
                          [ngClass]="(false) ?  'subtitleActive' :'subtitleInactive'">
                          {{element.dosage2}} {{element.duration2}}</div>
                        <div id="something" name="something" *ngIf="!element.editing"
                          [ngClass]="(false) ?  'subtitleActive' :'subtitleInactive'"
                          style="color: grey; font-size: 13px;">
                          {{element.instructions}}</div>

                      </div>
                    </div>
                  </div>
                </div>

                <div style="display: flex;flex-direction: row;justify-content: space-between;">
                  <div *ngIf="!pdfUrlForViewPrescription"
                    style="padding-top: 20px; color: #e9eaf0; font-size: 18px;font-weight: 600; text-decoration: underline;">
                    <span style="cursor: pointer;"></span>
                  </div>

                </div>

              </div>
              <!-- PREVIOUS MEDICINE END -->
            </div>
            <div style="width: 49%; background-color: #fff; margin-left: 2%;">
              <div
                style="background-color: #fff; font-weight: bold;cursor: pointer; font-size: 18px; text-align: center; padding: 8px; margin: 4px;">
                Preview</div>



              <div cdkDropList (cdkDropListDropped)="drop($event)" [ngClass]="{
                  'selectedMedPreviewPList': showPatientList,
                  'selectedMedPreviewNoPList': !showPatientList
                }">
                <div cdkDrag *ngFor="let medicine of allMedicines; let i = index"
                  style=" display: flex; flex-direction: row;  ">

                  <div style="width: 100%;">
                    <div style="font-weight: 600; margin-top: 8px; font-size: 18px; ">
                      {{medicine.name}}
                    </div>

                    <div style="font-size: 14px; ">
                      {{medicine.dosage}} {{medicine.whenToTake}} {{medicine.duration}}

                    </div> {{medicine.instructions}}

                  </div>
                  <div style="margin: 15px 2px 0px 0px;" (click)="openDialog(deleteMed_modal);getDeleteMed(medicine)">
                    <img style="height: 20px;cursor: pointer;" src="assets/images/delete_red.svg" />

                  </div>




                </div>
              </div>
            </div>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel (opened)="openGroup('Diet')" [class.show-patient-list]="showPatientList">
          <mat-expansion-panel-header [ngClass]="(selectedDietName.length) ?  'titleActive' :'titleInactive'">
            <mat-panel-title>
              <img src="assets/images/diet_icon.svg" alt="" />
              <span class="titleUi">Diet & Nutrition</span>
              <span [ngClass]="{
                  'previewPresentList': showPatientList,
                  'PreviewAbsPatientList': !showPatientList
                }">
                <ng-container *ngFor="let nutrition of selectedDietName; let last = last">
                  {{ nutrition }}{{ !last ? ', ' : '.' }}
                </ng-container>
              </span>

            </mat-panel-title>

          </mat-expansion-panel-header>

          <div style=" display: flex; flex-direction: column; width: 100%; margin-top: 6px; ">
            <input #searchFavourites placeholder="Search Diet and Nutrition..." type="text" aria-label="Number" matInput
              [(ngModel)]="selectedDietaryAdvices" class="searchTab" [matAutocomplete]="auto2"
              [formControl]="dietNutritionControl">

            <mat-autocomplete (optionSelected)='onDropdownSelectDiet($event.option.value)' autoActiveFirstOption
              #auto2="matAutocomplete">
              <mat-option *ngFor="let option of allDietName | async" [value]="option">
                <span>
                  <span style="font-weight: bold; color: #333;">{{option.dietAdvice}} </span>
                </span>

              </mat-option>
            </mat-autocomplete>

          </div>

          <div class="dataContainer" style="margin: 8px 0px; max-height: 160px; overflow-y: scroll; width: 100%;">
            <div *ngFor="let item of allDietaryAdvices let i = index " class="searchable-component-style selectedCard"
              (click)="selectDietaryAdvicesModalValue(item,i);">

              <!-- <img style="margin: 0px 8px 0px 0px;width:22px; color:#007d7e;" src="assets/images/newAddButton.svg"
              class="largerCheckbox" /> -->
              <div
                style="font-size: 18px; font-weight: 500; font-family: 'Mukta', sans-serif;line-height: 16px;     color: rgb(0 0 0 / 60%); font-size: 18px; ">
                {{item.dietAdvice}} </div>


            </div>

          </div>

          <div style="font-size: 16px; color: #333; padding: 4px; text-align: end;">
            Cannot find the advice you are looking for?
            <span (click)="openDialog(addNewModalDietNutrition, 0);"
              style="font-weight: bold; color: #5A73E2; cursor: pointer;">
              Add Diet & Nutrition</span>
          </div>

          <div style="font-size: 18px; color: #333; font-weight: bold; padding: 4px;">
            Selected Diet & Nutrition</div>

          <div class="dataContainer">
            <div *ngFor="let item of selectedDietName; let i = index "
              style="border: 1px solid #BFC1C6; background-color: #fff;"
              class="searchable-component-style selectedCard">
              <!-- (click)="selectComplaintModalValue(item,i);" -->

              <div
                style="font-size: 18px; font-weight: 500; font-family: 'Mukta', sans-serif;line-height: 16px; color: #181818; ">
                {{item}} </div>
              <div (click)="onClickDeleteData('Diet',item)" style="padding: 0px 0px 0px 0px; cursor: pointer;"> <img
                  style="width: 20px; margin-left: 6px;" src="assets/images/close_red_outline.png" alt="" /> </div>

            </div>
          </div>





        </mat-expansion-panel>
        <mat-expansion-panel (opened)="openGroup('GeneralIns')" [class.show-patient-list]="showPatientList">
          <mat-expansion-panel-header
            [ngClass]="(selectedGeneralInstructionsName.length) ?  'titleActive' :'titleInactive'">
            <mat-panel-title>
              <img src="assets/images/gen_icon.svg" alt="" />
              <span class="titleUi"> General Instruction</span>
              <span [ngClass]="{
                  'previewPresentList': showPatientList,
                  'PreviewAbsPatientList': !showPatientList
                }">
                <ng-container *ngFor="let instruction of selectedGeneralInstructionsName; let last = last">
                  {{ instruction }}{{ !last ? ', ' : '' }}
                </ng-container>
              </span>


            </mat-panel-title>

          </mat-expansion-panel-header>
          <div style=" display: flex; flex-direction: column; width: 100%; margin-top: 6px;">
            <input #searchFavourites placeholder="Search General Instructions..." type="text" aria-label="Number"
              matInput [(ngModel)]="selectedGeneralIns" class="searchTab" [matAutocomplete]="auto3"
              [formControl]="instructionControl">

            <mat-autocomplete (optionSelected)='onDropdownSelectGeneralInsModalValue($event.option.value)'
              autoActiveFirstOption #auto3="matAutocomplete">
              <mat-option *ngFor="let option of allGeneralInstructionsName | async" [value]="option">
                <span>
                  <span style="font-weight: bold; color: #333;">{{option.generalInstruction}} </span>
                </span>

              </mat-option>
            </mat-autocomplete>

          </div>


          <div class="dataContainer"
            style="width: 100%;margin: 10px 0px 15px 0px; max-height: 160px; overflow-y: scroll;">
            <div *ngFor="let item of allGeneralInstructions let i = index "
              class="searchable-component-style selectedCard" (click)="selectGeneralInsModalValue(item,i);">

              <!-- <img style="margin: 0px 8px 0px 0px;width:22px; color:#007d7e;" src="assets/images/newAddButton.svg"
              class="largerCheckbox" /> -->
              <div
                style="font-size: 18px; font-weight: 500; font-family: 'Mukta', sans-serif;line-height: 16px;     color: rgb(0 0 0 / 60%); font-size: 18px; ">
                {{item.generalInstruction}} </div>


            </div>



          </div>

          <div style="font-size: 16px; color: #333; padding: 4px; text-align: end;">
            Cannot find the advice you are looking for?
            <span (click)="openDialog(addNewModalGeneralInstruction, 0);"
              style="font-weight: bold; color: #5A73E2; cursor: pointer;">
              Add New Instructions</span>
          </div>

          <div style="font-size: 18px; color: #333; font-weight: bold; padding: 4px;">
            Selected General Instructions</div>

          <div class="dataContainer" style="width: 100%;margin: 6px 0px 15px 3px;">
            <div *ngFor="let item of selectedGeneralInstructionsName; let i = index "
              style="border: 1px solid #BFC1C6; background-color: #fff;"
              class="searchable-component-style selectedCard">


              <div
                style="font-size: 18px; font-weight: 500; font-family: 'Mukta', sans-serif;line-height: 16px; color: #181818; ">
                {{item}} </div>
              <div (click)="onClickDeleteData('GeneralInstructions',item)"
                style="padding: 0px 0px 0px 0px; cursor: pointer;"> <img style="width: 20px; margin-left: 6px;"
                  src="assets/images/close_red_outline.png" alt="" />
              </div>

            </div>
          </div>



        </mat-expansion-panel>
        <mat-expansion-panel [class.show-patient-list]="showPatientList">
          <mat-expansion-panel-header [ngClass]="(nextFolloupPreview.length) ?  'titleActive' :'titleInactive'">
            <mat-panel-title>
              <img src="assets/images/next-followup.svg" alt="" />
              <span class="titleUi">Next FollowUp</span>
              <span [ngClass]="{
                  'previewPresentList': showPatientList,
                  'PreviewAbsPatientList': !showPatientList
                }">
                <!-- <ng-container *ngIf="nextFolloupPreview.length > 50"> -->
                <!-- Display only the first 50 characters if the length is greater than 50 -->
                <!-- {{ nextFolloupPreview.slice(0, 50).join('') }} -->
                <!-- </ng-container> -->

                <ng-container *ngIf="nextFolloupPreview.length <= 50">
                  <!-- Display the entire array if its length is not greater than 50 -->
                  <ng-container *ngFor="let FollowUp of nextFolloupPreview; let last = last">
                    {{ FollowUp }}
                  </ng-container>
                </ng-container>
              </span>

            </mat-panel-title>

          </mat-expansion-panel-header>
          <div style="margin-top: 6px; margin-left: 2px;">

            <div style="font-size: 16px; color: #333; font-weight: bold; padding: 4px; margin-bottom: 6px;">
              Specify follow up message for patient</div>

            <app-language-select-textbox #langFollowupMessage [width]="97" [styles]="{'width': '100%'}"
              styleForWidth="instructions" [callBack]="onInputCustomComponent" placeholder="Followup Message"
              style="padding-left: 0px;" [(ngModel)]="nextFolloupPreview" [langSelectedText]="dataLanguageInput"
              (langSelectedTextChanged)="onLangSelectedTextChanged($event)"
              name="nextFolloupPreview"></app-language-select-textbox>
            <div style="    display: flex;
        flex-direction: column;
        height: 129px;
        max-height: 474px;">
              <div style="display: flex; align-items: center;">

                <mat-chip-list aria-label="Fish selection" [multiple]="true" style="margin-top:10px">
                  <mat-chip class="price-filter" style="font-size: 18px; background-color: #E8F7F5;" #ref="matChip"
                    *ngFor="let item of followUpMessageForLanguage;let i=index" (click)='selectFollowUpMessage(item);'
                    [value]="item">
                    {{item.value}}
                  </mat-chip>

                </mat-chip-list>

              </div>


              <div
                style="font-size: 16px; color: #333; font-weight: bold; padding: 4px; margin-bottom: 6px; margin-top: 16px;">
                Follow up Date</div>
              <div style="display: flex;flex-direction: row; margin-left: 6px;">

                <mat-form-field style="width: 30%;">
                  <input matInput [matDatepicker]="picker" (dateChange)="onDateChange($event)"
                    [(ngModel)]="followupDateNew" placeholder="Choose a date">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>

                </mat-form-field>


                <div style="display: flex; flex-direction:row; justify-content: center; ">
                  <mat-radio-group aria-label="Select an option" [(ngModel)]="selectedOption"
                    style="display: flex; flex-direction:row; align-items: center;">
                    <mat-radio-button style="padding: 0px 16px; font-size: 18px !important; " value="call" [checked]="true">Call
                    </mat-radio-button>
                    <mat-radio-button style="padding: 0px 16px;font-size: 18px !important;" value="visit">Visit</mat-radio-button>

                  </mat-radio-group>
                </div>
              </div>
            </div>

          </div>
        </mat-expansion-panel>
        <mat-expansion-panel [class.show-patient-list]="showPatientList">
          <mat-expansion-panel-header [ngClass]="(nextFolloupPreview.length) ?  'titleActive' :'titleInactive'">
            <mat-panel-title>
              <img src="assets/images/customise-rx.svg" alt="" />
              <span class="titleUi"> Customise Rx</span>


            </mat-panel-title>

          </mat-expansion-panel-header>
          <div style="background-color: #ffffff; padding: 8px; border-radius: 8px; width: 80%; height: 300px; ">



            <div *ngIf="specialVaccination.length" style="margin-top: 10px; font-size: 16px; font-weight: 600;">Special Vaccination</div>
            <div  *ngIf="specialVaccination.length" style="display: flex;flex-direction: row;flex-wrap: wrap; width: 100%; margin: 15px 0px 0px 0px;">
              <mat-checkbox-group aria-labelledby="example-checkbox-group-label" class="example-section">
                <mat-checkbox class="example-margin" *ngFor="let section of specialVaccination" [value]="section" [checked]="section.administeredStatus"
                  (change)="getspecialVaccination($event,section)">
                  {{section.name}}</mat-checkbox>
              </mat-checkbox-group>
            </div>

            <mat-form-field style="width: 100%;  margin-top: -18px;">
              <mat-select style="font-size: 18px; margin-left: 25px;" [value]="selectedVitalsField"
                (selectionChange)="selectModalVitalFieldValue($event.value)" multiple>
                <mat-option *ngFor="let item of selectVitalsField" [value]="item.value">
                  {{item.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>


            <mat-form-field style="width: 100%; ">
              <mat-label style="font-size: 16px;">Selected Fields</mat-label>
              <mat-select [value]="selectFieldsArray" style="font-size: 18px; margin-left: 25px;" multiple
                (openedChange)="openPanel($event)" (selectionChange)="selectModalFieldValue($event.value)">
                <mat-option style="font-size: 16px;" *ngFor="let item of selectFieldsArray" [value]="item">
                  {{item.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>


            <div style="display: flex; flex-direction: row; justify-content: space-between;">
              <mat-form-field style="width: 47%;font-size: 16px;">
                <mat-label style="font-size: 16px;">Speciality/Location</mat-label>
                <mat-select [value]="templateName"
                  (selectionChange)="onDropdownSelectTemplateLocation('item',$event.value)">

                  <mat-option style="font-size: 18px;" *ngFor="let item of selectedTemplate" [value]="item">
                    {{item}}
                  </mat-option>
                </mat-select>

              </mat-form-field>

              <mat-form-field style="width: 47%;">
                <mat-label style="font-size: 16px;">Rx Language</mat-label>
                <mat-select style="font-size: 18px;" [value]="selectedLanguage.name"
                  (selectionChange)="onDropdownSelectLanguage('language',$event.value)">
                  <mat-option style="font-size: 18px;" *ngFor="let language of languageArray" [value]="language.name">
                    {{language.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

            </div>
            <div style="display: flex; flex-direction: row; justify-content: space-between;">
              <div></div>
              <div style="color: #cb832d;
              font-weight: bold;
              font-style: italic;">*Patient's app preffered language is {{selectedLanguage.name}}</div>
            </div>


          </div>


        </mat-expansion-panel>
      </mat-accordion>


    </div>




    <div [ngStyle]="{'width': showPatientList ? '25%' : '23%'}">
      <div style="display: flex; align-items: center; height: 70px; padding-top: 10px;">
        <div style="font-size: 18px;color: #000000;font-weight: 600; margin-left: 8px;">Health Markers</div>
      </div>

      <div class="resulationPreviewDetails">
        <app-patient-previous-details
          *ngIf="(patientInfo | json) != '{}' && (patientAllRequiredConstant | json) != '{}' && (patientApponmentById | json) != '{}'"
          [ngStyle]="{'width' : showPatientList ?  '25%':'23%'}" 
          [patientAllRequiredConstant]="patientAllRequiredConstant" [patientApponmentById]="patientApponmentById"
          [patientInfo]="patientInfo" [pdfurlObjectForPrintingInChild]="pdfurlObjectForPrintingInChild"> </app-patient-previous-details>


      </div>
    </div>


  </div>
</div>





<div
  style="width: 100%; flex-direction: column; justify-content: center; align-items: center; background-color: #fff;padding: 10px; border-radius: 4px;">


  <ng-template #pendingVaccination_modal>

  <div style="padding: 20px;">
    <div *ngIf="vaccinesLoading" style="display: flex; justify-content: center; align-items: center;">
      <mat-spinner [diameter]="50"></mat-spinner>
      <p style="margin-bottom: 0px; padding: 16px;">Loading Vaccines</p>
    </div>
    <div *ngIf="!vaccinesLoading">
      <div style="display: flex;flex-direction: row; justify-content: space-between;">
        <div style="font-size: 20px; font-weight: 600;">Select next vaccination due</div>
        <div> <mat-form-field>
            <!-- [max]="maxDate" -->
            <input matInput [min]="minDate" [matDatepicker]="picker" (dateChange)="onDateChangeForVacc($event)"
              [(ngModel)]="vacDate" placeholder="Choose a date" required>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>

          </mat-form-field></div>
      </div>



      <hr *ngIf="pendingVaccination.length">
      <h5 *ngIf="pendingVaccination.length" style="color:#36404D">Pending Vaccinations</h5>
      <div *ngIf="pendingVaccination.length" style="height: 30vh; width: 55vw; overflow: scroll;">
        <div style="height:30vh; width: 55vw; overflow: scroll">
          <ng-container [ngTemplateOutlet]="renderImmunization" [ngTemplateOutletContext]="{value:pendingVaccination}">
          </ng-container>
        </div>
      </div>

      <ng-template #renderImmunization let-value="value">
        <table mat-table [dataSource]="value" style="width: 55vw; margin-top: 8px;">
          <ng-container>
            <!-- VISIT -->
            <ng-container matColumnDef="visit">
              <th mat-header-cell *matHeaderCellDef> Visit </th>
              <td mat-cell *matCellDef="let element"
                style="text-align: start !important; width:17%; font-weight: bold;">
                <mat-checkbox class="example-margin" (change)="getVaccData(element)"></mat-checkbox>
                {{element.visitName}}

              </td>
            </ng-container>

            <!-- VACCINE -->
            <ng-container matColumnDef="vaccine">
              <th mat-header-cell *matHeaderCellDef> Vaccine Name </th>
              <td mat-cell *matCellDef="let element" style="text-align: start !important; width: 17.5% !important;">
                {{element.name}}

              </td>
            </ng-container>

            <!-- RECOMMENDED DATE -->
            <ng-container matColumnDef="recommendedDate">
              <th mat-header-cell *matHeaderCellDef> Recommended Date </th>
              <td mat-cell *matCellDef="let element" style="width: 10% !important;">
                {{ element.dueDate ? (element.dueDate | date:"dd-MM-yyyy") : 'No Date' }}


              </td>
            </ng-container>

            <!-- CATCHUP DATE -->
            <ng-container matColumnDef="catchupDate">
              <th mat-header-cell *matHeaderCellDef> Catchup Date </th>
              <td mat-cell *matCellDef="let element" style="width: 10% !important;">
                {{element.catchupDate}}
              </td>
            </ng-container>

            <!-- STATUS -->


            <!-- RECEIVED DATE -->


            <!-- BRAND -->


            <!-- ACTION   -->


          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true" style="background-color: #eee;"></tr>
          <tr mat-row [ngClass]="{'highlight': selectedRowIndex == row.id}"
            *matRowDef="let row; columns: displayedColumns;" (click)="selectedRows(row)"></tr>
        </table>
      </ng-template>




      <hr>
      <h5 style="color:#36404D">Upcoming Vaccinations</h5>


      <div style="height: 30vh; width: 55vw; overflow: scroll;">
        <div style="height: 30vh; width: 55vw;overflow: scroll;">
          <ng-container [ngTemplateOutlet]="renderImmunization" [ngTemplateOutletContext]="{value:upcomingVaccineList}">
          </ng-container>
        </div>
      </div>

      <ng-template #renderImmunization let-value="value">
        <table mat-table [dataSource]="value" style="width: 55vw; margin-top: 8px;">
          <ng-container>
            <!-- VISIT -->
            <ng-container matColumnDef="visit">
              <th mat-header-cell *matHeaderCellDef> Visit </th>
              <td mat-cell *matCellDef="let element"
                style="text-align: start !important; width:17%; font-weight: bold;">
                {{element.visitName}}

              </td>
            </ng-container>

            <!-- VACCINE -->
            <ng-container matColumnDef="vaccine">
              <th mat-header-cell *matHeaderCellDef> Vaccine Name </th>
              <td mat-cell *matCellDef="let element" style="text-align: start !important; width: 17.5% !important;"
                [ngStyle]="{'background-color': (element.visitName == 'Special Vaccines') ? '#d1ecf157' : '#fff' }">
                {{element.name}}

              </td>
            </ng-container>

            <!-- RECOMMENDED DATE -->
            <ng-container matColumnDef="recommendedDate">
              <th mat-header-cell *matHeaderCellDef> Recommended Date </th>
              <td mat-cell *matCellDef="let element" style="width: 10% !important;"
                [ngStyle]="{'background-color': (element.visitName == 'Special Vaccines') ? '#d1ecf157' : '#fff' }">
                {{element.dueDate | date:"dd-MM-YYYY"}}

              </td>
            </ng-container>

            <!-- CATCHUP DATE -->
            <ng-container matColumnDef="catchupDate">
              <th mat-header-cell *matHeaderCellDef> Catchup Date </th>
              <td mat-cell *matCellDef="let element" style="width: 10% !important;"
                [ngStyle]="{'background-color': (element.visitName == 'Special Vaccines') ? '#d1ecf157' : '#fff' }">
                {{element.catchupDate}}
              </td>
            </ng-container>


            <!-- STATUS -->


            <!-- RECEIVED DATE -->


            <!-- BRAND -->


            <!-- ACTION   -->


          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true" style="background-color: #eee;"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </ng-template>
      <!-- <div style="display: flex; flex-direction: row; flex-wrap: wrap; margin-top: 16px; margin-bottom: 16px; width: 55vw; height: 30vh; overflow: scroll;">
                    <div *ngFor="let item of upcomingVaccineList; let i = index" (click)="selectModalValue(i)"
                    class="searchable-component-style" [ngStyle]="{'background-color': item.selected ? '#E5F6F4' : '#fff'}">
                        <img *ngIf="item.selected != true" style="margin: 0px 8px 0px 0px; height: 18px; width: 18px;"
                        src="assets/images/tick_mark_grey.svg" class="tick-icon" />
      
                      <img *ngIf="item.selected" style="margin: 0px 8px 0px 0px; height: 18px; width: 18px;"
                        src="assets/images/tick_mark_color.svg" class="tick-icon" />
                      <div style="font-size: 16px;">{{item.name}}</div><br>
                    
                    </div>
                    </div> -->
      <div style="padding-top: 16px; display: flex; justify-content: flex-end;">
        <button (click)="closeDialog()" style="background-color: #eee;
                font-size: 16px;
                          padding: 8px 16px;
                          border-radius: 6px;
                          color: #fff;
                          font-weight: bold;
                          border: 0px;
                          margin: 4px;
                          color: #444;
                          outline: none;">Cancel</button>

        <button (click)="savePendingVaccination(); closeDialog();" style="background-color: #5D86CC;
                font-size: 16px;
                      padding: 8px 16px;
                      border-radius: 6px;
                      color: #fff;
                      font-weight: bold;
                      border: 0px;
                      margin: 4px;
                    outline: none;" [disabled]="!isDateFilledAddVac">Add Vaccinations</button>
      </div>
    </div>
  </div>
  </ng-template>


</div>


<div class="footer" style="background-color:#fff; bottom: 50px;">

  <div style="width: 98%;margin: 20px; display: flex;flex-direction: row;justify-content: center;">

    <button (click)="openDialog(favMed_modal,0)" class="btn btn-secondary"
      style="margin-top: 6px; display: flex; justify-content: center; align-items: center;">

      <mat-icon [ngClass]="'blue-icon'">
        save
      </mat-icon>

      <span style="margin-left: 4px;">Favourite</span>
    </button>

    <button (click)="saveVisitCommanFun();" class="btn btn-secondary"
      style="margin-top: 6px; display: flex; justify-content: center; align-items: center;" [disabled]="saveButtonFlag">

      <mat-icon [ngClass]="'blue-icon'">
        save
      </mat-icon>

      <span style="margin-left: 4px;">Save</span>
    </button>
    <button (click)="saveAndPreview()" class="submitBtn" [disabled]="savePreviewButtonFlag"
      style="margin-top: 6px; display: flex; justify-content: center; align-items: center;">

      Save & Preview
    </button>
  </div>
</div>

<div class="footer">
  <div style=" display: flex;flex-direction: row; margin:6px 0px 6px 80px;">
    <button (click)="goToTab('Immunization');" class="btn btn-secondary"
      style="margin-top: 6px; display: flex; justify-content: center; align-items: center;">

      <mat-icon [ngClass]="'blue-icon'">
        vaccines
      </mat-icon>

      <span style="margin-left: 4px;">Immunization</span>
    </button>
    <button (click)="goToTab('Referral');" class="btn btn-secondary"
      style="margin-top: 6px; display: flex; justify-content: center; align-items: center;">

      <mat-icon [ngClass]="'blue-icon'">
        vaccines
      </mat-icon>

      <span style="margin-left: 4px;">Referral</span>
    </button>
    <button (click)="goToTab('Requisition');" class="btn btn-secondary"
      style="margin-top: 6px; display: flex; justify-content: center; align-items: center;">

      <mat-icon [ngClass]="'blue-icon'">
        vaccines
      </mat-icon>

      <span style="margin-left: 4px;">Requisition</span>
    </button>
    <button (click)="goToTab('History');" class="btn btn-secondary"
      style="margin-top: 6px; display: flex; justify-content: center; align-items: center;">

      <mat-icon [ngClass]="'blue-icon'">

        history
      </mat-icon>

      <span style="margin-left: 4px;">History</span>
    </button>
    <button (click)="goToTab('Receipts');" class="btn btn-secondary"
      style="margin-top: 6px; display: flex; justify-content: center; align-items: center;">

      <mat-icon [ngClass]="'blue-icon'">
        receipt
      </mat-icon>

      <span style="margin-left: 4px;">Receipts</span>
    </button>
    <button (click)="goToTab('Certificate');" class="btn btn-secondary"
      style="margin-top: 6px; display: flex; justify-content: center; align-items: center;">

      <mat-icon [ngClass]="'blue-icon'">
        card_membership
      </mat-icon>

      <span style="margin-left: 4px;">Certificate</span>
    </button>
    <button (click)="goToTab('splVacc')" class="btn btn-secondary"
      style="margin-top: 6px; display: flex; justify-content: center; align-items: center;">

      <mat-icon [ngClass]="'blue-icon'">
        vaccines
      </mat-icon>

      <span style="margin-left: 4px;">Special Vaccines</span>
    </button>
  </div>
</div>

<ng-template #searchDrugResultFormatterTemplate let-r="result" let-t="term">
  <ngb-highlight style="font-weight: bold;" [result]="r.product_name.toUpperCase()" [term]="t"></ngb-highlight>
  <label *ngIf="r.product_composition && r.product_composition!=''" for="">&nbsp;( </label>
  <ngb-highlight *ngIf="r.product_composition && r.product_composition!=''" [result]="r.product_composition">
  </ngb-highlight>
  <label *ngIf="r.product_composition && r.product_composition!=''" for=""> )</label>

</ng-template>


<ng-template #add_new_drug_modal let-modal>
<div style="padding: 20px;">
  <div class="modal-header" style="border-bottom: 0px; padding: 0px;">
    <div style="display: flex; flex-direction: column;">
      <h4 class="modal-title" id="modal-basic-title">Add New Drug</h4>

      <app-alertbox *ngIf="medicineName.length > 2" [title]="'Medicine not found'"
        [subtitle]="'The medicine name you have selected is not found in our system, you may need to add this medicine once in our system.'"
        [type]="'Warning'"></app-alertbox>

    </div>

  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <div style="flex-direction: column;" class="input-group">

          <div style="flex-direction: row; display: flex; width: 100%; background-color: rgb(255, 255, 255);">
            <div style="width: 50%;">
              <div style="color: grey;">Drug Name</div>
              <input [(ngModel)]="drugNameAddNewDrugs" id="searchbox"
                (input)="onSearchChange($event.target.value, 'drugTypes')" style="border-radius:6px; padding: 0px 8px 0px 8px; height: 40px; margin-top:0px; 
              font-size: 18px; width: 97%;" class="form-control" placeholder="Enter Drug Name" name="drugName">
            </div>

            <div style="width: 50%;">
              <div style="color: grey;">Drug Type</div>
              <input class="form-control" id="searchbox" placeholder="Enter Drug Type" type="text" aria-label="Number"
                matInput [matAutocomplete]="autoDrug" [formControl]="drugTypeControl" [(ngModel)]="drugTypeAddNewDrugs"
                style="border-radius: 6px;
                  padding: 0px 8px 0px 8px;
                  height: 40px;
                  margin-top: 0px;
                  width: 91%;
                  font-size: 18px;border: 1px solid #ced4da;">
              <mat-autocomplete autoActiveFirstOption #autoDrug="matAutocomplete"
                (optionSelected)="dropdownSelect('drugType',$event.option.value)">
                <mat-option *ngFor="let option of drugTypes | async" [value]="option">
                  {{option}}
                </mat-option>
              </mat-autocomplete>
            </div>
          </div>

          <div style="flex-direction: row; display: flex; width: 100%; margin-top: 16px;">
            <div style="width: 50%;">
              <div style="color: grey;">Generic Name (Drug Composition)</div>
              <input [(ngModel)]="genericDrugNameAddNewDrugs" id="generic"
                (input)="onSearchChange($event.target.value, 'drugTypes')" style="border-radius:6px; padding: 0px 8px 0px 8px; height: 40px; margin-top:0px;
              font-size: 16px; width: 97%;" class="form-control" placeholder="Enter Drug Generic Name"
                name="genericName">
            </div>

            <div style="width: 50%;">
              <div style="color: grey;">Drug Company</div>
              <div>
                <input [(ngModel)]="drugCompanyAddNewDrugs" id="drugCompany"
                  (input)="onSearchChange($event.target.value, 'drugTypes')" style="border-radius:6px; padding: 0px 8px 0px 8px; height: 40px; margin-top:0px; width: 97%;
                font-size: 16px; z-index: 0;" class="form-control" placeholder="Enter name of Drug Company"
                  name="drugCompany">
              </div>
            </div>
          </div>


        </div>

        <h5 class="modal-title" style="margin-top: 20px;" id="modal-basic-title">Set custom dosage for drug</h5>
        <div style="background-color: #F7F6F9; border-radius: 6px;">
          <div>

            <input class="unitInput" type="number" min="0" (input)="onUnitChange($event.target.value)"
              [(ngModel)]="unitForNewMed" name="unitfornewmed" style="border: 1px solid #949494;
                  border-radius: 6px;
                  height: 40px;
                  width: 60px;
                  font-size: 18px;
                  color: #333333;
                  font-weight: bold;
                  padding: 6px;" />

            <span style="padding: 6px;
              margin-top: 0px;
              font-size: 18px;
              width: 50px;
              font-weight: 500;
              color: #333;">{{drugTypeUnit}}</span>

            <mat-form-field style="width: 300px; margin: 0px 6px;">
              <!-- <mat-label>Frequency</mat-label> -->
              <mat-select placeholder="{{toFrequency}}" [formControl]="FrequencyFieldNewMed"
                [value]="frequencyForNewMed" multiple
                (selectionChange)="dropdownSelect('frequencyNewMed',$event.value)">
                <mat-option *ngFor="let item of frequency" [value]="item">{{item}}

                </mat-option>
              </mat-select>
            </mat-form-field>


            <mat-form-field id="third" style="width: 90px; margin: 0px 6px;">
              <!-- <mat-label>Duration</mat-label> -->
              <mat-select placeholder="{{Duration}}" [formControl]="DurationFieldNewMed" [value]="durationForNewMed"
                (selectionChange)="dropdownSelect('durationNewMed',$event.value)">
                <mat-option *ngFor="let day of duration" [value]="day">
                  {{day}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field id="fourth" style="width: 110px; margin: 0px 6px;">
              <!-- <mat-label>When to take</mat-label> -->
              <mat-select placeholder="{{Whentotake}}" [formControl]="WhentotakeFieldNewMed"
                [value]="whenToTakeForNewMed" (selectionChange)="dropdownSelect('whenToTakeNewMed',$event.value)">
                <mat-option *ngFor="let item of whenToTake" [value]="item">{{item}}</mat-option>
              </mat-select>
            </mat-form-field>

          </div>

          <input type="text" class="form-control" [(ngModel)]="instructionsForNewMed"
            (input)="updateNewMedInstructions($event.target.value)" placeholder="Instructions" style="
              width: 100%;
              border-radius: 0px;
              outline: none;    
              font-size: 18px;
              border:0px;
              padding-left: 16px;" id="fname" name="fname">



        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">

    <button (click)="closeDialog()" style="background-color: #eee;
      padding: 8px 16px;
      border-radius: 6px;
      color: #fff;
      font-weight: bold;
      font-size: 16px;
      border: 0px;
      margin: 8px 0px;
      color: #444;
      outline: none;">Cancel</button>

    <div (click)="saveModalValues('drugTypesAddNewDrug'); dialog.closeAll()" class="btn-type-filled"
      style="background-color:#5D86CC; font-size: 16px;">Add New Drug</div>
  </div>
</div>
</ng-template>



<ng-template #delete_modal>
<div style="padding: 20px;">
  <h4>Delete Favourite?</h4>
  <div style="color: #555; font-size: 16px;">Are you sure you want to delete this favourite collection?</div>
  <div style="padding-top: 16px; display: flex; justify-content: flex-end;">
    <button (click)="closeDialog()" style="background-color: #eee;
                  padding: 8px 16px;
                  border-radius: 6px;
                  color: #fff;
                  font-weight: bold;
                  border: 0px;
                  margin: 4px;
                  color: #444;
                  outline: none;">Cancel</button>

    <button (click)="onClickDeleteFavMed();closeDialog();" style="background-color: #f44336;
              padding: 8px 16px;
              border-radius: 6px;
              color: #fff;
              font-weight: bold;
              border: 0px;
              margin: 4px;
            outline: none;">Yes, Delete</button>
  </div>
</div>
</ng-template>

<ng-template #viewPrescriptionModal let-modal>
  <div>
    <div class="modal-header" style="border-bottom: none;">
      <h4 class="modal-title" id="modal-basic-title">View Previous Prescription</h4>
      <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
        (click)="closeDialog();">
        <span aria-hidden="true" style="font-size: 40px;color: #000;">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div style="display: flex; height: 70vh; margin-top: 0px; width: 70vw;">
        <embed [attr.src]="prescriptionPdfURL" type="application/pdf" width="100%" height="100%">
      </div>
    </div>

  </div>
</ng-template>


<ng-template #deleteMed_modal>
<div style="padding: 15px;">
  <h4>Delete Medicine?</h4>
  <div style="color: #555; font-size: 16px;">Are you sure you want to delete Medicine?</div>
  <div style="padding-top: 16px; display: flex; justify-content: flex-end;">
    <button (click)="closeDialog()" class="cancelBtnUi">Cancel</button>
    <button (click)="onClickMedDelete();closeDialog()" class="deleteBtn">Delete</button>
  </div>
</div>
</ng-template>


<!-- Medicine Instructions Modal -->
<ng-template #medicine_instructions_modal let-modal style="width: 55vw;">
  <div class="modal-header" style="border-bottom: 0px;">
    <h4 class="modal-title" id="modal-basic-title">Instructions</h4>
    <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
      (click)="closeDialog()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <div class="input-group">
          <input id="searchbox" (input)="onSearchChange($event.target.value, 'instructions')" style="border-radius:6px; padding: 0px 8px 0px 8px; height: 40px; margin-top: -20px;
          font-size: 18px;" class="form-control" placeholder="Start typing to search" name="dp">
        </div>
        <div style="height: 80vh; overflow: scroll;">
          <div
            style="display: flex; flex-direction: row; flex-wrap: wrap; margin-top: 16px; margin-bottom: 16px; width: 45vw; ">

            <div *ngFor="let item of allMedicineInstructions; let i = index" (click)="selectModalValue(i)"
              class="searchable-component-style1" [ngStyle]="{'background-color': item.selected ? '#E5F6F4' : '#fff'}">
              <img *ngIf="item.selected  != true" style="margin: 0px 8px 0px 0px; height: 18px; width: 18px;"
                src="assets/images/tick_mark_grey.svg" class="tick-icon" />

              <img *ngIf="item.selected" style="margin: 0px 8px 0px 0px; height: 18px; width: 18px;"
                src="assets/images/tick_mark_color.svg" class="tick-icon" />
              <div style="font-size: 18px;">{{item.name}}</div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">

    <div (click)="saveModalValues('medicine_instructions'); closeDialog()" class="btn-type-filled"
      style="background-color: #5D86CC; font-size: 15px;">Add Instructions</div>
  </div>
</ng-template>


<ng-template #favMed_modal let-modal>

<div style="padding: 20px;"> 
  <div class="modal-header" style="border-bottom: 0px;">
    <h4 class="modal-title" id="modal-basic-title"> Add as Favourite</h4>
    <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
      (click)="closeDialog()">
      <span aria-hidden="true" style="font-size: 37px;">&times;</span>
    </button>
  </div>
  <div
    style="background-color: #ffffff; padding: 8px; border-radius: 8px; border: 1px dashed gray; position: relative;">
    <div style="flex-direction: row; display: flex; align-items: center;">
      <img style="height: 30px; width: 30px;" src="assets/images/favourite_heart_red.svg" />

      <div style="margin-left: 8px;">
        <div style="font-size: 17px; font-weight: 600;">Do you want to add this prescription as
          Favourite ?
        </div>
        <div style="color: grey; font-size: 15px; margin-top: -4px;">(Specify name of the diagnosis e.g.
          Flu, Indigestion
          )
          and
          few words to mark it.</div>
      </div>
    </div>


    <div style="display: flex;flex-direction: row; align-items: center; padding: 0px;">
      <input style="padding: 8px; border: 1px solid #a4c2de; border-radius: 6px;" #favInput id="favInput" matInput
        [(ngModel)]="favApptName">

    </div>
  </div>

  <div (click)="getFavName(favApptName);closeDialog();" style="display: flex;flex-direction: row;justify-content: end;">

    <button class="btn-back">
      <img style="height: 24px; width: 24px;" src="assets/images/favourite_heart_red.svg" />
      Add as Favourite</button>
  </div>
</div>
</ng-template>
<ng-template #delete_modalForPopularMed>
  <div style="width: 100%; padding: 20px;">
  <h4>Delete Medicine?</h4>
  <div style="color: #555; font-size: 16px;">Are you sure you want to delete selected medicine?</div>
  <div style="padding-top: 16px; display: flex; justify-content: flex-end;">
    <button (click)="closeDialog()" style="background-color: #eee;
                padding: 8px 16px;
                border-radius: 6px;
                color: #fff;
                font-weight: bold;
                border: 0px;
                margin: 4px;
                color: #444;
                outline: none;">Cancel</button>

    <button (click)="deletePopularMedicine();closeDialog();" style="background-color: #f44336;
            padding: 8px 16px;
            border-radius: 6px;
            color: #fff;
            font-weight: bold;
            border: 0px;
            margin: 4px;
          outline: none;">Delete</button>
  </div>
  </div>

</ng-template>

<ng-template #addNewModalDiagnosis>
  <div style="width: 25vw; padding: 20px;">
    <h4>Add New Diagnosis?</h4>
    <input #searchFavourites placeholder="Enter Name of Diagnosis" type="text" aria-label="Number" matInput
      class="searchTab" [(ngModel)]="searchStringDiagnosis">
    <div style="padding-top: 16px; display: flex; justify-content: flex-end;">


      <button (click)="closeDialog()" style="background-color: #eee;
                padding: 8px 16px;
                border-radius: 6px;
                color: #fff;
                font-weight: bold;
                border: 0px;
                margin: 4px;
                color: #444;
                outline: none;">Cancel</button>

      <button (click)="searchDataDignosisAdd();  closeDialog();" style="background-color: #5D86CC;
            padding: 8px 16px;
            border-radius: 6px;
            color: #fff;
            font-weight: bold;
            border: 0px;
            margin: 4px;
          outline: none;">Save</button>
    </div>
  </div>
</ng-template>
<ng-template #addNewModalConcerns>
  <div style="width: 25vw; padding: 20px;">
    <h4>Add New Concerns and Issues?</h4>
    <input #searchFavourites placeholder="Enter Name of Concerns and Issues" type="text" aria-label="Number" matInput
      class="searchTab" [(ngModel)]="searchStringConcerns">
    <div style="padding-top: 16px; display: flex; justify-content: flex-end;">


      <button (click)="closeDialog()" style="background-color: #eee;
                padding: 8px 16px;
                border-radius: 6px;
                color: #fff;
                font-weight: bold;
                border: 0px;
                margin: 4px;
                color: #444;
                outline: none;">Cancel</button>

      <button (click)="searchComplaintAdd();  closeDialog();" style="background-color: #5D86CC;
            padding: 8px 16px;
            border-radius: 6px;
            color: #fff;
            font-weight: bold;
            border: 0px;
            margin: 4px;
          outline: none;">Save</button>
    </div>
  </div>
</ng-template>


<ng-template #addNewModalDietNutrition>
  <div style="width: 25vw; padding: 20px;">
    <h4>Add New Diet & Nutrition?</h4>
    <input #searchFavourites placeholder="Enter Name of Diet & Nutrition" type="text" aria-label="Number" matInput
      class="searchTab" [(ngModel)]="searchStringDietaryAdvice">
    <div style="padding-top: 16px; display: flex; justify-content: flex-end;">


      <button (click)="closeDialog()" style="background-color: #eee;
                padding: 8px 16px;
                border-radius: 6px;
                color: #fff;
                font-weight: bold;
                border: 0px;
                margin: 4px;
                color: #444;
                outline: none;">Cancel</button>

      <button (click)="addDietaryAdvice();  closeDialog();" style="background-color: #5D86CC;
            padding: 8px 16px;
            border-radius: 6px;
            color: #fff;
            font-weight: bold;
            border: 0px;
            margin: 4px;
          outline: none;">Save</button>
    </div>
  </div>
</ng-template>

<ng-template #addNewModalGeneralInstruction>
  <div style="width: 25vw; padding: 20px;">
    <h4>Add New Instructions?</h4>
    <input #searchFavourites placeholder="Enter Name of Instructions" type="text" aria-label="Number" matInput
      class="searchTab" [(ngModel)]="searchStringGeneralInstructions">
    <div style="padding-top: 16px; display: flex; justify-content: flex-end;">


      <button (click)="closeDialog()" style="background-color: #eee;
                padding: 8px 16px;
                border-radius: 6px;
                color: #fff;
                font-weight: bold;
                border: 0px;
                margin: 4px;
                color: #444;
                outline: none;">Cancel</button>

      <button (click)="addGeneralInstruction();  closeDialog();" style="background-color: #5D86CC;
            padding: 8px 16px;
            border-radius: 6px;
            color: #fff;
            font-weight: bold;
            border: 0px;
            margin: 4px;
          outline: none;">Save</button>
    </div>
  </div>
</ng-template>

<ng-template #incomplete_data_modal>
  <div style="width: 100%; padding: 20px;">
    <h4>Mandatory Fields</h4>
    <div style="color: #555; font-size: 16px;">Please fill all the required fields to save medicine.</div>
    <div style="padding-top: 16px; display: flex; justify-content: flex-end;">
      <button (click)="closeDialog()" style="background-color: #eee;
                  padding: 8px 16px;
                  border-radius: 6px;
                  color: #fff;
                  font-weight: bold;
                  border: 0px;
                  margin: 8px;
                  color: #444;
                  outline: none;">Ok</button>
    </div>
  </div>
 
</ng-template>
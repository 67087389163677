<!--start-->

<div
    style="display: flex; flex-direction: row; height: 70px; width: 100%; align-items: center; justify-content: space-between;border-bottom: 1px solid #eee;">

 

    <div style="display: flex; flex-direction: row;">
        <!-- <img  (click)="toggleSideNav()"  style="margin: 3px 8px 0px 0px"
        src="assets/images/menuIcon.svg" /> -->
            
        <div *ngIf="patientData.User && patientData.User.Customer && patientData.User.Customer.fcmtoken"
            title="Health app installed" class="healthIndicatorInstall">
        </div>
        <div *ngIf="patientData.User.Customer && !patientData.User.Customer.fcmtoken"
            title="Health app is not installed" class="healthIndicatorUnInstall"></div>

        <!-- <div 
            title="Health app is not installed" >
            <mat-icon [ngClass]="'editIcon'">edit</mat-icon>
        </div> -->
        <!-- <img style="height: 20px; margin-left: 12px; width: 30px; align-self: center;"
            src="assets/images/arrow-back.svg" alt="" /> -->


        <div (click)="editPatient()" title="Edit Patient" style="padding-left: 8px; font-size: 18px; font-weight: bold; color: #333; margin-left: 20px;
            cursor: pointer; ">

            <figure *ngIf="gender=='M'" style="text-align: center;">

                <img height="50px" width="50px" style="text-align: center;
                margin-top: 13px;
                border: 0.5px solid #ccc;
                border-radius: 12px !important;" *ngIf="profilePhoto !== ''" src="{{profilePhoto}}" alt="" />
                <img height="50px" width="50px" style="text-align: center;
                margin-top: 13px;
                border: 0.5px solid #ccc;
                border-radius: 12px !important;" *ngIf="profilePhoto == ''" src="assets/images/person_male.png"
                    alt="" />

            </figure>
            <figure *ngIf="gender=='F'">

                <img height="50px" width="50px" style="text-align: center;
                margin-top: 13px;
                border: 0.5px solid #ccc;
                border-radius: 12px !important;" *ngIf="profilePhoto !== ''" src="{{profilePhoto}}" alt="" />
                <img height="50px" width="50px" style="text-align: center;
                    margin-top: 13px;
                    border: 0.5px solid #ccc;
                    border-radius: 12px !important;" *ngIf="profilePhoto == ''" src="assets/images/person_female.png"
                    alt="" />


            </figure>


          
        </div>
        <div *ngIf="!showAgeYear"
            style="padding-left: 20px; font-size: 18px; font-weight: bold; color: #333; align-self: center;">
           
            {{first_name}}
            {{middle_name}}
            {{last_name}}
            ({{gender}} -{{ageObj.years}}y
            {{ageObj.months}}m {{ageObj.days}}d)

           
            <span *ngIf="parentForm=='QRX'" #moreInfoTrigger="matMenuTrigger" 
                style="cursor: pointer; font-size: 16px; color: #4687BF;text-decoration: underline;"
                [matMenuTriggerFor]="menuForPatientInfo" >More Information</span> <br>
            <span style="font-size: 16px;"> <span *ngIf="!locality"
                    style="color: #bcbcbc;  pointer-events: none; ">Locality</span> <span
                    *ngIf="locality">{{locality}}</span> (+{{mobileNumber}}) &nbsp;
                <span style="text-decoration:underline; cursor: pointer !important;"> <span (click)="editPatient()"
                        style="text-decoration:underline; color: #4687BF; ">Edit </span>&nbsp;
                        <mat-icon [ngClass]="'editPatientIcon'">edit</mat-icon>

                </span>
            </span>
        </div>

        <div *ngIf="showAgeYear"
            style="padding-left: 20px; font-size: 18px; font-weight: bold; color: #333; align-self: center;">
          
            {{first_name}}
            {{middle_name}}
            {{last_name}}
            ({{gender}} - {{ageYears}}y {{ageMonths}}m {{ageWeeks}}d)
            <!-- +{{mobileNumber}} -->
        </div>


    </div>

    <div style="flex-direction: row; display: flex;">


        <!-- MENU ONE INVESTIGATIONS -->
        <mat-menu #menu="matMenu" xPosition="before" class="menuUi">
            <div style="height: 100%; width: 100%;" (mouseleave)="closeTabMenu(0)">
                <div style="color:#798197; font-weight:bold; font-size:12px; padding: 8px;">INVESTIGATIONS</div>
                <div (click)="openRequisitions()" class="menu-item">
                    <div style="color:#333; font-weight:bold; font-size:16px">Requisitions</div>
                    <!-- <div style="height: 26px; width: 26px; background-color: #E2ECFB; border-radius: 50px; border: 1px solid #4687BF; color: #4687BF; font-weight: bold; text-align: center; padding-top: 2px;">
                    3</div> -->
                </div>

                <div (click)="openReports()" class="menu-item">
                    <div style="color:#333; font-weight:bold; font-size:16px">Reports</div>
                    <div></div>
                </div>
            </div>
        </mat-menu>

        <!-- MENU TWO HEALTH PROFILE -->
        <mat-menu #menuTwo="matMenu" xPosition="before" class="menuUi">
            <div style="height: 100%; width: 100%;" (mouseleave)="closeTabMenu(1)">
                <div style="color:#798197; font-weight:bold; font-size:12px; padding: 8px;">HEALTH PROFILE</div>
                <div (click)="openVitals()" class="menu-item">
                    <div style="color:#333; font-weight:bold; font-size:16px">Vitals</div>
                    <!-- <div
                    style="height: 26px; width: 26px; background-color: #E2ECFB; border-radius: 50px; border: 1px solid #4687BF; color: #4687BF; font-weight: bold; text-align: center; padding-top: 2px;">
                    3</div> -->
                </div>

                <div (click)="openImmunization()" class="menu-item">
                    <div style="color:#333; font-weight:bold; font-size:16px">Immunization</div>
                    <div></div>
                </div>




                <div style="color:#798197; font-weight:bold; font-size:12px; margin-top: 6px; padding: 8px;">COMING SOON
                </div>

                <div (click)="openMilestone()" *ngIf="ageYears < 8" class="menu-item">
                    <div style="color:#333; font-weight:bold; font-size:16px">Milestone</div>
                    <!-- <div
                        style="font-size: 10px; background-color: #FFF3CD; border-radius: 4px; border: 1px dashed #664D03; color: #664D03; font-weight: bold; text-align: center; padding: 2px 6px 2px 6px">
                        SOON</div> -->
                    <div></div>
                </div>

                <div class="menu-item">
                    <div style="color:#ccc; font-weight:bold; font-size:16px">Fit/Nutrition</div>
                    <div
                        style="font-size: 10px; background-color: #FFF3CD; border-radius: 4px; border: 1px dashed #664D03; color: #664D03; font-weight: bold; text-align: center; padding: 2px 6px 2px 6px">
                        SOON</div>
                </div>

                <div class="menu-item">
                    <div style="color:#ccc; font-weight:bold; font-size:16px">Opthalmic</div>
                    <div
                        style="font-size: 10px; background-color: #FFF3CD; border-radius: 4px; border: 1px dashed #664D03; color: #664D03; font-weight: bold; text-align: center; padding: 2px 6px 2px 6px">
                        SOON</div>
                </div>

                <!-- <div class="menu-item">
                    <div style="color:#ccc; font-weight:bold; font-size:16px">Dental</div>
                    <div
                        style="font-size: 10px; background-color: #FFF3CD; border-radius: 4px; border: 1px dashed #664D03; color: #664D03; font-weight: bold; text-align: center; padding: 2px 6px 2px 6px">
                        SOON</div>
                </div> -->
            </div>
        </mat-menu>

        <!-- MENU THREE DOCUMENTS -->
        <mat-menu #menuThree="matMenu" xPosition="before" class="menuUi">
            <div style="height: 100%; width: 100%;" (mouseleave)="closeTabMenu(2)">
                <div style="color:#798197; font-weight:bold; font-size:12px; padding: 8px;">DOCUMENTS</div>

                <div (click)="openDocuments();" class="menu-item">
                    <div style="color:#333; font-weight:bold; font-size:16px">All Documents</div>
                    <!-- <div
                    style="height: 26px; width: 26px; background-color: #E2ECFB; border-radius: 50px; border: 1px solid #4687BF; color: #4687BF; font-weight: bold; text-align: center; padding-top: 2px;">
                    3</div> -->
                </div>

                <div (click)="openUpload()" class="menu-item">
                    <div style="color:#333; font-weight:bold; font-size:16px">Upload</div>
                    <!-- <div
                    style="height: 26px; width: 26px; background-color: #E2ECFB; border-radius: 50px; border: 1px solid #4687BF; color: #4687BF; font-weight: bold; text-align: center; padding-top: 2px;">
                    3</div> -->
                </div>

                <div (click)="openReferral()" class="menu-item">
                    <div style="color:#333; font-weight:bold; font-size:16px">Referral</div>
                    <div></div>
                </div>

                <div (click)="openCertificate()" class="menu-item">
                    <div style="color:#333; font-weight:bold; font-size:16px">Certificate</div>
                    <div></div>
                </div>

                <div (click)="openReceipt()" class="menu-item">
                    <div style="color:#333; font-weight:bold; font-size:16px">Bill/Receipt</div>
                    <div></div>
                </div>

                <div style="color:#798197; font-weight:bold; font-size:12px; margin-top: 6px; padding: 8px;">RECORDS
                </div>
                <div (click)="openNotes()" class="menu-item">
                    <div style="color:#333; font-weight:bold; font-size:16px">Notes</div>
                    <div></div>
                </div>

            </div>

        </mat-menu>



        <mat-menu #menuForPatientInfo="matMenu" (menuOpened)="menuOpened()"  xPosition="before" class="my-menu">
            <div style="display: flex; flex-direction: row; justify-content: space-between;">
            <div style="font-size: 18px; font-weight: 600; margin: 10px 0px 0px 12px;">More Information</div>
            <div style="display: flex; flex-direction: row; justify-content: end;">

           
            <div (click)="openDialog(moreInfoModal,'moreInfoModal')">
                <button 
                 class="btn btn-secondary" 
                style=" display: flex; justify-content: center; align-items: center; border-radius: 4px; cursor: pointer;">
                <mat-icon [ngClass]="'blue-icon'">
                 edit
                </mat-icon>
                <span style="margin-left: 4px;">Edit</span>
            </button>
            </div>
            <div>
                <button 
                 class="btn cancelBtnUi" 
                style="margin-top: 6px; display: flex; justify-content: center; align-items: center; border-radius: 4px; cursor: pointer;">
              
                <span style="margin-left: 4px;">Close</span>
            </button>
            </div>
        </div>
        </div>
            <div style="height: 100%; width: 100%;" (mouseleave)="closeTabMenu(-1)">
                <div *ngIf="allergies" class="cardUi"> Allergy
                    <div *ngIf="allergies" class="textUnActiveCard">
                        {{allergies}}</div>
                </div>
              
                <div *ngIf="medicalConditions" class="cardUi"> Medical Conditions
                    <div class="textUnActiveCard">
                        {{medicalConditions}}</div>

                </div>
              
                <div *ngIf="additionalInfoForPatient.highRiskType && ageYears < 12" class="cardUi"> High-Risk
                    <div class="textUnActiveCard">
                        {{additionalInfoForPatient?additionalInfoForPatient.highRiskType:""}}</div>
                </div>
                <!-- <div *ngIf="!additionalInfoForPatient.highRiskType && ageYears < 12" class="cardUiUnActive"> High-Risk
                    <div class="textUnActiveCard" style="margin-top: -5px;">
                        NOT High-Risk</div>
                </div> -->
                <div *ngIf="additionalInfoForPatient.reactionToMeds" class="cardUi"> Reaction To Med
                    <div class="textUnActiveCard">
                        {{additionalInfoForPatient.reactionToMeds}}</div>

                </div>
                <!-- <div *ngIf="!additionalInfoForPatient.reactionToMeds" class="cardUiUnActive"> Reaction To Med
                    <div class="textUnActiveCard">
                        No reaction To med </div>

                </div> -->
                <div *ngIf="additionalInfoForPatient.geneticDisorders" class="cardUi"> Genetic Disorders
                    <div class="textUnActiveCard">
                        {{additionalInfoForPatient.geneticDisorders}}</div>
                </div>
                <!-- <div *ngIf="!additionalInfoForPatient.geneticDisorders" class="cardUiUnActive"> Genetic Disorders
                    <div class="textUnActiveCard">
                        No genetic disorders</div>

                </div> -->

            </div>
        </mat-menu>
        <div class="linktop" (click)="rxClicked()" style="cursor: pointer;">
            <img *ngIf="selectedIndex==0" style="height: 24px; width: 24px; align-self: center;"
                src="assets/images/new_prescription_topbar.svg" alt="" />
            <img *ngIf="selectedIndex!=0" style="height: 24px; width: 24px; align-self: center; fill: #000;"
                src="assets/images/new_prescription_topbar_off.svg" alt="" />
            <div style="font-size: 16px; color: #4687BF;"
                [ngStyle]="{'color': (selectedIndex == 0) ? '#4687BF' : '#807E7E'}">Quick Rx</div>

            <div style="height: 6px; width: 70%; background-color: #4687BF; border-top-right-radius: 6px; border-top-left-radius: 6px;"
                [ngStyle]="{'visibility': (selectedIndex == 0) ? 'visible' : 'hidden'}">
            </div>
        </div>


        <div (click)="openOverview()" style="cursor: pointer;" class="linktop">
            <img *ngIf="selectedIndex==1" style="height: 24px; width: 24px; fill: #000; align-self: center;"
                src="assets/images/new_overview_topbar.svg" alt="" />
            <img *ngIf="selectedIndex!=1" style="height: 24px; width: 24px; fill: #000; align-self: center;"
                src="assets/images/new_overview_topbar_off.svg" alt="" />
            <div style="font-size: 16px; color: #807E7E;"
                [ngStyle]="{'color': (selectedIndex == 1) ? '#4687BF' : '#807E7E'}">Overview
            </div>
            <div style="height: 6px; width: 100%; background-color: #4687BF; border-top-right-radius: 6px; border-top-left-radius: 6px;  visibility: hidden;"
                [ngStyle]="{'visibility': (selectedIndex == 1) ? 'visible' : 'hidden'}">
            </div>
        </div>


        <div (click)="openTabMenu(0)" [matMenuTriggerFor]="menu" style="cursor: pointer;" class="linktop">
            <img *ngIf="selectedIndex==2" style="height: 24px; width: 24px; align-self: center; fill: #000;"
                src="assets/images/new_investigations_topbar.svg" alt="" />
            <img *ngIf="selectedIndex!=2" style="height: 24px; width: 24px; align-self: center; fill: #000;"
                src="assets/images/new_investigations_topbar_off.svg" alt="" />
            <div style="font-size: 16px; color: #807E7E;"
                [ngStyle]="{'color': (selectedIndex == 2) ? '#4687BF' : '#807E7E'}">Investigations</div>
            <div style="height: 6px; width: 100%; background-color: #4687BF; border-top-right-radius: 6px; border-top-left-radius: 6px;  visibility: hidden;"
                [ngStyle]="{'visibility': (selectedIndex == 2) ? 'visible' : 'hidden'}">
            </div>
        </div>


        <div (click)="openTabMenu(1)" [matMenuTriggerFor]="menuTwo" class="linktop" style="cursor: pointer;">
            <img *ngIf="selectedIndex==3" style="height: 24px; width: 24px; align-self: center;"
                src="assets/images/new_profile_topbar.svg" alt="" />
            <img *ngIf="selectedIndex!=3" style="height: 24px; width: 24px; align-self: center;"
                src="assets/images/new_profile_topbar_off.svg" alt="" />
            <div style="font-size: 16px; color: #807E7E;"
                [ngStyle]="{'color': (selectedIndex == 3) ? '#4687BF' : '#807E7E'}">Health Profile</div>
            <div style="height: 6px; width: 100%; background-color: #4687BF; border-top-right-radius: 6px; border-top-left-radius: 6px; visibility: hidden;"
                [ngStyle]="{'visibility': (selectedIndex == 3) ? 'visible' : 'hidden'}">
            </div>
        </div>


        <div (click)="openTabMenu(2)" class="linktop" style="cursor: pointer;" [matMenuTriggerFor]="menuThree">
            <img *ngIf="selectedIndex==4" style="height: 24px; width: 24px; align-self: center;"
                src="assets/images/new_documents_topbar.svg" alt="" />
            <img *ngIf="selectedIndex!=4" style="height: 24px; width: 24px; align-self: center;"
                src="assets/images/new_documents_topbar_off.svg" alt="" />
            <div style="font-size: 16px; color: #807E7E;"
                [ngStyle]="{'color': (selectedIndex == 4) ? '#4687BF' : '#807E7E'}">Documents</div>
            <div style="height: 6px; width: 100%; background-color: #4687BF; border-top-right-radius: 6px; border-top-left-radius: 6px;  visibility: hidden;"
                [ngStyle]="{'visibility': (selectedIndex == 4) ? 'visible' : 'hidden'}">
            </div>
        </div>

        <div style="visibility: hidden;">
            <img style="height: 24px; width: 24px; align-self: center;" src="assets/images/new_settings_topbar.svg"
                alt="" />
            <div style="font-size: 16px; color: #807E7E;">Settings</div>
            <div style="height: 6px; width: 100%; background-color: #4687BF; border-top-right-radius: 6px; border-top-left-radius: 6px;"
                [ngStyle]="{'visibility': (selectedIndex == 5) ? 'visible' : 'hidden'}">
            </div>
        </div>

        <div style="visibility: hidden;">
            <img style="height: 24px; width: 24px; align-self: center;" src="assets/images/new_settings_topbar.svg"
                alt="" />
            <div style="font-size: 16px; color: #807E7E;">Settings</div>
            <div style="height: 6px; width: 100%; background-color: #4687BF; border-top-right-radius: 6px; border-top-left-radius: 6px;"
                [ngStyle]="{'visibility': (selectedIndex == 5) ? 'visible' : 'hidden'}">
            </div>
        </div>

    </div>

   

</div>

<ng-template #moreInfoModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">More Information</h4>
        <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
            (click)="closeDialog('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="width:50vw;">

        <div style="display: flex;flex-direction: column; padding-top: 10px;">

            <mat-form-field appearance="outline">
                <mat-label>Medical Conditions</mat-label>
                <input matInput [(ngModel)]="medicalConditions" placeholder="Enter Medical Conditions">
               
              </mat-form-field>
              <mat-chip-list aria-label="Fish selection" [multiple]="true" style="margin:10px 6px;">
                <mat-chip class="price-filter" style="font-size: 16px; background-color: #E8F7F5;" #ref="matChip"
                    *ngFor="let item of medicalConditionArray;let i=index" (click)='selectmedicalConditions(item);'
                    [value]="item">
                    {{item.name}}
                </mat-chip>
    
            </mat-chip-list>


              <mat-form-field appearance="outline">
                <mat-label>Allergies</mat-label>
                <input matInput [(ngModel)]="allergies" placeholder="Enter Allergies">
               
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Recurring Complaints</mat-label>
                <input matInput [(ngModel)]="recurringComplaints" placeholder="Enter Recurring Complaints">
               
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Accidental / Procedural
                    History</mat-label>
                <input matInput [(ngModel)]="accidentalHistory" placeholder="Enter Accidental / Procedural History">
               
              </mat-form-field>
        </div>
     
        <div style="display: flex;width: 100%;align-items: flex-end;justify-content: flex-end;">
            <button type="submit" class="new_btn"
                style="  display: flex;text-transform: capitalize;align-items: center;justify-content: center;"
                (click)="closeDialog('Cross click');saveMoreInfo()">Save</button>
        </div>
    </div>


</ng-template>
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MessageService } from 'primeng/api';
import { PatientService } from '../../../services/patient.service';
import * as moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';
import { refreshService } from '../../../services/refersh.service';
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { ActivatedRoute } from '@angular/router';
let dayWeek = [{ name: "Days", selected: true, value: "" }, { name: "Weeks", selected: false, value: "" },{ name: "Month", selected: false, value: "" }]
@Component({
  selector: 'app-patient-referral',
  templateUrl: './patient-referral.component.html',
  styleUrls: ['./patient-referral.component.css']
})
export class PatientReferralComponent implements OnInit {
  @ViewChild("addReferralModal") addReferralModal;
  @ViewChild("viewReferralModal") viewReferralModal;
  dayWeeksArray: any = [];
  selectedWhenToTake = ''
  daysWeeks: any = ''
  @Input() doctorObj: any
  @Input() patientResponse: any
  @Input()appointmentArr:any
  gender: any = ''
  genderHe = ""
  medicalCondtion
  medCondition
  duration
  selectedDate: any;
  durationnn
  templateText: any = ''
  docNamee = '';
  docEmail: any = '';
  pramPatientId:any;
  docMobNumber = '';
  refreelcertiPdfURL: any = '';
  refreelcertisURL: any = ''
  templateName: any = "Speciality";
  selectedTemplate: any = [];
  constructor(public dialog: MatDialog, private modalService: NgbModal, private refreshservice: refreshService, private sanitizer: DomSanitizer, private messageService: MessageService, private patientService: PatientService, private route:ActivatedRoute) {

    this.dayWeeksArray = dayWeek;
  }

  ngOnInit(): void {
  
    this.route.queryParams.subscribe(params => {
      this.pramPatientId=params.patientId ? params.patientId:null
    })
  
    console.log("AUG7777",this.appointmentArr)
    this.switchDaysWeeks(0);
    this.daysWeeks = "Days"
    this.gender = this.patientResponse.gender.toLowerCase() === 'male' ? 'M' : "F";
    if (this.gender == 'F') {
      this.genderHe = "She";
    }
    else {
      this.genderHe = 'He';
    }
    var services = JSON.parse(this.doctorObj.services);
    if (services.template_names) {
      this.templateName = 'Speciality';
      this.selectedTemplate = services.template_names;
    }
    else {
      this.selectedTemplate.push('default');
    }
    this.selectedDate = this.reverseDate(new Date());
    
    if (this.selectedTemplate.length > 0) {
      this.templateName = this.selectedTemplate[0]; // Select the first item by default
      console.log("this.templateName",this.templateName);
      
    }
  }
  openDialog(content) {
    this.dialog.open(content);
  }
  closeDialog() {

    this.dialog.closeAll();
  }
  switchDaysWeeks(index) {
    let array = this.dayWeeksArray;
    array.map((ins, ind) => {
      if (ind == index) {
        array[index].selected = true;

        this.selectedWhenToTake = ins.name;
        if (this.selectedWhenToTake == 'Days') {
          this.daysWeeks = 'Days'
        }
        else if(this.selectedWhenToTake == 'Weeks') {
          this.daysWeeks = 'Weeks'
        }
        else{
          this.daysWeeks = 'Month'
        }

        // this.daysWeeks='Weeks'
      } else {
        ins.selected = false;
        //this.daysWeeks='weeks'
        // this.daysWeeks='Weeks'
      }

    });

  }
  onClickViewRert = (referral) => {
    this.refreelcertiPdfURL = this.sanitizer.bypassSecurityTrustResourceUrl(referral.url);
    this.refreelcertisURL = referral.url;
    this.openDialog(this.viewReferralModal);
    // this.isViewRecipt = true;
  }

  editRefferal() {
    this.openDialog(this.addReferralModal);
  }
  replaceValuee(e) {
    this.medicalCondtion = e;
    this.medCondition = this.medicalCondtion
  }
  replaceValue(e) {

    this.duration = e;



    this.durationnn = this.duration


  }
  onDropdownSelectTemplate = (item) => {
    this.templateName = item
    console.log("this.templateName",item);
    
  }
  closeReferral() {
    this.docNamee = "";
    this.docEmail = "";
    this.duration = "";
    this.medicalCondtion = "";
    this.docMobNumber = "";
    this.selectedWhenToTake = " "

    this.medCondition = "(MedicalCondtion)"
    this.durationnn = "(Duration)";

    var refeeral: any = document.getElementById('refeeral').textContent;

    refeeral = " "
    // this.dayWeeksArray.map(ins=>ins.selected=false)
  }
  printNewRefreel(action) {
    
    if (this.docNamee === '') {
      this.messageService.add({ severity: 'custom', detail: 'Please enter referring doctor name' });
      // this.openDialog(this.addReferralModal)
    }
    else {


      if (this.templateName == "Speciality") {
        // alert("hello")
        this.messageService.add({ severity: 'custom', detail: 'Please specify speciality before proceeding' });


      }
      else {
        if (action == 'share') {
          this.refreelcertisURL;
        }
        else {



          if (this.docNamee == '') {

          }
          else if (this.docEmail == '') {

          }
          else if (this.docMobNumber == '') {

          }
          var refeeral: any = document.getElementById('refeeral').textContent;
          refeeral = refeeral
          this.templateText = refeeral;
        }
console.log("this.patientResponse.id", this.pramPatientId);

        var postData:any = {
          patient_id: this.patientResponse.id ? this.patientResponse.id : this.pramPatientId,
          action: action === 'share' ? 'share' : action,
          date: moment(this.parseDate(this.selectedDate)).format('YYYY-MM-DD'),
          type: 'Referal Note',
          title: 'ADVICE SOLICITED',
          text: this.templateText,
          docName: this.docNamee,
          docNo: this.docMobNumber,
          docEmail: this.docEmail,
          "templateName": this.templateName

        }
        var appointmentId=null
        if(this.appointmentArr.length){{
          this.appointmentArr.map(ins=>{
            appointmentId=ins.id
          })
        }
          postData.appointmentId=appointmentId
        }


        console.log("TEST", JSON.stringify(postData));

      }
      console.log("postdata 1234 ", postData);
      
      this.patientService.printCertificate(postData).subscribe(async response => {
        if (response) {

          console.log("resOct17", response)
          this.modalService.dismissAll();
          this.refreelcertiPdfURL = this.sanitizer.bypassSecurityTrustResourceUrl(response.url);
          this.refreelcertisURL = response.url;
          if (action === 'preview') {
            this.openDialog(this.viewReferralModal);
          }
          else if (action == 'share') {

            this.messageService.add({ severity: 'custom', detail: 'Certificate saved and shared with patient successfully' });


            this.refreshservice.OnPatTopNav_MenuSelected(this.patientResponse.id ? this.patientResponse.id : this.patientResponse._id + "", this.refreshservice.EventName_AllDocuments);

            // const result = await ({
            //   message:
            //     'Use this link to view certificate\n' + response.data.url,

            // });

          }
        }
      });
    }
  }
  reverseDate(date) {
    let d = new Date(date);
    return { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() };
  }
  parseDate(dateObject) {
    var date = new Date();
    date.setDate(dateObject.day);
    date.setMonth(dateObject.month - 1);
    date.setFullYear(dateObject.year);
    return moment(date).format("YYYY-MM-DD HH:mm:ss");
  }
  validateNumber(e) {
    return (
      (e.charCode > 47 && e.charCode < 58) ||
      e.charCode == 46 ||
      e.which == 8 ||
      e.which == 13 ||
      e.keyCode == 9
    );
  }
}

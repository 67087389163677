<div style="display: flex; height: 100vh; width: 100%; flex-direction: column; flex: 1;">
    <div class="heading">Referral</div>
    <div class="subheading">You can create referral for this patient by tapping 'Create Referral' button below
    </div>
    <div
        style="display: flex; background-color: #fff; height: 100%; width: 100%; flex: 1; justify-content: center; align-items: center; flex-direction: column;">

        <img style="height: 36%; width: 36%; margin-top:-100px;" src="assets/images/referral_icon.svg" alt="" />

        <button (click)="openDialog(addReferralModal);closeReferral();" class="btnUi" style="width: 14%;">Tap to create Referral</button>

    </div>
</div>

<ng-template #addReferralModal let-modal>
<div style="padding: 20px;">
    <div class="modal-header" style="border-bottom: none;">
        <h4 class="modal-title" id="modal-basic-title">Create New Referral</h4>

        <div style="display:flex; flex-direction: row;  margin-right: 30px;">

            <div ngbDropdown class="d-inline-block">
                <button class="btn btn-outline-primary" id="dropdownBasic1" style="border: 0px;  border-bottom: 1px solid #ccc;
            padding-right: 0px;
            color: #333;
            background-color: #fff;
            margin-right: 4px;
            " ngbDropdownToggle>{{templateName}} </button>

                <div ngbDropdownMenu aria-labelledby="dropdownBasic1" style="top:-11px">
                    <button (click)="onDropdownSelectTemplate(item)" style="margin: 0px 16px 0px 0px !important"
                        *ngFor="let item of selectedTemplate; let i = index" ngbDropdownItem>{{item}}</button>
                </div>
            </div>

            <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
                (click)="closeDialog();closeAddReceiptModal()">
                <span aria-hidden="true" style="font-size: 40px; color: #000;">&times;</span>
            </button>
        </div>
    </div>

    <div class="modal-body modal-lg">


        <div style="font-size: 18px; color:#000;font-weight: bold; margin-left: 5px;"> Referral Doctor Details</div>

        <div style="display: flex; flex-direction: row;justify-content: space-between; ">
            <mat-form-field style="width: 47%;">
                <mat-label style="font-size: 16px;">Referring Doctor's Full Name</mat-label>
                <input matInput [(ngModel)]="docNamee">
            </mat-form-field>
            <mat-form-field style="width: 47%;">
                <mat-label style="font-size: 16px;">Referring Doctor's Full Email</mat-label>
                <input matInput [(ngModel)]="docEmail">
            </mat-form-field>

        </div>
        <div style="display: flex; flex-direction: row;justify-content: space-between;">
            <mat-form-field style="width: 47%;">
                <mat-label style="font-size: 16px;">Referring Doctor's Mobile Number</mat-label>
                <input matInput [(ngModel)]="docMobNumber" [maxLength]="10" (keypress)="validateNumber($event)">
            </mat-form-field>
            <mat-form-field style="width: 47%;">
                <mat-label style="font-size: 16px;">Health Condition</mat-label>
                <input matInput (ngModelChange)='replaceValuee($event);' [(ngModel)]="medicalCondtion">
            </mat-form-field>

        </div>

        <div style="display: flex; flex-direction: row; ">
            <mat-form-field style="width: 47%;">
                <mat-label style="font-size: 16px;">Duration</mat-label>
                <input matInput (keypress)="validateNumber($event)" (ngModelChange)='replaceValue($event);'
                    [(ngModel)]="duration">
            </mat-form-field>

            <div style="display: flex; flex-direction: row;margin-left: 34px; margin-top: 15px; flex-wrap: wrap;">
                <div *ngFor="let item of dayWeeksArray; let i = index">
                    <div (click)="switchDaysWeeks(i)"
                        [ngClass]="(item.selected) ?  'highlightedBox' :'unhighlightedBox'" type="button"
                        class="btn btn" style="margin-bottom: -4px;
          font-size: 16px;
       
          height: 41px;
          margin-top: 2px; ">{{item.name}}</div>
                </div>
            </div>

        </div>
        <div style="display:flex; flex-direction: row; justify-content: space-between; flex: 1; margin-right: 15px;">
            <div class="newInputStyleLabel" style="font-size: 16px;margin-left: 5px;">Message Preview</div>

            <div style="color: #8B8B8B; font-size: 16px;">(Editable)</div>
        </div>

        <div id="refeeral" style="padding-top: 20px;" contentEditable="true" type="text" cols="40" rows="10"
            class="text-area-style" data-id="2">
            <div id="isPed" style="display:flex;flex-direction: row; margin-left: 5px;">For your expert opinion and
                advice {{genderHe}} has {{medCondition}} Since {{durationnn}} {{daysWeeks}}. Kindly, let me know your
                advice.
                Also,further course of treatment and any investigation if needed.
                Thanking you. </div> <br>

        </div>
        <div style="display: flex; justify-content: flex-end; ">
            <button type="submit" style="margin-top: 10px;" class="btnUi" (click)="printNewRefreel('preview','share')">Preview</button>
        </div>
    </div>

</div>

</ng-template>
<ng-template #viewReferralModal let-modal>
    <div>
        <div class="modal-header" style="border-bottom: none;">
            <h4 class="modal-title" id="modal-basic-title">Certificate</h4>
            <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
                (click)="closeDialog();">
                <span aria-hidden="true" style="font-size: 40px; color: #000;">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div style="display: flex;  height: 70vh; margin-top: 0px; width: 70vw;">
                <embed [attr.src]="refreelcertiPdfURL" type="application/pdf" width="100%" height="100%">
            </div>

            <br>

            <div style="display:flex; flex-direction: row; justify-content: flex-end; flex: 1; margin-right: 0px;">
                <!-- <div (click)="editRefferal();modal.dismiss();" class="quickrx" style="margin-left: -1px;"> Edit</div> -->

                <div (click)="printNewRefreel('share');closeDialog();" class="btnUi" style="margin-right:0px">Save &
                    Share</div>
            </div>



        </div>

    </div>
</ng-template>

<p-toast position="top-right" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
      <div class=" p-flex p-flex-column"
        style="flex: 1; background-color:#BAE8FC; opacity: 0.95; display: flex; flex-direction: row; align-items: center; border-left: 8px solid #0D8CCD; border-radius: 8px;">
        <div>
          <img (click)="goBack()" style="height: 36px; margin-left: 12px; width: 36px; align-self: center;"
            src="assets/images/exclamation-circle-blue.svg" alt="" />
        </div>
        <div class=" p-text-center">
          <div style="font-size: 18px;
                    font-weight: bold;
                    padding: 12px 0px 0px 12px;
                    
                    color: #034A70;">{{message.summary}}</div>
          <div style="padding-left: 12px; padding-bottom: 12px; color: #034A70; font-size: 16px; margin-top: -6px;">
            {{message.detail}}</div>
        </div>
      </div>
    </ng-template>
  </p-toast>
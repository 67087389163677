<script src="https://ajax.googleapis.com/ajax/libs/jquery/2.1.1/jquery.min.js"></script>
<script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js"></script>



<div class="main-content-loader d-flex justify-content-center align-items-center position-fixed" *ngIf="showLoading">
    <label for="" class="loader"></label>
</div>



<div style="background-color: #f0eaff; display:flex; height:60px; width:95%; box-shadow: 6px 6px 8px #94999e; margin-bottom: 16px;  align-items: center; position: fixed;
  z-index: 2;margin-top: 0px;padding-bottom: 17px;">

    <div *ngFor="let menu of tabArray;let i = index" style="display: flex;flex-direction: row;">

        <div (click)="switchMenu(i)"
            [ngClass]="(menu.selected && menu.highlighted )?'activeTabRound':(menu.selected && !menu.highlighted ) ? 'activeTab': 'unActiveTab' ">

            <div [ngClass]="(menu.selected) ?  'roundedBox' :'colorChangeRoundBox'">{{i+1}}

            </div>
            <p [ngClass]="(menu.selected) ?  'textStyle1 ' :'textStyle'" style="cursor: pointer;"> {{menu.name}}</p>
            <!-- <a  style="color: #FFF;"  (click)="changeTabsProfile()" class="nav-link textStyle"  >
              Profile
           </a> -->


        </div>


    </div>





</div>
<br>
<br>
<br>
<br>

<div *ngIf="showProfile" style="padding: 30px;">

    <div *ngIf="showProfile" style="display: flex;justify-content: center;">
        <h3 style="color: #94999e;">Profile</h3> <br>

    </div>
    <!-- <div style="display: flex;flex-direction: row;justify-content: center;">
        <div style="font-size: 18px;color: #6b6767;">Enter the login information for your account. You will</div>
    </div>
    <div style="display: flex;flex-direction: row;justify-content: center;">
        <div style="font-size: 18px;color: #6b6767;">be able to create addtional users after registering</div>
    </div> -->
    <br>

    <div>
        <br>


        <div style="display: flex;flex-direction: row;justify-content: center;">

            <div class="form" style="display: flex;flex-direction: row;justify-content: center;">

                <div style="margin-left: 5px; margin-top: -16px;">
                    <label class="fieldsetlable" style="margin-left: 5px;">Country</label>
                    <input type="text" maxlength="2" (keypress)="validateNumber($event)" [(ngModel)]="stdCode"
                        class="form-control" style="border-radius: 6px; width: 81px;" readonly>

                </div>



                <div *ngIf="!isEdit" class="form-item" style="margin-left: 10px;">
                    <input type="text" maxlength="10" (focus)="addLabelClass($event)"
                        (keypress)="validateNumber($event)" (blur)="removeLabelClass($event)" [(ngModel)]="mobileNumber"
                        class="form-control" autocomplete="off" required style="border-radius: 6px; width: 300px;">
                    <label>Mobile Number</label>
                </div>

                <div *ngIf="isEdit" style="margin-left: 18px;margin-top: -16px;">
                    <label class="fieldsetlable">Mobile Number</label>

                    <input type="text" maxlength="10" (focus)="addLabelClass($event)"
                        (keypress)="validateNumber($event)" (blur)="removeLabelClass($event)" [(ngModel)]="mobileNumber"
                        class="form-control" autocomplete="off" required style="border-radius: 6px; width: 300px;"
                        [disabled]="isEdit">

                </div>
                <div *ngIf="!isEdit" style="position: absolute; margin-left: -82px;">

                    <div *ngIf="!isOTPConfirmed && !modifyRmn">
                        <div *ngIf="mobileNumber.length === 10" (click)="sentParentOTP();open(otpModal, 'otpModal')"
                            class="confromBox"><span>Confirm</span></div>


                        <div *ngIf="mobileNumber.length < 10" class="confromBoxDisable" style="margin-left: -11px;">
                            Confirm</div>
                    </div>

                    <div *ngIf="modifyRmn">
                        <div *ngIf="mobileNumber.length === 10" (click)="sentParentOTP();open(otpModal, 'otpModal')"
                            class="confromBox"><span>Confirm</span></div>


                        <div *ngIf="mobileNumber.length < 10" class="confromBoxDisable" style="margin-left: -11px;">
                            Confirm</div>
                    </div>
                    <div *ngIf="!isEdit" style="position: absolute; margin-left: -82px;">
                        <div *ngIf="isOTPConfirmed">
                            <div style="margin-left: 38px;">
                                <img *ngIf="modifyRmn" style="margin-left: -46px;margin-top: 4px; display: none;"
                                    width="30" height="30" src="assets/images/tick_mark_color.svg" alt="" />
                                <img *ngIf="!modifyRmn" style="margin-left: 12px;margin-top: 4px; " width="30"
                                    height="30" src="assets/images/tick_mark_color.svg" alt="" />

                            </div>
                        </div>


                    </div>


                </div>

                <div style="margin-left: 39px; margin-top: -16px;">
                    <label class="fieldsetlable" style="margin-left: 5px;">Country</label>
                    <input type="text" maxlength="2" (keypress)="validateNumber($event)" [(ngModel)]="stdCode"
                        class="form-control" autocomplete="off" style="border-radius: 6px; width: 81px;" readonly>

                </div>

                <div class="form-item" style="margin-left: 10px;">
                    <input type="text" class="form-control" [(ngModel)]="secondNumber"
                        (keypress)="validateNumber($event)" maxLength="10" autocomplete="off" required
                        style="border-radius: 6px; width: 300px;">
                    <label>Addtional Mobile Number</label>
                </div>


            </div>

            <!-- <p class="errors" *ngIf="errors.secondNumber">{{errors.secondNumber}}</p> -->



        </div>
        <div style="display: flex;justify-content: center;">
            <div style="justify-content: center;align-items: center; width: 840px;height: 100%;">
                <div style="display: flex;flex-direction: row;">
                    <ngb-alert *ngIf="!closed" (close)="closed=true" style=" width: 50%;font-size: 12px;
      
            margin-top: 13px;"><strong>Why Family's Mobile Number?</strong><br> You are adding the mobile number as
                        Family's Registered.
                        Number(FRN) with Copious Care.while Visiting doctor patient will always be identified with this
                        numbe</ngb-alert>

                    <ngb-alert *ngIf="!closed" (close)="closed=true" style=" width: 50%;margin-left: 13px;font-size: 12px;
      
                margin-top: 13px;"> <strong>Why additinal Mobile Number?</strong><br> This is additional important
                        number in the family.
                        This is help number and not(FRN)</ngb-alert>
                </div>
            </div>
        </div>


        <!-- <div style="display: flex;flex-direction: row;justify-content: center;"> 
        <div class="alert alert-warning alert-dismissible" role="alert" style=" width: 30%;margin-left: 13px;font-size: 12px;
      
        margin-top: 13px;">
       <button type="button" class="close" (click)="tostFun()" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
            <strong>Why Family's Mobile Number?</strong><br> You are adding the mobile number as Family's Registered.
            Number(FRN) with Copious Care.while Visiting doctor patient will always be identified with this number
          </div>
          <div class="alert alert-warning alert-dismissible" style=" width: 30%;margin-left: 13px;font-size: 12px;
         
          margin-top: 13px;">
         <button type="button" class="close" (click)="tostFunSecondNo()" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
              <strong>Why additinal Mobile Number?</strong><br> This is additional important number in the family.
              This is help number and not(FRN)
            </div>
        </div> -->


        <br>
        <div style="display: flex; flex-direction: row; justify-content: center;">

            <div style="width: 280px;">
                <app-custom-textinput label="First Name" [callBack]="onFormValue"
                    field="{{selectedFormValue.firstname}}" placeholder="First Name" identifier="firstname" style="margin-bottom: 20px; background-color: #b91818;">
                </app-custom-textinput>
            </div>

            <app-custom-textinput label="Middle Name" [callBack]="onFormValue" field="{{selectedFormValue.middlename}}"
                placeholder="Middle Name" identifier='middlename' style="width: 280px; margin-left: 10px;">
            </app-custom-textinput>
            <app-custom-textinput label="Last Name" [callBack]="onFormValue" field="{{selectedFormValue.lastname}}"
                identifier='lastname' placeholder='Last Name' style="width: 280px;margin-left: 10px; ">
            </app-custom-textinput>

        </div>
        <br>
        <p style="display: flex;flex-direction: row;justify-content: center;
        display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: 387px;
    color: #6d6868;
    margin-top: -27px;">Gender</p>
        <div style="display: flex; flex-direction: row; justify-content: center;margin-left: -4px;margin-top: -8px;">

            <div *ngIf="showDate && ageYears" class="selectDropDown calendarIcon calender onFocus"
                style="width: 280px;margin-left:13px;margin-top: -20px;height: 40px; ">
                <label [hidden]="isEdit" class="fieldsetlable">Date of Birth<span class="required">*</span></label>
                <input placeholder="DD/MM/YYYY" type="text" [maxDate]="date" [minDate]="{year:1920,month:1,day:1}"
                    id="dobInput" class="form-control newInputBox" [(ngModel)]="dob" (ngModelChange)="getAge();"
                    ngbDatepicker #d="ngbDatepicker" readonly (click)="d.toggle();" [hidden]="isEdit"
                    style="border-radius: 4px !important; color: #656565 !important; font-size: 1rem !important; height: 40px;">
                <img (click)="d.toggle()" [hidden]="isEdit" class="calendarbg" style="top: 81px; right: 8px; width: 25px; padding-left: 5px;margin-bottom: 5px;
                                        margin-top: -51px;
                                        margin-left: 310px;position: absolute;" src="assets/images/calendarIcon.svg"
                    alt="" />

                <p class="errors" *ngIf="errors.ageYears">{{errors.ageYears}}</p>
                <!-- <p class="errors" *ngIf="errors.dob">{{errors.dob}}</p> -->




            </div>

            <div *ngIf="showDate && !ageYears" class="selectDropDown calendarIcon calender onFocus"
                style="width: 280px;margin-left:13px;margin-top: -20px;height: 40px; ">
                <label [hidden]="isEdit" class="fieldsetlable">Date of Birth<span class="required">*</span></label>
                <input placeholder="DD/MM/YYYY" type="text" [maxDate]="date" [minDate]="{year:1920,month:1,day:1}"
                    id="dobInput" class="form-control newInputBox" [(ngModel)]="dob" (ngModelChange)="getAge();"
                    ngbDatepicker #d="ngbDatepicker" readonly (click)="d.toggle();" [hidden]="isEdit"
                    style="border-radius: 4px !important; color: #656565 !important; font-size: 1rem !important; height: 40px;">
                <img (click)="d.toggle()" [hidden]="isEdit" class="calendarbg" style="top: 81px; right: 8px; width: 25px; padding-left: 5px;margin-bottom: 5px;
                                        margin-top: -51px;
                                        margin-left: 310px;position: absolute;" src="assets/images/calendarIcon.svg"
                    alt="" />

                <p class="errors" *ngIf="errors.ageYears">{{errors}}</p>
                <!-- <p class="errors" *ngIf="errors.dob">{{errors.dob}}</p> -->




            </div>


            <div *ngIf="!isEdit " class="form-item" style="margin-left: 15px;">
                <input type="text" class="form-control" [(ngModel)]="ageYears" (ngModelChange)="addAge()"
                    autocomplete="off" required style="border-radius: 6px; width: 280px;">
                <label for="username">Age In years</label>
            </div>


            <div *ngIf="isPediatric">
                <div *ngIf="isEdit " style="margin-left: 18px;margin-top: -16px;">
                    <label class="fieldsetlable">Age In years</label>

                    <input type="text" [disabled]="isEdit" class="form-control"
                        placeholder="{{ageYears}}y {{ageMonths}}m {{ageWeeks}}d" (ngModelChange)="addAge()"
                        autocomplete="off" required style="border-radius: 6px; width: 280px;">

                </div>
            </div>
            <div *ngIf="!isPediatric">
                <div *ngIf="isEdit " style="margin-left: 18px;margin-top: -16px;">
                    <label class="fieldsetlable">Age In years</label>

                    <input type="text" [disabled]="isEdit" class="form-control" placeholder="{{ageYears}}y "
                        (ngModelChange)="addAge()" autocomplete="off" required
                        style="border-radius: 6px; width: 280px;">

                </div>
            </div>



            <div *ngFor="let item of genderArry; let i = index" (click)="selectModalValueGender(i, 'genderArry')"
                class="searchable-component-style" style="width: 120px;margin-left: 17px;margin-top: 1px;">
                <img *ngIf="item.selected  != true" style="margin: 0px 8px 0px 0px"
                    src="assets/images/tick_mark_grey.svg" class="tick-icon" />

                <img *ngIf="item.selected" style="margin: 0px 8px 0px 0px" src="assets/images/tick_mark_color.svg"
                    class="tick-icon" />

                <div>{{item.name}}</div>
            </div>

        </div>

        <div style="display: flex; flex-direction: row; justify-content: center;    margin-left: -272px;
        margin-top: -8px; ">


        </div>

    </div>




    <div *ngIf="!isEdit" (click)="findPatientByName();" style="margin: 24px;
    width: 140px;
    align-self:center;
    cursor: pointer;
    margin-left: 45%;
    padding: 8px 24px 8px 24px;
    border-radius: 6px;
    background-color: #4687BF;
    color: #FFF;
    border-width: 0px;
    text-align: center;
    margin-top: 21px;
    font-weight: bold;">Next</div>

    <div *ngIf="isEdit" (click)="onSubmitButtonn();" style="margin: 24px;
width: 140px;
align-self:center;
cursor: pointer;
margin-left: 45%;
padding: 8px 24px 8px 24px;
border-radius: 6px;
background-color: #4687BF;
color: #FFF;
border-width: 0px;
text-align: center;
margin-top: 21px;
font-weight: bold;">Next</div>


</div>


<div *ngIf="showInfoBirth">
    <div style="display: flex;flex-direction: row;justify-content: center;">
        <h3 *ngIf="showInfoBirth" style="color: #959191;">Antenetal & Birth History</h3>
    </div>
    <br>




    <div style="display: flex; flex-direction: row;justify-content: center;">



        <div style="margin-left: 0px;margin-top: 0px;">
            <label class="fieldsetlable">Date Of Birth</label>
            <input type="text" class="form-control" [(ngModel)]="dobDate" autocomplete="off"
                style="border-radius: 6px; width: 280px;" readonly>

        </div>
        <!-- <div *ngIf="isEdit" class="selectDropDown calendarIcon calender onFocus customeInput" style="width: 280px;">
                            <label  class="fieldsetlable">Expected DOB<span class="required">*</span></label>
                  
                            <input placeholder="DD/MM/YYYY" type="text" [minDate]="dob" [ngClass]="{'error-field':error}"
                            id="edobInput" class="form-control newInputBox" [(ngModel)]="eDob" [disabled]="isEdit"
                            (ngModelChange)="d1.toggle();setExpectedBDateAndGestestionalAge()" ngbDatepicker #d1="ngbDatepicker"
                            (click)="d.toggle()" readonly
                            style="border-radius: 4px !important; color: #656565 !important; font-size: 1rem !important;">
                                <img (click)="d1.toggle()" class="calendarbg"   [hidden]="false" style="top: 30px; right: 8px; width: 25px; padding-left: 5px;margin-bottom: 5px;
                                margin-top: -51px;
                                margin-left: 249px;" src="assets/images/calendarIcon.svg" alt="" />

                 
                          </div> -->
        <div class="selectDropDown calendarIcon calender onFocus customeInput" style="width: 280px;">
            <label class="fieldsetlable">Expected DOB<span class="required">*</span></label>

            <input placeholder="DD/MM/YYYY" type="text" [minDate]="dob" [ngClass]="{'error-field':error}" id="edobInput"
                class="form-control newInputBox" [(ngModel)]="eDob" [disabled]="false"
                (ngModelChange)="d1.toggle();setExpectedBDateAndGestestionalAge()" ngbDatepicker #d1="ngbDatepicker"
                (click)="d.toggle()" readonly
                style="border-radius: 4px !important; color: #656565 !important; font-size: 1rem !important;">
            <img (click)="d1.toggle()" class="calendarbg" [hidden]="false" style="top: 30px; right: 8px; width: 25px; padding-left: 5px;margin-bottom: 5px;
                                margin-top: -51px;
                                margin-left: 249px;" src="assets/images/calendarIcon.svg" alt="" />


        </div>
        <div class="selectDropDown bloodGroup onFocus customeInput" style="width: 280px;">
            <label class="fieldsetlable">Delivery Type </label>
            <select class=" form-control newInputBox" [(ngModel)]="typeOfDelivery"
                style="border-radius: 4px !important; color: #656565 !important; font-size: 1rem !important; padding: 6px;">

                <option *ngFor="let docType of typeOfDeliveryArr; let i = index" value="{{docType}}">
                    {{docType}}
                </option>
            </select>
        </div>
        <!-- <custom-text-input [callBack]="onInputCustomComponent" class="customeInput" style="width: 280px;"
                    label="Gestational Age" placeholder="" identifier="weight" [maxLength]="15">
                  
                </custom-text-input> -->



    </div>
    <div style="display: flex;flex-direction: row;justify-content: center;margin-left: 8px;">
        <div class="form-item" style="margin-left: 10px;margin-top: 19px;">
            <input type="text" class="form-control" placeholder="{{gestationalAge.w}} W {{gestationalAge.d}} D"
                autocomplete="off" style="border-radius: 6px; width: 280px;">
            <label for="username">Gestational Age</label>
        </div>

        <div class="form-item" style="margin-left: 10px;margin-top: 19px;">

            <input type="text" [(ngModel)]="checkValue" class="form-control" autocomplete="off"
                style="border-radius: 6px; width: 280px;">
            <label for="username">High-Risk</label>
            <div class="item" style="margin-left: 226px;margin-top: -31px;">

                <div class="item-checkbox">

                    <input type="checkbox" [(ngModel)]="checkedValue" tabindex="0"
                        style="width:57px;height:24px;background-color: red;" (click)="checkBoxValueChnage($event)">
                </div>
            </div>

        </div>
        <div class="form-item" style="margin-left: 10px;margin-top: 19px;">

            <input type="text" [(ngModel)]="checkValueHeelPrickTest" class="form-control" autocomplete="off"
                style="border-radius: 6px; width: 280px;">
            <label for="username">New Born Screening</label>
            <div class="item" style="margin-left: 226px;margin-top: -31px;">

                <div class="item-checkbox">

                    <input type="checkbox" [(ngModel)]="heelPrickTest" tabindex="0"
                        style="width:57px;height:24px;background-color: red;" (click)="checkHeelPrickTest($event)">
                </div>
            </div>

        </div>

    </div>
    <div style="display: flex; justify-content: center; color: #565353;">{{uploadTestValue}}</div>

    <div *ngIf="showHighRisk" style="display: flex;justify-content: center;">
        <div class="form-item" style="margin-left: 22px;margin-top: 22px;">
            <div (click)="open(highRiskTypeModal)" class="inputfield">
                <img style="height: 30px; width: 30px;position: absolute; " src="assets/images/plus_button_new.svg" />
            </div>
            <input type="text" [(ngModel)]="highRiskType" placeholder="High-Risk category" class="form-control"
                autocomplete="off" required style="border-radius: 6px; width: 400px;">
            <label style="width: 163px;">Add high-risk category</label>
        </div>
    </div>
    <br>
    <div style="display: flex;justify-content: center;">
        <div style="color: #151414;height: 100%; font-size: 16px;width: 860px;
                            justify-content: flex-start;
                            align-items: center;
                            margin-left: 22px;
                            padding-bottom: 20px;">
            Vitals At Birth
        </div>




    </div>





    <div style="display:flex;flex-direction: row; justify-content: center; ">

        <app-custom-textinput label="Weight (kg)" [callBack]='onFormValue' field="{{selectedFormValue.birth_weight}}"
            placeholder='Enter Weight' identifier='birth_weight' [isNumeric]="true" [maxLength]="6"
            style="width: 280px; margin-left: 10px;"></app-custom-textinput>
        <app-custom-textinput label="Height (Cm)" [callBack]='onFormValue' field="{{selectedFormValue.birth_height}}"
            placeholder='Enter Height' identifier='birth_height' [isNumeric]="true" [maxLength]="5"
            style="width: 280px; margin-left: 10px;"></app-custom-textinput>
        <app-custom-textinput label="HC (Cm)" [callBack]='onFormValue' field="{{selectedFormValue.birth_hc}}"
            placeholder='Enter HC' identifier='birth_hc' [isNumeric]="true" [maxLength]="5"
            style="width: 280px; margin-left: 10px;"></app-custom-textinput>

    </div>

    <div style="display: flex;flex-direction: row;justify-content: center;">
        <app-custom-textinput label="CC (Cms)" [callBack]='onFormValue' field="{{selectedFormValue.birth_cc}}"
            placeholder='Enter CC' identifier='birth_cc' [isNumeric]="true" [maxLength]="5"
            style="width: 280px; margin-left: 10px;"></app-custom-textinput>
        <app-custom-textinput label="BP(Sys)" [callBack]='onFormValue' field="{{selectedFormValue.birth_bpsys}}"
            placeholder='BP(Sys)' identifier='birth_bpsys' [isNumeric]="true" [maxLength]="5"
            style="width: 280px; margin-left: 10px;"></app-custom-textinput>
        <app-custom-textinput label="BP(Dia)" [callBack]='onFormValue' field="{{selectedFormValue.birth_bpdia}}"
            placeholder='BP(Dia)' identifier='birth_bpdia' [isNumeric]="true" [maxLength]="5"
            style="width: 280px; margin-left: 10px;"></app-custom-textinput>

    </div>
    <div style="display: flex;flex-direction: row;justify-content: center;">

        <div style="width: 860px;display:flex;
                            justify-content: flex-start;
                            align-items: center;">
            <div class="selectDropDown bloodGroup onFocus customeInput" style="width: 280px;margin-top: -19px;">
                <label class="fieldsetlable">Blood Group </label>
                <select class=" form-control newInputBox" [(ngModel)]="blood_group"
                    style="border-radius: 4px !important; color: #656565 !important; font-size: 1rem !important; padding: 6px;">

                    <option *ngFor="let bloodGroup of  bloodGroups; let i = index" value="{{bloodGroup}}">
                        {{bloodGroup}}
                    </option>
                </select>
            </div>
            <!--<div *ngIf="!isEdit" class="selectDropDown bloodGroup onFocus customeInput" style="width: 280px;margin-top: -19px;"
             >
              <label class="fieldsetlable">Blood Group </label>
              <select class=" form-control newInputBox" [(ngModel)]="blood_group" 
                  style="border-radius: 4px !important; color: #656565 !important; font-size: 1rem !important; padding: 6px;">
                  
                  <option *ngFor="let bloodGroup of  bloodGroups; let i = index" value="{{bloodGroup}}">
                {{bloodGroup}}
                  </option>
              </select>
          </div> -->

            <!-- <div *ngIf="isEdit">
             <div class="selectDropDown calendarIcon calender onFocus"  style="width: 280px;margin-left:13px;margin-top: -1px; ">
                <label  class="fieldsetlable">Vaccination Date<span class="required">*</span></label>
                <input placeholder="{{vacDate}}" type="text" [minDate]="dob" [ngClass]="{'error-field':error}"
                id="vaccInput" class="form-control newInputBox" (ngModelChange)="d2.toggle();getAge()" ngbDatepicker
                #d2="ngbDatepicker" (click)="d2.toggle()"  [(ngModel)]="vacDate"
                [disabled]="true"
                style="border-radius: 4px !important; color: #656565 !important; font-size: 1rem !important;">
                <img (click)="d2.toggle()" class="calendarbg"   [hidden]="true" style="top: 30px; right: 8px; width: 25px; padding-left: 5px;margin-bottom: 5px;
                                        margin-top: -51px;
                                        margin-left: 249px;" src="assets/images/calendarIcon.svg" alt="" />
      
                
              </div>
            </div> -->
            <div>
                <div class="selectDropDown calendarIcon calender onFocus"
                    style="width: 280px;margin-left:13px;margin-top: 0px; ">
                    <label class="fieldsetlable">Vaccination Date<span class="required">*</span></label>
                    <input placeholder="{{vacDate}}" type="text" [minDate]="dob" [ngClass]="{'error-field':error}"
                        id="vaccInput" class="form-control newInputBox" (ngModelChange)="d2.toggle();getAge()"
                        ngbDatepicker #d2="ngbDatepicker" (click)="d2.toggle()" [(ngModel)]="vacDate" [disabled]="false"
                        style="border-radius: 4px !important; color: #656565 !important; font-size: 1rem !important;">
                    <img (click)="d2.toggle()" class="calendarbg" [hidden]="false" style="top: 30px; right: 8px; width: 25px; padding-left: 5px;margin-bottom: 5px;
                                           margin-top: -51px;
                                           margin-left: 249px;" src="assets/images/calendarIcon.svg" alt="" />


                </div>
            </div>
        </div>
    </div>



    <div (click)="addAntenetalBirth()" style="margin: 24px;
                        width: 140px;
                        align-self:center;
                        cursor: pointer;
                        margin-left: 45%;
                        padding: 8px 24px 8px 24px;
                        border-radius: 6px;
                        background-color: #4687BF;
                        color: #FFF;
                        border-width: 0px;
                        text-align: center;
                        margin-top: 21px;
                        font-weight: bold;">Next</div>












    <div style="padding-right: 30px;padding-left: 30px;display: flex;flex-direction: row;height: 110px;">
        <div class="profileSection" style="width: 10%;">

        </div>

    </div>


</div>



<br>
<br>
<div *ngIf="showHealthProfile">

    <div style="display: flex;flex-direction: row;justify-content: center;">
        <h3 style="color: #959191;">Health Profile</h3>

    </div>




    <div style="display:flex;flex-direction: row;justify-content: center; ">

        <div class="form-item" style="margin-left: 22px;margin-top: 22px;">
            <div (click)="open(medicalConditionsModal)" class="inputfield">
                <img style="height: 30px; width: 30px;position: absolute; " src="assets/images/plus_button_new.svg" />
            </div>
            <input type="text" [(ngModel)]="medicalConditions" placeholder="Medical Condtion" class="form-control"
                autocomplete="off" required style="border-radius: 6px; width: 400px;">
            <label style="width: 160px;">Medical Conditions</label>
        </div>

        <!-- <div style="margin-left: 7px;width: 400px; ">
                    <label class="fieldsetlable"><span class="required"></span></label>
                    <div (click)="open(familyMedicalConditionsModal)" class="inputfield">
                <img style="height: 30px; width: 30px; margin-right: 1445px; " src="assets/images/plus_button_new.svg" />
              </div>
              <input [(ngModel)]="familyMedicalConditions" id="searchbox"
              (input)="onSearchChange($event.target.value, 'drugTypes')" style="border-radius:6px; padding: 0px 8px 0px 8px; height: 40px; margin-top:0px;
            font-size: 16px; z-index: 0; margin-left: 10px; color: #999;" class="form-control" placeholder="Medical History" name="drugType">
                  </div> -->
        <app-custom-textinput label="Allergies" [callBack]='onFormValue' field="{{selectedFormValue.allergies}}"
            placeholder="Allergies" identifier='allergies' style="width: 400px; margin-top: 21px; margin-left: 19px;">
        </app-custom-textinput>




    </div>
    <div style="display:flex;flex-direction: row; justify-content: center;">
        <app-custom-textinput label="Reactions to Drug/Medicines" [callBack]='onFormValue'
            field="{{selectedFormValue.reactions}}" placeholder="Reactions" identifier='reactions'
            style="width: 400px;margin-top: 21px; margin-left: 19px;"></app-custom-textinput>
        <app-custom-textinput label="Recurring Complaints" [callBack]='onFormValue'
            field="{{selectedFormValue.recurring}}" placeholder="Recurring Complaints " identifier='recurring'
            style="width: 400px;margin-top: 21px; margin-left: 19px;"></app-custom-textinput>
    </div>

    <div style="display:flex;flex-direction: row; justify-content: center;">


        <app-custom-textinput label="Hospitalisation" [callBack]='onFormValue'
            field="{{selectedFormValue.hospitalisation}}" placeholder="Hospitalisation" identifier='hospitalisation'
            style="width: 400px;margin-top: 21px; margin-left: 19px;"></app-custom-textinput>
        <app-custom-textinput label="Health History" [callBack]='onFormValue'
            field="{{selectedFormValue.healthHistory}}" placeholder="Health History" identifier='healthHistory'
            style="width: 400px;margin-top: 21px; margin-left: 19px;"></app-custom-textinput>




    </div>

    <div style="display: flex;flex-direction: row; justify-content: center;">
        <app-custom-textinput label="Email Id" [callBack]='onFormValue' field="{{selectedFormValue.emailId}}"
            placeholder="Email Id" identifier='emailId' style="width: 400px;margin-top: 21px; margin-left: 19px;">
        </app-custom-textinput>
        <app-custom-textinput label="Other Id" [callBack]='onFormValue' field="{{selectedFormValue.otherId}}"
            placeholder="Other Id" identifier='otherId' style="width: 400px;margin-top: 21px; margin-left: 19px;">
        </app-custom-textinput>


    </div>
    <div style="display: flex;flex-direction: row;justify-content: center; margin-left: -412px;">
        <div class="form-item" style="margin-left: 22px;margin-top: 22px;">
            <div (click)="open(geneticDisordersModal)" class="inputfield">
                <img style="height: 30px; width: 30px;position: absolute; " src="assets/images/plus_button_new.svg" />
            </div>
            <input type="text" [(ngModel)]="geneticDisorders" placeholder="Genetic Disorders" class="form-control"
                autocomplete="off" required style="border-radius: 6px; width: 400px;">
            <label style="width: 209px;">Any Known Genetic Disorder</label>
        </div>

    </div>
    <div (click)="addHealthProfile()" style="margin: 24px;
width: 140px;
align-self:center;
cursor: pointer;
margin-left: 45%;
padding: 8px 24px 8px 24px;
border-radius: 6px;
background-color: #4687BF;
color: #FFF;


border-width: 0px;
text-align: center;
margin-top: 21px;
font-weight: bold;">Next</div>


</div>


<div *ngIf="showFamilyProfilePed">



    <h3 style="display: flex;flex-direction: row;justify-content: center;color: #94999e;">Family Profile</h3>


    <div style="display: flex;flex-direction: row;justify-content: center;">
        <div class="form-item" style="margin-left: 22px;margin-top: 22px;">
            <div (click)="open(familyMedicalConditionsModal)" class="inputfield">
                <img style="height: 30px; width: 30px;position: absolute; " src="assets/images/plus_button_new.svg" />
            </div>
            <input type="text" [(ngModel)]="familyMedicalConditions" placeholder="Family Medical History"
                class="form-control" autocomplete="off" required style="border-radius: 6px; width: 400px;">
            <label style="width: 209px;">Family Medical History</label>
        </div>

    </div>


    <div style="display: flex;flex-direction: row;justify-content: center;">
        <div class="form-item" style="margin-left: 22px;margin-top: 22px;">
            <div (click)="open(familyGeneticDisordersModal)" class="inputfield">
                <img style="height: 30px; width: 30px;position: absolute; " src="assets/images/plus_button_new.svg" />
            </div>
            <input type="text" [(ngModel)]="familyGeneticDisorders" placeholder="Enter Disorders" class="form-control"
                autocomplete="off" required style="border-radius: 6px; width: 400px;">
            <label style="width: 283px;">Any Known Family Genetic Disorders</label>
        </div>

    </div>



    <div (click)="addFamilyProfilePed()" style="margin: 24px;
                        width: 140px;
                        align-self:center;
                        cursor: pointer;
                        margin-left: 45%;
                        padding: 8px 24px 8px 24px;
                        border-radius: 6px;
                        background-color: #4687BF;
                        color: #FFF;
                        
                        
                        border-width: 0px;
                        text-align: center;
                        margin-top: 21px;
                        font-weight: bold;">Next</div>




</div>
<br>
<div *ngIf="showFamilyProfile">

    <div style="display: flex;flex-direction: row;justify-content: center;">
        <h3 style="color: #959191;">Family Profile</h3>
    </div>



    <div style="display:flex;flex-direction: row;justify-content: center;">
        <!-- <app-custom-textinput label="Father's Name" [callBack]="onFormValue" field="{{selectedFormValue. fathersName}}" placeholder="Father Name" identifier=" fathersName" style="width: 300px; margin-top: 21px; margin-left: 19px;" ></app-custom-textinput>
        
                            <app-custom-textinput label="Mother's Name" [callBack]="onFormValue" field="{{selectedFormValue.mothersName}}" placeholder="Mother Name" identifier="mothersName" style="width: 300px; margin-top: 21px; margin-left: 19px;" ></app-custom-textinput>
                         
                          
                           -->

    </div>


    <div class="onFocus">
        <label class="ifnoLabel newInputStyleLabel"
            style="display: flex;flex-direction: row;justify-content: center; margin-left: -462px;">Father's
            Height</label>
    </div>

    <div class="age" style="display: flex;flex-direction: row;justify-content: center;">
        <div class="row" style="display: flex;flex-direction: row;justify-content: center;">

            <div *ngIf="fathersHeightInFt === 'ft'">
                <div class="form-group onFocus" style="margin-left: -10px;">
                    <label class="ifnoLabel newInputStyleLabel"></label>

                    <input class="form-control newInputBox" placeholder="00" maxlength="2"
                        [(ngModel)]="fathersHeightFt.ft" (keypress)="validateNumber($event)"
                        style="width: 18%;display: inline-block; border-radius: 0px !important; color: #656565 !important; font-size: 1rem !important; border-top: 0px !important; border-left:  0px !important; border-right: 0px !important;"
                        type="text">
                    <label class="pt-1" style="font-size: 14px;display: inline-block;margin-right: 10px;">Ft</label>
                    <input class="form-control newInputBox" placeholder="00" maxlength="5"
                        [(ngModel)]="fathersHeightFt.in" (keypress)="validateNumber($event)"
                        style="margin-left: 2px;width: 18%;display: inline-block; border-radius: 0px !important; color: #656565 !important; font-size: 1rem !important; border-top: 0px !important; border-left:  0px !important; border-right: 0px !important;"
                        type="text">
                    <label class="pt-1" style="font-size: 14px;display: inline-block;margin-right: 10px;">Inches</label>

                </div>
            </div>
            <div *ngIf="fathersHeightInFt === 'cm'">
                <div class="form-group onFocus">
                    <label class="ifnoLabel newInputStyleLabel"></label>

                    <input class="form-control newInputBox" placeholder="00" maxlength="6" [(ngModel)]="fathersHeightCm"
                        (keypress)="validateNumber($event)"
                        style="width: 35%;display: inline-block; border-radius: 0px !important; color: #656565 !important; font-size: 1rem !important; border-top: 0px !important; border-left:  0px !important; border-right: 0px !important;"
                        type="text">
                    <label class="pt-1" style="font-size: 14px;display: inline-block;margin-right: 10px;">Cm</label>


                </div>
            </div>

        </div>
        <div style="width: 5%">

            <div class="form-group selectDropDown bloodGroup onFocus" style="width: 100%;margin-left: -105px;">
                <select class="newInputBox" (change)="onChange($event.target.value,'father')"
                    style="border-radius: 4px !important; color: #656565 !important; font-size: 1rem !important; padding: 6px;">
                    <option *ngFor="let fathersHeightInFt of heightArr; let i = index" value="{{fathersHeightInFt}}">
                        {{fathersHeightInFt}}
                    </option>
                </select>
                <p class="errors"></p>
            </div>

        </div>
    </div>
    <label class="ifnoLabel newInputStyleLabel"
        style="display: flex;flex-direction: row;justify-content: center;margin-left: -462px;">Mother's Height</label>
    <div style="padding-left: 43px;"></div>
    <div class="age" style="display: flex;flex-direction: row;justify-content: center;">
        <div class="row">
            <div class="onFocus">

            </div>
            <div *ngIf="mothersHeightInFt === 'ft'">
                <div class="form-group onFocus" style="margin-left: -20px;">
                    <label class="ifnoLabel newInputStyleLabel"></label>

                    <input class="form-control newInputBox" placeholder="00" maxlength="2"
                        [(ngModel)]="mothersHeightFt.ft" (keypress)="validateNumber($event)"
                        style="width: 18%;display: inline-block; border-radius: 0px !important; color: #656565 !important; font-size: 1rem !important; border-top: 0px !important; border-left:  0px !important; border-right: 0px !important;"
                        type="text">
                    <label class="pt-1" style="font-size: 14px;display: inline-block;margin-right: 10px;">Ft</label>
                    <input class="form-control newInputBox" placeholder="00" maxlength="4"
                        [(ngModel)]="mothersHeightFt.in" (keypress)="validateNumber($event)"
                        style="margin-left: 2px;width: 18%;display: inline-block; border-radius: 0px !important; color: #656565 !important; font-size: 1rem !important; border-top: 0px !important; border-left:  0px !important; border-right: 0px !important;"
                        type="text">
                    <label class="pt-1" style="font-size: 14px;display: inline-block;margin-right: 10px;">Inches</label>

                </div>
            </div>
            <div *ngIf="mothersHeightInFt === 'cm' && mothersHeightInFt !== 'ft'">
                <div class="form-group onFocus">
                    <label class="ifnoLabel newInputStyleLabel"></label>

                    <input class="form-control newInputBox" placeholder="00" maxlength="6" [(ngModel)]="mothersHeightCm"
                        (keypress)="validateNumber($event)"
                        style="width: 35%;display: inline-block; border-radius: 0px !important; color: #656565 !important; font-size: 1rem !important; border-top: 0px !important; border-left:  0px !important; border-right: 0px !important;"
                        type="text">
                    <label class="pt-1" style="font-size: 14px;display: inline-block;margin-right: 10px;">Cm</label>


                </div>
            </div>
        </div>

        <div style="width: 4%;">
            <div class="row">
                <div class="form-group selectDropDown bloodGroup onFocus" style="width: 100%;margin-left: -105px;">
                    <select class="newInputBox" (change)="onChange($event.target.value,'mother')"
                        style="border-radius: 4px !important; color: #656565 !important; font-size: 1rem !important; padding: 6px;">
                        <option *ngFor="let mothersHeightInFt of heightArr; let i = index"
                            value="{{mothersHeightInFt}}">
                            {{mothersHeightInFt}}
                        </option>
                    </select>
                    <p class="errors"></p>
                </div>
            </div>
        </div>
    </div>

    <div style="display: flex;flex-direction: row;justify-content: center;">
        <div class="form-item" style="margin-left: 22px;margin-top: 22px;">
            <div (click)="open(familyMedicalConditionsModal)" class="inputfield">
                <img style="height: 30px; width: 30px;position: absolute; " src="assets/images/plus_button_new.svg" />
            </div>
            <input type="text" [(ngModel)]="familyMedicalConditions" placeholder="Family Medical History"
                class="form-control" autocomplete="off" required style="border-radius: 6px; width: 618px;">
            <label style="width: 209px;">Family Medical History</label>
        </div>

    </div>
    <div style="display: flex;flex-direction: row;justify-content: center;">
        <div class="form-item" style="margin-left: 22px;margin-top: 22px;">
            <div (click)="open(familyGeneticDisordersModal)" class="inputfield">
                <img style="height: 30px; width: 30px;position: absolute; " src="assets/images/plus_button_new.svg" />
            </div>
            <input type="text" [(ngModel)]="familyGeneticDisorders" placeholder="Enter Disorders" class="form-control"
                autocomplete="off" required style="border-radius: 6px; width: 618px;">
            <label style="width: 283px;">Any Known Family Genetic Disorders</label>
        </div>

    </div>

    <div (click)="addFamilyPrfofileisPediatric()" style="margin: 24px;
width: 140px;
align-self:center;
cursor: pointer;
margin-left: 45%;
padding: 8px 24px 8px 24px;
border-radius: 6px;
background-color: #4687BF;
color: #FFF;
border-width: 0px;
text-align: center;
margin-top: 21px;
font-weight: bold;">Next</div>

</div>




<div *ngIf="showHealthHistory">






    <div style="display:flex;flex-direction: row; justify-content: center; ">

        <app-custom-textinput label="Health History Highlights" [callBack]='onFormValue'
            field="{{selectedFormValue.healthHistoryHighlights}}" placeholder="Enter Health History"
            identifier='healthHistoryHighlights' style="width: 400px; margin-top: 21px; margin-left: 19px;">
        </app-custom-textinput>









        <div class="selectDropDown bloodGroup onFocus customeInput" style="width: 400px;">
            <label class="fieldsetlable">Blood Group </label>
            <select class=" form-control newInputBox" [(ngModel)]="blood_group"
                (change)="onChangeDoc($event.target.value)"
                style="border-radius: 4px !important; color: #656565 !important; font-size: 1rem !important; padding: 6px;">

                <option *ngFor="let bloodGroup of  bloodGroups; let i = index" value="{{bloodGroup}}">
                    {{bloodGroup}}
                </option>
            </select>
        </div>






    </div>







    <br>
    <div style="display: flex;justify-content: center;">
        <div style="justify-content: flex-start;align-items: center;width: 800px;height: 100%;margin-left: 30px;">
            <div style="border: solid 1px #CECECE; border-radius: 8px;width: 100%; ">
                <div
                    style="padding-bottom: 20px;width: 800px; height: 100%; box-sizing: border-box;  color:  #656565;  border-radius: 8px; margin-left: 5px;">
                    <div style="margin-left: 14px;">
                        <label class="section-label" style="color: #959191;">Habits</label>
                    </div>
                    <div>
                        <p style="display: flex;flex-direction: row;flex: 1;margin-left: 156px;">Past</p>
                        <p style="    display: flex;
                                    flex-direction: row;
                                    flex: 1;
                                    justify-content: flex-end;
                                  
                                    margin-right: 288px;
                                    margin-top: -34px;">Present</p>
                    </div>
                    <div style="display: flex;flex-direction: row; margin-left: -63px; margin-top: 20px;">
                        <div style="display: flex;flex-direction: column;margin-left: 91px;">
                            <div>1.Chewing</div>
                            <div style="margin-top: 15px;">2.Smoking</div>
                            <div style="margin-top: 15px;">3.Drinking</div>
                            <div style="margin-top: 15px;">4.Drugs</div>
                            <div style="margin-top: 15px;">5.Other</div>
                        </div>
                        <div class="item" style="display:flex;flex-direction: row;width: 100%;">

                            <div style="display: flex; width: 100%;">

                                <div class="item-checkbox"
                                    style="display: flex;flex-direction: column; margin-left: 44px;">

                                    <input [checked]="habbitsArray['Chewing']['past']==1" type="checkbox"
                                        style="width:57px;height:24px;margin-top: 1px;"
                                        (change)="pastHabbitsAdd($event,'Chewing','past')">

                                    <input [checked]="habbitsArray['Smoking']['past']==1" type="checkbox"
                                        style="width:57px;height:24px;margin-top: 10px;"
                                        (change)="pastHabbitsAdd($event,'Smoking','past')">
                                    <input [checked]="habbitsArray['Drinking']['past']==1" type="checkbox"
                                        style="width:57px;height:24px;margin-top: 10px;"
                                        (change)="pastHabbitsAdd($event,'Drinking','past')">
                                    <input [checked]="habbitsArray['Drugs']['past']==1" type="checkbox"
                                        style="width:57px;height:24px;margin-top: 10px;"
                                        (change)="pastHabbitsAdd($event,'Drugs','past')">
                                    <input [checked]="habbitsArray['Other']['past']==1" type="checkbox"
                                        style="width:57px;height:24px;margin-top: 10px;"
                                        (change)="pastHabbitsAdd($event,'Other','past')">

                                </div>
                                <div class="item-checkbox"
                                    style="display: flex;flex-direction: column; justify-content: flex-end; width: 100%;">

                                    <input [checked]="habbitsArray['Chewing']['present']==1" type="checkbox"
                                        style="width:57px;height:24px;margin-top: 1px;"
                                        (change)="pastHabbitsAdd($event,'Chewing','present')">
                                    <input [checked]="habbitsArray['Smoking']['present']==1" type="checkbox"
                                        style="width:57px;height:24px;margin-top: 10px;"
                                        (change)="pastHabbitsAdd($event,'Smoking','present')">
                                    <input [checked]="habbitsArray['Drinking']['present']==1" type="checkbox"
                                        style="width:57px;height:24px;margin-top: 10px;"
                                        (change)="pastHabbitsAdd($event,'Drinking','present')">
                                    <input [checked]="habbitsArray['Drugs']['present']==1" type="checkbox"
                                        style="width:57px;height:24px;margin-top: 10px;"
                                        (change)="pastHabbitsAdd($event,'Drugs','present')">
                                    <input [checked]="habbitsArray['Other']['present']==1" type="checkbox"
                                        style="width:57px;height:24px;margin-top: 10px;"
                                        (change)="pastHabbitsAdd($event,'Other','present')">

                                </div>

                            </div>
                        </div>

                    </div>


                </div>
            </div>

        </div>


    </div>
    <div (click)="addHealthHistory()" style="margin: 24px;
                        width: 140px;
                        align-self:center;
                        cursor: pointer;
                        margin-left: 45%;
                        padding: 8px 24px 8px 24px;
                        border-radius: 6px;
                        background-color: #4687BF;
                        color: #FFF;
                        border-width: 0px;
                        text-align: center;
                        margin-top: 21px;
                        font-weight: bold;">Next</div>


</div>
<div class="row d-flex justify-content-center" style="flex-direction: column;">



    <!-- <div style="border-bottom:1px solid #999;"></div> -->
    <br>
    <div *ngIf="showOtherInformation && isPediatric">




        <br>



        <div style="padding-right: 30px;padding-left: 30px;display: flex;flex-direction: row;height: 110px;">
            <div class="profileSection" style="width: 10%;">

            </div>

        </div>

    </div>
    <div *ngIf="showOtherInformation && !isPediatric" style="padding-top: 20px;">

    </div>

    <ng-template #profilePicModal let-modal>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Select Image</h4>
            <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
                (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div>
                <div class="row upload_buttons"><button type="button" class="newBtnStyle"
                        (click)="modal.dismiss('Cross click');selectFileForPatient()">Choose
                        Image</button>
                </div>
                <div class="row upload_buttons"><button type="button" class="btn-primary opd-secondary-btn btn-md"
                        style="text-transform: none; font-size: 14px;"
                        (click)="modal.dismiss('Cross click');hideDialogForPatient(); removePhoto();"
                        [disabled]="">Remove</button>
                </div>

            </div>
        </div>


    </ng-template>

    <p-dialog [modal]="true" [draggable]="false" [blockScroll]="true" [closable]="true"
        (onHide)="hideDialogForPatient()" [(visible)]="displayForProfile" styleClass="changePropic"
        [style]="{width: '20vw'}">
        <p-header style="font-size: 18px;">
            Select
        </p-header>
        <div class="row upload_buttons"><button type="button" class="newBtnStyle"
                (click)="selectFileForPatient()">Choose
                Image</button>
        </div>
        <div class="row upload_buttons"><button type="button" class="btn-primary opd-secondary-btn btn-md"
                style="text-transform: none; font-size: 14px;" (click)="hideDialogForPatient(); removePhoto();"
                [disabled]="">Remove</button></div>

    </p-dialog>
    <p-dialog [modal]="true" [draggable]="false" [blockScroll]="true" [closable]="true"
        (onHide)="hideDialogForPatient()" [(visible)]="cropperDisplayforProfile" styleClass="changePropic">
        <p-header>
            Upload patient's profile picture
        </p-header>
        <img-cropper #cropper [image]="dataPic" [settings]="cropperSettingsForProfile"></img-cropper>
        <p class="errors" *ngIf="imageError">{{imageError}}</p>
        <div class="col-lg-12 cropper_buttons pl-0" style="display: flex; justify-content: flex-end; padding-top: 8px;">
            <button type="button" class="btn-primary opd-primary-btn btn-md mr-2 newBtnStyle"
                style="text-transform: none;" (click)="hideCropperForPatient();uploadProfileImageFile();">Save</button>
            <button type="button" class="btn-primary opd-secondary-btn btn-md"
                style="text-transform: none; padding: 6px 10px;" (click)="hideCropperForPatient();">Cancel</button>
        </div>
    </p-dialog>
    <p-confirmDialog class="pConfirmZindex"></p-confirmDialog>
</div>



<ng-template #patientListModal let-modal>
    <div class="modal-header" style="border-bottom: 0px;">
        <h5 class="modal-title" id="modal-basic-title" style="color: #94999e;">Already onboarded patient with same
            mobile number</h5>
        <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div style="height: 60vh; overflow: scroll;">

            <ul *ngFor="let patient of patientList">
                <li class="test rounded-circle clearfix">
                    <p style=" margin-left: 1px; text-align: center; margin-top: 8px; color: #94999e;font-size: 19px;">
                        {{patient.firstName.toUpperCase().charAt(0)}} {{patient.lastName.toUpperCase().charAt(0)}}</p>
                </li>
                <li class="clearfix" style="font-size: 17px; color: #333; font-family: 'Mukta', sans-serif; margin-left: 88px;
        margin-top: -43px;">

                    {{patient.firstName}} {{patient.lastName}} </li>

                <li class="clearfix" style="font-size: 15px; color: grey; margin-top: -4px; margin-left: 88px;">
                    {{patient.contactNo}} /{{patient.gender.toUpperCase().charAt(0)}}</li>
            </ul>
        </div>
    </div>

    <div class="modal-footer">
        <div style="display:flex; flex-direction: row; justify-content: space-between; flex: 1; margin-right: 30px;">
            <div (click)="patientFormOpen();modal.dismiss();" class="quickrx" style="margin-left: -1px;"> Modify RMN
            </div>

            <div (click)="onSubmitButtonn();open(continueProfileModal);modal.dismiss();" class="quickrx"
                style="margin-right:-29px;height: 49px;">Proceed With same RMN</div>
        </div>
        <!-- <div (click)="onSubmitButtonn();modal.dismiss();" class="btn-type-filled">Proceed With same RMN
            </div> -->
    </div>
</ng-template>

<ng-template #continueProfileModal let-modal>
    <div class="modal-header" style="border-bottom: 0px; display: flex;flex-direction: column;">
        <div style="display: flex;flex-direction: row; justify-content: space-between; flex: 1;">
            <h4 class="modal-title" id="modal-basic-title">Choose Action</h4>

            <button type="button" class="close" style="outline: none; border-width: 0px; margin-left: 58px;"
                aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true" style="margin-left: 217px;">&times;</span>
            </button>


        </div>

        <br>
        <h6 class="modal-title" id="modal-basic-title" style="color: #94999e;">Do you want to start treating this
            patient?</h6>

    </div>
    <div class="modal-body">
        <div class="row"
            style="background-color: #fff; padding: 16px; box-shadow: 0 0 2px #ccc; margin-left: -14px; border-radius: 6px;">
            <div style="display: flex;flex-direction: column;">
                <div *ngIf="isPediatric" style="display: flex;flex-direction: row;" class="hovereffect"
                    (click)="getvaccDate();open(checkDateModal);" style="cursor: pointer;">
                    <i class="fa fa-pencil-square-o iconsAdd clearfix"></i>
                    <span class="iconsText">Add Vitals</span>
                </div>
                <div *ngIf="!isPediatric" style="display: flex;flex-direction: row;" class="hovereffect"
                    (click)="goToAddVitals();modal.dismiss();" style="cursor: pointer;">
                    <i class="fa fa-pencil-square-o iconsAdd clearfix"></i>
                    <span class="iconsText">Add Vitals</span>
                </div>
                <hr style="width: 281%;">
                <div *ngIf="isAppointmentEnabled == '1'" style="display: flex;flex-direction: row;"
                    (click)="createAppointment();modal.dismiss();" style="cursor: pointer;">
                    <i class="fa fa-calendar iconsAdd clearfix"></i>
                    <span class="iconsText">Create Appointment </span>
                </div>
                <div *ngIf="isAppointmentEnabled == '2'" style="display: flex;flex-direction: row;"
                    (click)="createAppointment();modal.dismiss();" style="cursor: pointer;">
                    <i class="fa fa-calendar iconsAdd clearfix"></i>
                    <span class="iconsText">Add to Queue </span>
                </div>

                <hr style="width: 281%;">
                <div style="display: flex;flex-direction: row;" (click)="uploadDocument();modal.dismiss();"
                    style="cursor: pointer;">
                    <i class="fa fa-upload iconsAdd clearfix"></i>
                    <span class="iconsText">Upload Documents</span>
                </div>
                <hr style="width: 281%;">
                <div style="display: flex;flex-direction: row;" (click)="goToRx();modal.dismiss();">
                    <div class="iconsAdd clearfix" style="cursor: pointer;">
                        <img src="assets/images/icons8-prescription-32.png" alt="" />
                    </div>

                    <span class="iconsText" style="cursor: pointer;">Quick Rx</span>
                </div>
                <hr style="width: 281%;">
                <div style="display: flex;flex-direction: row;" (click)="gotNextTab(tabArray);modal.dismiss();">
                    <div class="iconsAdd clearfix" style="cursor: pointer;">
                        <img src="assets/images/edit-user-32.png" alt="" />
                    </div>

                    <span class="iconsText" style="cursor: pointer;">Continue to complete detail profile</span>
                </div>


            </div>
        </div>
    </div>

    
    <div class="modal-footer">
        <div (click)="nextTabHomePage(); modal.dismiss()" class="btn-type-filled">Done</div>
    </div>
</ng-template>


<ng-template #doneProfileModal let-modal>
    <div class="modal-header" style="border-bottom: 0px; display: flex;flex-direction: column;">
        <div style="display: flex;flex-direction: row; justify-content: space-between; flex: 1;">
            <h4 class="modal-title" id="modal-basic-title">Success</h4>

            <button type="button" class="close" style="outline: none; border-width: 0px; margin-left: 273px;"
                aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true" style="margin-left: 68px;">&times;</span>
            </button>


        </div>

        <br>
        <h6 class="modal-title" id="modal-basic-title" style="color: #94999e;">Patient detail profile with family
            history is completed</h6>

    </div>
    <div class="modal-body">
        <div class="row"
            style="background-color: #fff; width: 106%; padding: 16px; box-shadow: 0 0 2px #ccc; margin-left: -14px; border-radius: 6px;">
            <div style="display: flex;flex-direction: column;">
                <div style="display: flex;flex-direction: row;" class="hovereffect"
                    (click)="goToAddVitals();modal.dismiss();" style="cursor: pointer;">
                    <i class="fa fa-pencil-square-o iconsAdd clearfix"></i>
                    <span class="iconsText">Add Vitals</span>
                </div>
                <hr style="width: 281%;">
                <div *ngIf="isAppointmentEnabled == '1'" style="display: flex;flex-direction: row;"
                    (click)="createAppointment();modal.dismiss();" style="cursor: pointer;">
                    <i class="fa fa-calendar iconsAdd clearfix"></i>
                    <span class="iconsText">Create Appointment </span>
                </div>
                <div *ngIf="isAppointmentEnabled == '2'" style="display: flex;flex-direction: row;"
                    (click)="createAppointment();modal.dismiss();" style="cursor: pointer;">
                    <i class="fa fa-calendar iconsAdd clearfix"></i>
                    <span class="iconsText">Add to Queue </span>
                </div>

                <hr style="width: 281%;">
                <div style="display: flex;flex-direction: row;" (click)="uploadDocument();modal.dismiss();"
                    style="cursor: pointer;">
                    <i class="fa fa-upload iconsAdd clearfix"></i>
                    <span class="iconsText">Upload Documents</span>
                </div>
                <hr style="width: 281%;">
                <div style="display: flex;flex-direction: row;" (click)="goToRx();modal.dismiss();">
                    <div class="iconsAdd clearfix" style="cursor: pointer;">
                        <img src="assets/images/icons8-prescription-32.png" alt="" />
                    </div>

                    <span class="iconsText" style="cursor: pointer;">Quick Rx</span>
                </div>
            </div>
        </div>
    </div>


    
    <div class="modal-footer">
        <div (click)="nextTabHomePage(); modal.dismiss()" class="btn-type-filled">Done</div>
    </div>
</ng-template>

<ng-template #medicalConditionsModal let-modal>
    <div class="modal-header" style="border-bottom: 0px;">
        <h4 class="modal-title" id="modal-basic-title">Medical Conditions</h4>
        <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form>

            <div class="form-group">
                <div class="input-group">
                    <input id="searchbox" (input)="onSearchChange($event.target.value, 'medicalConditions')" style="border-radius:6px; padding: 0px 8px 0px 8px; height: 40px; margin-top: -20px;
            font-size: 16px;" class="form-control" placeholder="Start typing to search" name="dp">
                </div>
                <div style="height: 50vh; overflow: scroll;">
                    <div
                        style="display: flex; flex-direction: row; flex-wrap: wrap; margin-top: 16px; margin-bottom: 16px; width: 45vw; ">

                        <div *ngFor="let item of medicalConditionArray; let i = index"
                            (click)="selectModalValue(i, 'medicalConditions')" class="searchable-component-style">
                            <img *ngIf="item.selected  != true" style="margin: 0px 8px 0px 0px"
                                src="assets/images/tick_mark_grey.svg" class="tick-icon" />

                            <img *ngIf="item.selected" style="margin: 0px 8px 0px 0px"
                                src="assets/images/tick_mark_color.svg" class="tick-icon" />
                            <div>{{item.name}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <div (click)="saveModalValues('medicalConditions'); modal.dismiss()" class="btn-type-filled">Add Medical
            Conditions</div>
    </div>
</ng-template>


<ng-template #highRiskTypeModal let-modal>
    <div class="modal-header" style="border-bottom: 0px;">
        <h4 class="modal-title" id="modal-basic-title">High-Risk Category</h4>
        <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form>

            <div class="form-group">

                <div class="input-group">
                    <input id="searchbox" (input)="onSearchChange($event.target.value, 'highRiskType')" style="border-radius:6px; padding: 0px 8px 0px 8px; height: 40px; margin-top: px;
                        font-size: 16px;" class="form-control" placeholder="Start typing to search" name="sb">
                </div>
                <div style="height: 60vh; overflow: scroll;">
                    <div
                        style="display: flex; flex-direction: column; flex-wrap: wrap; margin-top: 16px; margin-bottom: 16px; width: 48vw;   ">

                        <div *ngFor="let item of highRiskTypeArray; let i = index" class="searchable-component-style"
                            (click)="selectModalValue(i, 'highRiskType')" style="height: 55px;">
                            <img *ngIf="item.selected  != true" style="margin: 0px 8px 0px 0px;height:16px;width:16px;"
                                src="assets/images/tick_mark_grey.svg" class="tick-icons" />

                            <img *ngIf="item.selected" style="margin: 0px 8px 0px 0px;height:16px;width:16px;"
                                src="assets/images/tick_mark_color.svg" class="tick-icons" />
                            <div>{{item.name}}</div>
                        </div>
                    </div>
                </div>


            </div>
        </form>
    </div>
    <div class="modal-footer">
        <div (click)="saveModalValues('highRiskType'); modal.dismiss()" class="btn-type-filled">Add High-Risk
            Category</div>
    </div>
</ng-template>


<!-- Investigations Modal -->
<ng-template #familyMedicalConditionsModal let-modal>
    <div class="modal-header" style="border-bottom: 0px;">
        <h4 class="modal-title" id="modal-basic-title">Family Medical Conditions</h4>
        <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form>

            <div class="form-group">
                <div class="input-group">
                    <input id="searchbox" (input)="onSearchChange($event.target.value, 'familyMedicalConditions')"
                        style="border-radius:6px; padding: 0px 8px 0px 8px; height: 40px; margin-top: -20px;
            font-size: 16px;" class="form-control" placeholder="Start typing to search" name="dp">
                </div>
                <div style="height: 60vh; overflow: scroll;">
                    <div
                        style="display: flex; flex-direction: row; flex-wrap: wrap; margin-top: 16px; margin-bottom: 16px; width: 45vw; ">

                        <div *ngFor="let item of familyMedicalArray; let i = index"
                            (click)="selectModalValue(i, 'familyMedicalConditions')" class="searchable-component-style">
                            <img *ngIf="item.selected  != true" style="margin: 0px 8px 0px 0px"
                                src="assets/images/tick_mark_grey.svg" class="tick-icon" />

                            <img *ngIf="item.selected" style="margin: 0px 8px 0px 0px"
                                src="assets/images/tick_mark_color.svg" class="tick-icon" />
                            <div>{{item.name}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <div (click)="saveModalValues('familyMedicalConditions'); modal.dismiss()" class="btn-type-filled">Add Family
            Medical Conditions </div>
    </div>
</ng-template>


<ng-template #geneticDisordersModal let-modal>
    <div class="modal-header" style="border-bottom: 0px;">
        <h4 class="modal-title" id="modal-basic-title">Genetic Disorders</h4>
        <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form>

            <div class="form-group">
                <div class="input-group">
                    <input id="searchbox" (input)="onSearchChange($event.target.value, 'geneticDisorders')" style="border-radius:6px; padding: 0px 8px 0px 8px; height: 40px; margin-top: -20px;
            font-size: 16px;" class="form-control" placeholder="Start typing to search" name="dp">
                </div>
                <div style="height: 60vh; overflow: scroll;">
                    <div
                        style="display: flex; flex-direction: row; flex-wrap: wrap; margin-top: 16px; margin-bottom: 16px; width: 45vw; ">

                        <div *ngFor="let item of geneticDisordersArray; let i = index"
                            (click)="selectModalValue(i, 'geneticDisorders')" class="searchable-component-style">
                            <img *ngIf="item.selected  != true" style="margin: 0px 8px 0px 0px"
                                src="assets/images/tick_mark_grey.svg" class="tick-icon" />

                            <img *ngIf="item.selected" style="margin: 0px 8px 0px 0px"
                                src="assets/images/tick_mark_color.svg" class="tick-icon" />
                            <div>{{item.name}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <div (click)="saveModalValues('geneticDisorders'); modal.dismiss()" class="btn-type-filled">Add Genetic
            Disorders </div>
    </div>
</ng-template>

<ng-template #familyGeneticDisordersModal let-modal>
    <div class="modal-header" style="border-bottom: 0px;">
        <h4 class="modal-title" id="modal-basic-title">Family Genetic Disorders</h4>
        <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form>

            <div class="form-group">
                <div class="input-group">
                    <input id="searchbox"  (input)="onSearchChange($event.target.value, 'familyGeneticDisorders')" style="border-radius:6px; padding: 0px 8px 0px 8px; height: 40px; margin-top: -20px;
            font-size: 16px;" class="form-control" placeholder="Start typing to search" name="dp">
                </div>
                <div style="height: 50vh; overflow: scroll;">
                    <div
                        style="display: flex; flex-direction: row; flex-wrap: wrap; margin-top: 16px; margin-bottom: 16px; width: 45vw; ">

                        <div *ngFor="let item of familyGeneticDisordersArray; let i = index"
                            (click)="selectModalValue(i, 'familyGeneticDisorders')" class="searchable-component-style">
                            <img *ngIf="item.selected  != true" style="margin: 0px 8px 0px 0px"
                                src="assets/images/tick_mark_grey.svg" class="tick-icon" />

                            <img *ngIf="item.selected" style="margin: 0px 8px 0px 0px"
                                src="assets/images/tick_mark_color.svg" class="tick-icon" />
                            <div>{{item.name}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <div (click)="saveModalValues('familyGeneticDisorders'); modal.dismiss()" class="btn-type-filled">Add Genetic
            Disorders </div>
    </div>
</ng-template>




<ng-template #otpModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
            Copious Healthcare
        </h4>
        <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div>
            <div class="newLoginHeader"> <label class="newLoginLabel">Welcome SMS sent to
                    {{stdCode}}{{mobileNumber}}</label></div>
            <!-- <h6 class="email-id"><span class="p-cursor" {{user.phone_number.slice(-3)}} (click)="showEmail()">{{emailId}}</span></h6> -->
            <div style="margin-top: 2%;margin-left: 4%;">

            </div>



            <div class="login-wrapper">
                <form>


                    <button type="submit" class=" opd-primary-btn btn-md w125 newBtnStyle"
                        style="margin-left: 60%;margin-top: 5%;text-transform: capitalize;"
                        (click)="modal.dismiss('Cross click');confirmOTP()">Confirm</button>
                </form>
            </div>
        </div>
    </div>


</ng-template>


<ng-template #addUploadModal let-modal style="position: relative; top:115px">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Upload Documents</h4>

        <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
            (click)="modal.dismiss('Cross click');closeAddReceiptModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body modal-lg">
        <p style="color: #777477;font-size: 14px;">Upload health reports and related documents as photos,images and
            PDFs.</p>
        <div>
            <div style="display: flex;">
                <div style="width:100%;padding-left: 5px;margin-top: -15px;  ">
                    <div class="row">
                        <div class="selectDropDown bloodGroup onFocus" style="width: 98%; margin-left: 5px;">
                            <label class="fieldsetlable">Document Type </label>
                            <select class=" form-control newInputBox" [(ngModel)]="selectedDocumentType"
                                (change)="onChangeDoc($event.target.value)"
                                style="border-radius: 4px !important; color: #656565 !important; font-size: 1rem !important; padding: 6px;">
                                <!-- <option >{{selectedDocumentType}}</option> -->
                                <option *ngFor="let docType of documentType; let i = index" value="{{docType}}">
                                    {{docType}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div style="margin-left: 5px;">

                <label class="fieldsetlable">Created By</label>
                <input type="text" class="form-control newInputBox " maxlength="15" placeholder="Created By"
                    style="border-radius: 4px !important; color: #949494 !important; font-size: 1rem !important;"
                    [(ngModel)]="notes">

            </div>
            <div style="margin-left: 1px;width: 567px; margin-top: 5px;">
                <label class="fieldsetlable">Document On<span class="required">*</span></label>

                <input placeholder="DD/MM/YYYY" type="text" id="dobInput" [maxDate]="date" [minDate]="minDate"
                    class="form-control " [(ngModel)]="createdOnRaw" ngbDatepicker #d="ngbDatepicker" readonly
                    (click)="d.toggle()"
                    style="border-radius: 4px !important;  color: #8C8B8C !important; font-size: 1rem !important;margin-left: 4px;">

                <img (click)="d.toggle()" class="calendarbg" style="top: 30px; right: 8px; width: 25px; padding-left: 5px;margin-bottom: 5px;
                    margin-top: -51px;
                    margin-left: 518px;" src="assets/images/calendarIcon.svg" alt="" />
            </div>

            <div style="margin-left: 5px;">
                <p style="color: black; font-size: 16px; font-weight: bold;margin-top: -10px;"> Select Document(Max:10
                    mb)</p>
                <p style="margin-top: -16px;color: #777477;font-size: 14px;"> Uploaded documents are shared with your
                    doctor</p>
            </div>
            <div style="display: flex; justify-content: space-between;">
                <div style="margin-left: 5px;">

                    <div class="container" appDnd (fileDropped)="onFileDropped($event)"
                        style="margin-left: 5px;width: 561px;height: 256px;">
                        <input type="file" #fileDropRef id="fileDropRef"
                            (change)="fileBrowseHandler($event.target.files)" />

                        <img src="assets/images/upload-to-cloud.svg" alt="" width="63" height="64" />

                        <h3 style="font-weight: 500; font-size: 16px;">Drag and drop pdf file here(Max,'10'mb)</h3>
                        <h3>or</h3>
                        <h6 style="color:red">{{report_message}}</h6>


                        <h6 style="color: green;">{{fileInfo}}</h6>
                        <label for="fileDropRef">Browse for file</label>
                    </div>

                    <div class="files-list">
                        <div class="single-file" *ngFor="let file of files; let i = index">
                            <div class="file-icon" style="width: 50px">
                                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 58 58"
                                    style="enable-background:new 0 0 58 58;" xml:space="preserve">
                                    <polygon style="fill:#EDEADA;" points="51.5,14 37.5,0 6.5,0 6.5,58 51.5,58 " />
                                    <g>
                                        <path style="fill:#CEC9AE;"
                                            d="M16.5,23h25c0.552,0,1-0.447,1-1s-0.448-1-1-1h-25c-0.552,0-1,0.447-1,1S15.948,23,16.5,23z" />
                                        <path style="fill:#CEC9AE;"
                                            d="M16.5,15h10c0.552,0,1-0.447,1-1s-0.448-1-1-1h-10c-0.552,0-1,0.447-1,1S15.948,15,16.5,15z" />
                                        <path style="fill:#CEC9AE;"
                                            d="M41.5,29h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,29,41.5,29z" />
                                        <path style="fill:#CEC9AE;"
                                            d="M41.5,37h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,37,41.5,37z" />
                                        <path style="fill:#CEC9AE;"
                                            d="M41.5,45h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,45,41.5,45z" />
                                    </g>
                                    <polygon style="fill:#CEC9AE;" points="37.5,0 37.5,14 51.5,14 " />
                                </svg>
                            </div>
                            <div class="info">
                                <h4 class="name">
                                    {{ file?.name }}
                                </h4>
                                <p class="size">
                                    {{ formatBytes(file?.size) }}
                                </p>
                                <app-progress [progress]="file?.progress"></app-progress>
                            </div>

                            <div class="delete" (click)="deleteFile(i)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18">
                                    <path fill="#B1B1B1" fill-rule="nonzero"
                                        d="M1 16c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2v10zm3.17-7.83a.996.996 0 0 1 1.41 0L7 9.59l1.42-1.42a.996.996 0 1 1 1.41 1.41L8.41 11l1.42 1.42a.996.996 0 1 1-1.41 1.41L7 12.41l-1.42 1.42a.996.996 0 1 1-1.41-1.41L5.59 11 4.17 9.58a.996.996 0 0 1 0-1.41zM10.5 1L9.79.29C9.61.11 9.35 0 9.09 0H4.91c-.26 0-.52.11-.7.29L3.5 1H1c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1h-2.5z" />
                                </svg>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>

        <!-- <div style="display:flex; flex-direction: row; justify-content: space-between; flex: 1; margin-top: 5px; ">
            <div (click)="editRefferal();modal.dismiss();" class="quickrx" style="margin-left: -1px;"> Cancel</div>
           
             <div (click)=" printNewRefreel('share')" class="quickrx" style="margin-right:-29px">Upload</div>
        </div> -->
        <button type="submit" class=" opd-primary-btn btn-md w125 newBtnStyle"
            style="margin-left: 78%;margin-top: 0%;text-transform: capitalize;"
            (click)="uploadDocument();">Upload</button>
    </div>


</ng-template>

<ng-template #checkDateModal let-modal>
    <div class="modal-header" style="border-bottom: 0px;">
        <h4 class="modal-title" id="modal-basic-title">Check Dates</h4>
        <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
            <span style="margin-right: 6px;" aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div style="width: 98%;
        padding-left: 5px;
        margin-top: 14px;
        margin-bottom: 4px;
        margin-left: 8px;">
            <div class="row">
                We have added Expected DOB as {{dobDate}} and Vaccination <br>
                Start date as {{vacDate}} Would you like to modify or continue with same?<br>

                Once continued these dates cannot be changed

            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div style="display: flex;flex-direction: row;justify-content: space-between;">
            <div (click)="modifyVitals();" class="btn-type-filled">MODIFY</div>
            <div (click)="goToAddVitals(); modal.dismiss()" class="btn-type-filled">CONTINUE</div>
        </div>

    </div>
</ng-template>

<p-toast position="top-right" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class=" p-flex p-flex-column"
            style="flex: 1; background-color:#BAE8FC; opacity: 0.95; display: flex; flex-direction: row; align-items: center; border-left: 8px solid #0D8CCD; border-radius: 8px;">
            <div>
                <img (click)="goBack()" style="height: 36px; margin-left: 12px; width: 36px; align-self: center;"
                    src="assets/images/exclamation-circle-blue.svg" alt="" />
            </div>
            <div class=" p-text-center">
                <div style="font-size: 18px;
                    font-weight: bold;
                    padding: 12px 0px 0px 12px;
                    
                    color: #034A70;">{{message.summary}}</div>
                <div
                    style="padding-left: 12px; padding-bottom: 12px; color: #034A70; font-size: 16px; margin-top: -6px;">
                    {{message.detail}}</div>
            </div>
        </div>
    </ng-template>
</p-toast>
import { ChangeDetectorRef, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSelect } from '@angular/material/select';
import { MatStepper } from '@angular/material/stepper';
import * as moment from 'moment';
import { PatientService } from '../../../services/patient.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from '../../../services/global.service';
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { refreshService } from '../../../services/refersh.service';
import { DoctorService } from '../../../services/doctor.service';
import { AuthService } from '../../../services/auth.service';
import { I } from '@angular/cdk/keycodes';
import { MessageService } from 'primeng/api';
import { DateAdapter, MatDateFormats, MAT_DATE_FORMATS, MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material/core'

let habbits = [{ name: 'Chewing', past: '0', present: '0' }, { name: 'Smoking', past: '0', present: '0' }, { name: 'Drinking', past: '0', present: '0' }, { name: 'Drugs', past: '0', present: '0' }, { name: 'other', past: '0', present: '0' }]
let gender = [{ name: "Female", selected: false, value: "" }, { name: "Male", selected: false, value: "" }]


export class AppDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: Object): string {
    if (displayFormat === 'input') {
      let day: string = date.getDate().toString();
      day = +day < 10 ? '0' + day : day;
      let month: string = (date.getMonth() + 1).toString();
      month = +month < 10 ? '0' + month : month;
      let year = date.getFullYear();
      return `${day}/${month}/${year}`;
    }
    return date.toDateString();
  }
}
export const APP_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'numeric' },
    dateA11yLabel: {
      year: 'numeric', month: 'long', day: 'numeric'
    },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  }
};
@Component({
  selector: 'app-new-onboard-patient',
  templateUrl: './new-onboard-patient.component.html',
  styleUrls: ['./new-onboard-patient.component.css'],
  providers: [MessageService,
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }],
    encapsulation: ViewEncapsulation.None
})
export class NewOnboardPatientComponent implements OnInit {
  languageArray: any = [{ name: 'English', language: 'en' }, { name: 'Marathi', language: 'mr' }, { name: 'Hindi', language: 'hi' }, { name: 'Kannada', language: 'kn' }];
  selectedLanguage: any = ""
  patientSelectedLang:any=""
  disabledButton:boolean=false
  additionalInfo:any={}
  @ViewChild("patientListModal") patientListModal;
  @ViewChild("checkDateModal") checkDateModal;
  @ViewChild('stepper') private stepper: MatStepper;
  @ViewChild("continueProfileModal ") continueProfileModal;
  @ViewChild("doneProfileModal ") doneProfileModal;
  nextButtonFlag:boolean=false
  isOTPConfirmedForSecondno = false
  mainFormGroup: FormGroup;
  isLinear = true;
  stdCode
  jsonObj: any = []
  patientAddress=""
  patientLocality=""
  patientCity=""
  patientPincode=""
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  isOTPConfirmed = false;
  patientResponse
  newBornScreening:any = 0
  adultblood_group: any=""
  errors: any = {};
  fathersHeightCm: any
  uploadTestValue = ""
  patientData
  mobileNumber
  medicalConditions: string = '';
  highRiskType: string = "";
  patientId: any
  familyMedicalConditions: string = '';
  geneticDisorders: string = '';
  familyGeneticDisorders: string = '';
  patientList: any = [];
  secondNumber
  showHighRisk = false;
  shownewBornscreen=false
  isHighRisk = false

  recievedOtp: any
  deliveryType: string
  firstname
  FamilyProfileAdultFlag: boolean = false
  isEdit = false
  patientNewData
  isPediatric = false;
  currentStep = 0;
  currentIndex = 0;
  ageYears: any
  isAppointmentEnabled = '0';
  medicalConditionArrayOg: any = [];
  geneticDisordersOg: any = [];
  geneticDisordersArray: any = [];
  medicalConditionArray: any = [];
  familyMedicalArray: any = [];
  highRiskTypeArray: any = [];
  highRiskTypeOg: any = [];
  familyMedicalArrayOg: any = [];
  familyGeneticDisordersArray: any = [];
  familyGeneticDisordersOrg: any = [];
  gestationalAge
  middlename
  ageMonths: any
  paramPatienId:any;
  lastname
  blood_group:any=""
  eDob: any
  mothersHeightCm: any
  healthHistoryHighlights: any
  ageWeeks
  countryCode = "+91"
  highRiskForBabies=""
  vacDate
  heightArr = ['ft', 'cm'];
  fathersHeightFt = { ft: '0', in: '0' };
  mothersHeightFt = { ft: '0', in: '0' };
  fathersHeightInFt = 'ft';
  mothersHeightInFt = 'ft';
  isPreTerm
  userID: any
  highRiskTypeBabies:any=[]
  showDate: boolean = false
  closeResult: any;
  healthHistoryUi: boolean = false
  selectGender: string
  habbitsArray: any = { Chewing: { past: 0, present: 0 }, Smoking: { past: 0, present: 0 }, Drinking: { past: 0, present: 0 }, Drugs: { past: 0, present: 0 }, Other: { past: 0, present: 0 } }
  dob: any
  habbits: any
  bloodGroups = [];
  typeOfDeliveryArr = ['Normal', 'Vaccum', 'Forceps', 'C-Section'];
  selectedIndexOnTab: number = 0
  profileUiFlag: boolean = true
  healthProfileFlag: boolean = false
  antenetalBirthFlag: boolean = false
  genderArray = gender
  steps: any = [{ name: "Profile", value: "Profile", selected: false }, { name: "Health Profile", value: "Health Profile", selected: false }, { name: "Family Profile", value: "Family Profile", selected: false }];
  isEditable = false;
  familyProfilePed: boolean = false
  profileForm: FormGroup;
  patientObj: any
  rxUi:any=""
  minDate
  todayDate
  newHighRisksCategoriesForBabies:any=[]
  visits: any;



  constructor(private cdr: ChangeDetectorRef,private messageService: MessageService, private authService: AuthService, private doctorService: DoctorService, private refreshservice: refreshService, private modalService: NgbModal, private globalService: GlobalService, private router: Router, public dialog: MatDialog, private fb: FormBuilder, private patientService: PatientService, private route:ActivatedRoute) {

    this.userID = this.authService.getUser();
    this.habbits = habbits
    this.todayDate = new Date();
    // this.profileForm.controls['dob'].setValue(todayDate)

    this.minDate = this.reverseDate(new Date(this.dob));
  }

  ngOnInit(): void {
    
    this.showDate = false
    this.isEdit = false
    this.profileUiFlag = true
    this.profileForm = this.fb.group({
      countryCode: this.countryCode,
      mobileNumber: ['', Validators.required],
      secondNumber: [''],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      middlename: [''],
      dob: ['', Validators.required],
      ageYears: [''],
      gender: ['', Validators.required],
      vacDate: [''],
      dateOfBirth: [''], expectedDOB: [''], deliveryType: [''],blood_group:[''], gestationalAge: [''], vaccinationDate: [''],
      eDob: [''], highRisk: ['No'], newBornScreening: ['No'], birth_weight: [''],
      birth_height: [''], birth_hc: [''], birth_cc: [''], birth_bpsys: [''],
      birth_bpdia: [''], 
      medicalConditions: [''],
      allergies: [''],
      recurring: [''],
      hospitalisation: [''],
      healthHistory: [''],
      emailId: [''],
      otherId: [''],
      highRiskType: [''],
      geneticDisorders: [''],
      reactions: [''],
      fathersHeightFt_ft: ['0'],
      fathersHeightFt_in: ['0'],
      mothersHeightFt_fit: ['0'],
      mothersHeightFt_in: ['0'],
      fathersHeightCm: ['0'],
      mothersHeightCm: ['0'],
      familyMedicalConditions: [''],
      familyGeneticDisorders: [''],
      patientAddress:[''],
      patientCity:[''],
      patientLocality:[''],
      patientPincode:[''],

      heightInFit: [''],



    });
 

    this.getConstants();
    this.getDoctorDetails();
    this.getAllRequiredConstants();
    setTimeout(()=>{
      this.getPatientInfo();
    },500)
 

    var patientHistory: any = localStorage.getItem("patientHistory");
    this.rxUi= (localStorage.getItem('RxUi'))
    patientHistory = JSON.parse(patientHistory)
    if (patientHistory) {
      this.patientObj = patientHistory
      this.isEdit = true;
      this.errors["ageYears"]=""
      this.patientId = patientHistory._id
    
 
       this.getPatientInfo();
   

   
    }
    setTimeout(() => {
      this.genderArray.map(ins => ins.selected = false);
    })
    setTimeout(() => {
      this.vacDate = moment(this.parseDate(this.vacDate)).format("YYYY-MM-DD");

    })
    setTimeout(() => {
      this.removeTabFromCollection("Antenetal & Birth History");
      this.removeTabFromCollection("Health History");

    })

    setTimeout(() => {

      if (this.patientId !== '' && this.patientId !== undefined && this.patientId !== null) {


        var age = this.profileForm.controls.ageYears.value
        //var age = moment().diff(moment(new Date(this.parseDate(this.dob)), 'DD-MMM-YYYY'), 'years');

        if (age < 12 || age == 'under 12 year') {
          this.removeTabFromCollection("Antenetal & Birth History");
          this.checkIfTabsArrayContains("Antenetal & Birth History")
          this.removeTabFromCollection("Health History");
          this.steps.splice(1, 0, { name: "Antenetal & Birth History", selected: false, value: "", highlighted: false })

        }
        else {
          console.log("History........", JSON.stringify(this.steps));
          this.removeTabFromCollection("Health History");
          if (!this.checkIfTabsArrayContains("Health History")) {
            console.log("1234");
            this.removeTabFromCollection("Antenetal & Birth History");
            this.steps.splice(1, 0, { name: "Health History", selected: false, value: "", highlighted: false })
          }

        }
      }
      else {

        this.steps = this.steps;
      }
    }, 500);


    setTimeout(() => {
      // this.steps.push(`'${this.steps.length + 1}'`);
      // this.currentIndex = this.steps.length - 1;
      console.log("step", this.steps)
      console.log("current", this.currentIndex)



    });
  }
  setIndex(event) {
    console.log("event", event)
    //this.isLinear = true;
    this.selectedIndexOnTab = event.selectedIndex;
    // if(this.profileForm.controls.mobileNumber.value.length < 10){
    // }
    // else if(this.profileForm.controls.firstname.value=='' || this.profileForm.controls.lastname.value==''){

    // }
    // else if(this.isPediatric==true && this.profileForm.controls.dob.value==''){

    // }
    //else{

    if (this.selectedIndexOnTab == 1) {
     // this.blood_group=this.patientData.blood_group
      var agesYear = this.profileForm.controls.ageYears.value
      var dob = this.profileForm.controls.dob.value
      dob = moment(this.parseDate(dob)).format("DD/MM/YYYY")
      var edob=this.patientData.eDOB
      var vacDate:any= moment(this.parseDate(this.patientData.vaccinationStartDate)).format("DD-MMM-YYYY")
     // edob=new Date(edob)
     this.profileForm.controls['vacDate'].setValue(new Date(vacDate))
      setTimeout(()=>{
        this.profileForm.controls['eDob'].setValue(new Date(edob))
      },200)
     
      
      if (agesYear == 'under 12 year') {

      //  this.profileForm.controls['eDob'].setValue(dob)
      
        if(this.isEdit){
         // this.blood_group=this.patientData.blood_group
          //this.vacDate=this.patientData.vaccinationStartDate
         var vacDate:any= moment(this.parseDate(this.patientData.vaccinationStartDate)).format("DD-MMM-YYYY")
         // vacDate=new Date(vacDate)
          this.eDob= moment(this.parseDate(this.patientData.eDOB)).format("DD/MM/YYYY")
         // this.profileForm.controls['eDob'].setValue(new Date(this.eDob))
          this.profileForm.controls['vacDate'].setValue(new Date(vacDate))
          this.gestationalAge=this.patientData.gestationalAge
          this.profileForm.controls['gestationalAge'].setValue(this.gestationalAge.w + " " + "W" + " " + this.gestationalAge.d + "D");
        }
        this.profileUiFlag = false
        this.healthProfileFlag = false
        this.antenetalBirthFlag = true
        this.familyProfilePed = false
        this.healthHistoryUi = false
        this.FamilyProfileAdultFlag = false
      }
      else {

        this.profileUiFlag = false
        this.healthProfileFlag = false
        this.antenetalBirthFlag = false
        this.familyProfilePed = false
        this.healthHistoryUi = true
        this.FamilyProfileAdultFlag = false
      }
    }


    else if (this.selectedIndexOnTab == 0) {
      this.profileUiFlag = true
      this.healthProfileFlag = false
      this.antenetalBirthFlag = false
      this.familyProfilePed = false
      this.healthHistoryUi = false
      this.FamilyProfileAdultFlag = false
    }
    else if (this.selectedIndexOnTab == 2) {
      this.profileUiFlag = false
      this.antenetalBirthFlag = false
      this.healthProfileFlag = true
      this.familyProfilePed = false
      this.healthHistoryUi = false
      this.FamilyProfileAdultFlag = false

    }
    else if (this.selectedIndexOnTab == 3) {
      var agesYear = this.profileForm.controls.ageYears.value
      if (agesYear == 'under 12 year') {
        this.profileUiFlag = false
        this.antenetalBirthFlag = false
        this.healthProfileFlag = false
        this.familyProfilePed = true
        this.healthHistoryUi = false
        this.FamilyProfileAdultFlag = false
      }
      else {
        this.profileUiFlag = false
        this.antenetalBirthFlag = false
        this.healthProfileFlag = false
        this.familyProfilePed = false
        this.FamilyProfileAdultFlag = true
        this.healthHistoryUi = false
      }


    }
    // }
  }
  addItem() {
    console.log("One", this.profileForm.controls);
  }
  onSubmit() {
    // TODO: Use EventEmitter with form value
    console.log("Two", this.profileForm.controls);
  }


  open(content, type) {
    this.modalService.open(content, { backdrop: false, size: 'xl', centered: false, windowClass: 'dark-modal' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      console.log("dismissed")
      // this.isEditableVisit = false

    });
  }

  getAllRequiredConstants = () => {
    this.patientService.getAllRequiredConstants('').subscribe(response => {
      if (response) {

        console.log("Response Constants", response);
        this.newHighRisksCategoriesForBabies=response.data.newHighRisksCategoriesForBabies
        this.jsonObj=response.data.newHighRisksCategoriesForBabies
        console.log("newHighRisk",this.newHighRisksCategoriesForBabies)
        this.medicalConditionArrayOg = response.data.medicalConditions.map(ins => { return { name: ins.name, selected: false } });
        this.medicalConditionArray = response.data.medicalConditions.map(ins => { return { name: ins.name, selected: false } });

        this.familyMedicalArray = response.data.familyConditions.map(ins => { return { name: ins.name, selected: false } });
        this.familyMedicalArrayOg = response.data.familyConditions.map(ins => { return { name: ins.name, selected: false } });

        this.geneticDisordersArray = response.data.geneticDisorders.map(ins => { return { name: ins.name, selected: false } });
        this.geneticDisordersOg = response.data.geneticDisorders.map(ins => { return { name: ins.name, selected: false } });

        this.familyGeneticDisordersArray = response.data.familyGeneticDisorders.map(ins => { return { name: ins.name, selected: false } });
        this.familyGeneticDisordersOrg = response.data.familyGeneticDisorders.map(ins => { return { name: ins.name, selected: false } });

        this.highRiskTypeArray = response.data.highRiskType.map(ins => { return { name: ins.name, selected: false } });
        this.highRiskTypeOg = response.data.highRiskType.map(ins => { return { name: ins.name, selected: false } });




      } else {
        alert('Something went wrong')
      }
    });
  }
  getDoctorDetails = () => {
    let params = {
      user_id: this.userID
    }
    this.doctorService.getDetails(params).subscribe(response => {
      this.isAppointmentEnabled = response.doctor && response.doctor.isAppointmentEnabled ? response.doctor.isAppointmentEnabled : '0';
    })
  }
  selectModalValue(index, type) {

    switch (type) {
      case 'medicalConditions':
        if (this.medicalConditionArray[index].selected) {
          this.medicalConditionArray[index].selected = false;
          this.medicalConditionArrayOg[index].selected = false;

          this.medicalConditions = this.medicalConditions.replace(this.medicalConditionArray[index].name + ',', '');
          this.medicalConditions = this.medicalConditions.replace(this.medicalConditionArray[index].name, '');
        } else {
          this.medicalConditionArray[index].selected = true;
        }
        break;

      case 'familyMedicalConditions':
        if (this.familyMedicalArray[index].selected) {
          this.familyMedicalArray[index].selected = false;
          this.familyMedicalArrayOg[index].selected = false;

          this.familyMedicalConditions = this.familyMedicalConditions.replace(this.familyMedicalArray[index].name + ',', '');
          this.familyMedicalConditions = this.familyMedicalConditions.replace(this.familyMedicalArray[index].name, '');
        } else {
          this.familyMedicalArray[index].selected = true;
        }
        break;
      case 'geneticDisorders':
        if (this.geneticDisordersArray[index].selected) {
          this.geneticDisordersArray[index].selected = false;
          this.geneticDisordersOg[index].selected = false;

          this.geneticDisorders = this.geneticDisorders.replace(this.geneticDisordersArray[index].name + ',', '');
          this.geneticDisorders = this.geneticDisorders.replace(this.geneticDisordersArray[index].name, '');
        } else {
          this.geneticDisordersArray[index].selected = true;
        }
        break;

      case 'familyGeneticDisorders':
        if (this.familyGeneticDisordersArray[index].selected) {
          this.familyGeneticDisordersArray[index].selected = false;
          this.familyGeneticDisordersOrg[index].selected = false;

          this.familyGeneticDisorders = this.familyGeneticDisorders.replace(this.geneticDisordersArray[index].name + ',', '');
          this.familyGeneticDisorders = this.familyGeneticDisorders.replace(this.geneticDisordersArray[index].name, '');
        } else {
          this.familyGeneticDisordersArray[index].selected = true;
        }
        break;
      case 'highRiskType':
        if (this.highRiskTypeArray[index].selected) {
          this.highRiskTypeArray[index].selected = false;
          this.highRiskTypeOg[index].selected = false;

          this.highRiskType = this.highRiskType.replace(this.highRiskTypeArray[index].name + ',', '');
          this.highRiskType = this.highRiskType.replace(this.highRiskTypeArray[index].name, '');
        } else {
          this.highRiskTypeArray[index].selected = true;
        }
        break;
      default:
        break;
    }
  }


  selectModalValueGender(index) {
    this.genderArray.map((ins, ind) => {
      if (ind == index) {
        this.genderArray[index].selected = true;
        this.selectGender = ins.name;

      } else {
        ins.selected = false;
      }
    });
    // this.profileForm.controls['gender'].setValue(this.selectGender);
    // this.profileForm.setValue({gender: this.selectGender,});

  }
  closeDialog() {
    this.dialog.closeAll();

  }
  openDialog(content, index) {
    this.selectedIndexOnTab = index;

    this.dialog.open(content);
  }

  getConstants() {
    this.globalService.getConstants().subscribe(response => {
      this.bloodGroups = response.blood_groups;
    });
  }

  dropdownSelectForHeight(isFrom, value) {
    if (isFrom === 'heightInFit') {
      if (value === 'cm') {
        this.fathersHeightInFt = 'cm'
      }
      else {
        this.fathersHeightInFt = 'ft'
      }
      this.convertUnitsFather();
    }
    else {
      if (value === 'cm') {
        this.mothersHeightInFt = 'cm'
      }
      else {
        this.mothersHeightInFt = 'ft'
      }
      this.convertUnitsMother();
    }

  }
  pastHabbitsAdd(e, str1, str2) {
    this.habbitsArray[str1][str2] = e.target.checked ? 1 : 0;

    console.log("newHabbits", this.habbitsArray)




  }

  patientFormOpen() {
    this.modalService.dismissAll();
    this.profileForm.controls["mobileNumber"].setValue("")
    this.isOTPConfirmed = false;
    this.isOTPConfirmedForSecondno = false
    this.nextButtonFlag=false

    // this.router.navigate([`/doctor/patients/patientOnboard`])
    // this.modifyRmn = true;
    // this.isOTPConfirmed = false
  }

  findPatientByName() {
   // this.disabledButton=true
   this.isOTPConfirmed=true
    //var edob = this.profileForm.controls.dob.value
    // if (this.profileForm.controls.mobileNumber.value.length < 10) {
    //   this.disabledButton=false
    // }
    // else if (this.profileForm.controls.firstname.value == '' || this.profileForm.controls.lastname.value == '') {
    //   this.disabledButton=false
    // }
    // else if (this.isPediatric == true && this.profileForm.controls.dob.value == '') {
    //   this.disabledButton=false
    // }
    // else if (!this.selectGender) {
    //   this.messageService.add({ severity: 'custom', detail: 'Please Select Gender' });
    //   this.disabledButton=false

    // }
    // else if (!this.isOTPConfirmed) {
    //   this.messageService.add({ severity: 'custom', detail: 'Please Confrim Mobile Number' });
    //   this.disabledButton=false
    // }

    // else {
       if (this.dob != 'Invalid date') {
         this.profileForm.controls['dob'].setValue(this.dob)
    }


      // this.profileForm.controls["eDob"].setValue(this.eDob)
      // this.profileForm.controls["vacDate"].setValue(this.vacDate)
     

      let postData: any = {
        contactNo: this.profileForm.controls.countryCode.value + this.profileForm.controls.mobileNumber.value,
        spouseContactNo: this.profileForm.controls.countryCode.value + this.profileForm.controls.secondNumber.value,
        profilePic: "",
        gender: "m",
        firstName: "dummy",
        lastName: "test",
        middleName: "test",
        DOB: moment(this.parseDate(1-1-2022)).format("DD-MMM-YYYY"),
        eDOB: moment(this.parseDate(1-1-2022)).format("DD-MMM-YYYY"),
     //   vaccinationStartDate: moment(this.parseDate(this.profileForm.controls.vacDate.value)).format("YYYY-MM-DD"),
       
      }
      this.patientService.findPatientsByNameDOBNumber(postData).subscribe(response => {

        if (response) {
          console.log("patientList", response)
          this.patientList = response;


          // var initial=fullName.charAt(0).toUpperCase()+fullName.charAt(0).toUpperCase();
          // console.log("initial",initial)
         // this.disabledButton=true
       
          this.open(this.patientListModal, 'patientListModal')
          setTimeout(() => {
            this.isOTPConfirmed = true;
            this.isOTPConfirmedForSecondno = true
                 
          }, 500);
          console.log('It came here')
        //  this.nextButtonFlag=true
        
        
          //  this.open(this.continueProfileModal, 'continueProfileModal')
          //  this.onSubmitButton();
        
      
        }
        else {
          console.log('It came here too')
          this.isOTPConfirmed = true;
          this.isOTPConfirmedForSecondno = true
          this.nextButtonFlag=false
               
          //this.disabledButton=true
          //this.open(this.continueProfileModal, 'continueProfileModal')
         // this.onSubmitButton();
        }
      })
   // }

  }
  gotNextTab() {
    this.modalService.dismissAll();
    this.onSubmitButton();
    // this.selectedIndexOnTab=1
    // this.stepper.next()
  }
  onSubmitButton() {
    this.disabledButton=true
       if (this.profileForm.controls.mobileNumber.value.length < 10) {
      this.disabledButton=false
    }
     else if (this.profileForm.controls.firstname.value == '' || this.profileForm.controls.lastname.value == '') {
       this.disabledButton=false
    }
    else if (this.isPediatric == true && this.profileForm.controls.dob.value == '') {
      this.disabledButton=false
    }
    else if (!this.selectGender) {
     this.messageService.add({ severity: 'custom', detail: 'Please Select Gender' });
     this.disabledButton=false

     }
     else if (this.profileForm.controls.ageYears.value == ''){
      this.messageService.add({ severity: 'custom', detail: 'Please Enter Patient Age' });
      this.disabledButton=false
     }
     else if (this.profileForm.controls.ageYears.value== 1 ){
      this.messageService.add({ severity: 'custom', detail: 'Please Use DOB for age less than 1 yrs '});
      this.disabledButton=false
     }
     else{

   
    var edob:any
   
    var dob:any
    if(this.isEdit){
     // this.getAge();
      dob=moment(this.parseDate(this.patientData.DOB)).format("DD-MMM-YYYY")
      edob=moment(this.parseDate(this.patientData.eDOB)).format("DD-MMM-YYYY")

    }
    else{
       if (this.dob != 'Invalid date') {
         this.profileForm.controls['dob'].setValue(this.dob)
    }


      this.profileForm.controls["eDob"].setValue(this.eDob)
      this.profileForm.controls["vacDate"].setValue(this.vacDate)
    
            dob= moment(this.parseDate(this.profileForm.controls.dob.value)).format("DD-MMM-YYYY")
            edob=moment(this.parseDate(this.profileForm.controls.dob.value)).format("DD-MMM-YYYY")
          
      

    }
   // this.profileForm.controls["eDob"].setValue(this.eDob)
   // this.profileForm.controls["vacDate"].setValue(this.vacDate)
    // if(this.dob){
    //   this.profileForm.controls["dob"].setValue(this.dob)
    // }
    // this.selectedIndexOnTab=1
    // this.stepper.next()
    let postData: any = {
      contactNo: this.profileForm.controls.countryCode.value + " " + this.profileForm.controls.mobileNumber.value,
      spouseContactNo: this.profileForm.controls.countryCode.value + " " + this.profileForm.controls.secondNumber.value,
      profilePic: "",
      gender: this.selectGender,
      firstName: this.profileForm.controls.firstname.value,
      lastName: this.profileForm.controls.lastname.value,
      middleName: this.profileForm.controls.middlename.value,
      DOB: dob,
      eDOB: edob,
      address:this.profileForm.controls.patientAddress.value,
      locality:this.profileForm.controls.patientLocality.value,
      city:this.profileForm.controls.patientCity.value,
      pincode:this.profileForm.controls.patientPincode.value,
      selectedLanguage: this.selectedLanguage,
      vaccinationStartDate: moment(this.parseDate(this.vacDate)).format("YYYY-MM-DD"),
      isHighRisk: "",
      patientTypes: ['Ba'],
      visit: [],
    }
   
    var age = moment().diff(moment(new Date(this.parseDate(this.profileForm.controls.dob.value)), 'DD-MMM-YYYY'), 'years');
    console.log("ageNumber", age)
    if (Number(age < 12)) {

      postData.patientTypes = ['Ba', 'Pe'];
      postData.visit = {
        weight: '',
        height: 0,
        hc: 0,
        cc: 0,
        bpSys: 0,
        bpDia: 0,
        addedBy: 'doctor'
      }
    }
    if (this.isEdit) {
      this.showDate = false
      var data:any={


      spouseContactNo: this.profileForm.controls.countryCode.value + " " + this.profileForm.controls.secondNumber.value,
    
      gender: this.selectGender,
      firstName: this.profileForm.controls.firstname.value,
      lastName: this.profileForm.controls.lastname.value,
      middleName: this.profileForm.controls.middlename.value,
    
     
      address:this.profileForm.controls.patientAddress.value,
      locality:this.profileForm.controls.patientLocality.value,
      city:this.profileForm.controls.patientCity.value,
      pincode:this.profileForm.controls.patientPincode.value,
      selectedLanguage: this.selectedLanguage,
    }
      // postData.gestationalAge = this.gestationalAge;
      // postData.isSMSInviteSent = this.patientResponse.isSMSInviteSent;

      // const data = {
      //   patient: postData,
      //   patientCreatedAt: this.patientResponse.createdAt
      // }

      this.patientService.patientpartialupdate(data, this.patientResponse._id).subscribe(response => {
        if (response) {
          // console.log("data",response);
          // console.log("data1",this.patientResponse)
        // this.patientResponse = response
         
          // this.patientData['firstName']=response.firstName
          // this.patientData['middleName']=response.middleName
          // this.patientData['address']=response.address
           // this.getPatientInfo
          this.patientNewData = this.patientResponse
        // this.getPatientInfo();
          
         
     

        
        }
        this.patientData = this.patientResponse
        this.stepper.next()
      })
    } else {


      this.patientService.addNewPatient(postData).subscribe(response => {
        if (response) {

          this.patientData = response;
         // this.patientNewData=response
          this.antenetalBirthFlag = true
          this.selectedIndexOnTab = 1
        
          var dob = this.profileForm.controls.dob.value
          var edob = this.profileForm.controls.eDob.value;
          var vacDate =moment(this.parseDate(response.vaccinationStartDate)).format("DD/MM/YYYY")
         // vacDate = moment(this.parseDate(vacDate)).format("DD/MM/YYYY")
          // var gestationalAge=
          dob = moment(this.parseDate(dob)).format("DD/MM/YYYY")
          edob = response.eDOB
          edob=new Date(edob)

          this.profileForm.controls['dateOfBirth'].setValue(dob);
         // this.profileForm.controls['eDob'].setValue(new Date(edob));
          this.profileForm.controls['vacDate'].setValue(new Date(vacDate));
          this.profileForm.controls['gestationalAge'].setValue(this.gestationalAge.w + " " + "W" + " " + this.gestationalAge.d + "D");
          let obj = {}
          let objData = {}
          obj['id'] = this.patientData.userId;
          obj['phone_number'] = this.patientData.contactNo

          obj['spousecontactno'] = this.patientData.spousecontactno
          obj['dob'] = this.patientData.DOB
          var gender: any = ""
          if (this.patientData.gender == 'Male') {
            gender = "male"
          }
          else {
            gender = "female"
          }
          obj['gender'] = gender
          obj['first_name'] = this.patientData.firstName
          obj['last_name'] = this.patientData.lastName
          obj['middle_name'] = this.patientData.middleName
          obj['locality']=this.patientData.locality
          obj['profile_photo'] = this.patientData.profilePic ? this.patientData.profilePic : '';

          objData['User'] = { ...obj, Customer: { phone_number: this.patientData.contactNo, spousecontactno: this.patientData.spousecontactno } };
          objData['id'] = this.patientData._id;

          this.patientNewData = objData
          console.log("newPa", this.patientNewData)
          localStorage.setItem("patientData", JSON.stringify(this.patientNewData))
          this.open(this.continueProfileModal, 'continueProfileModal')
            this.stepper.next()
         
        
          // this.toaster.success("Success");
          // this.showLoading = false;
          // this.router.navigate(['/doctor/homepage'])
        }
      })
    }
  }
  }


  checkIfTabsArrayContains(tabname) {
    for (var i = 0; i < this.steps.length; i++) {
      if (this.steps[i].name == tabname) {
        return true;
      }
    }
    return false;
  }

  removeTabFromCollection(tabname) {
    for (var i = 0; i < this.steps.length; i++) {
      if (this.steps[i].name == tabname) {
        this.steps.splice(i, 1);
        return;
      }
    }
  }

  getAge() {
    var dob:any
    if(this.isEdit){
     dob= this.patientData.DOB
    }
    else{
      dob = this.profileForm.controls.dob.value
    }
  
    var eDob = this.profileForm.controls.eDob.value

    dob = this.reverseDate(dob);
    const { day, month, year } = dob;
    this.eDob = dob;
    console.log("edob", this.eDob)
    this.profileForm.controls['eDob'].setValue(this.eDob);

    let bdate = new Date();
    bdate.setMonth(month - 1);
    bdate.setDate(day);
    // 0
    bdate.setFullYear(year);
    var vacDate = bdate;
    vacDate.setDate(vacDate.getDate() + 2);
    this.vacDate = this.reverseDate(vacDate);

    console.log("tabmenu", this.steps)

    let age1 = moment().diff(moment(bdate, 'DD-MMM-YYYY'), 'years');
    if (age1 < 12) {
      this.isPediatric = true;
    } else {
      this.isPediatric = false;
    }

    this.setExpectedBDateAndGestestionalAge();
    let age = this.calculateAge(new Date(), bdate);
    this.ageYears = age[0];

    if (this.ageYears <= 12 || this.ageYears == "under 12 year") {
      this.ageYears = "under 12 year";

      if (!this.checkIfTabsArrayContains("Antenetal & Birth History")) {

        this.removeTabFromCollection("Health History");
        this.steps.splice(1, 0, { name: "Antenetal & Birth History", selected: false, value: "", highlighted: false })

      }
    } else {
      this.ageYears = age[0]
      if (!this.checkIfTabsArrayContains("Health History")) {
        this.removeTabFromCollection("Antenetal & Birth History");
        this.steps.splice(1, 0, { name: "Health History", selected: false, value: "", highlighted: false })
      }
    }
    this.ageMonths = age[1];
    if (this.ageMonths <= 0) {
      this.ageMonths = "Under 1 year";

    }
    else {
      this.ageMonths = age[0]

    }
    this.ageWeeks = Math.floor(age[2] / 7);

    var fromDate = moment(new Date(this.parseDate(dob))).format("MM/DD/YYYY");
    var eDobDate = moment(new Date(this.parseDate(eDob))).format("DD/MM/YYYY");

    dob = new Date(fromDate)


    this.profileForm.controls['ageYears'].setValue(this.ageYears);


  }


  parseDate(dateObject) {
    if (dateObject?.year) {
      return `${dateObject.year}-${dateObject.month}-${dateObject.day}`;
    } else {
      return `${dateObject}`
    }
  }
  addAge() {
    this.showDate = false
    this.ageYears = this.profileForm.controls.ageYears.value
    if (this.ageYears < 2 || this.ageYears == "under 12 year") {
      if(this.isEdit){
        this.errors["ageYears"] = ""
      }
      else{
        this.errors["ageYears"] = "Use DOB for age less than 1 yrs";
      }
 
      if (!this.checkIfTabsArrayContains("Antenetal & Birth History")) {
        this.showDate = false;
        this.isPediatric = true
        this.removeTabFromCollection("Health History");
        this.steps.splice(1, 0, { name: "Antenetal & Birth History", selected: false, value: "", })
      }
    }
    else {
      this.errors["ageYears"] = "";
      if (!this.checkIfTabsArrayContains("Health History")) {
        this.removeTabFromCollection("Antenetal & Birth History");
        this.steps.splice(1, 0, { name: "Health History", selected: false, value: "", highlighted: false })
      }
      this.showDate = true
      this.isPediatric = false
    }
    let age: any = moment().format('YYYY');
    age = age - this.ageYears;
    this.dob = moment(`01-JAN-${age}`).format("DD-MMM-YYYY");
    console.log("dobhhh", this.dob)
    this.profileForm.controls['eDob'].setValue(this.dob);
  }


  setExpectedBDateAndGestestionalAge() {
    var dob
    var edob
    if(this.isEdit){
      dob=this.patientData.DOB
      }
      else{
       dob = this.profileForm.controls.dob.value
      }
     edob=this.profileForm.controls.eDob.value
    if(edob){
      this.eDob=edob
      this.eDob = this.reverseDate(this.eDob);
    }
    else{
     this.eDob=this.eDob
    }
   
  
    dob = this.reverseDate(dob);
    

    const { day, month, year } = dob;
    let bdate = new Date();
    bdate.setMonth(month - 1);
    bdate.setDate(day);
    bdate.setFullYear(year);
    let edobdate = new Date();
    edobdate.setMonth(this.eDob.month - 1);
    edobdate.setDate(this.eDob.day);
    edobdate.setFullYear(this.eDob.year);

    try {
      const PATIENT_DOB_FORMAT = "DD-MMM-YYYY";
      const actualDate = moment(bdate, PATIENT_DOB_FORMAT); // sept 18 2018
      const expectedDate = moment(edobdate, PATIENT_DOB_FORMAT);// oct 15 2018
      const refDate = expectedDate.subtract(40, "weeks"); //january 8, 2018
      const diffWeeks = actualDate.diff(refDate, "days"); // 253
      console.log("??????????????/", bdate, this.eDob)
      const gestationalAge = {
        w: Math.round(Number(diffWeeks / 7)), // 36
        d: diffWeeks % 7 // 1
      };
      this.gestationalAge = gestationalAge;
      console.log("ges", this.gestationalAge)
      var weeks = this.calculateWeek(new Date(dob).getTime(), new Date(this.eDob).getTime());
      if (weeks < 17 && weeks >= 13) {
        this.isPreTerm = 2;
      } else if (weeks < 13 && weeks > 2) {
        this.isPreTerm = 1;
      }
      else {
        this.isPreTerm = 0;
      }
    } catch (Exception) {
      this.gestationalAge = {
        w: 0, // 36
        d: 0 // 1
      };
    }
    this.profileForm.controls['gestationalAge'].setValue(this.gestationalAge.w + "W" + this.gestationalAge.d + "D")
  }
  calculateWeek(d1, d2) {
    return Math.round((d2 - d1) / (7 * 24 * 60 * 60 * 1000));
  }

  calculateAge(endingDate, startingDate) {
    var startDate = new Date(
      new Date(startingDate).toISOString().substr(0, 10)
    );
    if (!endingDate) {
      endingDate = new Date().toISOString().substr(0, 10); // need date in YYYY-MM-DD format
    }
    var endDate = new Date(endingDate);
    if (startDate > endDate) {
      var swap = startDate;
      startDate = endDate;
      endDate = swap;
    }
    var startYear = startDate.getFullYear();
    var february =
      (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0
        ? 29
        : 28;
    var daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    var yearDiff = endDate.getFullYear() - startYear;
    var monthDiff = endDate.getMonth() - startDate.getMonth();
    if (monthDiff < 0) {
      yearDiff--;
      monthDiff += 12;
    }
    var dayDiff = endDate.getDate() - startDate.getDate();
    if (dayDiff < 0) {
      if (monthDiff > 0) {
        monthDiff--;
      } else {
        yearDiff--;
        monthDiff = 11;
      }
      dayDiff += daysInMonth[startDate.getMonth()];
    }
    return [yearDiff, monthDiff, dayDiff];
  }
  reverseDate(date) {
    let d = new Date(date);
    return { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() };
  }

  dropdownSelect(key, value) {
    switch (key) {
      case 'bloodGroup':
        var blood_group = value
       // this.profileForm.controls['blood_group'].setValue(blood_group)
        this.blood_group = value

        break;
      case 'delivery':
        var deliveryType = value
        this.profileForm.controls['deliveryType'].setValue(deliveryType)
        break;
    }

  }

  addAntenetalBirth() {
 
   this.patientData.selectedLanguage=this.selectedLanguage
    // this.patientData.DOB =  this.patientData.DOB;
    // this.patientData.eDOB = moment(this.parseDate(this.eDob)).format("DD-MMM-YYYY");
    // this.patientData.vaccinationStartDate = moment(this.parseDate(this.profileForm.controls.vacDate.value)).format("YYYY-MM-DD");
    // this.patientData.blood_group =this.profileForm.controls.blood_group.value;
    // this.patientData.type_of_delivery = this.profileForm.controls.deliveryType.value;
    // this.patientData.gestationalAge = this.gestationalAge;
    // this.patientData.isHighRisk = this.showHighRisk;
    var additionalInfo = this.additionalInfo && this.additionalInfo != null ? this.additionalInfo : {};
   additionalInfo.newBornScreening = this.profileForm.controls.newBornScreening.value;
   additionalInfo.highRiskType = this.profileForm.controls.highRiskType.value;
   //this.patientData.additionalInfo = JSON.stringify(additionalInfo);
    var age = moment().diff(moment(new Date(this.parseDate(this.patientData.DOB)), 'DD-MMM-YYYY'), 'years');
    // if (Number(age < 12)) {

    //   this.patientData.patientTypes = ['Ba', 'Pe'];
    //   this.patientData.visit = {
    //     weight: this.profileForm.controls.birth_weight.value === '' ? 0 : this.profileForm.controls.birth_weight.value,
    //     height: this.profileForm.controls.birth_height.value === '' ? 0 : this.profileForm.controls.birth_height.value,
    //     hc: this.profileForm.controls.birth_hc.value === '' ? 0 : this.profileForm.controls.birth_hc.value,
    //     cc: this.profileForm.controls.birth_cc.value === '' ? 0 : this.profileForm.controls.birth_cc.value,
    //     bpSys: this.profileForm.controls.birth_bpsys.value === '' ? 0 : this.profileForm.controls.birth_bpsys.value,
    //     bpDia: this.profileForm.controls.birth_bpdia.value === '' ? 0 : this.profileForm.controls.birth_bpdia.value,
    //     addedBy: 'doctor',

    //   }
    // }

    const data:any = {
      eDOB:moment(this.parseDate(this.profileForm.controls.eDob.value)).format("YYYY-MM-DD"),
      vaccinationStartDate:moment(this.parseDate(this.profileForm.controls.vacDate.value)).format("YYYY-MM-DD"),
      blood_group:this.profileForm.controls.blood_group.value,
      type_of_delivery:this.profileForm.controls.deliveryType.value,
      gestationalAge:this.gestationalAge,
      isHighRisk:this.showHighRisk,
      additionalInfo:JSON.stringify(additionalInfo)

      // patient: this.patientData,
      // patientCreatedAt: this.patientData.createdAt
    }
    if (Number(age < 12)) {

    data.patientTypes = ['Ba', 'Pe'];
   data.visit = {
        weight: this.profileForm.controls.birth_weight.value === '' ? 0 : this.profileForm.controls.birth_weight.value,
        height: this.profileForm.controls.birth_height.value === '' ? 0 : this.profileForm.controls.birth_height.value,
        hc: this.profileForm.controls.birth_hc.value === '' ? 0 : this.profileForm.controls.birth_hc.value,
        cc: this.profileForm.controls.birth_cc.value === '' ? 0 : this.profileForm.controls.birth_cc.value,
        bpSys: this.profileForm.controls.birth_bpsys.value === '' ? 0 : this.profileForm.controls.birth_bpsys.value,
        bpDia: this.profileForm.controls.birth_bpdia.value === '' ? 0 : this.profileForm.controls.birth_bpdia.value,
        addedBy: 'doctor',

      }
    }

    this.patientService.patientpartialupdate(data, this.patientData._id).subscribe(response => {
      if (response) {
        console.log("Antental And Birth History Post Record",data, response)
        // this.toaster.success("Success");
        // this.showLoading = false;
        //this.switchMenu(2);
        this.stepper.next()
        // this.router.navigate(['/doctor/homepage'])
      }
   //this.getPatientInfo();
    })
 
  }

  addHealthProfile() {
   // var additionalInfo:any = {}
  //  this.getPatientInfo();
   var additionalInfo = this.additionalInfo && this.additionalInfo != null ? (this.additionalInfo) : {};
   additionalInfo.geneticDisorders = this.profileForm.controls.geneticDisorders.value;
    additionalInfo.healthHistory = this.profileForm.controls.healthHistory.value;
    additionalInfo.reactionToMeds = this.profileForm.controls.reactions.value;
    const data={
      email:this.profileForm.controls.emailId.value ? this.profileForm.controls.emailId.value:'',
      otherIdNo:this.profileForm.controls.otherId.value,
      allergies:this.profileForm.controls.allergies.value,
      comment:this.profileForm.controls.recurring.value,
      affected_comment:this.profileForm.controls.medicalConditions.value,
      accidentalHistory:this.profileForm.controls.hospitalisation.value,
      additionalInfo:JSON.stringify(additionalInfo),
      HealthProfile:'HealthProfile'
    }
    // this.patientData.selectedLanguage=this.selectedLanguage
    // this.patientData.email = this.profileForm.controls.emailId.value;
    // this.patientData.otherIdNo = this.profileForm.controls.otherId.value;
    // this.patientData.allergies = this.profileForm.controls.allergies.value;
    // this.patientData.comment = this.profileForm.controls.recurring.value;
    // this.patientData.affected_comment = this.profileForm.controls.medicalConditions.value;
    // this.patientData.accidentalHistory = this.profileForm.controls.hospitalisation.value;
  
    // this.patientData.additionalInfo = JSON.stringify(additionalInfo);
    // const data = {
    //   patient: payload,
    //   patientCreatedAt: this.patientData.createdAt
    // }
    this.patientService.patientpartialupdate(data, this.patientData._id).subscribe(response => {
      if (response) {
        console.log("Health Profile Post Record", data,response)
       
        this.stepper.next()
        
      //  setTimeout(() => {
      //   this.getPatientInfo();
      //  }, 1000);
        // this.toaster.success("Success");
        //  this.showLoading = false;
        // this.switchMenu(3);
        //  this.router.navigate(['/doctor/homepage'])
      }
   
    })

    // this.router.navigate(['/doctor/homepage'])
   // 
  }

  addFamilyPrfofileisPediatric() {
   // this.getPatientInfo();
    // this.patientData.mothersName = "";
    // this.patientData.fathersName = "";
    if (this.fathersHeightInFt === 'ft') {
      this.fathersHeightInFt = 'cm'
      this.convertUnitsFather();

    }
    if (this.mothersHeightInFt === 'ft') {
      this.mothersHeightInFt = 'cm'
      this.convertUnitsMother();
    }
    // this.patientData.fathersHeight = this.fathersHeightCm ? this.fathersHeightCm : '0.0',
    //   this.patientData.mothersHeight = this.mothersHeightCm ? this.mothersHeightCm : '0.0',


    //   this.patientData.familyHistory = this.familyMedicalConditions
    //   this.patientData.selectedLanguage=this.selectedLanguage
    var additionalInfo = this.additionalInfo && this.additionalInfo != null ? (this.additionalInfo) : {};
    additionalInfo.familyGeneticDisorders = this.familyGeneticDisorders;
  //  this.patientData.additionalInfo = JSON.stringify(additionalInfo);
    const data = {
      fathersHeight:this.fathersHeightCm ? this.fathersHeightCm : '0.0',
      mothersHeight:this.mothersHeightCm ? this.mothersHeightCm : '0.0',
      familyHistory:this.familyMedicalConditions,
      additionalInfo:JSON.stringify(additionalInfo),
      FamilyPrfofileisPed:'FamilyPrfofileisPed'
      // patient: this.patientData,
      // patientCreatedAt: this.patientData.createdAt
    }
   
    this.patientService.patientpartialupdate(data, this.patientData._id).subscribe(response => {
      if (response) {
        console.log("familyProfilePed", response)
        this.patientObj=this.patientData
        
       
          this.patientData=this.patientData
     

     
        console.log("ggggggTTTT",this.patientData)
        // this.patientData = this.patientData
        // this.toaster.success("Success");

        let obj = {}
        let objData = {}
        obj['id'] = this.patientData.userId;
        obj['phone_number'] = this.patientData.contactNo
        obj['dob'] = this.patientData.DOB
        var gender: any = ""
        if (this.patientData.gender == 'Male') {
          gender = "male"
        }
        else {
          gender = "female"
        }
        obj['gender'] = gender
        obj['first_name'] = this.patientData.firstName
        obj['last_name'] = this.patientData.lastName
        obj['middle_name'] = this.patientData.middleName
        obj['locality']=this.patientData.locality
        obj['profile_photo'] = this.patientData.profilePic ? this.patientData.profilePic : '';
        objData['User'] = { ...obj, Customer: { phone_number: this.patientData.contactNo, spousecontactno: this.patientData.spousecontactno } };
        objData['id'] = this.patientData._id;
        objData['id'] = this.patientData._id;
       

        this.patientNewData = objData
        
        console.log("CCCCCC",this.patientNewData)
        localStorage.setItem("patientData", JSON.stringify(this.patientNewData))
        //this.showLoading = false;
        //   this.switchMenu(2);
        localStorage.setItem('patientData', JSON.stringify(this.patientNewData));
        this.open(this.doneProfileModal, "doneProfileModal");
        // this.closeDialog();
        // this.router.navigate([`/doctor/patients/profileSummary/view`], { queryParams: { patientId: this.patientNewData.id } });
      }
      
    })
    
  }


  addHealthHistory() {
    // this.patientData.selectedLanguage=this.selectedLanguage
    // this.patientData.blood_group = this.blood_group;
    var additionalInfo = this.additionalInfo && this.additionalInfo != null ? (this.additionalInfo) : {};
   additionalInfo.healthHistoryHighlights = this.healthHistoryHighlights;
   additionalInfo.habits = this.habbitsArray;
   // this.patientData.additionalInfo = JSON.stringify(additionalInfo);

    const data = {
      blood_group : this.blood_group,
      additionalInfo:JSON.stringify(additionalInfo),
      HealthHistoryAdult:'HealthHistoryAdult'
      // patient: this.patientData,
      // patientCreatedAt: this.patientData.createdAt
    }

    this.patientService.patientpartialupdate(data, this.patientData._id).subscribe(response => {
      if (response) {
        console.log("HealthHitory", response)
     
        // this.toaster.success("Success");
        // this.showLoading = false;
        // this.switchMenu(2);
        // this.router.navigate(['/doctor/homepage'])
      }
      this.stepper.next();
    })

  }
  goToRx() {


    var localstoredpatient: any = {}
    var objData: any = {}
    // localstoredpatient.UserId=this.patientNewData.UserId
    localstoredpatient.id = this.patientNewData.id
    localstoredpatient['Patient'] = this.patientNewData
    localStorage.setItem('patientData', JSON.stringify(this.patientNewData));

    //add to top of locally save Patients so that new patient appears on top of home page

    let localacessedpatientsstr = localStorage.getItem("localacessedpatients");
    let localacessedpatients = [];
    if (localacessedpatientsstr) {
      localacessedpatients = JSON.parse(localacessedpatientsstr);
    }

    // remove/add current patient on top
    for (var i = 0; i < localacessedpatients.length; i++) {
      if (localacessedpatients[i].Patient.User.id == localstoredpatient.Patient.User.id) {
        localacessedpatients.splice(i, 1);
      }
    }
    //adding back on top of list
    localacessedpatients.unshift(localstoredpatient);
    localStorage.setItem("localacessedpatients", JSON.stringify(localacessedpatients));


    localStorage.setItem('patientData', JSON.stringify(this.patientNewData));
    if(this.rxUi=='NewRxUI'){
      this.router.navigate([`/newrx/rxHomePage`], { queryParams: { patientId: this.patientNewData.id } });
    }
    else{
      this.router.navigate([`/doctor/patients/quickprescription/view`], { queryParams: { patientId: this.patientNewData.id } });
    }
   
   // 
  


    setTimeout(() => {
      this.refreshservice.OnPatTopNav_MenuSelected(this.patientNewData.id, this.refreshservice.EventName_Rx)
    }, 500)
    this.closeDialog();
  }
  uploadDocument() {


    localStorage.setItem('patientData', JSON.stringify(this.patientNewData));
    this.router.navigate([`/doctor/patients/profileSummary/view`], { queryParams: { patientId: this.patientNewData.id, uploadDocument: true } });

    this.closeDialog()
    // setTimeout(()=>{
    //   this.refreshservice.OnPatTopNav_MenuSelected(this.patientNewData.id,this.refreshservice.EventName_Upload)
    // },500)
  }

  nextTabHomePage() {
    // this.onSubmitButton()

    var localstoredpatient: any = {}
    var objData: any = {}



    localstoredpatient['Patient'] = this.patientNewData
    localStorage.setItem('patientData', JSON.stringify(this.patientNewData));
    console.log("TTTTTTTTTTT",localstoredpatient)

    //add to top of locally save Patients so that new patient appears on top of home page
    let localacessedpatientsstr = localStorage.getItem("localacessedpatients");
  
    let localacessedpatients = [];
    if (localacessedpatientsstr) {
      localacessedpatients = JSON.parse(localacessedpatientsstr);
    }

    // remove/add current patient on top
    for (var i = 0; i < localacessedpatients.length; i++) {
      if (localacessedpatients[i].Patient.User.id == localstoredpatient.Patient.User.id) {
        localacessedpatients.splice(i, 1);
      }
    }
    //adding back on top of list
    localacessedpatients.unshift(localstoredpatient);
    localStorage.setItem("localacessedpatients", JSON.stringify(localacessedpatients));
    this.refreshservice.profileRefershed();

    this.router.navigate([`/doctor/patients/profileSummary/view`], { queryParams: { patientId: this.patientNewData.id } });
    this.refreshservice.appointrefreshed();
    this.closeDialog();
    this.modalService.dismissAll();
    
  }
  createAppointment() {

    localStorage.setItem('patientData', JSON.stringify(this.patientNewData));
    //this.patientName=this.patientResponse.first_name+" "+this.patientResponse.middle_name+" "+this.patientResponse.last_name
    if (this.isAppointmentEnabled == '1') {
      this.closeDialog();
      this.router.navigate(['/doctor/homepage/'], { queryParams: { patientId: this.patientNewData.id } });

    }
    else if (this.isAppointmentEnabled == '2') {
      this.closeDialog();
      this.router.navigate(['/doctor/homepage/'], { queryParams: { patientidfornewQueue: this.patientNewData.id, queueFlag: true } });

    }
    else {

    }

  }



  saveModalValues(type: string) {

    switch (type) {
      case 'medicalConditions':
        this.medicalConditionArray.map(ins => {
          if (ins.selected && !this.medicalConditions.includes(ins.name)) {
            this.medicalConditions = this.medicalConditions.length ? this.medicalConditions + ', ' + ins.name : ins.name

            this.profileForm.controls['medicalConditions'].setValue(this.medicalConditions)

            console.log(">>>>>>>>", this.medicalConditions);
          }
        });
        break;

      case 'familyMedicalConditions':
        this.familyMedicalArray.map(ins => {
          if (ins.selected && !this.familyMedicalConditions.includes(ins.name)) {
            this.familyMedicalConditions = this.familyMedicalConditions.length ? this.familyMedicalConditions + ', ' + ins.name : ins.name
            this.profileForm.controls['familyMedicalConditions'].setValue(this.familyMedicalConditions)
          }
        });
        break;
      case 'geneticDisorders':

        this.geneticDisordersArray.map(ins => {
          if (ins.selected && !this.geneticDisorders.includes(ins.name)) {
            this.geneticDisorders = this.geneticDisorders.length ? this.geneticDisorders + ', ' + ins.name : ins.name
            this.profileForm.controls['geneticDisorders'].setValue(this.geneticDisorders)
          }
        });
        break;
      case 'familyGeneticDisorders':
        this.familyGeneticDisordersArray.map(ins => {
          if (ins.selected && !this.familyGeneticDisorders.includes(ins.name)) {
            this.familyGeneticDisorders = this.familyGeneticDisorders.length ? this.familyGeneticDisorders + ', ' + ins.name : ins.name
            this.profileForm.controls['familyGeneticDisorders'].setValue(this.familyGeneticDisorders)

          }
        });
        break;
      case 'highRiskType':
       // this.highRiskType;
     
        // this.newHighRisksCategoriesForBabies.data.map(ins => {
        //   if(this.highRiskType==undefined){
        //     this.highRiskType = ins.name 
        //     this.profileForm.controls['highRiskType'].setValue(this.highRiskType)
        //   }
        //   else{

         
          if (this.highRiskTypeBabies.length) {
            var highRiskType=this.highRiskTypeBabies.join(",")
            highRiskType= highRiskType.replace(/^,|,$/g, '');
            this.profileForm.controls['highRiskType'].setValue(highRiskType)
          }
     //   }
       // });
        break;


      default:
        break;
    }


  }


  addFamilyProfilePed() {
    this.patientData.selectedLanguage=this.selectedLanguage
  //  this.patientData.familyHistory = this.profileForm.controls.familyMedicalConditions.value
  
    var additionalInfo = this.additionalInfo && this.additionalInfo != null ? (this.additionalInfo) : {};
    additionalInfo.familyGeneticDisorders = this.profileForm.controls.familyGeneticDisorders.value;
 //   this.patientData.additionalInfo = JSON.stringify(additionalInfo);
    const data = {
      familyHistory:this.profileForm.controls.familyMedicalConditions.value,
      additionalInfo:JSON.stringify(additionalInfo),
      FamilyPrfofileisPed:'FamilyPrfofileisPed'
      // patient: this.patientData,
      // patientCreatedAt: this.patientData.createdAt
    }
    console.log("familyProfile", data);
    this.patientService.patientpartialupdate(data, this.patientData._id).subscribe(response => {
      // if (response) {
       console.log("familyProfile", this.patientResponse)
        this.patientObj=this.patientData
      
     //  this.patientData=this.patientData
     //   this.patientData = response
     //   this.patientObj._id
        // this.toaster.success("Success");
        // this.showLoading = false;


        let obj = {}
        let objData = {}
        obj['id'] = this.patientData.userId;
        obj['phone_number'] = this.patientData.contactNo
        obj['dob'] = this.patientData.DOB
        var gender: any = ""
        if (this.patientData.gender == 'Male') {
          gender = "male"
        }
        else {
          gender = "female"
        }
        obj['gender'] = gender
        obj['first_name'] = this.patientData.firstName
        obj['last_name'] = this.patientData.lastName
        obj['middle_name'] = this.patientData.middleName
        obj['locality']=this.patientData.locality
        obj['profile_photo'] = this.patientData.profilePic ? this.patientData.profilePic : '';
        objData['User'] = { ...obj, Customer: { phone_number: this.patientData.contactNo, spousecontactno: this.patientData.spousecontactno } };
        objData['id'] = this.patientData._id;
        objData['id'] = this.patientData._id;

        this.patientNewData = objData
        localStorage.setItem("patientData", JSON.stringify(this.patientNewData))
        //   this.switchMenu(2);
        localStorage.setItem('patientData', JSON.stringify(this.patientNewData));
        this.open(this.doneProfileModal, "doneProfileModal");
        // this.router.navigate(['/doctor/homepage'])
     // }
    })

  }


  getPatientInfo() {
    this.route.queryParams.subscribe(params => {
    this.paramPatienId = params.patientId;
    })
    console.log("patientData6666",this.paramPatienId);
    var patientID = this.paramPatienId
    console.log("TTTTTTTTT",this.patientObj.id ? this.patientObj.id : patientID)
    this.patientService.getPatientInfo(this.patientObj.id ? this.patientObj.id : patientID).subscribe(response => {
      if (response) {
        this.errors["ageYears"]=""
        var addtionalInfo: any = null
        // var allPatientInfo: any = localStorage.getItem("allPatientInfo");
        // allPatientInfo = JSON.parse(allPatientInfo)
        console.log("allPatient", response)
        localStorage.setItem("patientOnBoardData", JSON.stringify(response))
        this.patientResponse = response;
        this.patientData = this.patientResponse;
        this.patientAddress=response.address ? response.address :"";
        this.patientLocality=response.locality ? response.locality :"";
        this.patientCity=response.city ? response.city :"";
        this.patientPincode=response.pincode ?response.pincode:""
        //this.profileImageofpatient = response.profilePic
        this.blood_group=response.blood_group
        this.profileForm.controls['firstname'].setValue(response.firstName);
        this.profileForm.controls['middlename'].setValue(response.middleName);
        this.profileForm.controls['lastname'].setValue(response.lastName);
        this.profileForm.controls['patientAddress'].setValue(response.address)
        this.profileForm.controls['patientLocality'].setValue(response.locality)
        this.profileForm.controls['patientCity'].setValue(response.city)
        this.profileForm.controls['patientPincode'].setValue(response.pincode)
        this.patientId = response._id;
       
        this.selectGender = response.gender;
        if (this.selectGender == "Male") {
          this.genderArray.map(ins => {
            if (ins.name == "Male") {
              ins.selected = true;
            }
            else {
              ins.selected = false;

            }
          })
        }
        if (this.selectGender == "Female") {
          this.genderArray.map(ins => {
            if (ins.name == "Female") {
              ins.selected = true;
            }
            else {
              ins.selected = false;

            }
          })
        }
      
        // if(this.isPediatric==false){
        //   console.log("ttttttttMMMM",response.blood_group)
        //   this.blood_group=response.blood_group
          
        // }
        // else{
          // console.log("tehhdfhhhhhh",response.blood_group)
        
          
     //  this.profileForm.controls['blood_group'].setValue(response.blood_group);
       //  }
      var selectedLanguage = response.parent.selectedLanguage;
        this.languageArray.map(ins=>{
          if(ins.language==selectedLanguage){
            this.patientSelectedLang=ins.name 
          }
          else{
            this.patientSelectedLang="English"
          }

        })

        console.log("Dec19",this.patientSelectedLang)
        this.dob = this.reverseDate(response.DOB);
        var dobDate = moment(this.parseDate(this.dob)).format("DD/MM/YYYY")
        this.profileForm.controls['dob'].setValue(new Date(dobDate))
        this.profileForm.controls['dateOfBirth'].setValue((dobDate))
        this.eDob = this.reverseDate(response.eDOB);
        this.eDob = moment(this.parseDate(this.eDob)).format("DD/MM/YYYY")
      //  this.profileForm.controls['eDob'].setValue(new Date(this.eDob))
        this.vacDate = this.reverseDate(response.vaccinationStartDate);
        var vacDate = moment(this.parseDate(this.vacDate)).format("DD/MM/YYYY")
        this.profileForm.controls['vacDate'].setValue(new Date(vacDate))
        // if(Object.keys(response.gestationalAge).length){
        //   this.profileForm.controls['gestationalAge'].setValue(response.gestationalAge.w+"W" +response.gestationalAge.d+"D")
        // }
        this.profileForm.controls['gestationalAge'].setValue(response.gestationalAge.w + "W" + response.gestationalAge.d + "D")

        this.ageYears = response.age.years;
        this.ageMonths = response.age.months;
        this.ageWeeks = response.age.days;
        this.mobileNumber = response["contactNo"].replace("91", "");
        this.profileForm.controls['mobileNumber'].setValue(this.mobileNumber)
          var secondNumber=response.spousecontactno
        if(secondNumber.length!=14){
          console.log("gggggg",response.spousecontactno)
          this.profileForm.controls['secondNumber'].setValue("")

        }
        else{
         // this.secondNumber = response["spousecontactno"].replace("+91", "");
         console.log("secccc",secondNumber  )
         var secondMobileNUmber=secondNumber
          if (secondMobileNUmber.includes(+91)) {
            console.log("trueeeeees")
          var secondNo = secondMobileNUmber.substring(4)
            this.profileForm.controls['secondNumber'].setValue(secondNo)
          }
       
        }
        
        this.profileForm.controls['emailId'].setValue(response.parent.email);
        // this.relation = response.relationWithCustomer;

        this.profileForm.controls['hospitalisation'].setValue(response.accidentalHistory)
        this.profileForm.controls['deliveryType'].setValue(response.type_of_delivery)
   
        this.profileForm.controls['blood_group'].setValue(response.blood_group)
     
      
        // this.selectedFormValue.fathersName = response.parent.fathersName;
        // this.selectedFormValue.mothersName = response.parent.mothersName;
        this.isHighRisk = response.isHighRisk;
        if (this.isHighRisk == true) {
          //  this.checkedValue = true;
          this.profileForm.controls['highRisk'].setValue("Yes")
          this.showHighRisk = true;
          this.highRiskType = ""
        }
        else {
          // this.checkedValue = false;
          this.showHighRisk = false;
          this.profileForm.controls['highRisk'].setValue("No")
          this.highRiskType = ""
        }


        this.profileForm.controls['otherId'].setValue(response.otherIdNo);
        this.fathersHeightCm = response.fathersHeight;
        this.mothersHeightCm = response.mothersHeight;
        // this.showOtherInformation = true;
        this.fathersHeightInFt = 'ft';
        this.mothersHeightInFt = 'ft';
        this.isPediatric = response.patientTypes.length === 2 ? true : false;
        // this.isBabyOf = response.babyOf;
        // this.newBornScreening = response.newBornScreening;
        this.profileForm.controls['medicalConditions'].setValue(this.medicalConditions)
        this.medicalConditions = response.affected_comment ? response.affected_comment : '';
        this.profileForm.controls['medicalConditions'].setValue(this.medicalConditions)
        this.familyMedicalConditions = response.familyHistory ? response.familyHistory : '';
        this.profileForm.controls['familyMedicalConditions'].setValue(this.familyMedicalConditions)
        this.profileForm.controls['allergies'].setValue(response.allergies)
        this.profileForm.controls['recurring'].setValue(response.comment)
        this.profileForm.controls['hospitalisation'].setValue(response.accidentalHistory)
        addtionalInfo = response.additionalInfo ? JSON.parse(response.additionalInfo) : {}
        this.additionalInfo=addtionalInfo
        if (addtionalInfo.habits == null) {
          this.habbitsArray = this.habbitsArray
        }
        else {
          this.habbitsArray = addtionalInfo.habits;
        }



        // this.newBornScreening = this.selectedFormValue.additionalInfo.newBornScreening;
        this.highRiskType =  addtionalInfo.highRiskType ? addtionalInfo.highRiskType:""
        this.highRiskType=  this.highRiskType.replace(/^,|,$/g, '');
        this.profileForm.controls['highRiskType'].setValue(this.highRiskType)
        this.highRiskTypeBabies.push(this.highRiskType)

        this.newBornScreening = addtionalInfo.newBornScreening
        if (this.newBornScreening == 'Done') {
          this.shownewBornscreen = true;
          // this.heelPrickTest = true;

          this.profileForm.controls['newBornScreening'].setValue(this.newBornScreening)
          this.uploadTestValue = 'Upload heel prick test documents from upload document section'

        }
        else {
          // this.heelPrickTest = false;
          this.shownewBornscreen = false;
          this.profileForm.controls['newBornScreening'].setValue("No")
        }

        this.geneticDisorders = addtionalInfo.geneticDisorders ? addtionalInfo.geneticDisorders : '';
        this.profileForm.controls['geneticDisorders'].setValue(this.geneticDisorders)
        this.familyGeneticDisorders = addtionalInfo.familyGeneticDisorders ? addtionalInfo.familyGeneticDisorders : ''
        this.profileForm.controls['familyGeneticDisorders'].setValue(this.familyGeneticDisorders)
        this.healthHistoryHighlights = (addtionalInfo.healthHistoryHighlights)
        this.profileForm.controls['healthHistory'].setValue(addtionalInfo.healthHistory ? addtionalInfo.healthHistory : '')
        this.profileForm.controls['reactions'].setValue(addtionalInfo.reactionToMeds ? addtionalInfo.reactionToMeds : '')
        if (!this.checkIfTabsArrayContains("Health History")) {
          console.log("1234");
          this.removeTabFromCollection("Antenetal & Birth History");
          this.steps.splice(1, 0, { name: "Health History", selected: false, value: "", highlighted: false })
        }
   

        if (this.isPediatric) {
          this.profileForm.controls['birth_weight'].setValue(response.visits[0]["weight"])
          this.profileForm.controls['birth_height'].setValue(response.visits[0]["height"])
          this.profileForm.controls['birth_hc'].setValue(response.visits[0]["hc"])
          this.profileForm.controls['birth_cc'].setValue(response.visits[0]["cc"])
          this.profileForm.controls['birth_bpsys'].setValue(response.visits[0]["bpSys"])
          this.profileForm.controls['birth_bpdia'].setValue(response.visits[0]["bpDia"])
        }
        this.convertUnitsFather();
        this.convertUnitsMother();
      }
    })

    localStorage.removeItem("patientHistory")
  }


  validateNumber(e) {
    return ((e.charCode > 47  && e.charCode < 58)) || (e.which == 8)||(e.which == 46) || (e.which == 13) || (e.keyCode == 9) || (e.keyCode == 190) || (e.keyCode == 110)



  }

  sentParentOTP() {
    this.stdCode = this.profileForm.controls.countryCode.value
    this.mobileNumber = this.profileForm.controls.mobileNumber.value
    const data = { contactNo: this.profileForm.controls.countryCode.value + this.profileForm.controls.mobileNumber.value };

    this.patientService.sentParentOTP(data).subscribe(response => {
      if (response) {
        console.log(">>>>>>>>>>>>>>>", response);
        this.recievedOtp = response.otp;
      }
    })
  }
  confirmOTP() {

    this.isOTPConfirmed = true;
    this.isOTPConfirmedForSecondno = true
  }

  checkBoxValueChnage(e) {
    if (e == true) {
      this.showHighRisk = true;
      this.isHighRisk = true
      this.profileForm.controls['highRisk'].setValue("Yes")
      this.highRiskType = ""
    }
    else {
      this.showHighRisk = false;
      this.isHighRisk = false
      this.profileForm.controls['highRisk'].setValue("No")
      this.highRiskType = ""
    }

  }


  checkHeelPrickTest(e) {
    if (e == true) {

      this.newBornScreening = 1
      // this.checkValueHeelPrickTest = "Done"
      this.profileForm.controls['newBornScreening'].setValue("Done")
      this.uploadTestValue = 'Upload heel prick test documents from upload document section'

    }
    else {
      this.newBornScreening = 0
      this.profileForm.controls['newBornScreening'].setValue("No")
      this.uploadTestValue = ""

    }

  }
  modifyVitals(){
    this.modalService.dismissAll();
  }
  getvaccDate() {
    var dobDate = moment(this.parseDate(this.dob)).format("DD-MMM-YYYY");
    this.vacDate = moment(this.parseDate(this.vacDate)).format("YYYY-MM-DD");
   // this.onSubmitButton();
  }

  goToAddVitals() {
    if(this.patientResponse && this.patientResponse.visits ){
      this.visits = this.patientResponse.visits;
   

    this.visits.map((element, index) => {
      if (element._id && element._id === "synthetic_record") {
      console.log("log in synthetic_record3");

        this.visits.splice(index, 1);
      }

    
    });
    }
    var localstoredpatient: any = {}
    var objData: any = {}
    
console.log("this.patientResponse in goToVitals",this.patientResponse);
if(this.patientResponse && this.patientResponse.visits && this.patientResponse.visits.length>0){
  var lastVisitId = this.patientResponse.visits[this.patientResponse.visits.length-1]._id;
this.refreshservice.setFlag(lastVisitId)

}

    localstoredpatient['Patient'] = this.patientNewData
    localStorage.setItem('patientData', JSON.stringify(this.patientNewData));
    console.log("TTTTTTTTTTT",localstoredpatient)

    //add to top of locally save Patients so that new patient appears on top of home page
    let localacessedpatientsstr = localStorage.getItem("localacessedpatients");
  
    let localacessedpatients = [];
    if (localacessedpatientsstr) {
      localacessedpatients = JSON.parse(localacessedpatientsstr);
    }

    // remove/add current patient on top
    for (var i = 0; i < localacessedpatients.length; i++) {
      if (localacessedpatients[i].Patient.User.id == localstoredpatient.Patient.User.id) {
        localacessedpatients.splice(i, 1);
      }
    }
    //adding back on top of list
    localacessedpatients.unshift(localstoredpatient);
    localStorage.setItem("localacessedpatients", JSON.stringify(localacessedpatients));

      localStorage.setItem('patientDataForVitals', JSON.stringify(this.patientData));
      this.refreshservice.appointrefreshed();
      this.router.navigate([`/doctor/patients/profileSummary/view`], { queryParams: { patientId: this.patientData._id,visit:true } });
     
      this.closeDialog()
      this.modalService.dismissAll();

  }



  convertUnitsFather() {
    if (this.fathersHeightInFt === 'cm' && this.fathersHeightFt) {
      var fatherCmHeight = 0.0;
      if (this.fathersHeightFt.ft != '') {
        if (Number(this.fathersHeightFt.ft) == 0) {
          fatherCmHeight = Math.round((Number(this.fathersHeightFt.in) * 2.54 * 100)) / 100;
          this.fathersHeightCm = fatherCmHeight.toString();
        } else {
          fatherCmHeight = Math.round(((((Number(this.fathersHeightFt.ft) * 12) + Number(this.fathersHeightFt.in)) * 2.54) * 100)) / 100;
          this.fathersHeightCm = fatherCmHeight.toString();
        }

      }
      else {
        fatherCmHeight = Math.round((Number(this.fathersHeightFt.in) * 2.54 * 100)) / 100;
        this.fathersHeightCm = fatherCmHeight.toString();
      }
    }
    else {
      if (this.fathersHeightCm != '') {


        if (Number(this.fathersHeightCm) < 31) {
          var fatherInHeight = 0.0;
          fatherInHeight = Math.round(Number(this.fathersHeightCm) / 2.54 * 10) / 10;
          this.fathersHeightFt = { ft: '0', in: fatherInHeight.toString() };
        }
        else {
          var divideby2 = 0;
          var divideby12 = 0;
          var divideby12Mod = 0;
          divideby2 = Math.round(Number(this.fathersHeightCm) / 2.54 * 10) / 10;
          divideby12Mod = Math.round(divideby2 % 12 * 10) / 10;
          divideby12 = Math.trunc(divideby2 / 12);

          this.fathersHeightFt = { ft: divideby12.toString(), in: divideby12Mod.toString() };
        }
      }

    }

  }
  convertUnitsMother() {
    if (this.mothersHeightInFt === 'cm' && this.mothersHeightFt) {
      var motherCmHeight = 0.0;
      if (this.mothersHeightFt.ft != '') {
        if (Number(this.mothersHeightFt.ft) == 0) {
          motherCmHeight = Math.round((Number(this.mothersHeightFt.in) * 2.54 * 100)) / 100;
          this.mothersHeightCm = motherCmHeight.toString();
        } else {
          motherCmHeight = Math.round(((((Number(this.mothersHeightFt.ft) * 12) + Number(this.mothersHeightFt.in)) * 2.54) * 100)) / 100;
          this.mothersHeightCm = motherCmHeight.toString();
        }
      }
      else {
        motherCmHeight = Math.round((Number(this.mothersHeightFt.in) * 2.54 * 100)) / 100;
        this.mothersHeightCm = motherCmHeight.toString();
      }
    }
    else {
      if (this.mothersHeightCm != '') {


        if (Number(this.mothersHeightCm) < 31) {
          var motherInHeight = 0.0;
          motherInHeight = Math.round(Number(this.mothersHeightCm) / 2.54 * 10) / 10;
          this.mothersHeightFt = { ft: '0', in: motherInHeight.toString() }
        }
        else {
          var divideby2 = 0;
          var divideby12 = 0;
          var divideby12Mod = 0;
          divideby2 = Math.round(Number(this.mothersHeightCm) / 2.54 * 10) / 10;
          divideby12Mod = Math.round(divideby2 % 12 * 10) / 10;
          divideby12 = Math.trunc(divideby2 / 12);

          this.mothersHeightFt = { ft: divideby12.toString(), in: divideby12Mod.toString() }
        }
      }

    }
  }


  onDropdownSelectLanguage(key, value) {
   
    let lang = "";

    switch (value) {
      case 'English':
        lang = "en"
        break;

      case 'Hindi':
        lang = "hi"
        break;

      case 'Marathi':
        lang = "mr"
        break;

      case 'Kannada':
        lang = "kn"
        break;

      default:
        break;
    }

    this.selectedLanguage = lang ;
    console.log("lang", this.selectedLanguage)

  }

  selectmedicalConditions(item){
    var medConditions= this.profileForm.controls.medicalConditions.value;
    if(medConditions){
      this.profileForm.controls['medicalConditions'].setValue(medConditions+","+" "+item.name)
    }
    else{
      this.profileForm.controls['medicalConditions'].setValue(item.name)
    }
  
  }
  selectgeneticDisorders(item){
    var geneticDisorders= this.profileForm.controls.geneticDisorders.value;
    if(geneticDisorders){
      this.profileForm.controls['geneticDisorders'].setValue(geneticDisorders+","+" "+item.name)
    }
    else{
      this.profileForm.controls['geneticDisorders'].setValue(item.name)
    }

  }

  backToProfile(){
    this.stepper.previous();
  }
  backaddHealthProfile(){
    this.stepper.previous();
  }
  backaddFamilyProfilePed(){
    this.stepper.previous();
  }
  backaddFamilyPrfofileisPediatric(){
    this.stepper.previous();
  }
  backaddHealthHistory(){
    this.stepper.previous();
  }

  selectHighRiskValue(item,index1,index2){
 
    if (this.newHighRisksCategoriesForBabies[index1].data[index2].selected == true) {

      this.newHighRisksCategoriesForBabies[index1].data[index2].selected = false
      this.highRiskTypeBabies.map(ins=>{
        if(ins==item.name){
          var itemList = this.highRiskTypeBabies.indexOf(ins);
            this.highRiskTypeBabies.splice(itemList, 1)
        }
      })
  
    }
    else {
      this.newHighRisksCategoriesForBabies[index1].data[index2].selected = true;
      //this.selectedOnExamination.push(item.examination)
      this.highRiskTypeBabies.push(item.name)
 
    }
  }
  onSearchChange(searchValue: string): void {
   
  


      let newTestArr = this.jsonObj;
      if (searchValue == '') {
        this.newHighRisksCategoriesForBabies = newTestArr;
      } else {
        var testArr = [];
        for (let index = 0; index < newTestArr.length; index++) {
          var element = newTestArr[index];
          if (element.section.toLowerCase().includes(searchValue.toLowerCase())) {
            testArr.push(element);
          }
          var newArr = []
          for (let j = 0; j < element.data.length; j++) {
            const test = element.data[j];
            if (test.name.toLowerCase().includes(searchValue.toLowerCase())) {
              newArr.push(test);
            }
          }
          if (newArr.length) {
            element.data = newArr
            testArr.push(element);
          }
        }
        testArr = testArr.filter(function (item, pos, self) {
          return self.indexOf(item) == pos;
        });

        this.newHighRisksCategoriesForBabies = testArr;

      }

    
    
  }

 
}

import { Component, OnInit, ViewChild, Input, TemplateRef, ElementRef, EventEmitter, ChangeDetectorRef, Output } from '@angular/core';
import { NavigationServiceService } from '../../services/navigation-service.service';
import { PatientService } from '../../services/patient.service';
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Observable, observable } from 'rxjs';
import { DateAdapter, MatDateFormats, MatOption, MAT_DATE_FORMATS, NativeDateAdapter } from '@angular/material/core';
import { debounceTime, distinctUntilChanged, map, filter, pairwise, startWith } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { AuthService } from '../../services/auth.service';
import { UtilService } from '../../util/utilService';
import { DoctorService } from '../../services/doctor.service';
import { refreshService } from '../../services/refersh.service';
import { LanguageSelectTextboxComponent } from '../../components/language-select-textbox/language-select-textbox.component';
import { MatDialog } from '@angular/material/dialog';
import { MessageService } from 'primeng/api';
import { DomSanitizer } from '@angular/platform-browser';
import { E } from '@angular/cdk/keycodes';
import { MatAccordion } from '@angular/material/expansion';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { debounce } from 'lodash';
export class AppDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: Object): string {
    if (displayFormat === 'input') {
      let day: string = date.getDate().toString();
      day = +day < 10 ? '0' + day : day;
      let month: string = (date.getMonth() + 1).toString();
      month = +month < 10 ? '0' + month : month;
      let year = date.getFullYear();
      return `${day}/${month}/${year}`;
    }
    return date.toDateString();
  }
}

export const APP_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'numeric' },
    dateA11yLabel: {
      year: 'numeric', month: 'long', day: 'numeric'
    },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  }
};
let printingFieldsArray = ["showVitals", "showComplaints", "showOnExamination", "showDiagnosis", "showPrescription", "showGeneralIntructions", "showDiet", "showNextFollowup"]
// let printingVitalArray = ["showWeight", "showHeight", "showBP", "showPulse", "showTemp", "showSPO2", "showLmp", "showHaemoglobin", "showBloodSugar(R)", "showBloodSugar(F)", "showBloodSugar(PP)", "showBlood(Hb1ac)",
//   "showHeartrate"];
let selectFields = [{ name: "Vitals", selected: true, value: "showVitals" }, { name: "OnExamination", selected: true, value: "showOnExamination" }, { name: "Complaints", selected: true, value: "showComplaints" }, { name: "Diagnosis", selected: true, value: "showDiagnosis" }, { name: "Prescription", selected: true, value: "showPrescription" }, { name: "General Intructions", selected: true, value: "showGeneralIntructions" }, { name: "Dietary Advice", selected: true, value: "showDiet" }, { name: "Next Followup", selected: true, value: "showNextFollowup" }];
let selectedVitalsFields = [{ name: "weight", selected: true, value: "showWeight" }, { name: "height", selected: true, value: "showHeight" }, { name: "BP", selected: true, value: "showBP" },
{ name: "Pulse", selected: true, value: "showPulse" }, { name: "Temp", selected: true, value: "showTemp" }, { name: "LMP", selected: true, value: "showLmp" }, { name: "Haemoglobin", selected: true, value: "showHaemoglobin" },
{ name: "Blood Sugar (R)", selected: true, value: "showBloodSugar(R)" }, { name: "Blood Sugar (F)", selected: true, value: "showBloodSugar(F)" }, { name: "Blood Sugar (PP)", selected: true, value: "showBloodSugar(PP)" },
{ name: "Blood (Hb1ac)", selected: true, value: "showBlood(Hb1ac)" }, { name: "Cholesterol (HDL)", selected: false, value: "showCholesterol(HDL)" }, { name: "Cholestrol (LDL) (mg/dL)", selected: false, value: "showCholestrol(LDL)(mg/dL)" },
{ name: "Total Cholesterol (mg/dL)", selected: false, value: "showTotalCholesterol(mg/dL)" }, { name: "Tryglycerides (mg/dL)", selected: false, value: "showTryglycerides(mg/dL)" },
{ name: "Heartrate", selected: true, value: "showHeartrate" }]
let drugUnitTypes = ["Unit", "Fingertip", "Fingertip,Apply-Locally", "Drop", "ml", "Puff", "Sachet", "Scoop", "Bottle", "Strip", "Spoon", "Device"]
let frequencyWithVal = [{ name: "Morning", value: '' }, { name: "Afternoon", value: '' }, { name: "Evening", value: '' }, { name: "Night", value: '' }, { name: "Once a week", value: '' }, { name: "Once a month", value: '' }, { name: "Anytime", value: '' }, { name: "If Required", value: '' }];
let drugTypeSelection = ["Tablet", "Syrup", "Liquid", "Suspension", "Drop", "Capsule", "Cream", "Gel", "Jelly", "Lotion", "Oil", "Ointment", "Powder", "Sachet", "Granules", "Inhaler", "Spray", "Respule", "Injection", "Vial", "Soap", "Suppository"];
let whenToTake = ["-", "Before food", "After food", "With food", "With Milk", "With fruit juice", "At bed time", "Early morning", "Empty stomach", "If required", "As needed"];
let duration = ['-', '1 Day(s)', '2 Day(s)', '3 Day(s)', '4 Day(s)', '5 Day(s)', '6 Day(s)', '7 Day(s)', '8 Day(s)', '9 Day(s)', '10 Day(s)', '11 Day(s)', '12 Day(s)', '13 Day(s)', '14 Day(s)', '15 Day(s)', '1 Week(s)', '2 Week(s)', '3 Week(s)', '1 Month', '2 Months', '3 Months', 'Until Finished', "Until cured"];
let units = [0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15];
let frequency = ["-", "Morning", "Afternoon", "Evening", "Night", "Once a week", "Once a month", "Anytime", "If Required"];
@Component({
  selector: 'app-new-quick-rx',
  templateUrl: './new-quick-rx.component.html',
  styleUrls: ['./new-quick-rx.component.scss'],
  providers: [MessageService,
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }],
  // animations: [
  //   trigger('fadeInOut', [
  //     state('void', style({ opacity: 0 })),
  //     transition('void <=> *', animate(500)),
  //   ]),
  // ]
  // animations: [
  //   trigger('slideInOut', [
  //     state('void', style({ transform: 'translateX(-100%)' })),
  //     state('*', style({ transform: 'translateX(0)' })),
  //     transition('void => *', animate('500ms ease-in-out')),
  //     transition('* => void', animate('500ms ease-in-out')),
  //   ]),
  // ],

  //aaleenm
  animations: [
    trigger('slideIn', [
      // state('void', style({ transform: 'translateX(-100%)' })),
      // transition('void => *', animate('500ms ease-in-out')),
      state('false', style({ transform: 'translateX(-100%)' })),
      state('true', style({ transform: 'translateX(0)' })),
      transition('false => true', animate('500ms ease-in-out')),
      transition('true => false', animate('500ms ease-in-out')),
    ]),
  ],
})




export class NewQuickRxComponent implements OnInit {
  printingVitalArray: any;
  selectedVitalsField: any;
  printingVitals: any;
  saveButtonFlag:boolean;
  savePreviewButtonFlag :boolean;
  public slideInStart = false;

  vaccinesLoading = true;

  appointmentObjPurposeVisit: any = []
  prescriptionPreviewArray: any = []
  finalSelectedComplaints = []
  panelOpenState: boolean = false;
  @ViewChild(MatAccordion) testing: MatAccordion;
  pendingVaccination: any = [];
  vaccineList: any = [];
  upcomingVaccineList: any = [];
  displayedColumns: string[] = ['visit', 'vaccine', 'recommendedDate', 'catchupDate'];
  selectedVaccination: any = [];
  vaccinationFlag: boolean = false;
  vacDate: any
  complaintsObj: any = [];
  @Input() public set selectedGeneralInstructions(input: string) {
    if (input) {
      setTimeout(() => {

        this.lngMedInstr.setTextBoxValue(input)
      }, 500)
    }
  }
  ageObj: any = {};
  fistNameObj: any = {};

  ageObjFromTopRx: EventEmitter<any> = new EventEmitter<any>();
  fnameObjFromTopRx: EventEmitter<any> = new EventEmitter<any>();

  selectedVisitdate: any;
  frequency = frequency
  prescriptionPdfURL: any
  whenToTake = whenToTake;
  duration = duration;
  prescriptionreportsURL: any
  lmpDate: any

  showLoading: boolean = false;
  sectionLoading: boolean = false;
  searchMed;
  patientSelectedLanguage: any = "En"
  @ViewChild("incomplete_data_modal") incomplete_data_modal: TemplateRef<any>;
  @ViewChild("followup_modal") followup_modal;

  @ViewChild("add_new_drug_modal") add_new_drug_modal: TemplateRef<any>;
  @ViewChild("langFollowupMessage") langFollowupMessage: LanguageSelectTextboxComponent;
  @ViewChild('idChiefComplaints') idChiefComplaints: ElementRef;
  @ViewChild('langDietary') langDietary: LanguageSelectTextboxComponent

  @ViewChild('langMedInstructions') langMedInstructions: LanguageSelectTextboxComponent
  @ViewChild("pendingVaccination_modal") pendingVaccination_modal
  @ViewChild('langInstructions') langInstructions: LanguageSelectTextboxComponent;


  @Input() isEditableVisit
  @Input() public selectedOnExamination: string
  @Input() public nextFollowUp: string
  @Input() public editMode: boolean;
  @Input() callBack: (args: any) => void;

  showLmp: boolean = false
  showLMPAfterEight: boolean = false

  visitIdFromPrescription: any;
  patientId = null;
  todaysDate: any;
  fullName: any = "";
  gender: any = "";
  dob: any = "";
  contactNo: any = "";
  showAge: any = "";
  age: any = "";
  patient: any = null;
  vitalsArray: any = [];
  weight: any = "";
  height: any = "";
  doctor: any = {};
  otherId: any
  complaints: any = "";
  onExamination: any = "";
  diagnosis: any = "";
  dietaryAdvice: any = "";
  generalInstructions: any = "";
  selectedIndex: any = null;
  selectedIndexForTab: any
  editFlag = false;
  birthweight = '';

  showOtherId: number
  editMedicineTextInstructions: any = "";
  editMedicineTextDuration: any = "";
  editMedicineText: any = "";
  editMedicineWhenToTake
  patientAllData: any;
  followUpMessageForLanguage: any = [];

  allDignosisOg: any = []
  drugTypeList: any = [];
  drugArray: any = [];
  // allMeds: any = allMeds;
  allMedicinesForDiagnosis: any = [];
  allMedicines: any = [];
  frequencyCollapsibleVariable = 4;
  units: any = units;
  unitForNewMed: any = '';
  frequencyForNewMed: any = '';
  frequencyForEditMedicine: any = [];
  durationForNewMed: any = '';
  whenToTakeForNewMed: any = '';
  instructionsForNewMed: any = '';
  allMedicinesForDiagnosisOrg: any = [];
  selectedPreviouslyMed: any = ""
  selectedPreviouslyMedArray: any = []
  Tounit = "Unit"
  medDuplicateindex: any = ""
  toFrequency = "Frequency";
  Duration = "Duration";
  Whentotake = "When to take";
  drugType: any = 'Drug Type';
  frequencyWithVal = frequencyWithVal;
  drugUnitTypes = drugUnitTypes;
  drugTypeSelection = drugTypeSelection;
  selectedDrugUnit = '';
  appointmentFlag: any = ""
  drugMolecularFlag: any = ""
  doctorObj: any = {}
  favApptName: any = "";
  showLocality: any
  complaintDetails: any = []
  favMedicineArray: any = []
  drugTypeAddNewDrugs: string = '';
  drugCompanyAddNewDrugs: string = '';
  dataLanguageInput: string;
  genericDrugNameAddNewDrugs: string = '';
  drugNameAddNewDrugs: string = '';
  medicineLoader: boolean = false
  appointmentId = null;
  followupDateNew: any;
  selectedOption: string = '';
  followupType: any = ""
  allMedicienOrg: any = []
  @ViewChild("viewPrescriptionModal") viewPrescriptionModal;
  favArray: any = []
  nextAppointmentDays: any;
  followupDateField: any = new FormControl('', [Validators.required]);
  diagnosisCollectionArray: any[] = []
  diagnosisCollectionArrayOrg: any[] = []
  selectedSpecialVaccination: any = []

  backToEdit: boolean = false;
  openImmunizationFlag: boolean = false;
  searchText = "Start typing a drug name"
  selectedUnit = '';
  @ViewChild("lngMedInstr") lngMedInstr: LanguageSelectTextboxComponent;
  @ViewChild('searchFavourites') searchFavourites: ElementRef;
  allGeneralInstructionsOg: any = [];
  allMedicineInstructions: any = [];
  allMedicineInstructionsOg: any = [];
  allMedicineInstructionsImmutable: any = [];
  printingFields = printingFieldsArray;
  visits: any = null;
  dataSent: boolean = false;
  @Output() openChild = new EventEmitter<void>();

  drugUnit: any = null;
  currentPopularMedObj: any = {}
  complaintDetailsData: any = []
  drugTypeUnit: string = "";
  editMedicineDosage: string = "";
  doseCount: any = [{ unit: "", drugType: "", frequency: "", duration: "", whenToTake: "" }];
  searchedMeds: any = [];
  unselectedDrugName = false;
  searchArray: any = [];
  searchStringDiagnosis: any = ""
  searchStringDietaryAdvice: any = "";
  searchStringGeneralInstructions: any = "";
  searchStringConcerns: any = "";
  searchedMedicineObj: any = {}
  medicineName: any = {};
  selectedDrugType: any = '';
  selectedDuration = '';
  selectedWhenToTake = '';
  selectedFrequency: any = [];
  complaintsDetailsArray: any = []
  selectedInstructions: any = "";
  UnitField: any = new FormControl('', [Validators.required]);
  FrequencyField: any = new FormControl('', [Validators.required]);
  FrequencyFieldEdit: any = new FormControl('', [Validators.required]);
  DurationField: any = new FormControl('', [Validators.required]);
  WhentotakeField: any = new FormControl('', [Validators.required]);
  DrugUnitField: any = new FormControl('', [Validators.required])
  drugTypeField: any = new FormControl('', [Validators.required]);
  searchTextField: any = new FormControl('', [Validators.required]);
  selectedInstructionsForAsPerLangaugae: any
  UnitFieldNewMed: any = new FormControl('', [Validators.required]);
  FrequencyFieldNewMed: any = new FormControl('', [Validators.required]);
  DurationFieldNewMed: any = new FormControl('', [Validators.required]);
  WhentotakeFieldNewMed: any = new FormControl('', [Validators.required]);
  drugTypes: Observable<string[]>;
  drugTypesOg: any = [];
  isAddMedicine = true;
  medicineSectionToggle = 1;
  step = 0;
  isDateFilledAddVac: boolean = false;
  maxDate: string;
  patientSelected: boolean = false
  rxPreview: any = true
  addFollowUpFlag: boolean = true;
  searchPatient: any = ""
  selectFieldsValue: any = "All Fields"

  nextFollowUpMessage = ""
  selectVitalsField: any = selectedVitalsFields
  medicine_instructions: string = '';
  pdfurlObjectForPrintingInChild: any = {}

  currentUserInteractions = [
    { name: 'first', key: 'firstStep' },
    { name: 'second', key: 'secondStep' },
    { name: 'third', key: 'thirdStep' },
    { name: 'fourth', key: 'fourthStep' },
  ];
  @ViewChild('stepper') private stepper: MatStepper;
  selectedDiagnosis: any = ""
  showPatientList: boolean = false
  isLinear = true
  completed = true;
  allComplaints: any = []
  selectedDignosisName: any = []
  selectedDietName: any = []
  selectedGeneralInstructionsName: any = []
  allSelectedComplaintsAndMoreSym: any = []
  allComplaintsName: Observable<string[]>
  allDignosisName: Observable<string[]>
  allDietName: Observable<string[]>
  allGeneralInstructionsName: Observable<string[]>
  allComplaintsOrg: any = []
  selectedPreviousMedDate = '';
  selectedComplaints: any = []
  selectedComplinats = ""
  selecteddiagnosis = ""
  medObj: any
  previousMedsArr: any = [];
  selectedDietaryAdvices = ""
  selectedGeneralIns = ""
  suggestComplaints: any = []
  allDignosis: any = []

  allDignosisOrg: any = []
  allDietaryAdvices: any = []
  allDietaryAdvicesOrg: any = []
  allSelectedComplaints: any = []
  favArrayNames: Observable<string[]>;
  moreSymptoms = false
  searchPatientControl = new FormControl();
  diagnosisControl = new FormControl();
  complaintsControl = new FormControl();
  myControlFav = new FormControl();
  myControlFavMed = new FormControl();
  instructionControl = new FormControl();
  dietNutritionControl = new FormControl();
  drugTypeControl = new FormControl();
  selectedMoreSysmtoms = ""
  selectedMatexpansionpanelValue: any = ""
  allGeneralInstructions: any = []
  allGeneralInstructionsOrg: any = []
  firstFormGroup: any
  allergy: any = []
  specialVaccination: any = []
  firstName: any = ""
  lastName: any = ""
  locality: any = ""
  mobile: any = ""
  selectFieldsArray: any = [{ name: "All Fields", value: "" }];
  selectedTemplate: any = [];
  templateName: any = "Custom Prescription";
  languageArray: any = [{ name: 'English', language: 'en' }, { name: 'Marathi', language: 'mr' }, { name: 'Hindi', language: 'hi' }, { name: 'Kannada', language: 'kn' }];
  selectedLanguage: any = { name: "English", language: 'en' };
  userId: any = null
  appointmentCollection = [];
  appointmentCollectionArrived = [];
  appointmentCollectionAttending = [];
  appointmentCollectionDone = [];
  cancelledAppointments: any = []
  originalAppointments: any;
  originalCopy: any = [];
  order = []
  searchAppData: any = {};
  searchby_name_number: string = ""
  searchby_from_date: any = ""
  moreSymptomsName: any = "More Details";
  selectedFavMed: any = [];
  selectedPreviousMed = [];
  selectedPreviousFavMed: any = [];
  todayDateDesabled: string;
  currentFavMedObj: any = {};
  pdfUrlForViewPrescription: any
  selectedVisit: any = {}
  printVitals: any = {}
  l_patientid: any
  patientObj: any = {}
  patientApponmentById: any = {}
  patientGetPrescriptions: any = {}
  patientInfo: any = {}

  patientAppointments: any = {}
  patientAllRequiredConstant: any = {}
  todayDate
  sinceDay: any = ""
  debouncedSaveVisit: () => Promise<void>;
  nextFolloupPreview: string[] = [];
  // ageObj: any = {};
  constructor(private sanitizer: DomSanitizer, private messageService: MessageService, public dialog: MatDialog, private refreshservice: refreshService, private doctorService: DoctorService, private util: UtilService, private authService: AuthService, private route: ActivatedRoute, private router: Router, private _formBuilder: FormBuilder, private navService: NavigationServiceService, private patientService: PatientService) {
    this.saveButtonFlag = false;
    this.savePreviewButtonFlag = false;

    
    this.debouncedSaveVisit = debounce(this.saveVisit.bind(this), 500);
    // this.maxDate = this.lmpDate?.toISOString().split('T')[0];
    this.todayDate = new Date();


    this.dataLanguageInput = '';

    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required],
    });
    this.selectedVisitdate = new Date();
  }
  onLangSelectedTextChanged(text: string) {
    console.log("texttext", text);
    if (text) {
      // Split the sentence by space to get individual words
      const words = text.split(' ');

      // Take the first word
      let firstWord = words[0];

      // Remove the last character from the first word
      firstWord = firstWord.substring(0, firstWord.length);

      // Create the modified sentence by joining the words back together
      const modifiedSentence = [firstWord, ...words.slice(1)].join(' ');

      // Update nextFolloupPreview with the modified sentence
      this.nextFolloupPreview = [modifiedSentence];
    } else {
      // this.nextFolloupPreview = []; // Clear if the text is empty
    }
    if (this.dataLanguageInput) {
      this.nextFolloupPreview.splice(0, this.nextFolloupPreview.length, this.dataLanguageInput);
    }
  }

  // onLangSelectedTextChanged(text: string) {
  //   console.log("texttext", text);
  //   if (text) {
  //     // Split the sentence by space to get individual words
  //     const words = text.split(' ');

  //     // Take the first word
  //     let firstWord = words[0];

  //     // Remove the last character from the first word
  //     firstWord = firstWord.substring(0, firstWord.length - 1);

  //     // Create the modified sentence by joining the words back together
  //     const modifiedSentence = [firstWord, ...words.slice(1)].join(' ');

  //     // Update nextFolloupPreview with the modified sentence
  //     this.nextFolloupPreview = [modifiedSentence];
  //   } else {
  //     // this.nextFolloupPreview = []; // Clear if the text is empty
  //   }
  //   if (this.dataLanguageInput) {
  //     this.nextFolloupPreview.splice(0, this.nextFolloupPreview.length, this.dataLanguageInput);
  //   }
  // }

  ageFromTopBar(updatedAgeObj: any) {
    console.log("Updated Age Object in Parent", updatedAgeObj.value);
    this.ageObj = updatedAgeObj;

  }
  fistNameFromTopBar(updatedfistNameObj: any) {
    console.log("Updated fname Object in Parent", updatedfistNameObj.value);
    this.fistNameObj = updatedfistNameObj;

  }

  // preprocessComplaints(complaints: string[]): string {
  //   console.log("preprocessComplaints is rinning");

  //   let maxLength = this.showPatientList ? 50 : 90;
  //   complaints = complaints.filter(element => element.trim()); // Filter out empty elements
  //   if (complaints.length === 0) {
  //     return "No complaints available"; // Return a message if no complaints available
  //   } else {
  //     let combinedText = complaints.join(', '); // Join complaints into a single string
  //     if (combinedText.length > maxLength) {
  //       combinedText = combinedText.substring(0, maxLength) + '...etc';
  //     }

  //     return [combinedText]; // Return the truncated combined text as a single-item array
  //   }
  // }


  previewDataVitals(data: string[]): string[] {
    console.log("previewDataVitals");

    let maxLength = this.showPatientList ? 50 : 90;  // Maximum length of combined strings
    let filteredData = data.filter(item => item.trim() !== ''); // Filter out empty strings
    let combinedText = filteredData.join(', '); // Join all non-empty items into a single string separated by comma and space

    // Truncate the combined text if it exceeds maxLength
    if (combinedText.length > maxLength) {
      combinedText = combinedText.substring(0, maxLength) + '...etc';
    }

    return [combinedText]; // Return the truncated combined text as a single-item array

  }







  ngOnInit(): void {


    // this.getPatientInfo();
    //  this.showPatientList=true
    // this.handleAgeObjUpdate(this.ageObj)
    this.ageObjFromTopRx.subscribe(updatedAgeObj => {
      // Handle the updated ageObj as needed
      console.log("age fron child top rx ", this.ageObj);

      console.log("11111111Updated Age Object in Parent", updatedAgeObj);
    });
    this.selectedVisit = {
      weight: '',
      height: '',
      Temp: '',
      spo2: '',
      // lmp: new Date(),
      hc: '',
      cc: '',
      // lmp:null,
      Bloodhaemoglobin: '',
      bpSys: '',
      bpDia: '',
      Heartrate: '',
      Pulse: '',
      BloodSugarRandom: '',
      Bloodsugar_F: '',
      Bloodsugar_PP: '',
      Blood_Hba1c: '',
      Chol_HDL: '',
      Chol_LDL: '',
      TotalCholesterol: '',
      Triglycerides_LFT: '',
      addedBy: 'doctor',
      date: this.reverseDate(new Date())

    };
    this.fnameObjFromTopRx.subscribe(updatedAgeObj => {
      // Handle the updated ageObj as needed
      console.log("11111111Updated name Object in Parent", updatedAgeObj);
    });

    // this.getPatientsVaccination();
    console.log("11111111Updated Age Object in Parent", this.ageObj);

    this.userId = this.authService.getUser()
    console.log("userId", this.userId)
    this.getMedicinesAsPerDiagnosis();
    this.getDoctorDetails();




    // this.getPrescriptionMedicines();


    console.log('Selected visit:', this.selectedVisit);

    this.searchby_from_date = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };

    this.route.queryParams
      .subscribe(params => {
        console.log("log2", params); // { orderby: "price" }
        // if(params.patientId){
        //   this.patientId = params;
        // }
        this.patientId = params.patientId;
        this.backToEdit = params.backToEdit ? params.backToEdit : false;




        // used previously for showing vaccines given in instructions
        // now handling this logic from backend
        // this.getPatientsVaccination();

        if (this.backToEdit) {
          //Workaround!
          this.getLangTextBoxData();
          setTimeout(() => {
            this.stepper.next()
            this.stepper.next()
            this.stepper.next()

          }, 200);
          //this.getPrescriptionMedicines();

        } else {

        }

        // this.getPatientInfo();
        this.resetFromState()

      }
      )
    this.getPrescriptionMedicines();

    this.getPatientInfo();
    this.getAppointments();
    setTimeout(() => {
      this.getComplaintsOfDoctor();
    }, 500)


    this.patientService.patientInfo$.subscribe(data => {
      this.patientInfo = data;
      console.log("datadatadatadatadatadata", data);

      // You can perform any additional processing here
    });

    // Fetch patient data
    // this.patientService.fetchPatientInfo(this.patientId);

    // Other initialization code...


    this.getAllRequiredConstants();
    this.getAllDoctorPrescriptions();


  }


  setStep(index: number) {
    this.step = index;
  }
  resetFromState() {
    // return;
    this.l_patientid = sessionStorage.getItem("qrx_patientid");
    if (this.l_patientid && this.l_patientid != this.patientId) {
      //purge all previous data
      sessionStorage.setItem("qrx_patientid", null);
      sessionStorage.setItem("qrx_selectedComplaints", null);
      sessionStorage.setItem("qrx_selectedOnExamination", null);
      sessionStorage.setItem("qrx_selectedDiagnosis", null);
      sessionStorage.setItem("qrx_selectedDietaryAdvices", null);
      sessionStorage.setItem("qrx_selectedGeneralInstructions", null);
      sessionStorage.setItem("qrx_allMedicines", null);
      sessionStorage.setItem("qrx_allMedicinesForDiagnosis", null);
    } else {
      if (sessionStorage.getItem("qrx_selectedComplaints")) {
        this.selectedComplaints = JSON.parse(sessionStorage.getItem("qrx_selectedComplaints"));
      }
      if (sessionStorage.getItem("qrx_selectedOnExamination")) {
        this.selectedOnExamination = JSON.parse(sessionStorage.getItem("qrx_selectedOnExamination"));
      }
      if (sessionStorage.getItem("qrx_selectedDiagnosis")) {
        this.selectedDiagnosis = JSON.parse(sessionStorage.getItem("qrx_selectedDiagnosis"));
      }
      if (sessionStorage.getItem("qrx_selectedDietaryAdvices")) {
        this.selectedDietaryAdvices = JSON.parse(sessionStorage.getItem("qrx_selectedDietaryAdvices"));

      }
      if (sessionStorage.getItem("qrx_selectedGeneralInstructions")) {
        this.selectedGeneralInstructions = JSON.parse(sessionStorage.getItem("qrx_selectedGeneralInstructions"));

      }
      if (sessionStorage.getItem("qrx_allMedicines")) {

        this.allMedicines = JSON.parse(sessionStorage.getItem("qrx_allMedicines"));
        this.allMedicines = this.allMedicines ? this.allMedicines : []

        if (!this.allMedicines.length) {

        } else {
          this.allMedicines.map(ins => { return ins.selected = true });
        }
      }
      if (sessionStorage.getItem("qrx_allMedicinesForDiagnosis")) {
        let parsedData = sessionStorage.getItem("qrx_allMedicinesForDiagnosis");
      }

    }

  }

  openDialog(content, index) {
    // this.selectedIndexOnTab = index;
    this.selectedIndex = index;
    this.dialog.open(content);
  }
  reverseDate(date) {
    let d = new Date(date);
    return { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() };
  }
  validateNumber(e) {
    return ((e.charCode > 47 && e.charCode < 58)) || (e.which == 8) || (e.which == 13) || (e.keyCode == 9) || (e.keyCode == 190) || (e.keyCode == 110) || (e.keyCode == 46)
    const value = (event.target as HTMLInputElement).value;
    return !isNaN(Number(value));
  }

  getAppointments = () => {


    let reqVars = {
      doctor_id: this.userId,
      searchby_name_number: this.searchby_name_number,
      searchby_from_date: this.searchby_from_date ? this.util.parseDate(this.searchby_from_date) : "",
      searchby_to_date: this.searchby_from_date ? this.util.parseDate(this.searchby_from_date) : "",
      order: this.order
    }
    // let that = this
    let d = new Date()
    this.appointmentCollection = [];
    this.appointmentCollectionArrived = [];
    this.appointmentCollectionAttending = [];
    this.appointmentCollectionDone = [];

    let cancelledAppointments = []

    // this.showLoading = true;

    this.doctorService.getAppointments(reqVars).subscribe(response => {
      if (response) {
        this.patientAppointments = response;
        console.log("patientAppointments in patientService ", this.patientAppointments);

      }
      console.log('Response Appt: ', response)
      var appointmentboj: any = {}
      // this.showLoading = false;
      if (response.appointments) {
        this.searchAppData = response.appointments;

        this.originalAppointments = response.appointments;
        console.log("marchTest31", this.originalAppointments)

        response.appointments.forEach((element) => {

          let isReceipt = false
          console.log({ thissssss2: this })
          if (element.Patient.User.first_name == "favourite" && element.Patient.User.last_name == "creation") {

          } else {


            if (element.Patient && element.Patient.User) {
              var age = element.Patient.User.dob ? this.util.calculateAge(element.Patient.User.dob) : { years: '-' }
              if (age['years'] < 1) {

                age['years'] = "Under 1 Year";
              }
              else {
                age;
              }
              var gender = element.Patient.User.gender != null ? element.Patient.User.gender : "-"

              if (element.Certificates && element.Certificates != null) {
                var certificate = JSON.parse(element.Certificates)
                certificate.map(item => {
                  if (item.type == 'Receipt') {
                    isReceipt = true
                  }
                })

              }

              appointmentboj = {
                id: element.id,
                isReceipt,
                visitedOn: element.visit_date,
                from: element.from,
                to: element.to,
                mobile: element.Patient.User.phone_number,
                name: element.Patient.User.first_name + " " + element.Patient.User.last_name + " (" + gender.substring(0, 1).toUpperCase() + '/' + age['years'] + ")",
                clinicName: element.Clinic && element.Clinic.name ? element.Clinic.name : '-',
                deleted: element.deleted,
                comepleteData: element,
                clinicId: element.Clinic && element.Clinic.id ? element.Clinic.id : null,
                comment: element.comment,
                fullName: element.Patient.User.first_name + " " + element.Patient.User.last_name,
                status: element.status,
                userId: element.Patient.User.id,
                showStartCall: false,
                zoomStartURL: element.zoomStartURL,
                PatientId: element.PatientId,
                showCancel: false,
                showCovid: false,
                showNoShow: false,
                arrived: element.arrived,
                in: element.in,
                done: element.done,
                bookedFrom: element.bookedFrom,
                isTeleCall: element.isTeleCall
              };
              var visited = (appointmentboj.visitedOn).substr(0, 11) + "23:59:59.000Z"
              if (new Date(appointmentboj.from).getDate() === new Date().getDate() && new Date(appointmentboj.from).getMonth() === new Date().getMonth() && new Date(appointmentboj.from).getFullYear() === new Date().getFullYear()) {
                appointmentboj.showCovid = true;
              }
              if (element.isTeleCall == '1' && new Date(visited).getTime() > d.getTime() && element.Doctor.isMeetingURLAvailable) {
                appointmentboj.showStartCall = true
              }
              if (new Date(appointmentboj.from).getTime() > d.getTime() && !appointmentboj.done) {
                appointmentboj.showCancel = true;
              }
              if (!appointmentboj.done && !appointmentboj.in && !appointmentboj.arrived && appointmentboj.status == null && (new Date(appointmentboj.from).getDate() == new Date().getDate() && new Date(appointmentboj.from).getMonth() == new Date().getMonth() && new Date(appointmentboj.from).getFullYear() == new Date().getFullYear())) {
                appointmentboj.showNoShow = true;
              }
              if (new Date(appointmentboj.visitedOn).getTime() < d.getTime()) {
                appointmentboj.cancelled = true

              }


              this.appointmentCollection.push(appointmentboj)
              console.log("Test31", this.appointmentCollection)
              this.appointmentCollection = this.appointmentCollection.reduce((unique, o) => {
                if (!unique.some(obj => obj.PatientId == o.PatientId
                )) {
                  unique.push(o);
                }
                return unique;
              }, []);

              if (appointmentboj.status == 'noshow') {
                cancelledAppointments.push(appointmentboj)
              }

            }
            this.appointmentCollection.map(ins => {
              if (ins.comepleteData.prescriptionPDFURL) {
                ins.done = "done",
                  ins.arrived = "done"
                ins.in = "done"
                ins.showNoShow = false
              }
            })

            return this.appointmentCollection.sort((a, b) => {
              return <any>new Date(a.from) - <any>new Date(b.from);


            });
          }

        })

        this.cancelledAppointments = cancelledAppointments;

        let doneArr = [];
        let notDoneArr = [];
        let noShowArr = [];

        console.log("NOV3", this.appointmentCollection)
        this.appointmentCollection.map(item => {

          if (item.done != null) {

            doneArr.push(item)

          }
          else if (item.status != null) {
            noShowArr.push(item)
          }
          else {
            notDoneArr.push(item)
          }
        });

        let slots = [
          ...notDoneArr, ...doneArr, ...noShowArr
        ]

        this.appointmentCollection = slots;
        console.log("HOOOO", this.appointmentCollection)
        this.originalCopy = this.appointmentCollection;

        this.appointmentCollectionDone = doneArr;

        this.appointmentCollection.map(ins => {
          if (ins.arrived && !ins.in && !ins.done) {
            this.appointmentCollectionArrived.push(ins)
          }

          if (ins.arrived && ins.in && !ins.done) {
            this.appointmentCollectionAttending.push(ins)
          }
        })
        localStorage.removeItem("covidques");
        // this.apppointmentCount();
      }
    })
    // this.apppointmentCount();

  }
  getAllRequiredConstants = () => {

    // var postData:any={
    //   lang:'mr'
    // }
    this.patientService.getAllRequiredConstants("").subscribe(response => {
      if (response) {
        this.patientAllRequiredConstant = response;
        console.log("patientAllRequiredConstant in patientService ", this.patientAllRequiredConstant);

        console.log("june9999", response)
        this.followUpMessageForLanguage = response.data.nextFollowupMessages;
        // this.followUpMessageForLanguage = response.data.nextFollowupMessages;
        this.allMedicineInstructions = response.data.medicineInstructions.map(ins => { return { name: ins.name, selected: false } });
        this.allMedicineInstructionsOg = response.data.medicineInstructions.map(ins => { return { name: ins.name, selected: false } });
        console.log("MedIns", this.followUpMessageForLanguage)
        this.complaintDetails = response.data.complaintDetails

        this.drugTypes = response.data.drugTypes.map(ins => { return ins.name });
        this.drugTypesOg = response.data.drugTypes.map(ins => { return ins.name });

        //here
        this.drugTypes = this.drugTypeControl.valueChanges.pipe(
          startWith(''),
          map(value => this._filterDrug(value))
        );




      }
    })
  }
  private _filterDrug(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.drugTypesOg.filter(option => option.toLowerCase().includes(filterValue));
  }
  onDateChange(event) {
    var followupDate: any = this.reverseDate(event.value);
    followupDate = moment(new Date(this.parseDate(followupDate))).format("DD/MM/YYYY");
    localStorage.setItem("followupDate", followupDate)

  }
  onDateChangeLmp(event) {
    console.log("Dateevent", event);
    var lmpDate: any = this.reverseDate(event.value);
    lmpDate = moment(new Date(this.parseDate(lmpDate))).format("YYYY-MM-DD");

    if (this.backToEdit) {
      // Perform specific logic when back to edit
      this.selectedVisit.lmp = lmpDate;
      console.log("Back to edit mode", lmpDate);
      // Update selectedVisit only when back to edit
    } else {
      // Perform default logic
      console.log("Not in edit mode");
      // Update selectedVisit regardless of back to edit status
      this.selectedVisit.lmp = lmpDate;
    }
  }

  getPatientInfo = () => {
    console.log(" getPatientInfo called in new QuickRx", this.patientId);

    this.specialVaccination = []

    // this.showLoading = true;
    this.selectedSpecialVaccination = []
    this.patientService.getPatientInfo(this.patientId).subscribe(response => {
      console.log("11111111114444411111111111111", this.patientId);

      if (response) {
        this.showLoading = false;


        this.patientInfo = response;

        console.log("patientInfo in patientService", this.patientInfo);
        // this.firstName = this.patientInfo.firstName;

        // this.lastName = this.patientInfo.lastName;
        this.locality = this.patientInfo.locality;
        this.mobile = this.patientInfo.contactNo;

        //         var ageObj = this.patientInfo.age;
        this.ageObj = this.patientInfo.age;
        console.log("this.patientInfo.age in newRx", this.patientInfo.age);
        console.log("this.patientInfo.age in newRx", this.patientInfo.age);

        console.log("Satyjeet ", this.patientInfo); // Check if patientInfo is defined and has the expected structure
        console.log(this.patientInfo.visits); // Check if visits array is defined and has data






        console.log("ageObj?.years ", this.ageObj.years);
        console.log("ageObj?.months ", this.ageObj.months);
        console.log("ageObj?.days ", this.ageObj.days);


        console.log("this.patientInfo.age.years", this.patientInfo.age.years);
        console.log("this.patientInfo.gender", this.patientInfo.gender);


        var additionalInfo = this.patientInfo.additionalInfo ? JSON.parse(this.patientInfo.additionalInfo) : {};


        var visits = this.patientInfo.visits && this.patientInfo.visits.length ? this.patientInfo.visits : [];
        console.log("visits visits", visits)
        let formattedVisits: any = {};
        if (response.age.years >= 8 && response.gender == "Female") {
          console.log("with lmppppppppppppppp");

          this.printingVitalArray = ["showWeight", "showHeight", "showBP", "showPulse", "showTemp", "showSPO2", "showLmp", "showHaemoglobin", "showBloodSugar(R)", "showBloodSugar(F)", "showBloodSugar(PP)", "showBlood(Hb1ac)",
            "showHeartrate"];
        } else {
          console.log("without lmppppppppppppppp");

          this.printingVitalArray = ["showWeight", "showHeight", "showBP", "showPulse", "showTemp", "showSPO2", "showHaemoglobin", "showBloodSugar(R)", "showBloodSugar(F)", "showBloodSugar(PP)", "showBlood(Hb1ac)",
            "showHeartrate"];
        }

        this.selectedVitalsField = this.printingVitalArray
        this.printingVitals = this.printingVitalArray

        if (this.patientInfo.age.years >= 8 && this.patientInfo.gender == "Female") {

          console.log("showLMPAfterEightshowLMPAfterEightshowLMPAfterEight", response);

          this.showLmp = true
          this.showLMPAfterEight = true
        } else {
          this.showLmp = false
          this.showLMPAfterEight = false
        }

        visits.map((element, index) => {


          if (new Date(element.date).getDate() == new Date().getDate() && new Date(element.date).getMonth() == new Date().getMonth() && new Date(element.date).getFullYear() == new Date().getFullYear()) {
            console.log("VisitJan15", element)

            if (!element.weight || element.weight == 'null' || element.weight == '0') { element.weight = "" }
            if (!element.height || element.height == 'null' || element.height == '0') { element.height = "" }
            if (!element.hc || element.hc == 'null' || element.hc == '0') { element.hc = "" }
            if (!element.cc || element.cc == 'null' || element.cc == '0') { element.cc = "" }
            if (!element.Temp || element.Temp == 'null' || element.Temp == '0') { element.Temp = "" }
            if (!element.spo2 || element.spo2 == 'null' || element.spo2 == '0') { element.spo2 = "" }
            if (!element.lmp || element.lmp == '0') { element.lmp = "" }

            if (!element.bpSys || element.bpSys == 'null' || element.bpSys == '0') { element.bpSys = "" }
            if (!element.bpDia || element.bpDia == 'null' || element.bpDia == '0') { element.bpDia = "" }
            if (!element.BloodSugarRandom || element.BloodSugarRandom == 'null' || element.BloodSugarRandom == '0') { element.BloodSugarRandom = "" }
            if (!element.Blood_Hba1c || element.Blood_Hba1c == 'null' || element.Blood_Hba1c == '0') { element.Blood_Hba1c = "" }
            if (!element.Bloodhaemoglobin || element.Bloodhaemoglobin == 'null' || element.Bloodhaemoglobin == '0') { element.Bloodhaemoglobin = "" }
            if (!element.Bloodsugar_F || element.Bloodsugar_F == 'null' || element.Bloodsugar_F == '0') { element.Bloodsugar_F = "" }
            if (!element.Bloodsugar_PP || element.Bloodsugar_PP == 'null' || element.Bloodsugar_PP == '0') { element.Bloodsugar_PP = "" }
            if (!element.Heartrate || element.Heartrate == 'null' || element.Heartrate == '0') { element.Heartrate = "" }
            if (!element.Pulse || element.Pulse == 'null' || element.Pulse == '0') { element.Pulse = "" }
            if (!element.Chol_HDL || element.Chol_HDL == 'null' || element.Chol_HDL == '0') { element.Chol_HDL = "" }
            if (!element.Chol_LDL || element.Chol_LDL == 'null' || element.Chol_LDL == '0') { element.Chol_LDL = "" }
            if (!element.TotalCholesterol || element.TotalCholesterol == 'null' || element.TotalCholesterol == '0') { element.TotalCholesterol = "" }
            if (!element.Triglycerides_LFT || element.Triglycerides_LFT == 'null' || element.Triglycerides_LFT == '0') { element.Triglycerides_LFT = "" }

            this.selectedVisit = element;

            console.log("this.selectedVisit", this.selectedVisit);


            // this.selectedVisitdate = new Date(element.date)
            if (element.lmp == 'null' || element.lmp == '0000-00-00') {
              this.selectedVisit.lmp = null
            }
            else {
              this.lmpDate = new Date(element.lmp)
            }



          }
        })
        this.printVitals = this.selectedVisit;
        console.log("FEB777777777", this.selectedVisit)

        var specialVaccination = additionalInfo ? additionalInfo.specialVaccination : [];
        //set language of text bioxes
        this.lngMedInstr.setLanguage(this.patientSelectedLanguage);

        //  this.langDietary.setLanguage(this.patientSelectedLanguage);
        //  this.langInstructions.setLanguage(this.patientSelectedLanguage);



        if (specialVaccination) {
          var todayDate = new Date();
          let todayDateNew = moment(todayDate).format('DD-MMM-YYYY');
          console.log("dateJune19", todayDateNew);

          specialVaccination.map(ins => {
            if (ins.administeredDate == todayDateNew) {
              var splVacc = "";
              this.specialVaccination.push(ins);
              if (ins.name.includes(',')) {
                splVacc = ins.name;
              }
              else {
                splVacc = "" + ins.name;
              }

              this.selectedSpecialVaccination.push(splVacc)

            }
          })



        }
        console.log(">>>>>>>>>", this.specialVaccination)
      }
    })
  }
  validateField = (field) => {
    if (field && field != '0' && field != '' && field != 'undefined' && field != 'null' && field != '-') {
      return true;
    } else {
      return false;
    }
  }

  toggleSideNavRx() {
    this.navService.setShowNav(true);

  }

  private _filterFavourites(value: any): string[] {
    console.log('Value', value);

    let filterValue: string = '';

    if (typeof value === 'string' || value instanceof String) {
      filterValue = value.toLowerCase();
    } else if (value && value.searchstr) {
      filterValue = value.searchstr.toLowerCase();
    }

    return this.allComplaints.filter(option => option.complaint.toLowerCase().includes(filterValue));
  }
  // =========================================

  searchDataDignosisAdd() {

    if (this.searchArray) {
      if (this.searchStringDiagnosis == "") {

      }
      else {
        this.selectedDignosisName.push(this.searchStringDiagnosis)
        var postData = {
          diagnosis: this.searchStringDiagnosis
        }
        console.log("SearchArray", postData)
        this.patientService.createNewDiagnosisOfDoctor(postData).subscribe(response => {
          if (response) {
            console.log("diagnosisdiagnosisdiagnosis", response);
          }

        })

        this.allDignosis.push(this.diagnosis)
        setTimeout(() => {
          this.getDiagnosisOfDoctorAsPerComplaints();
        }, 1000);
        this.searchStringDiagnosis = "";
        // for updating data in allDignosis.items 

        // this.isAddButton = false
      }

    }
  }
  searchComplaintAdd() {

    if (this.searchArray) {
      if (this.searchStringConcerns == "") {

      }
      else {
        this.selectedComplaints.push(this.searchStringConcerns)
        console.log(" this.selectedComplaints", this.selectedComplaints);

        var postData = {
          complaint: this.searchStringConcerns
        }
        console.log("SearchArray", postData)
        this.patientService.createNewComplaintOfDoctor(postData).subscribe(response => {
          if (response) {
            var moredata3: any = {}

            moredata3 = {
              complaint: response.complaint.complaint,
              selected: false,
              name: response.complaint.complaint,
              moreSymptoms: [],
              sinceDay: "",
              key: response.key,
            }

            console.log("customComplaints", response);
            this.allSelectedComplaintsAndMoreSym.push(moredata3)
            this.finalSelectedComplaints.push(moredata3.complaint)
            console.log("moredata3 allSelectedComplaintsAndMoreSym", moredata3);

            console.log("allSelectedComplaintsAndMoreSym", this.allSelectedComplaintsAndMoreSym);

          }

        })

        this.searchStringConcerns = "";
        this.allComplaints = this.allComplaintsOrg
        console.log("allComFeb25", this.allComplaints)
        // this.isAddButton = false
      }

    }
    setTimeout(() => {
      this.getComplaintsOfDoctor();
    }, 1000);

  }
  // =========================

  addDietaryAdvice() {
    console.log("addDietaryAdvice called");

    // this.searchStringDietaryAdvice = this.lngDietaryInst.getValue();
    var dignosisIds: any = [];
    this.allDignosis.map(ins => {

      if (ins.selected) {
        dignosisIds.push(ins.id)
      }

    })
    if (this.searchArray) {
      if (this.searchStringDietaryAdvice == "") {

      }
      else {
        if (dignosisIds != 0) {
          dignosisIds = "," + dignosisIds.join(',') + ","
        }
        else {
          dignosisIds = ""
        }
        var postData = {
          dietAdvice: this.searchStringDietaryAdvice,
          diagnosisCollection: dignosisIds
        }
        console.log("searchStringDietaryAdvice", this.searchStringDietaryAdvice);

        console.log("SearchArray", postData)
        this.patientService.createNewDietAdviceOfDoctor(postData).subscribe(response => {
          if (response) {
            console.log("custom1Complaints", response);
          }

        })
        this.selectedDietName.push(this.searchStringDietaryAdvice)

        // this.lngDietaryInst.clearTextBox()
        // this.allDietaryAdvices = this.allDietaryAdvicesOrg
        this.searchStringDietaryAdvice = "";
        setTimeout(() => {
          this.getDietAdviceOfDoctorAsPerDiagnosis();
        }, 1000);
        // this.isAddButton = false
      }

    }

  }
  // ===========================
  addGeneralInstruction() {
    // this.searchStringGeneralInstructions = this.lngGenInst.getValue();
    var dignosisId: any = [];
    this.allDignosis.map(ins => {
      if (ins.selected) {
        dignosisId.push(ins.id)
      }

    })
    if (this.searchArray) {
      if (this.searchStringGeneralInstructions == "") {

      }
      else {
        if (dignosisId != 0) {
          dignosisId = "," + dignosisId.join(',') + ","
        }
        else {
          dignosisId = ""
        }
        var postData = {
          generalInstruction: this.searchStringGeneralInstructions,
          diagnosisCollection: dignosisId
        }
        console.log("SearchArray", postData)
        this.patientService.createGeneralInstructionsOfDoctor(postData).subscribe(response => {
          if (response) {
            console.log("customGeneralInstruction", response);

          }

        })
        this.selectedGeneralInstructionsName.push(this.searchStringGeneralInstructions);


        this.searchStringGeneralInstructions = "";
        setTimeout(() => {
          this.getGeneralInstructionsOfDoctor();
        }, 1000);

        // this.lngGenInst.clearTextBox()
        //this.allGeneralInstructions = this.allGeneralInstructionsOg
        // this.isAddButton = false
      }
    }

  }
  getComplaintsOfDoctor() {
    var postData = {
      docUserId: this.userId
    }
    var splComplaints = []
    this.patientService.getComplaintsOfDoctor(postData).subscribe(response => {
      if (response) {
        console.log("getCompl", response)
        // splComplaints= response.complaints
        this.allComplaints = response.complaints

        this.allComplaints.map(ins => {
          //this.ageObj.years  > 6 &&
          if (ins.complaint == 'New Born') {
            var itemList = this.allComplaints.indexOf(ins);
            this.allComplaints.splice(itemList, 4)
          }
        })


        console.log("KKKKKK1", this.allComplaints)
        const size: any = 8

        const items = this.allComplaints.slice(0, size)
        console.log("size", items)


        this.allComplaintsOrg = this.allComplaints;

        this.allComplaintsName = this.allComplaints.map(ins => { return ins.complaint });
        console.log("compSep14", this.allComplaintsName)

        this.allComplaintsName = this.complaintsControl.valueChanges.pipe(
          startWith(''),
          map(value => this._filterFavourites(value))
        );
        this.selectedComplaints = this.selectedComplaints ? this.selectedComplaints : [];
        this.selectedComplaints.map(ins => {
          this.allComplaints.map(item => {
            if (ins == item.complaint) {
              item.selected = true
            }
          })
        })
      }
    })

  }
  // onDropdownSelectComplaints(item) {
  //   this.suggestComplaints = []
  //   this.selectedComplinats = item.complaint;
  //   console.log("this.finalSelectedComplaints",this.selectedComplinats);



  //   //this.allSelectedComplaints.push(item.complaint)
  //   console.log("SEP23", item)
  //   var suggArry: any = item.suggestedComplaintIds ? item.suggestedComplaintIds.split(',') : [];
  //   var filteredSuggestedComplaints = suggArry.filter(function (el) {
  //     return el != "";


  //   });




  //   filteredSuggestedComplaints.map(item1 => {
  //     this.allComplaints.map(ins => {
  //       if ((Number(item1) == ins.id)) {

  //         this.suggestComplaints.push(ins);

  //         this.moreSymptoms = true

  //       }
  //     })
  //   })
  //   this.finalSelectedComplaints.push(item.complaint);
  //   console.log("allSelectedComplaints_in_dropdownselect", this.suggestComplaints);

  //   let newObj = {
  //     name: item.complaint,
  //     moreSymptoms: this.suggestComplaints
  //   }
  //   this.allSelectedComplaintsAndMoreSym.push(newObj)
  //   setTimeout(() => {
  //     this.selectedComplinats = "";
  //     // this.dietNutritionControl.setValue('');
  //   }, 0);
  // }
  onDropdownSelectComplaints(item) {
    this.suggestComplaints = [];
    this.selectedComplinats = item.complaint;

    console.log("this.finalSelectedComplaints", this.selectedComplinats);

    // Check if the complaint is already in finalSelectedComplaints
    if (!this.finalSelectedComplaints.includes(item.complaint)) {
      // Add the complaint to finalSelectedComplaints
      this.finalSelectedComplaints.push(item.complaint);

      // Filter out any empty values from suggestedComplaintIds
      const filteredSuggestedComplaints = (item.suggestedComplaintIds || this.backToEdit).split(',')
        .filter(complaintId => complaintId.trim() !== '');

      // Map filteredSuggestedComplaints to corresponding complaints
      filteredSuggestedComplaints.forEach(complaintId => {
        const complaint = this.allComplaints.find(c => c.id === Number(complaintId));
        if (complaint) {
          this.suggestComplaints.push(complaint);
          this.moreSymptoms = true;
        }
      });

      // Create a new object for allSelectedComplaintsAndMoreSym
      const newObj = {
        name: item.complaint,
        moreSymptoms: this.suggestComplaints
      };
      this.allSelectedComplaintsAndMoreSym.push(newObj);
    }

    setTimeout(() => {
      this.selectedComplinats = "";
    }, 0);
  }

  toggleShowPatientList(): void {



    if (this.showPatientList) {
      this.slideInStart = !this.slideInStart;
      setTimeout(() => {
        //aaleenm
        this.showPatientList = !this.showPatientList
      }, 400);
    } else {
      setTimeout(() => {
        this.slideInStart = !this.slideInStart;
      }, 50);
      this.showPatientList = !this.showPatientList;
    }

  }
  // getPanelWidth(): string {
  //   return !this.showPatientList ? '72.2vw' : '20vw'; // Adjust the width values accordingly
  // }

  hasPositiveValues(): boolean {
    return (
      this.selectedVisit.weight > 0 ||
      this.selectedVisit.height > 0 ||
      this.selectedVisit.Temp > 0 ||
      this.selectedVisit.hc > 0 ||
      this.selectedVisit.cc > 0 ||
      this.selectedVisit.spo2 > 0 ||




      this.selectedVisit.bpSys > 0 ||
      this.selectedVisit.bpDia > 0 ||
      this.selectedVisit.Heartrate > 0 ||
      this.selectedVisit.Pulse > 0 ||

      this.selectedVisit.Bloodhaemoglobin > 0 ||
      this.selectedVisit.BloodSugarRandom > 0 ||
      this.selectedVisit.Bloodsugar_F > 0 ||
      this.selectedVisit.Bloodsugar_PP > 0 ||
      this.selectedVisit.Blood_Hba1c > 0 ||

      this.selectedVisit.Chol_HDL > 0 ||
      this.selectedVisit.Chol_LDL > 0 ||
      this.selectedVisit.TotalCholesterol > 0 ||
      this.selectedVisit.Triglycerides_LFT > 0
    );
  }







  selectSuggestComplaints(item, index) {
    var data: any = []
    this.complaintDetails.map(ins => {
      data.push({ name: ins, selected: false })
    })
    console.log("FEB155555Data", data)
    return

    if (item.complaint == "Fever") {

      this.complaintDetailsData = this.formatArray(this.complaintDetails.fever);
      console.log("Feverrrrr", this.complaintDetailsData)
    }
    else if (item.complaint == "Cough") {
      this.complaintDetailsData = this.formatArray(this.complaintDetails.cough);

    }
    else if (item.complaint == "Vomitting") {
      this.complaintDetailsData = this.formatArray(this.complaintDetails.vomitting);
    }
    else if (item.complaint == "Toothache") {
      this.complaintDetailsData = this.formatArray(this.complaintDetails.toothache);
    }
    else if (item.complaint == "Headache") {
      this.complaintDetailsData = this.formatArray(this.complaintDetails.headache);
    }
    else if (item.complaint == "Abdominal Pain") {
      this.complaintDetailsData = this.formatArray(this.complaintDetails.abdominalPain);
    }
    else if (item.complaint == "Cold") {
      this.complaintDetailsData = this.formatArray(this.complaintDetails.cold);
    }
    else if (item.complaint == "Loose Motions") {
      this.complaintDetailsData = this.formatArray(this.complaintDetails.looseMotions);
    }
    else if (item.complaint == "Rash") {
      this.complaintDetailsData = this.formatArray(this.complaintDetails.rash);
    }
    else if (item.complaint == "Bodyache") {
      this.complaintDetailsData = this.formatArray(this.complaintDetails.bodyache);
    }
    else {
      this.complaintDetailsData = []
    }


    this.suggestComplaints = [];

    if (this.complaintDetailsData.length) {
      var moredata1 = {}

      moredata1 = {
        name: item.complaint,
        moreSymptoms: this.complaintDetailsData


      }

      this.allSelectedComplaintsAndMoreSym.push(moredata1)

      console.log("complaintDetailsData", this.complaintDetailsData);

      console.log("FEB1444mORE", this.allSelectedComplaintsAndMoreSym)
    }
    else {
      var moredata1 = {}

      moredata1 = {
        name: item.complaint,
        moreSymptoms: this.complaintDetailsData


      }

      this.allSelectedComplaintsAndMoreSym.push(moredata1)

    }
    if (item.complaint == "Vaccination") {
      //  this.dialog.open(this.redirect_vaccination_modal);
      // alert('Vaccination pe le jao')
    }
    let cond = this.selectedComplaints.includes(item.complaint)
    if (this.allComplaints[index].selected == true || cond) {

      var suggArry: any = item.suggestedComplaintIds ? item.suggestedComplaintIds.split(',') : [];
      var filteredSuggestedComplaints = suggArry.filter(function (el) {
        return el != "";
      });

      filteredSuggestedComplaints.map(item => {
        console.log("Nov7", item)
        this.allComplaints.map(ins => {
          if (item == ins.id) {

            this.suggestComplaints.push(ins);
            this.moreSymptoms = true


          }
        })
      })

      if (item.complaint != "Vaccination") {
        this.complaintsDetailsArray = []
        this.selectedComplaints.push(item.complaint)
        this.allSelectedComplaints.push(item.complaint)
        this.complaintsDetailsArray.push(item.complaint)
      }

    } else {
      this.complaintsDetailsArray = []
      this.allComplaints[index].selected = true;
      if (item.complaint != "Vaccination") {
        this.selectedComplaints.push(item.complaint)
        this.allSelectedComplaints.push(item.complaint)
        this.complaintsDetailsArray.push(item.complaint)
      }





      var suggArry: any = item.suggestedComplaintIds ? item.suggestedComplaintIds.split(',') : [];
      var filteredSuggestedComplaints = suggArry.filter(function (el) {
        return el != "";
      });

      console.log("feb1", filteredSuggestedComplaints)

      filteredSuggestedComplaints.map(item => {
        this.allComplaints.map(ins => {
          if (item == ins.id) {

            this.suggestComplaints.push(ins);
            this.moreSymptoms = true


          }
        })
      })
    }
  }

  selectcomplaintDetails(item, index) {
    // this.allSelectedComplaintsAndMoreSym = [];
    //this.complaintDetailsData=[]



    if (index == "dropDownValue") {
      this.selectedComplinats = item.complaint
    }
    console.log("index", index)



    var complaint = item.complaint
    this.finalSelectedComplaints = this.finalSelectedComplaints.filter(existingItem => !existingItem.includes(item.complaint));
    this.finalSelectedComplaints.push(complaint)
    this.complaintsObj.push({ name: complaint, sinceDay: "", moreDetails: "" })
    const symptomsArray = Object.entries(this.complaintDetails).map(([name, value]) => ({ name, value }));
    var moredata: any = {}
    symptomsArray.map(ins => {
      if (ins.name == item.complaint.toLowerCase()) {

        moredata = {
          complaint: item.complaint,
          selected: false,
          name: item.complaint,
          moreSymptoms: ins.value,
          sinceDay: "",
          key: item.complaint,
          isHistory: false
        }
        const complaintExists = this.allSelectedComplaintsAndMoreSym.some(data => data.complaint === moredata.complaint);

        // If the complaint doesn't exist, add it to this.complaintDetailsData
        if (!complaintExists) {
          this.allSelectedComplaintsAndMoreSym.push(moredata);
        }


      }

    })

    if (Object.keys(moredata).length === 0) {
      var moredata1: any = {}

      moredata1 = {
        complaint: item.complaint,
        selected: false,
        name: item.complaint,
        moreSymptoms: [],
        sinceDay: "",
        key: item.complaint,
        isHistory: false


      }
      this.allSelectedComplaintsAndMoreSym.push(moredata1)
    }


    this.suggestComplaints = [];
    //this.allSelectedComplaintsAndMoreSym = this.complaintDetailsData
    console.log("FEB1444mORE", this.allSelectedComplaintsAndMoreSym)
    this.allSelectedComplaintsAndMoreSym = this.allSelectedComplaintsAndMoreSym.reduce((unique, o) => {
      if (!unique.some(obj => obj.complaint == o.complaint)) {
        unique.push(o);
      }
      return unique;
    }, []);

    if (item.complaint == "Vaccination") {
      //  this.dialog.open(this.redirect_vaccination_modal);
      // alert('Vaccination pe le jao')
    }
    let cond = this.selectedComplaints.includes(item.complaint)
    console.log("this.allComplaints[index]", this.allComplaints[index]);
    // this.allComplaints[index].selected == true ||
    if (cond) {

      var suggArry: any = item.suggestedComplaintIds ? item.suggestedComplaintIds.split(',') : [];
      var filteredSuggestedComplaints = suggArry.filter(function (el) {
        return el != "";
      });

      filteredSuggestedComplaints.map(item => {
        console.log("Nov7", item)
        this.allComplaints.map(ins => {
          if (item == ins.id) {

            this.suggestComplaints.push(ins);
            this.moreSymptoms = true


          }
        })
      })

      if (item.complaint != "Vaccination") {
        this.complaintsDetailsArray = []
        this.selectedComplaints.push(item.complaint)
        this.allSelectedComplaints.push(item.complaint)
        this.complaintsDetailsArray.push(item.complaint)
      }

    } else {
      this.complaintsDetailsArray = []
      this.allComplaints[index].selected = true;
      if (item.complaint != "Vaccination") {
        this.selectedComplaints.push(item.complaint)
        this.allSelectedComplaints.push(item.complaint)
        this.complaintsDetailsArray.push(item.complaint)
        this.selectedComplinats = "";

      }





      var suggArry: any = item.suggestedComplaintIds ? item.suggestedComplaintIds.split(',') : [];
      var filteredSuggestedComplaints = suggArry.filter(function (el) {
        return el != "";
      });

      console.log("feb1", filteredSuggestedComplaints)

      filteredSuggestedComplaints.map(item => {
        this.allComplaints.map(ins => {
          if (item == ins.id) {

            this.suggestComplaints.push(ins);
            this.moreSymptoms = true


          }
        })
      })
    }
    this.selectedComplinats = "";

  }






  formatArray(elemet) {
    var moreComplaints = []

    var moredata = {}
    elemet.map(ins => {
      moredata = {
        complaint: ins,
        selected: false
      }
      moreComplaints.push(moredata)
    })
    return moreComplaints

  }


  selectComplaintModalValue(item, index) {
    this.suggestComplaints = []; // Reset suggestComplaints array
    var objData: any = {};
    var dataArray = [];

    // Check if the item is already in finalSelectedComplaints
    if (!this.finalSelectedComplaints.includes(item.complaint)) {
      this.finalSelectedComplaints.push(item.complaint);
    }

    var suggArry: any = item.suggestedComplaintIds ? item.suggestedComplaintIds.split(',') : [];
    var filteredSuggestedComplaints = suggArry.filter(function (el) {
      return el != "";


    });

    let cond = this.selectedComplaints.includes(item.complaint)
    if (this.allComplaints[index].selected == true || cond) {

      var suggArry: any = item.suggestedComplaintIds ? item.suggestedComplaintIds.split(',') : [];
      var filteredSuggestedComplaints = suggArry.filter(function (el) {
        return el != "";
      });

      filteredSuggestedComplaints.map(item => {
        console.log("Nov7", item)
        this.allComplaints.map(ins => {
          if (item == ins.id) {

            this.suggestComplaints.push(ins);
            objData['User'] = ins

            this.moreSymptoms = true
            dataArray.push(objData)

          }
        })
      })
      console.log("sep20", dataArray)


    }
    else {
      this.allComplaints[index].selected = true;
      if (item.complaint != "Vaccination") {
        // this.selectedComplaints.push(item.complaint)
        console.log("selectedComplaintspush", this.selectedComplaints);
        console.log("allSelectedComplaints", this.allSelectedComplaints);


        this.allSelectedComplaints.push(item.complaint)


      }

      var suggArry: any = item.suggestedComplaintIds ? item.suggestedComplaintIds.split(',') : [];
      var filteredSuggestedComplaints = suggArry.filter(function (el) {
        return el != "";
      });

      console.log("feb1", filteredSuggestedComplaints)

      filteredSuggestedComplaints.map(item1 => {
        this.allComplaints.map(ins => {
          if (item1 == ins.id) {

            this.suggestComplaints.push(ins);

            this.moreSymptoms = true

          }
        })
      })
      let newObj = {
        name: item.complaint,
        moreSymptoms: this.suggestComplaints
      }
      this.allSelectedComplaintsAndMoreSym.push(newObj)


      console.log("Mrunali", this.allSelectedComplaintsAndMoreSym)
    }
    console.log("JANNNNN19", this.selectedComplinats)
  }
  onChangeSymptoms(ele) {
    console.log("sep23", ele)

  }
  onDropdownSelectLanguage(key, value) {
    // if (key == 'language') {
    //   this.selectedLanguage = value
    // }



    let lang = "";

    switch (value) {
      case 'English':
        lang = "en"
        break;

      case 'Hindi':
        lang = "hi"
        break;

      case 'Marathi':
        lang = "mr"
        break;

      case 'Kannada':
        lang = "kn"
        break;

      default:
        break;
    }

    this.selectedLanguage = { name: value, language: lang };
    console.log("lang", this.selectedLanguage)

  }
  onDropdownSelectTemplateLocation(key, value) {
    if (key == 'item') {
      this.templateName = value

      localStorage.setItem("doctorsTemplate", value);
    }
    console.log("template", this.templateName)
  }
  onDropdownSelectDignosis(item) {
    this.selectedDiagnosis = item.diagnosis
    this.selectedDignosisName.push(item.diagnosis);
    setTimeout(() => {
      this.selectedDiagnosis = "";
      this.diagnosisControl.setValue('');
    }, 0);

  }
  onDropdownSelectDiet(item) {
    this.selectedDietaryAdvices = item.dietAdvice
    this.selectedDietName.push(item.dietAdvice);
    setTimeout(() => {
      this.selectedDietaryAdvices = "";
      this.dietNutritionControl.setValue('');
    }, 0);
  }
  selectDietaryAdvicesModalValue(ele, index) {
    if (this.allDietaryAdvices[index].selected == true) {

    } else {
      this.allDietaryAdvices[index].selected = true;
      this.selectedDietName.push(ele.dietAdvice)
    }
  }
  onDropdownSelectGeneralInsModalValue(item) {
    this.selectedGeneralIns = item.generalInstruction
    this.selectedGeneralInstructionsName.push(item.generalInstruction)
    setTimeout(() => {
      this.selectedGeneralIns = "";
      this.instructionControl.setValue('');
    }, 0);

  }
  selectDignosisModalValue(item) {
    if (!this.selectedDignosisName.includes(item.diagnosis)) {
      this.selectedDignosisName.push(item.diagnosis);
    }

  }
  selectGeneralInsModalValue(item, index) {
    if (this.allGeneralInstructions[index].selected == true) {

    } else {
      this.allGeneralInstructions[index].selected = true;
      this.selectedGeneralInstructionsName.push(item.generalInstruction)
    }

  }

  getDiagnosisOfDoctorAsPerComplaints() {
    // this.sectionLoading = true;
    let testArr = [];
    //this.allComplaintsName=observable<string[]>
    this.allComplaintsOrg.map(ins => {
      if (ins.selected) {

        testArr.push(ins.id);
      }
    });
    console.log("testArray", testArr)

    var postData = {
      complaint_collection: testArr ? testArr : []
    }
    console.log("postdata", postData)
    this.patientService.getDiagnosisOfDoctorAsPerComplaints(postData).subscribe(response => {
      if (response) {
        console.log("getDiagnosisFeb21", response)
        if (!response.response.resultArr.length) {
          this.allDignosis = response.response.remainingArr
          this.allDignosisOrg = response.response.remainingArr

        }
        else {
          this.allDignosis = response.response.resultArr
          this.allDignosisOrg = response.response.remainingArr.concat(this.allDignosis)
        }
        console.log("allDignosis", this.allDignosisOrg);
        console.log("resultArrDignosis", this.allDignosis);
        this.allDignosisName = this.allDignosis.map(ins => { return ins.diagnosis });

        this.allDignosisName = this.diagnosisControl.valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value))
        );
        // this.sectionLoading = false
        console.log("Sep17", this.allDignosisName)
      }
    })
  }
  private _filter(value: any): string[] {
    let filterValue = '';

    if (value) {
      if (typeof value === 'string' || value instanceof String) {
        filterValue = value.toLowerCase();
      } else if (value.searchstr) {
        filterValue = value.searchstr.toLowerCase();
      }
    }

    if (this.selectedMatexpansionpanelValue == 'Diet') {
      return this.allDietaryAdvices.filter(option => option.dietAdvice.toLowerCase().includes(filterValue));
    } else if (this.selectedMatexpansionpanelValue == 'GeneralIns') {
      return this.allGeneralInstructions.filter(option => option.generalInstruction.toLowerCase().includes(filterValue));
    } else {
      return this.allDignosis.filter(option => option.diagnosis.toLowerCase().includes(filterValue));
    }



  }

  getDietAdviceOfDoctorAsPerDiagnosis() {
    var dignosisIds: any = [];
    this.allDignosis.map(ins => {
      if (ins.selected) {
        dignosisIds.push(ins.id)
      }

    })
    console.log("dignosisIds", dignosisIds)


    var postData = {
      diagnosisCollection: dignosisIds ? dignosisIds : [],
      lang: ''

    }

    this.patientService.getDietAdviceOfDoctorAsPerDiagnosis(postData).subscribe(response => {
      if (response) {
        console.log("resp", response)
        if (!response.response.resultArr || response.response.resultArr.length == 0) {

          var returndiets: any = []
          response.response.remainingArr.map(eachDietFromDB => {
            for (let i = 0; i < dignosisIds.length; i++) {
              let searchablediagnosisId = "," + dignosisIds[i] + ",";
              if (eachDietFromDB.diagnosisCollection && eachDietFromDB.diagnosisCollection.includes(searchablediagnosisId)) {
                returndiets.push(eachDietFromDB);
              }
            }
          })
          //set search array filtered
          this.allDietaryAdvices = response.response.remainingArr;
          console.log("dietData", this.allDietaryAdvices)

          //keep original array 
          this.allDietaryAdvicesOrg = response.response.remainingArr;

        }
        else {
          this.allDietaryAdvices = response.response.resultArr
          this.allDietaryAdvicesOrg = response.response.remainingArr.concat(this.allDietaryAdvices)
        }
        this.allDietName = this.allDietaryAdvices.map(ins => { return ins.dietAdvice });

        this.allDietName = this.dietNutritionControl.valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value))
        );
        console.log("allDietary", this.allDietaryAdvices)


        // this.selectedDietaryAdvices.map(ins => {
        //   this.allDietaryAdvices.map(item => {
        //     if (ins == item.dietAdvice) {
        //       item.selected = true

        //     }
        //   })
        // })

        //calling search to show everything
        //  this.searchDietaryAdvice("", 'dietaryAdvice');

      }
    }, error => {
      console.log(error)
    });

  }

  getGeneralInstructionsOfDoctor() {
    var dignosisIds: any = [];
    this.allDignosis.map(ins => {
      if (ins.selected) {
        dignosisIds.push(ins.id)
      }

    })
    var postData = {
      diagnosisCollection: dignosisIds ? dignosisIds : [],
      lang: ''
    }
    this.patientService.getGeneralInstructionsOfDoctor(postData).subscribe(response => {
      if (response) {
        if (!response.response.resultArr || response.response.resultArr.length == 0) {

          var data: any = []
          response.response.remainingArr.map(eachGInstFromDB => {
            for (let i = 0; i < dignosisIds.length; i++) {
              let searchableGIstId = "," + dignosisIds[i] + ",";
              if (eachGInstFromDB.diagnosisCollection && eachGInstFromDB.diagnosisCollection.includes(searchableGIstId)) {
                data.push(eachGInstFromDB);
              }
            }
          })

          //  this.allGeneralInstructions = data;
          this.allGeneralInstructions = response.response.remainingArr
          console.log("dietData", data)
          this.allGeneralInstructionsOrg = response.response.remainingArr
        }
        else {
          this.allGeneralInstructions = response.response.resultArr
          this.allGeneralInstructionsOrg = response.response.remainingArr.concat(this.allGeneralInstructions)
        }
        this.allGeneralInstructionsName = this.allGeneralInstructions.map(ins => { return ins.dietAdvice });

        this.allGeneralInstructionsName = this.instructionControl.valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value))
        );
        // this.selectedGeneralInstructions.map(ins => {
        //   this.allGeneralInstructions.map(item => {
        //     if (ins == item.generalInstruction) {
        //       item.selected = true;
        //     }
        //   })
        // })

        //  this.searchGeneralInstructions('', 'generalInstructions');

      }
    })
  }
  openGroup(event) {
    console.log("SEP18", event)
    if (event == 'Diagnosis') {
      this.getDiagnosisOfDoctorAsPerComplaints();
    }
    if (event == "Complaints") {
      this.getComplaintsOfDoctor();
    }
    if (event == "Diet") {
      this.selectedComplaints = ""
      this.nextFollowUpMessage = ""
      this.getDietAdviceOfDoctorAsPerDiagnosis();
    }
    if (event == "GeneralIns") {
      this.getGeneralInstructionsOfDoctor();
    }
    this.selectedMatexpansionpanelValue = event

  }

  onClickOverview() {
    this.router.navigate([`/doctor/patients/profileSummary/view`], { queryParams: { patientId: this.patientId } })
  }

  getspecialVaccination(event, item) {
    console.log("element", event)
    if (event.checked == true) {
      var name = ""
      if (item.name.includes(',')) {
        name = item.name
      }
      else {
        name = item.name + " ,"
      }

      this.selectedSpecialVaccination.push(name)
      //  console.log("ele1", this.selectedSpecialVaccination.join(" "))
    }
    if (event.checked == false) {

      var itemList = this.selectedSpecialVaccination.indexOf(item.name);
      this.selectedSpecialVaccination.splice(itemList, 1);
      console.log("ele2", this.selectedSpecialVaccination)
    }
    this.selectedSpecialVaccination = this.selectedSpecialVaccination.reduce((unique, o) => {
      if (!unique.some(obj => obj == o)) {
        unique.push(o);
      }
      return unique;
    }, []);
  }

  selectModalVitalFieldValue = (item) => {


    let selectedFieldsArray = selectedVitalsFields
    let selectedVitals: any = []

    selectedFieldsArray.map(ins => {
      item.map(itr => {
        if (ins.value == itr) {
          ins.selected = true
          selectedVitals.push(ins)
        }
      })
    })
    var data = selectedVitals;

    this.selectFieldsArray = selectedVitals

    var arr = data.filter(field => { if (field.selected) { return field } });
    this.printingVitals = arr.map(field => { return field.value });

    this.selectFieldsArray = selectFields
  }
  openPanel(isOpen) {
    if (isOpen) {
      this.selectFieldsArray = selectFields
      this.selectFieldsValue = ""
    }
  }
  onDeleteComplaints(item, ind) {



    // if(item.key){
    var itemList = this.allSelectedComplaintsAndMoreSym.indexOf(item);
    //   var complaintData=this.complaintDetailsData.indexOf(item)
    //  this.complaintDetailsData.splice(index,1)
    this.allSelectedComplaintsAndMoreSym.splice(itemList, 1);

    this.finalSelectedComplaints.splice(ind, 1);
    // var finalCom=this.finalSelectedComplaints.indexOf(item.key);
    // this.finalSelectedComplaints.splice(finalCom, 1);

    //else{
    // var finalCom=this.finalSelectedComplaints.indexOf(item.key);

    //}


    // this.finalSelectedComplaints.map((ins,ind) => {
    //   if (index == ind) {
    //    this.finalSelectedComplaints.splice(ins,1)

    //   }
    // })
  }
  onClickDeleteData(ele, item) {
    if (ele == 'GeneralInstructions') {
      var itemList = this.selectedGeneralInstructionsName.indexOf(item);
      this.selectedGeneralInstructionsName.splice(itemList, 1);
      this.allGeneralInstructions.map(ins => {
        if (ins.generalInstruction == item) {
          ins.selected = false
        }
      })
    }
    if (ele == "Diet") {
      var itemList = this.selectedDietName.indexOf(item);
      this.selectedDietName.splice(itemList, 1);
      this.allDietaryAdvices.map(ins => {
        if (ins.dietAdvice == item) {
          ins.selected = false
        }
      })

    }
    if (ele == 'Dignosis') {
      var itemList = this.selectedDignosisName.indexOf(item);
      this.selectedDignosisName.splice(itemList, 1);
    }
  }


  onClickPatient(item) {
    // var patientId= this.patientId;
    // this.testing.closeAll();
    this.patientObj = item
    this.patientId = item.PatientId
    this.patientSelected = true
    this.finalSelectedComplaints = []
    this.complaintDetailsData = []
    this.allSelectedComplaintsAndMoreSym = []
    this.selectedPreviousFavMed = []
    this.suggestComplaints = []

    this.getPatientInfo();
    this.getMedicinesAsPerDiagnosis();
    this.getAllDoctorPrescriptions();
    this.getPrescriptionMedicines();

    this.navService.setShowNav(false);
    this.refreshservice.patientHistory(this.patientId);

    this.router.navigate(['/newrx/rxHomePage'], { queryParams: { patientId: this.patientId } });
  
  }
  navigateToOverview() {

    // localStorage.setItem('patientInfo', JSON.stringify(this.patientInfo));
    this.router.navigate([`/doctor/patients/profileSummary/view`], { queryParams: { patientId: this.patientId } })
  }
  openReceipt() {
    this.selectedIndexForTab = 4;
    this.navigateToOverview();
    setTimeout(() => {
      this.refreshservice.OnPatTopNav_MenuSelected(this.patientId, this.refreshservice.EventName_Receipt);
    }, 500);
  }


  openChildComponent() {
    this.router.navigate([`/doctor/patients/profileSummary/view/receipt`], { queryParams: { patientId: this.patientId, } })

  }


  goToTab(item) {
    if (item == "Receipts") {
      console.log(" item==Receipts fired ");
      this.router.navigate([`/doctor/patients/profileSummary/view`], { queryParams: { patientId: this.patientId, navgationValue: item, selectedIndex: 4 } })


    } else {
      this.router.navigate([`/doctor/patients/profileSummary/view`], { queryParams: { patientId: this.patientId, navgationValue: item } })

    }

  }
  toggleMedicineSection = (value) => {

    if (value == 3) {
      this.getFavApptsOfDoctor();
      setTimeout(() => {
        if (this.searchFavourites) {
          this.searchFavourites.nativeElement.focus();
        }
      }, 300);
      // if(value==4){
      //   this.getAllDoctorPrescriptions();
      // }
    }

    this.medicineSectionToggle = value
  }


  addNewMedicine($event) {

    if (!this.isAddMedicine) {

      setTimeout(() => {
        this.UnitField.setErrors(null);
        this.FrequencyField.setErrors(null)
        this.WhentotakeField.setErrors(null)
        this.drugTypeField.setErrors(null)
        this.DurationField.setErrors(null)
      }, 500);

      //this.clearAddMedicine()
    }

    this.isAddMedicine = true;

  }

  clearAddMedicine() {

    this.selectedUnit = '';
    this.selectedDrugType = '';
    this.selectedFrequency = [];
    this.selectedDuration = '';
    this.selectedWhenToTake = '';
    this.medicineName = "";
    this.doseCount = [{ unit: "", drugType: "", frequency: "", duration: "", whenToTake: "" }];
    this.searchedMedicineObj = {}
    this.selectedInstructions = "";
    this.drugUnit = null;

    this.UnitField.value = "";
    this.FrequencyField.value = "";
    this.WhentotakeField.value = "";
    this.drugTypeField.value = ""
    this.DurationField.value = "";
    this.DrugUnitField.value = "";

    this.drugTypeUnit = "";



    this.allMedicineInstructions = this.allMedicineInstructions.map(ins => { return { name: ins.name, selected: false } });
    this.allMedicineInstructionsOg = this.allMedicineInstructions;

    this.lngMedInstr.clearTextBox();
  }



  searchDrugsByTyping = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term === '' ? []
        : this.searchedMeds?.filter(v => JSON.stringify(v)))
    )
  formatter = (result: any) => result.product_name;
  formatMatches = (result: any) => result.product_name ? result.product_name : '';



  getSearchedMeds = (searchString) => {
    var searchDrugOnDoctorId: any = []
    if (searchString.length >= 3) {
      this.medicineLoader = true;
      var obj = { "query": searchString };
      this.patientService.getDrugs(obj).subscribe(response => {
        console.log('Response', response);
        this.medicineLoader = false;
        this.searchedMeds = response.drugs.reduce((unique, o) => {
          if (!unique.some(obj => obj.product_name.replaceAll(' ', '').toLowerCase() === o.product_name.replaceAll(' ', '').toLowerCase() && obj.product_type === o.product_type)) {
            unique.push(o);
          }
          return unique;
        }, []);
        this.searchedMeds.map(ins => {
          if (ins.doctorId == this.doctorObj.id || ins.doctorId == null) {
            searchDrugOnDoctorId.push(ins)
          }
        })
        this.searchedMeds = searchDrugOnDoctorId


        console.log(this.doctorObj)
        // this.searchedMeds = this.searchedMeds.splice(0, 5);

      }
      )
    } else {
      this.searchedMeds = [];
      this.medicineLoader = false;
    }
  }


  getDoctorDetails = () => {
    let params = {
      user_id: this.userId
    }
    this.doctorService.getDetails(params).subscribe(response => {
      console.log('Doctor Response', response.doctor)
      this.appointmentFlag = response.doctor.isAppointmentEnabled
      this.doctorObj = response.doctor

      //  this.currentDoctorObj = response.doctor
      if (response.success) {
        if (response.doctor.services && response.doctor.services !== null) {
          var services = JSON.parse(response.doctor.services);

          this.showOtherId = services && services.showAnotherId ? services.showAnotherId : ''
          this.showLocality = services && services.showLocality ? services.showLocality : false
          console.log("services", services)
          //  this.selectedTemplate=JSON.parse(response.doctor.ser)
          this.drugMolecularFlag = services && services.drugMoleculeFlag ? services.drugMoleculeFlag : false
          var language = services && services.prescriptionLang ? services.prescriptionLang : 'en'
          var localLanguage: any = JSON.parse(localStorage.getItem("doctorsLanguage"));
          this.nextAppointmentDays = services && services.nextApptDays ? services.nextApptDays : ''

          if (this.nextAppointmentDays) {
            this.followupDateNew = new Date(new Date().setDate(new Date().getDate() + this.nextAppointmentDays));

            localStorage.setItem("followupDate", this.followupDateNew)
          }
          else {
            this.followupDateNew = new Date(new Date().setDate(new Date().getDate() + 5));
            localStorage.setItem("followupDate", this.followupDateNew)
          }

          var selectedLanguage = {}
          this.languageArray.map(ins => {
            if (ins.language == language) {
              selectedLanguage = { name: ins.name, language: ins.language }
            }
          })
          if (this.backToEdit) {
            this.selectedLanguage = localLanguage ? localLanguage : selectedLanguage
          }
          else {
            this.selectedLanguage = selectedLanguage
          }



          if (services.template_names) {
            // this.tabs.push('Receipts');
            this.templateName = 'Custom Prescription';
            this.selectedTemplate = services.template_names;
            // here
            let temp = localStorage.getItem("selectDoctorsTemplate");

            if (temp) {
              this.templateName = temp
            } else {
              this.templateName = services.template_names[0];
            }
          }
          else {
            this.selectedTemplate.push('default');
          }
        }
        else {
          this.templateName = 'default';
          this.selectedTemplate.push('default');
        }
      }
    })
  }

  selectMedicine(item, index) {

  }

  setSelectMedicine = ({ item }) => {

    console.log('ITEM', item);

    //

    let arr = item.advisedFrequency ? item.advisedFrequency.split(', ') : [];


    this.frequencyWithVal.map(eachFrequency => {

      arr.map(innerFrequency => {
        if (eachFrequency.name.includes(innerFrequency)) {
          eachFrequency.value = item.advisedDosage
        }
      })

    })

    this.searchedMedicineObj = item;
    this.selectedInstructions = item.advisedInstructions ? item.advisedInstructions : ""
    console.log("selectedInstructions", this.selectedInstructions);

    setTimeout(() => {
      this.lngMedInstr.clearTextBox()
    }, 100)
    setTimeout(() => {
      this.lngMedInstr.appendTexttoBox(this.selectedInstructions)
    }, 200)

    console.log("ins", this.selectedInstructions)
    console.log("medData", this.searchedMedicineObj)


    var drugType = this.handleDrugType(item.product_type);

    if (drugType && drugType != "") {
      // this.drugTypeField.setErrors(null)
    }
    // this.selectedDrugType = drugType

    let index = this.doseCount.length - 1;

    if (this.drugTypeSelection.includes(drugType)) {
      this.doseCount[index].drugType = drugType;
      this.selectedDrugType = drugType;
      this.dropdownSelect('drugType', this.selectedDrugType);
      this.drugTypeField.setErrors(null);
    } else {
      this.doseCount[index].drugType = "";
      this.selectedDrugType = ""
    }

    if (item.advisedDosage && parseInt(item.advisedDosage)) {
      this.doseCount[index].unit = item.advisedDosage
      this.selectedUnit = item.advisedDosage;
      // this.drugUnit = item.advisedDosage;
      let name = document.getElementById('drugUnit_0');
      this.drugUnit = item.advisedDosage;

      // name = item.advisedDosage

      this.UnitField.setErrors(null);
    } else {
      // this.doseCount[index].unit = ""
      // this.selectedUnit = "";
      this.UnitField.setErrors(null);
    }

    // FREQUENCY
    if (item.advisedFrequency && item.advisedFrequency != "" && item.advisedFrequency.toLowerCase() != "null") {

      let formattedFrequency = item.advisedFrequency.split(', ').map(ins =>
        ins.charAt(0).toUpperCase() + ins.slice(1)
      );

      this.doseCount[index].frequency = formattedFrequency;
      this.selectedFrequency = formattedFrequency;
      this.FrequencyField.setErrors(null);
    } else {
      this.doseCount[index].frequency = "";
      this.selectedFrequency = "";
    }


    //DURATION
    if (item.advisedDuration && item.advisedDuration != "" && item.advisedDuration.toLowerCase() != "null") {

      this.DurationField.setErrors(null)
      if (item.advisedDurationUnit) {
        this.doseCount[index].duration = item.advisedDuration + ' ' + item.advisedDurationUnit
        this.selectedDuration = item.advisedDuration + ' ' + item.advisedDurationUnit
      } else {
        if (item.advisedDuration == "1") {
          this.doseCount[index].duration = item.advisedDuration + ' ' + 'Day(s)';
          this.selectedDuration = item.advisedDuration + ' ' + 'Day(s)';
        } else {
          this.doseCount[index].duration = item.advisedDuration + ' ' + 'Day(s)';
          this.selectedDuration = item.advisedDuration + ' ' + 'Day(s)';
        }
      }
    } else {
      this.doseCount[index].duration = "";
      this.selectedDuration = "";
    }

    //WHEN TO TAKE
    if (item.advisedWhenToTake && item.advisedWhenToTake != "" && item.advisedWhenToTake.toLowerCase() != "null" && whenToTake.includes(item.advisedWhenToTake.charAt(0).toUpperCase() + item.advisedWhenToTake.slice(1))) {

      // element.drugType.charAt(0).toUpperCase() + element.drugType.slice(1) : " ";
      this.doseCount[index].whenToTake = item.advisedWhenToTake.charAt(0).toUpperCase() + item.advisedWhenToTake.slice(1)
      this.selectedWhenToTake = item.advisedWhenToTake.charAt(0).toUpperCase() + item.advisedWhenToTake.slice(1)
      this.WhentotakeField.setErrors(null);

    } else {
      this.doseCount[index].whenToTake = ""
      this.selectedWhenToTake = ""
    }

    var duration = "";
    this.doseCount.map(ins => {
      if (ins.duration == '') {
        duration = ins
      }
    });
    setTimeout(() => {

      this.selectedDrugUnit = this.drugTypeUnit;
      this.DrugUnitField.setErrors(null)
    }, 200);

  }


  handleDrugType(productType) {
    var drugType = "";
    if (productType.toLowerCase().includes('cap')) {
      drugType = "Capsule"
    }
    else if (productType.toLowerCase().includes('cre')) {
      drugType = "Cream"
    }
    else if (productType.toLowerCase().includes('tab')) {
      drugType = "Tablet"
    }
    else if (productType.toLowerCase().includes('stri')) {
      drugType = "Strip"
    }
    else if (productType.toLowerCase().includes('syru')) {
      drugType = "Syrup"
    }
    else if (productType.toLowerCase().includes('drop')) {
      drugType = "Drop"
    } else if (productType.toLowerCase().includes('inhale')) {
      drugType = "Inhaler"
    } else if (productType.toLowerCase().includes('liqui')) {
      drugType = "Liquid"
    } else if (productType.toLowerCase().includes('powd')) {
      drugType = "Powder"
    } else if (productType.toLowerCase().includes('sach')) {
      drugType = "Sachet"
    } else if (productType.toLowerCase().includes('spray')) {
      drugType = "Spray"
    } else if (productType.toLowerCase().includes('susp')) {
      drugType = "Suspension"
    } else if (productType.toLowerCase().includes('vial')) {
      drugType = "Vial"
    }
    return drugType || productType
  }



  dropdownSelect(key, value) {
    this.drugType = value

    console.log('key', value);
    switch (key) {

      case 'unitNewMed':
        this.unitForNewMed = value;
        break;

      case 'frequencyNewMed':
        this.frequencyForNewMed = value;
        break;

      case 'durationNewMed':
        this.durationForNewMed = value
        break;

      case 'whenToTakeNewMed':
        this.whenToTakeForNewMed = value;
        break;

      case 'unit':
        this.Tounit = "";
        if (this.doseCount.length > 1) {

          this.doseCount[this.doseCount.length - 1].unit = value

        } else {
          this.selectedUnit = value

        }
        break;

      case 'drugType':


        switch (value) {
          case "Tablet":
            this.units = [0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15];
            this.drugTypeUnit = "Unit";
            this.selectedDrugType = value
            this.selectedDrugUnit = "Unit"
            this.DrugUnitField.value = "Unit"
            break;

          case "Syrup":
          case "Liquid":
          // case "Lotion":
          case "Suspension":
            this.units = ["1 ml", "1.5 ml", "2 ml", "2.5 ml", "3 ml", "3.5 ml", "4 ml", "4.5 ml", "5 ml", "5.5 ml", "6.5 ml", "7 ml", "7.5 ml", "8 ml", "8.5 ml", "9 ml", "9.5 ml", "10 ml"]
            this.drugTypeUnit = "ml";
            this.selectedDrugType = value
            this.selectedDrugUnit = "ml";
            this.DrugUnitField.value = "ml"
            break;

          case "Drop":
            this.units = ["1", "2", "3", "4", "5", "10", "15", "20"]
            this.drugTypeUnit = "Drop";
            this.selectedDrugUnit = "Drop"
            this.DrugUnitField.value = "Drop"
            this.selectedDrugType = "Drop"


            break;

          case "Cream":
          case "Gel":
          case "Ointment":
          case "Oil":
          case "Jelly":
          case "Lotion":
          case "Soap":
          case "Suppository":


            this.units = ['Fingertip']
            this.drugUnit = "Fingertip";


            this.selectedDrugType = value

            if (this.doseCount.length > 1) {
              this.doseCount[this.doseCount.length - 1].unit = "Fingertip";
              this.selectedDrugUnit = "Fingertip"
            } else {
              this.selectedDrugUnit = "Fingertip"
              this.doseCount[0].unit = "Fingertip"
            }

            this.DrugUnitField.value = "Fingertip"

            break;

          case "Inhaler":
            this.units = ['1 Puff', '2 Puff', '3 Puff', '4 Puff', '5 Puff', '6 Puff', "7 Puff", "8 Puff", "9 Puff", "10 Puff"]
            this.drugTypeUnit = "Puff";
            this.selectedDrugType = value

            if (this.doseCount.length > 1) {
              this.doseCount[this.doseCount.length - 1].unit = "Puff"

            } else {
              // this.selectedDrugUnit = "Puff"
              this.doseCount[0].unit = "Puff"
            }

            this.DrugUnitField.value = "Puff"
            break;

          case "Powder":
          case "Granules":
            this.units = ['1 Sachet', '2 Sachet', '3 Sachet', '4 Sachet', '5 Sachet', '6 Sachet', "10 Sachet"];
            this.drugTypeUnit = "Sachet";
            this.selectedDrugType = value

            this.selectedDrugUnit = "Sachet"
            this.DrugUnitField.value = "Sachet"
            break;

          case "Vial":
            this.units = ['2.5', '5', '10', '20', '30', '40', '50']
            this.drugTypeUnit = "Unit";
            this.selectedDrugUnit = "Unit"
            this.DrugUnitField.value = "Unit"
            break;

          case "Capsule":
          case "Sachet":
          case "Spray":
          case "Injection":
            this.selectedDrugType = value
            this.units = ['1', '2', '3', '4', '5', '6', '7', '8'];
            this.drugTypeUnit = "Unit";
            this.selectedDrugUnit = "Unit";
            this.DrugUnitField.value = "Unit"
            break;

          default:
            this.units = ['1', '2', '3', '4', '5', '6', '7', '8'];
            this.drugTypeUnit = "Unit";
            this.selectedDrugUnit = "Unit";
            this.DrugUnitField.value = "Unit"
            break;
        }


        if (this.doseCount.length > 1) {
          this.doseCount[this.doseCount.length - 1].drugType = value

        } else {
          this.selectedDrugType = value
        }
        break;

      case 'frequency':
        if (this.doseCount.length > 1) {
          this.doseCount[this.doseCount.length - 1].frequency = value
        } else {
          this.selectedFrequency = value;
          this.doseCount[0].frequency = value
        }
        break;
      case 'duration':
        if (this.doseCount.length > 1) {
          this.doseCount[this.doseCount.length - 1].duration = value
          this.selectedDuration = this.selectedWhenToTake;
        } else {
          this.doseCount[0].duration = value
          this.selectedDuration = value
        }
        break;

      case 'whenToTake':
        if (this.doseCount.length > 1) {
          this.doseCount[this.doseCount.length - 1].whenToTake = value
        } else {
          this.selectedWhenToTake = value;
          this.doseCount[0].whenToTake = value
        }
        break;

      case 'drugType':
        if (this.doseCount.length > 1) {
          this.doseCount[this.doseCount.length - 1].drugType = value
        } else {
          this.selectedDrugType = value;
        }

      case 'drugUnit':
        this.selectedUnit = value;

        this.drugTypeUnit = value;
        this.DrugUnitField.value = value
        break;

      default:
        break;
    }
  }

  onChangeDrugName = () => {
    this.unselectedDrugName = false;
  }
  toggleFrequencyCollapse() {
    if (this.frequencyCollapsibleVariable == 4) {
      this.frequencyCollapsibleVariable = 9;
    } else {
      this.frequencyCollapsibleVariable = 4;
    }
  }
  onUnitChange(value, index) {

    if (this.doseCount.length > 1) {

      this.doseCount[this.doseCount.length - 1].unit = value

    } else {

      this.selectedUnit = value;
      this.doseCount[0].unit = value
    }
  }

  addToList() {

    var dignosisId: any = [];
    this.allDignosis.map(ins => {
      if (ins.selected) {
        dignosisId.push(ins.id)
      }

    });

    let dosage = '';
    let frequencyArr: any = [];
    let frequency = ''
    this.frequencyWithVal.map(eachFrequency => {
      let dose: any = ''
      // if(eachFrequency.value==""){
      //   dose=""
      // }
      if (eachFrequency.value && eachFrequency.value != '0' && eachFrequency.value != 'NULL') {


        let frequency = '';


        if (this.drugTypeUnit == 'ml') {
          dose = (eachFrequency.value + "")

        }
        else {
          dose = this.convertDoseUnitToUnicode(eachFrequency.value + "")

        }

        let doseValue = ""
        if (this.drugTypeUnit == "Fingertip,Apply-Locally") {
          this.drugUnit = this.drugTypeUnit
        }
        if (this.drugUnit == "Fingertip") {
          this.drugTypeUnit = this.drugUnit
        }


        doseValue = this.drugTypeUnit == 'ml' ? dose + ' ml' : dose;
        let unit = this.drugTypeUnit == 'Unit' || this.drugTypeUnit == 'ml' ? this.selectedDrugType : this.drugTypeUnit;
        frequency = frequency + `${doseValue} - ${unit} - ${eachFrequency.name}`
        frequencyArr.push(frequency);
      }
    });

    let name = "";
    if (this.medicineName.product_name && this.medicineName.product_name != "" && this.selectedDrugType != "") {

      let unitType = '';
      let newUnitFinal = ""
      if (this.drugTypeUnit == 'ml' ||
        this.drugTypeUnit == 'Puff'
        || this.drugTypeUnit == 'Sachet') {
        unitType = this.drugTypeUnit
      }
      if (this.drugTypeUnit == 'ml') {
        newUnitFinal = this.selectedUnit
      }
      else {
        newUnitFinal = this.convertDoseUnitToUnicode(this.selectedUnit);
      }

      if (this.drugMolecularFlag == 1) {
        console.log("drugFlag", this.drugMolecularFlag)

        name = this.medicineName.product_name && this.medicineName.product_name;
        console.log("name", name)
        if (this.selectedFrequency.length) {
          dosage = `${newUnitFinal} ${unitType} - ${this.selectedDrugType} - ${this.selectedFrequency.join(', ')} - ${this.selectedDuration}, ${this.selectedWhenToTake ? this.selectedWhenToTake : ""}`
        }
        else {
          dosage = `${newUnitFinal} ${unitType} - ${this.selectedDrugType} - ${this.selectedFrequency} - ${this.selectedDuration}, ${this.selectedWhenToTake ? this.selectedWhenToTake : ""}`
        }
      }
      else {
        name = this.medicineName.product_name && this.medicineName.product_name;
        if (this.selectedFrequency.length) {
          dosage = `${newUnitFinal} ${unitType} - ${this.selectedDrugType} - ${this.selectedFrequency.join(', ')} - ${this.selectedDuration}, ${this.selectedWhenToTake ? this.selectedWhenToTake : ""}`

        }
        else {
          dosage = `${newUnitFinal} ${unitType} - ${this.selectedDrugType} - ${this.selectedFrequency} - ${this.selectedDuration}, ${this.selectedWhenToTake ? this.selectedWhenToTake : ""}`
        }
      }


      // Drug molecular flag.
      // if (this.drugMolecularFlag == 1) {
      //   name = this.medicineName.product_name && this.medicineName.generic_name ? this.medicineName.product_name + ' (' + this.medicineName.generic_name + ')' : this.medicineName.product_name;

      // }
      // else {
      //   name = this.medicineName.product_name && this.medicineName.product_name;
      // }
      // END


      let Drug = {
        id: this.searchedMedicineObj.id,
        product_name: this.medicineName.product_name,
        generic_name: this.medicineName.generic_name,

      }
      console.log("Drug Obj", Drug, this.lngMedInstr.getValue());
      var dosageValue: any = ""
      if (frequencyArr.length == 0) {
        dosageValue = newUnitFinal
      }
      else {
        dosageValue = frequencyArr.join(', ')
      }
      let obj = {
        // id: this.searchedMedicineObj.id,... drud id isin "Drug" obj below
        name: this.medicineName.product_name,
        generic_name: this.medicineName.generic_name,

        dosage: dosageValue,
        //dosage2: frequencyArr.join(', '),
        dosage2: dosageValue,
        duration2: this.selectedDuration,
        whenToTake: this.selectedWhenToTake ? this.selectedWhenToTake : "",
        drugType: this.selectedDrugType,
        duration: this.selectedDuration,
        unitType: unitType,
        dosageValue: newUnitFinal,
        frequency: this.selectedFrequency && this.selectedFrequency.length ? this.selectedFrequency.join() : '',
        Drug,
        //instructions: this.selectedInstructions,
        instructions: this.lngMedInstr.getValue(),
        selected: true
      }
      this.allMedicines.push(obj);
      console.log("allMedicinesallMedicines", this.allMedicines);

      console.log("newMed", Drug, "all", this.allMedicines)


      //save this drug and doage to doctor's popular used
      var postData: any = {
        drugObj: obj,
        commaSeparatedDiagnosisIds: "" // dignosisId.join() // not saving medicines against diagnosis
      }
      this.patientService.saveMedicineOfDoctorperDiagnosis(postData).subscribe(response => {
        if (response) {
          console.log("saved 1 drug to doc popular for diag", response);
          this.getMedicinesAsPerDiagnosis();

          this.frequencyWithVal.map(eachFrequency => {
            if (eachFrequency.value && eachFrequency.value != '0') {
              eachFrequency.value = "";
            }
          });
        }
      })

      this.clearAddMedicine();
    } else {
      if (!this.medicineName.product_name && this.medicineName.length > 2) {
        this.proceedAddMedicine();
        this.dialog.open(this.add_new_drug_modal);
      } else {
        this.dialog.open(this.incomplete_data_modal);
      }

      this.UnitField.markAsTouched();
      this.DurationField.markAsTouched();
      this.drugTypeField.markAsTouched();
      this.searchTextField.markAsTouched();
      this.DrugUnitField.markAsTouched();

    }

    setTimeout(() => {
      document.getElementById("typeahead-format").focus();
    }, 500);

  }
  // addToList() {

  //   var dignosisId: any = [];
  //   this.allDignosis.map(ins => {
  //     if (ins.selected) {
  //       dignosisId.push(ins.id)
  //     }

  //   });

  //   let dosage = '';
  //   let frequencyArr: any = [];
  //   let frequency = ''
  //   this.frequencyWithVal.map(eachFrequency => {
  //     let dose: any = ''
  //     // if(eachFrequency.value==""){
  //     //   dose=""
  //     // }
  //     if (eachFrequency.value && eachFrequency.value != '0' && eachFrequency.value != 'NULL') {


  //       let frequency = '';


  //       if (this.drugTypeUnit == 'ml') {
  //         dose = (eachFrequency.value + "")

  //       }
  //       else {
  //         dose = this.convertDoseUnitToUnicode(eachFrequency.value + "")

  //       }

  //       let doseValue = ""
  //       if (this.drugTypeUnit == "Fingertip,Apply-Locally") {
  //         this.drugUnit = this.drugTypeUnit
  //       }
  //       if (this.drugUnit == "Fingertip") {
  //         this.drugTypeUnit = this.drugUnit
  //       }


  //       doseValue = this.drugTypeUnit == 'ml' ? dose + ' ml' : dose;
  //       let unit = this.drugTypeUnit == 'Unit' || this.drugTypeUnit == 'ml' ? this.selectedDrugType : this.drugTypeUnit;
  //       frequency = frequency + `${doseValue} - ${unit} - ${eachFrequency.name}`
  //       frequencyArr.push(frequency);
  //     }
  //   });

  //   let name = "";
  //   if (this.medicineName.product_name && this.medicineName.product_name != "" && this.selectedDrugType != "") {

  //     let unitType = '';
  //     let newUnitFinal = ""
  //     if (this.drugTypeUnit == 'ml' ||
  //       this.drugTypeUnit == 'Puff'
  //       || this.drugTypeUnit == 'Sachet') {
  //       unitType = this.drugTypeUnit
  //     }
  //     if (this.drugTypeUnit == 'ml') {
  //       newUnitFinal = this.selectedUnit
  //     }
  //     else {
  //       newUnitFinal = this.convertDoseUnitToUnicode(this.selectedUnit);
  //     }

  //     if (this.drugMolecularFlag == 1) {
  //       console.log("drugFlag", this.drugMolecularFlag)

  //       name = this.medicineName.product_name && this.medicineName.product_name;
  //       console.log("name", name)
  //       if (this.selectedFrequency.length) {
  //         dosage = `${newUnitFinal} ${unitType} - ${this.selectedDrugType} - ${this.selectedFrequency.join(', ')} - ${this.selectedDuration}, ${this.selectedWhenToTake ? this.selectedWhenToTake : ""}`
  //       }
  //       else {
  //         dosage = `${newUnitFinal} ${unitType} - ${this.selectedDrugType} - ${this.selectedFrequency} - ${this.selectedDuration}, ${this.selectedWhenToTake ? this.selectedWhenToTake : ""}`
  //       }
  //     }
  //     else {
  //       name = this.medicineName.product_name && this.medicineName.product_name;
  //       if (this.selectedFrequency.length) {
  //         dosage = `${newUnitFinal} ${unitType} - ${this.selectedDrugType} - ${this.selectedFrequency.join(', ')} - ${this.selectedDuration}, ${this.selectedWhenToTake ? this.selectedWhenToTake : ""}`

  //       }
  //       else {
  //         dosage = `${newUnitFinal} ${unitType} - ${this.selectedDrugType} - ${this.selectedFrequency} - ${this.selectedDuration}, ${this.selectedWhenToTake ? this.selectedWhenToTake : ""}`
  //       }
  //     }



  //     let Drug = {
  //       id: this.searchedMedicineObj.id,
  //       product_name: this.medicineName.product_name,
  //       generic_name: this.medicineName.generic_name,

  //     }
  //     console.log("Drug Obj", Drug, this.lngMedInstr.getValue());
  //     var dosageValue: any = ""
  //     if (frequencyArr.length == 0) {
  //       dosageValue = newUnitFinal
  //     }
  //     else {
  //       dosageValue = frequencyArr.join(', ')
  //     }
  //     let obj = {
  //       name: this.medicineName.product_name,
  //       generic_name: this.medicineName.generic_name,
  //       dosage: dosageValue,
  //       dosage2: dosageValue,
  //       duration2: this.selectedDuration,
  //       whenToTake: this.selectedWhenToTake ? this.selectedWhenToTake : "",
  //       drugType: this.selectedDrugType,
  //       duration: this.selectedDuration,
  //       unitType: unitType,
  //       dosageValue: newUnitFinal,
  //       frequency: this.selectedFrequency && this.selectedFrequency.length ? this.selectedFrequency.join() : '',
  //       Drug,
  //       // instructions: this.selectedInstructions,
  //       // instructions: this.langMedInstructions.getValue(),
  //      instructions: this.lngMedInstr.getValue(),


  //       selected: true
  //     }
  //     this.allMedicines.push(obj);
  //     console.log("newMed", Drug, "all", this.allMedicines)


  //     //save this drug and doage to doctor's popular used
  //     var postData: any = {
  //       drugObj: obj,
  //       commaSeparatedDiagnosisIds: dignosisId.join()
  //     }
  //     this.patientService.saveMedicineOfDoctorperDiagnosis(postData).subscribe(response => {
  //       if (response) {
  //         console.log("saved 1 drug to doc popular for diag", response);
  //         this.getMedicinesAsPerDiagnosis();

  //         this.frequencyWithVal.map(eachFrequency => {
  //           if (eachFrequency.value && eachFrequency.value != '0') {
  //             eachFrequency.value = "";
  //           }
  //         });
  //       }
  //     })

  //     this.clearAddMedicine();
  //   } else {
  //     if (this.medicineName.product_name && this.medicineName.product_name != "" && this.selectedDrugType != "") {

  //       let unitType = '';
  //       let newUnitFinal = ""
  //       if (this.drugTypeUnit == 'ml' ||
  //         this.drugTypeUnit == 'Puff'
  //         || this.drugTypeUnit == 'Sachet') {
  //         unitType = this.drugTypeUnit
  //       }
  //       if (this.drugTypeUnit == 'ml') {
  //         newUnitFinal = this.selectedUnit
  //       }
  //       else {
  //         newUnitFinal = this.convertDoseUnitToUnicode(this.selectedUnit);
  //       }

  //       if (this.drugMolecularFlag == 1) {
  //         console.log("drugFlag", this.drugMolecularFlag)

  //         name = this.medicineName.product_name && this.medicineName.product_name;
  //         console.log("name", name)
  //         if (this.selectedFrequency.length) {
  //           dosage = `${newUnitFinal} ${unitType} - ${this.selectedDrugType} - ${this.selectedFrequency.join(', ')} - ${this.selectedDuration}, ${this.selectedWhenToTake ? this.selectedWhenToTake : ""}`
  //         }
  //         else {
  //           dosage = `${newUnitFinal} ${unitType} - ${this.selectedDrugType} - ${this.selectedFrequency} - ${this.selectedDuration}, ${this.selectedWhenToTake ? this.selectedWhenToTake : ""}`
  //         }
  //       }
  //       else {
  //         name = this.medicineName.product_name && this.medicineName.product_name;
  //         if (this.selectedFrequency.length) {
  //           dosage = `${newUnitFinal} ${unitType} - ${this.selectedDrugType} - ${this.selectedFrequency.join(', ')} - ${this.selectedDuration}, ${this.selectedWhenToTake ? this.selectedWhenToTake : ""}`

  //         }
  //         else {
  //           dosage = `${newUnitFinal} ${unitType} - ${this.selectedDrugType} - ${this.selectedFrequency} - ${this.selectedDuration}, ${this.selectedWhenToTake ? this.selectedWhenToTake : ""}`
  //         }
  //       }


  //       // Drug molecular flag.
  //       // if (this.drugMolecularFlag == 1) {
  //       //   name = this.medicineName.product_name && this.medicineName.generic_name ? this.medicineName.product_name + ' (' + this.medicineName.generic_name + ')' : this.medicineName.product_name;

  //       // }
  //       // else {
  //       //   name = this.medicineName.product_name && this.medicineName.product_name;
  //       // }
  //       // END


  //       let Drug = {
  //         id: this.searchedMedicineObj.id,
  //         product_name: this.medicineName.product_name,
  //         generic_name: this.medicineName.generic_name,

  //       }
  //       console.log("Drug Obj", Drug, this.lngMedInstr.getValue());
  //       var dosageValue: any = ""
  //       if (frequencyArr.length == 0) {
  //         dosageValue = newUnitFinal
  //       }
  //       else {
  //         dosageValue = frequencyArr.join(', ')
  //       }
  //       let obj = {
  //         // id: this.searchedMedicineObj.id,... drud id isin "Drug" obj below
  //         name: this.medicineName.product_name,
  //         generic_name: this.medicineName.generic_name,

  //         dosage: dosageValue,
  //         //dosage2: frequencyArr.join(', '),
  //         dosage2: dosageValue,
  //         duration2: this.selectedDuration,
  //         whenToTake: this.selectedWhenToTake ? this.selectedWhenToTake : "",
  //         drugType: this.selectedDrugType,
  //         duration: this.selectedDuration,
  //         unitType: unitType,
  //         dosageValue: newUnitFinal,
  //         frequency: this.selectedFrequency && this.selectedFrequency.length ? this.selectedFrequency.join() : '',
  //         Drug,
  //         //instructions: this.selectedInstructions,
  //         instructions: this.lngMedInstr.getValue(),
  //         selected: true
  //       }
  //       this.allMedicines.push(obj);
  //       console.log("newMed", Drug, "all", this.allMedicines)


  //       //save this drug and doage to doctor's popular used
  //       var postData: any = {
  //         drugObj: obj,
  //         commaSeparatedDiagnosisIds: dignosisId.join()
  //       }
  //       this.patientService.saveMedicineOfDoctorperDiagnosis(postData).subscribe(response => {
  //         if (response) {
  //           console.log("saved 1 drug to doc popular for diag", response);
  //           this.getMedicinesAsPerDiagnosis();

  //           this.frequencyWithVal.map(eachFrequency => {
  //             if (eachFrequency.value && eachFrequency.value != '0') {
  //               eachFrequency.value = "";
  //             }
  //           });
  //         }
  //       })

  //       this.clearAddMedicine();
  //     } else {
  //       if (!this.medicineName.product_name && this.medicineName.length > 2) {

  //       } else {
  //         this.dialog.open(this.incomplete_data_modal);
  //       }

  //       this.UnitField.markAsTouched();
  //       this.DurationField.markAsTouched();
  //       this.drugTypeField.markAsTouched();
  //       this.searchTextField.markAsTouched();
  //       this.DrugUnitField.markAsTouched();

  //     }

  //     setTimeout(() => {
  //       document.getElementById("typeahead-format").focus();
  //     }, 500);

  //   }

  //   setTimeout(() => {
  //     document.getElementById("typeahead-format").focus();
  //   }, 500);

  // }

  convertDoseUnitToUnicode(dose) {
    const dosesVariant = {
      "5": "\u00BD",
      "50": "\u00BD",
      "25": "\u00BC",
      "75": "\u00BE"
    }

    const doseUnit = ("" + dose).split('.');
    const newUnit = doseUnit[0];
    const decimalUnit = doseUnit[1];
    if (dosesVariant[decimalUnit]) {
      return (Number(newUnit) > 0 ? newUnit : "") + dosesVariant[decimalUnit]
    } else {
      return dose;
    }
  }



  getMedicinesAsPerDiagnosis() {
    this.allMedicinesForDiagnosis = []

    // this.showLoading = true;

    this.drugTypeList = []
    var dignosisId: any = [];
    this.allDignosis.map(ins => {
      if (ins.selected) {
        dignosisId.push(ins.id)
      }

    })

    var postData = {
      diagnosisCollection: dignosisId
    }
    console.log("postdatadign", postData)
    this.patientService.getMedicinesOfDoctorAsPerDiagnosis(postData).subscribe(response => {
      if (response) {
        this.showLoading = false;

        let result: any = response.finalResult;


        this.drugArray = result
        console.log("medResult", result)


        var diagnosis = this.allDignosisOg


        this.diagnosisCollectionArray = this.diagnosisCollectionArray.reduce((unique, o) => {
          if (!unique.some(obj => obj.name == o.name)) {
            unique.push(o);
          }
          return unique;
        }, []);

        this.diagnosisCollectionArrayOrg = this.diagnosisCollectionArray

        //console.log("digFeb22", this.diagnosisCollectionArray)

        result.map((item, index) => {
          //ins.drugs.map(item => {

          let Drug = {
            id: item.id,
            product_name: item.product_name,
            generic_name: item.generic_name
          }
          let whenToTakeFormatted = item.advisedWhenToTake ? item.advisedWhenToTake.charAt(0).toUpperCase() + item.advisedWhenToTake.slice(1) : "";

          let formattedFrequencyArr = [];
          let formattedFrequencyStr = ""

          if (item.advisedFrequency) {
            item.advisedFrequency.split(', ').map(ins => {
              console.log(ins.charAt(0).toUpperCase() + ins.slice(1));
              formattedFrequencyArr.push(ins.charAt(0).toUpperCase() + ins.slice(1))
            });
          }

          formattedFrequencyStr = formattedFrequencyArr.join(', ');

          let obj = {};
          obj['name'] = item.product_name;
          obj['genric_name'] = item.generic_name;
          //obj['dosage'] = item.dosage + ' ' + item.duration;
          //obj['dosage2'] = item.dosage;
          // obj['id'] = item.drugId
          obj['duration2'] = item.advisedDurationUnit ? item.advisedDuration + " " + item.advisedDurationUnit : item.advisedDuration;
          obj['instructions'] = item.advisedInstructions;
          obj['drugType'] = item.product_type;
          obj['whenToTake'] = whenToTakeFormatted
          obj['frequency'] = formattedFrequencyStr;
          obj['diagnosisCollection'] = item.diagnosisCollection
          // obj['unit'] = item.advisedDosage;


          obj['dosage'] = item.advisedDosage && item.advisedDosage != "" && item.advisedDosage.toLowerCase() != "null" ? `${item.advisedDosage} ${item.unit ? item.unit.toLowerCase() : ''} - ${item.product_type} - ${formattedFrequencyStr} - ${item.advisedDuration}, ${whenToTakeFormatted}` : `${item.product_type} - ${formattedFrequencyStr} - ${item.advisedDuration}, ${whenToTakeFormatted}`;
          // obj['dosage2'] = item.advisedDosage && item.advisedDosage != "" && item.advisedDosage.toLowerCase() != "null" ? `${item.advisedDosage} ${item.unit ? item.unit.toLowerCase() : ''} - ${item.product_type} - ${formattedFrequencyStr} - ${whenToTakeFormatted}` : `${item.product_type} - ${formattedFrequencyStr} - ${whenToTakeFormatted}`
          obj['dosage2'] = item.advisedDosage;
          obj['dosageValue'] = item.advisedDosage;


          obj['Drug'] = Drug;
          obj['MedGroupDrug_id'] = item.MGD_Id
          obj['updatedAt'] = item.updatedAt
          obj['selected'] = false;


          this.allMedicinesForDiagnosis.push(obj);

        })

        if (Array.isArray(this.allMedicinesForDiagnosis)) {
          // Reverse the array in place
          this.allMedicinesForDiagnosis.reverse();

          // Optionally, if you need to keep the original order in another variable
          this.allMedicienOrg = [...this.allMedicinesForDiagnosis];
        } else {
          // Handle the case where this.allMedicinesForDiagnosis is not an array
          console.error("allMedicinesForDiagnosis is not an array");
        }

        console.log("allMedicinesForDiagnosis (reversed)", this.allMedicinesForDiagnosis);

        // this.allMedicienOrg = this.allMedicinesForDiagnosis
        // console.log("allMedicinesForDiagnosis", this.allMedicinesForDiagnosis);

        // this.allMedicinesForDiagnosisOrg = this.allMedicinesForDiagnosis

        //console.log("dddd", this.allMedicienOrg)

        this.allMedicinesForDiagnosis.map((ins, idx) => {
          ins.idx = idx;
          var tabletsArray: any = this.allMedicinesForDiagnosis
          var data: any = []
          tabletsArray.map((ins, index) => {
            if (ins.drugType && ins.drugType.includes('tablets')) {
              ins.drugType = ins.drugType.replace('tablets', 'tablet')
              data.push({ name: ins });
            }
            if (tabletsArray[index].drugType) {
              tabletsArray[index].drugType = tabletsArray[index].drugType.toLowerCase()
            }

          })

          this.drugTypeList.push({ name: "All", selected: false })
          if (ins.drugType) {
            this.drugTypeList.push({ name: ins.drugType, selected: false })
          }


        });

        this.drugTypeList = this.drugTypeList.reduce((unique, o) => {
          if (!unique.some(obj => obj.name == o.name)) {
            unique.push(o);
          }
          return unique;
        }, []);


      }

    })
    console.log("MMMMM", this.allMedicinesForDiagnosis)
    // this.saveState();
  }

  proceedAddMedicine() {
    this.drugNameAddNewDrugs = this.medicineName;
    this.drugTypeAddNewDrugs = this.selectedDrugType;
    this.unitForNewMed = this.drugUnit;

    this.FrequencyFieldNewMed.value = this.FrequencyField.value;
    this.frequencyForNewMed = this.FrequencyField.value;

    this.DurationFieldNewMed.value = this.DurationField.value
    this.durationForNewMed = this.DurationField.value

    this.WhentotakeFieldNewMed.value = this.WhentotakeField.value;
    this.whenToTakeForNewMed = this.WhentotakeField.value;

    this.instructionsForNewMed = this.lngMedInstr.getValue();
  }

  saveModalValues(type: string) {

    let closeDialogFlag = true;
    if (!Array.isArray(this.selectedInstructions)) {
      this.selectedInstructions = [];
    }
    switch (type) {

      case 'copyMedicines':

        //aaleee
        // COMMENT
        this.selectedPreviousMed = this.selectedPreviousMed.reduce((unique, o) => {
          if (!unique.some(obj => obj.Drug.id == o.Drug.id)) {
            unique.push(o);
          }
          return unique;
        }, []);

        this.selectedPreviousMed.map(ins => {

          if (ins.selected) {
            // // ins.id = null;
            // // ins.medicineName = ins.Drug.product_name;
            // this.allMedicines.push({ name: ins.medicineName ? ins.medicineName : ins.Drug.product_name, dosage: ins.dosage2 })
            ins.name = ins.medicineName ? ins.medicineName : ins.Drug.product_name;
            // ins.dosage = ins.dosage2 + ' ' + ins.duration2;
            ins.dosage = ins.dosage2 + ' ' + ins.duration2;
            delete ins['id'];

            this.allMedicines.push(ins);
          }
        });
        this.allMedicines = this.allMedicines.reduce((unique, o) => {
          if (!unique.some(obj => obj.Drug.id == o.Drug.id)) {
            unique.push(o);
          }
          return unique;
        }, []);

        this.selectedPreviousMed.map(med => {
          med.selected = false;
        })

        console.log('COPIED MEDICINES', this.allMedicines)

        // this.prescriptionPreviewArray = [...this.selectedPreviousMed, ...this.prescriptionPreviewArray];
        // this.medicine_list = [...this.selectedPreviousMed, ...this.medicine_list]

        break;
      case 'copyMedicinesFav':
        console.log("allMed", this.allMedicines)
        //  this.allMedicines.map(ind=>{
        //   this.selectedFavMed.map(ins => {
        //   })
        //  })




        this.selectedFavMed.map(ins => {


          if (this.allMedicines.length) {
            this.allMedicines.map(itr => {
              if (ins.Drug.id == itr.Drug.id) {

              }
              else {
                if (ins.selected) {
                  ins.name = ins.medicineName ? ins.medicineName : ins.Drug.product_name;
                  if (!ins.duration) {
                    ins.duration = ""
                    ins.dosage = ins.dosage2 + ' ' + ins.duration;
                  }
                  else {
                    ins.dosage = ins.dosage2 + ' ' + ins.duration;
                  }

                  delete ins['id'];
                  this.allMedicines.push(ins);
                  this.allMedicines = this.allMedicines.reduce((unique, o) => {
                    if (!unique.some(obj => obj.Drug.id == o.Drug.id)) {
                      unique.push(o);
                    }
                    return unique;
                  }, []);

                }
              }

            })

          }
          else {
            if (ins.selected) {
              ins.name = ins.medicineName ? ins.medicineName : ins.Drug.product_name;
              if (!ins.duration) {
                ins.duration = ""
                ins.dosage = ins.dosage2 + ' ' + ins.duration;
              }
              else {
                ins.dosage = ins.dosage2 + ' ' + ins.duration ? ins.duration : '';
              }

              // if(!ins.whenToTake){
              //   ins.whenToTake=''
              // }
              delete ins['id'];
              this.allMedicines.push(ins);
            }

          }
        })
        // this.prescriptionPreviewArray = [...this.selectedFavMed, ...this.prescriptionPreviewArray];
        // this.medicine_list = [...this.selectedFavMed, ...this.medicine_list]
        break;
      // Assuming this code is inside a switch statement or a function handling a specific case

      // Assuming this code is inside a switch statement or a function handling a specific case

      case 'medicine_instructions':
        // Get the current value of instructions from the UI
        var instructions = this.lngMedInstr.getValue();

        // Iterate through all medicine instructions
        this.allMedicineInstructionsOg.forEach(ins => {
          // Check if the instruction is selected and not already included in selectedInstructions
          if (ins.selected && !this.selectedInstructions.includes(ins.name)) {
            // Add the newly selected instruction to selectedInstructions array
            this.selectedInstructions.push(ins.name);

            // If instructions are already present, append the new instruction
            if (instructions !== undefined && instructions !== "") {
              instructions += ', ' + ins.name;
            } else {
              // If no instructions exist, set the instruction directly
              instructions = ins.name;
            }
          }
        });

        // Update the textbox value with the joined selectedInstructions
        if (instructions !== undefined && instructions !== "") {
          this.lngMedInstr.setTextBoxValue(instructions);
        } else {
          // If no instructions are selected, handle it accordingly
          console.log("No instructions selected");
        }
        break;



      // case 'medicine_instructions':
      //   var instructions = this.lngMedInstr.getValue();

      //   this.allMedicineInstructionsOg.forEach(ins => {
      //     if (ins.selected && !this.selectedInstructions.includes(ins.name)) {
      //       // Check if instructions are already present
      //       if (instructions !== undefined && instructions !== "") {
      //         // If instructions already exist, append the new instruction
      //         this.lngMedInstr.setTextBoxValue(instructions.length ? instructions + ', ' + ins.name : ins.name);
      //       } else {
      //         // If no instructions exist, set the instruction directly
      //         this.lngMedInstr.setTextBoxValue(ins.name);
      //       }
      //     }
      //   });
      //   break;

      case 'drugTypesAddNewDrug':

        if (this.drugNameAddNewDrugs != "" && this.drugTypeAddNewDrugs != "") {
          this.addNewDrugs();
        } else {
          closeDialogFlag = false;
          alert('Please specify drug name and type.')
        }

        break;

      case 'copyPopularMedicines':

        // for (let index = 0; index < this.allMedicinesForDiagnosis.length; index++) {
        //   const element = this.allMedicinesForDiagnosis[index];
        //   this.allMedicines.push(element);
        // }


        this.allMedicienOrg.forEach(element => {

          if (element.selected) {
            element.dosage = element.dosage ? element.dosage : " ";
            element.dosage2 = element.dosage2 ? element.dosage2 : " "
            element.drugType = element.drugType ? element.drugType.charAt(0).toUpperCase() + element.drugType.slice(1) : " ";
            element.duration2 = element.duration2 ? element.duration2 : " ";
            element.instructions = element.instructions ? element.instructions : " ";
            element.whenToTake = element.whenToTake ? element.whenToTake : " "

            this.allMedicines.push(element);
          }



        });

        let uniq = [...Array.from(new Set(this.allMedicines))];

        //this.allMedicines = uniq;
        this.allMedicines = this.allMedicines.reduce((unique, o) => {
          if (!unique.some(obj => obj.Drug.id == o.Drug.id)) {
            unique.push(o);
          }
          return unique;
        }, []);
        // if(this.medDuplicateindex){
        //   this.allMedicines = uniq;
        // }
        // else{
        //   this.allMedicines =    this.allMedicines.reduce((unique, o) => {
        //     if (!unique.some(obj => obj.Drug.id == o.Drug.id)) {
        //       unique.push(o);
        //     }
        //     return unique;
        //   }, []);
        // }


        break;

      default:
        break;
    }

    if (closeDialogFlag) {
      //this.dialog.closeAll()
    }
    // this.saveState();


  }
  

  addNewDrugs = () => {


    const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    let otp = "";
    for (let i = 0; i < 4; i++) {
      //changed to 6 from 4
      otp += numbers[Math.floor(Math.random() * 8) + 1];
    }



    var obj = {
      "product_name": this.drugNameAddNewDrugs,
      "product_company": this.drugCompanyAddNewDrugs,
      "product_type": this.drugTypeAddNewDrugs,
      "product_composition": this.genericDrugNameAddNewDrugs,
      "product_id": `${this.drugNameAddNewDrugs.substring(0, 3).trim() + otp}`,

      "advisedDosage": this.unitForNewMed,
      "advisedFrequency": this.frequencyForNewMed.length ? this.frequencyForNewMed.join(', ') : '',
      "advisedDuration": this.durationForNewMed.length ? this.durationForNewMed.split(' ')[0] : '',
      "advisedDurationUnit": this.durationForNewMed.length ? this.durationForNewMed.split(' ')[1] : '',
      "advisedWhenToTake": this.whenToTakeForNewMed,
      "advisedInstructions": this.instructionsForNewMed
    }



    this.patientService.addNewDrug(obj).subscribe(result => {
      if (result.Drug) {


        this.messageService.add({ severity: 'success', summary: 'Drug added successfully', detail: 'Drug has been successfully added to your records.' });


        console.log('Saved New Drug');
        this.searchedMeds = result.Drug;
        console.log("newDrug", this.searchedMeds);
        this.medicineName = result.Drug;
        this.setSelectMedicine({ item: this.searchedMeds });
        this.addToList();
        this.drugCompanyAddNewDrugs = '';
        this.genericDrugNameAddNewDrugs = '';
        this.drugTypeAddNewDrugs = '';
        this.drugNameAddNewDrugs = '';
      } else {
        alert('Something went wrong');
      }
    });
  }

  onInputCustomComponent = (value) => {
    this.selectedInstructions = value;
  }

  toggle(ref: any): void {
    console.log("ref object:", ref);

    let med: any = [];
    const refValue = ref._value;

    // Manually toggle selection if 'toggleSelected()' is unavailable
    ref._selected = !ref._selected;

    // Always reset the list to the full dataset before filtering
    this.allMedicinesForDiagnosis = [...this.allMedicienOrg];

    // If "All" or "All Medicines" is selected, reset the list to show all items
    if (ref._value.name === 'All Medicines' || ref._value.name === 'All') {
        console.log("Resetting to all medicines");
        return; // No need to filter, just reset the list
    }
    this.drugTypeList.forEach((ins)=>{
      console.log("ins",ins);
      console.log("refffff",ref._value.name)
      if(ins.name==ref._value.name){
        console.log("truedsdjsdj")
        ins.selected=true
      }
      // if (drugType === refName || diagnosisCollection === refValueCollection) {
      //   med.push(ins);
    
    })

    // Apply filter based on drugType or diagnosisCollection
    this.allMedicienOrg.forEach((ins) => {
        const drugType = ins.drugType ? ins.drugType.trim().toLowerCase() : '';
        const diagnosisCollection = ins.diagnosisCollection ? ins.diagnosisCollection.trim().toLowerCase() : '';
        const refName = refValue.name ? refValue.name.trim().toLowerCase() : '';
        const refValueCollection = refValue.value ? refValue.value.trim().toLowerCase() : '';

        // Ensure case-sensitivity or trim any extra spaces to avoid mismatches
        if (drugType === refName || diagnosisCollection === refValueCollection) {
            med.push(ins);
        }
    });

    console.log("Filtered list:", med);

    // Assign the filtered list back to 'allMedicinesForDiagnosis'
    this.allMedicinesForDiagnosis = med;

    console.log("Final Selection State:", ref._selected);
}

  onDropdownSelectFavMed = (item) => {
    setTimeout(() => {
      this.selectedPreviousFavMed = item.value
      this.selectedFavMed = item.medicine;
      this.currentFavMedObj = item;
    }, 200);

  }
  clearFavouriteText() {
    this.selectedPreviousFavMed = "";
    setTimeout(() => {
      this.searchFavourites.nativeElement.focus();
    }, 300);

  }

  onSelectMedicine = (index, type) => {
    this.medDuplicateindex = ""
    if (type == 1) {
      if (this.allMedicines[index].selected) {
        this.allMedicines[index].selected = false
      } else {
        this.allMedicines[index].selected = true;
      }
    } else if (type == 2) {
      if (this.allMedicienOrg[index].selected == true) {
        this.allMedicienOrg[index].selected = false


      } else {

        this.allMedicienOrg[index].selected = true;


      }
    } else if (type == 3) {
      if (this.selectedFavMed[index].selected == true) {
        this.selectedFavMed[index].selected = false
      } else {
        this.selectedFavMed[index].selected = true;
      }
    } else if (type == 4) {
      //aaleee
      if (this.selectedPreviousMed[index].selected == true) {
        this.selectedPreviousMed[index].selected = false
      } else {
        this.selectedPreviousMed[index].selected = true;
      }
    }

    //this.saveState()
  }




  onClickDeleteFavMed() {
    console.log("favId", this.currentFavMedObj);
    console.log("favMes", this.selectedDiagnosis)




    let postData = {
      appointmentId: this.currentFavMedObj.id
    }
    this.patientService.removeFavApptOfDoctor(postData).subscribe(response => {
      // if (confirm("Are you sure to delete ")) {
      console.log("delete", response);


      this.getFavApptsOfDoctor();
      this.selectedPreviousFavMed = "";
      // this.modalService.dismissAll();
    });
    //console.log("", this.favArrayNames)
    // this.selectedDiagnosis.map(item => {
    //   if (this.currentFavMedObj && this.currentFavMedObj.Visit && this.currentFavMedObj.Visit.complaint_diagnosis == item) {
    //     var itemIndex = this.selectedDiagnosis.splice(item, 1)
    //   }
    // })

    this.dialog.closeAll()

  }

  getFavApptsOfDoctor = () => {
    // this.showRequisitionLoading = true;
    this.patientService.getFavApptsOfDoctor().subscribe(response => {
      if (response) {
        // this.currentFavMedObj=response;
        this.favArray = [];
        this.selectedFavMed = [];
        this.favMedicineArray = response

        //let myid=this.currentFavMedObj.id;
        this.currentFavMedObj = response.length ? response[0] : {};
        console.log("favappt1", this.currentFavMedObj);
        console.log("favappt", response)
        localStorage.setItem("doctorsFavTemplate", this.currentFavMedObj);

        // this.showRequisitionLoading = false;
        for (let ins = 0; ins < response.length; ins++) {

          const element = response[ins];
          var obj: any = {}


          obj.Medicines = element.Visit && element.Visit != null ? element.Visit.Medicines && element.Visit.Medicines.length > 0 ? element.Visit.Medicines : null : null;
          if (element.Visit && element.Visit != null) {
            var med: any = {};
            med.id = element.id;
            med.value = element.favApptName;
            med.medicine = element.Visit.Medicines;
            med.selectedComplaints = element.Visit.chief_complaints ? element.Visit.chief_complaints : '';
            med.selectedDiagnosis = element.Visit.complaint_diagnosis ? element.Visit.complaint_diagnosis : '';
            // med.selectedGeneralInstructions = element.Visit.general_instruction ? element.Visit.general_instruction : '';
            // med.selectedDietaryAdvices = element.Visit.nutrition_diet_advice ? element.Visit.nutrition_diet_advice : '';
            // med.nextFollowUp = element.Visit.next_vaccination;
            //here
            this.favArray.push(med);
          }


          console.log('FAV-ARRAY', this.favArray)



        }

        this.favArrayNames = this.favArray.map(ins => { return ins.value });
        console.log("favMarch1", this.favArrayNames)

        this.favArrayNames = this.myControlFavMed.valueChanges.pipe(
          startWith(''),
          map(value => this._filterMedFavourites(value))
        );



        console.log("favArrar", this.favArrayNames);


        console.log("sele", this.selectedFavMed)



        this.selectedFavMed.map(ins => {

          console.log("logggg", ins.value)
          if (ins.Drug) {
            ins.dosage2 = ins.duration2 ? ins.dosage2.replace('--', `- ${ins.duration2} -`) : ins.dosage2;
            ins.medicineName = ins.Drug.product_name;
          }
        })


      }
    })
  }
  private _filterMedFavourites(value: any): string[] {
    console.log('Value', value)

    let filterValue = ''

    if (typeof value === 'string' || value instanceof String) {
      filterValue = value.toLowerCase();
    } else {
      filterValue = value.value.toLowerCase();
    }


    return this.favArray.filter(option => option.value.toLowerCase().includes(filterValue) || option.selectedDiagnosis.toLowerCase().includes(filterValue));
  }


  getAllDoctorPrescriptions = () => {
    console.log("OCT111", this.patientId)

    var obj = {
      "patientId": this.patientId,
      "isForHistory": true,
    }
    // this.showLoading = true;

    this.patientService.getAppointmentsByPatientId(obj).subscribe(response => {
      if (response) {
        this.patientApponmentById = response;
        console.log("patientApponmentById in patient service", this.patientApponmentById);

      }
      const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      if (response.success) {
        console.log("mmm1111", response)
        if (response.historyArr.length) {

          this.showLoading = false;



          var medArr = [];
          var arr: any = [];
          for (let index = 0; index < response.historyArr.length; index++) {
            const element = response.historyArr[index];
            var obj: any = {}
            obj.createdAt = element.from;
            obj.from = element.from;
            obj.to = element.to;
            obj.id = element.id;
            obj.favApptName = element.favApptName;
            obj.docName = element.Doctor.User?.first_name + " " + element.Doctor.User?.last_name;
            obj.Visit = element.Visit && element.Visit != null ? element.Visit : null;
            obj.Medicines = element.Visit && element.Visit != null ? element.Visit.Medicines && element.Visit.Medicines.length > 0 ? element.Visit.Medicines : null : null;
            obj.prescriptionPDFURL = element.prescriptionPDFURL && element.prescriptionPDFURL != null ? element.prescriptionPDFURL : null;
            obj.Certificates = element.Certificates && element.Certificates != null ? element.Certificates : null;
            if (element.Visit && element.Visit != null && element.Visit.Medicines && element.Visit.Medicines.length > 0) {
              var med: any = {};
              if (new Date(element.from).getDate() == new Date().getDate() && new Date(element.from).getMonth() == new Date().getMonth() && new Date(element.from).getFullYear() == new Date().getFullYear()) {
                this.isEditableVisit = true
              }
              else {
                this.isEditableVisit = false

                med.value = `${days[new Date(element.from).getDay()]}, ${moment(element.from).format('DD MMM YYYY')}`
                med.medicine = element.Visit.Medicines;
                med.complaints = element.Visit.chief_complaints;
                med.diagnosis = element.Visit.complaint_diagnosis;
                if (element.prescriptionPDFURL != null) {
                  med.pdfUrl = element.prescriptionPDFURL
                  console.log("med.pdfUrlmed.pdfUrl", med.pdfUrl);

                }

                medArr.push(med);
              }
            }
            arr.push(obj);
            console.log("pdf", arr)

          }



          if (medArr && medArr.length > 0) {
            this.previousMedsArr = medArr.reverse()
            console.log("May3333", this.previousMedsArr)

            this.selectedPreviousMed = medArr[0].medicine;

            this.selectedPreviousMedDate = medArr[0].value;
            this.pdfUrlForViewPrescription = medArr[0].pdfUrl
            this.pdfurlObjectForPrintingInChild = this.pdfUrlForViewPrescription;
            console.log("prescriptionreportsURLprescriptionreportsURL", this.pdfUrlForViewPrescription);

            // this.selectedPreviousFavMed=medArr[0].favApptName

            this.selectedPreviousMed.map(ins => {
              if (ins.Drug) {
                ins.dosage2 = ins.duration2 ? ins.dosage2.replace('--', `- ${ins.duration2} -`) : ins.dosage2;
                ins.medicineName = ins.Drug.product_name;
              }
            })
          }
        }

      }
    });
  }
  onDropdownSelect = (item) => {
    // this.selectedPreviousMedDate = item?.value?.value;

    this.selectedPreviousMed = item?.value?.medicine;

    this.pdfUrlForViewPrescription = item?.value?.pdfUrl
  }

  onClickViewPrescription = () => {
    if (this.pdfUrlForViewPrescription) {


      this.prescriptionPdfURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.pdfUrlForViewPrescription);
      this.prescriptionreportsURL = this.pdfUrlForViewPrescription;

      this.openDialog(this.viewPrescriptionModal, 'viewPrescriptionModal');
      // this.isViewRecipt = true;
    }
  }
  closeDialog() {
    this.dialog.closeAll();
    // this.allMedicineInstructions = this.allMedicineInstructionsOg
  }
  getDeleteMed(ele) {

    this.medObj = ele
  }
  onClickMedDelete() {
    var itemList = this.allMedicines.indexOf(this.medObj);
    this.allMedicines.splice(itemList, 1);
    this.allMedicienOrg.map(ins => {
      if (this.medObj.idx == ins.idx) {
        ins.selected = false
      }
    })
    this.selectedFavMed.map(ele => {
      if (this.medObj.name == ele.name) {
        ele.selected = false
      }
    })

  }


  selectModalValue(index, type) {

    if (type == 'drugTypes') {

      if (this.drugTypes[index].selected) {
        this.drugTypes[index].selected = false;
        this.drugTypesOg[index].selected = false;
      } else {
        this.drugTypes[index].selected = true;
        this.drugTypes.map((ins, ind) => {
          if (index != ind) {
            this.drugTypes[ind].selected = false;
          }
        })
      }

    } else {

      // if (this.allMedicineInstructions[index].selected) {
      //   this.allMedicineInstructions[index].selected = false;
      //   this.allMedicineInstructionsOg[index].selected = false;

      //   this.selectedInstructions = this.selectedInstructions.replace(this.allMedicineInstructions[index].name + ',', '');
      //   this.selectedInstructions = this.selectedInstructions.replace(this.allMedicineInstructions[index].name, '');
      // } else {
      //   this.allMedicineInstructions[index].selected = true;
      // }
      if (this.allMedicineInstructions[index].selected) {
        this.allMedicineInstructions[index].selected = false;
        this.allMedicineInstructionsOg[index].selected = false;

        // Remove the item from the selectedInstructions array
        const itemName = this.allMedicineInstructions[index].name;
        this.selectedInstructions = this.selectedInstructions.filter(item => item !== itemName);
      } else {
        this.allMedicineInstructions[index].selected = true;
      }

      this.allMedicineInstructions = this.allMedicineInstructionsOg
      this.allMedicineInstructions = this.allMedicineInstructions.reduce((unique, o) => {
        if (!unique.some(obj => obj.name.toLowerCase() == o.name.toLowerCase())) {
          unique.push(o);
        }
        return unique;
      }, []);

    }

  }
  onSearchChange(searchValue: string, type: string): void {

    let data = [];
    let newData = [];

    if (type == 'drugTypes') {
      data = this.drugTypesOg;
    } else {
      data = this.allMedicineInstructionsOg;
    }

    if (searchValue.length) {
      newData = data.filter(item => {
        return item.name.toLowerCase().includes(searchValue.toLowerCase())
      });
    } else {
      newData = data;
    }

    if (type == 'drugTypes') {
      // this.drugTypesOg = newData;
    } else {
      this.allMedicineInstructions = newData;
      this.allMedicineInstructions = this.allMedicineInstructions.reduce((unique, o) => {
        if (!unique.some(obj => obj.name.toLowerCase() == o.name.toLowerCase())) {
          unique.push(o);
        }
        return unique;
      }, []);
    }

  }
  

  previewPrescriptionPDF(actionparam: string): Promise<void> {
    console.log(" return 22222222222");

    return new Promise((resolve, reject) => {
      console.log("tempName", this.templateName);
      let flag = 0;

      if (this.favApptName == "" && flag) {
        // Handle this case if needed
      } else {
        this.closeDialog();

        if (this.followupDateNew != null) {
          var newFollowUpDate: any = new Date(this.followupDateNew);
          newFollowUpDate = newFollowUpDate ? newFollowUpDate : '';
          var nextFollowUpDate: any = newFollowUpDate.toDateString();
        }
        var nextFollowUp = "";
        var folloupMessage: any = this.langFollowupMessage ? this.langFollowupMessage.getValue() : "";

        localStorage.setItem("followUpMessage", folloupMessage);
        folloupMessage = folloupMessage.split(',  ').join(':');

        if (this.addFollowUpFlag == true) {
          nextFollowUp = (nextFollowUpDate ? nextFollowUpDate : "") + ":" + this.selectedOption + ":" + folloupMessage;
        }

        localStorage.setItem("bookAppointment", nextFollowUp);
        console.log("nextVac", nextFollowUp);

        this.allMedicines.map(ins => {
          if (!this.drugMolecularFlag) {
            ins.Drug.generic_name = "";
          }
        });
        console.log("finalCom", this.finalSelectedComplaints);

        var chief_complaints: any = "";
        var on_examination = "";
        var complaint_diagnosis = this.selectedDignosisName.join(', ');
        var general_instruction = this.selectedGeneralInstructionsName.join(', ');
        var nutrition_diet_advice = this.selectedDietName.join(', ');

        let obj: any = {};
        var patient: any = {};
        var Drug: any = {};

        patient.first_name = this.patientInfo.firstName;
        patient.last_name = this.patientInfo.lastName;
        patient.gender = this.patientInfo.gender;
        patient.phone_number = this.patientInfo.contactNo;

        let finalMedicines = [];

        this.allMedicines.map(ins => {
          if (ins.selected && ins.dosage2) {
            finalMedicines.push(ins);
          }
        });

        patient.age = this.patientInfo.age;
        patient.weight = 'N/A';
        patient.height = 'N/A';
        console.log("patiMru", patient);
        obj = {
          "visit_id": null,
          "clinic_id": null,
          "read_only": false,
          "date": null,
          "appointmentId": null,
          "action": "share",
          "patientId": this.patientId,
          "patient": patient,
          "medicines": this.allMedicines,
          "general_instruction": general_instruction,
          "covidQues": "",
          "suggested_investigation": '',
          "chief_complaints": this.finalSelectedComplaints.join(','),
          "next_vaccination": nextFollowUp,
          "expirationDate": '',
          "on_examination": on_examination,
          "complaint_diagnosis": complaint_diagnosis,
          "savePrescriptionParams": {},
          'newVersion': true,
          "medicinePreview": '',
          "language": this.selectedLanguage.language,
          "nutrition_diet_advice": nutrition_diet_advice,
          "templateName": this.templateName,
          "favApptName": this.favApptName,
          "newVersionValue": 'a',
          "specialVaccination": this.selectedSpecialVaccination.join(',')
        };
        if (actionparam == 'saveonly') {
          obj.action = "saveonly";
        }
        this.allMedicines.map(ins => {
          if (ins.dosage2) {
            ins.dosage2 = ins.dosage2.split(",").join(" - ");
          }
        });
        this.allMedicines.map((ins, ind) => {
          ins.sortId = ind;
          console.log("sordId", ins.sortId);
        });
        let savePrescriptionParams = {
          visit: {
            complaint_diagnosis: obj.complaint_diagnosis,
            general_instruction: obj.general_instruction,
            chief_complaints: obj.chief_complaints,
            next_vaccination: obj.next_vaccination,
            complaint_examination: obj.on_examination,
            suggested_investigation: obj.suggested_investigation,
            covidQues: null,
            expirationDate: obj.expirationDate,
            nutrition_diet_advice: obj.nutrition_diet_advice,
          },
          patient_id: obj.patientId,
          medicine_list: this.allMedicines,
        };
        obj.savePrescriptionParams = savePrescriptionParams;
        obj.printingFields = this.printingFields;
        obj.printingVitalsField = this.printingVitals;
        console.log("printingVitalsField", this.printingVitals);

        console.log(this.printingVitals);
        console.log("RETRO>>>>>>", JSON.stringify(obj.next_vaccination));
        console.log("sent obj to pdf ", obj);

        this.patientService.printPrescriptionVersion2(obj).subscribe(response => {
          if (response) {
            console.log(" return response 22222222222");
            console.log("saveVisitCommanFun called  1");

            console.log('PRESCRIPTION PDF Version 1', response);
            console.log('PRESCRIPTION PDF response.visit._id', response.visitId);
this.visitIdFromPrescription = response.visitId;
            let url = response.url;
            let appointmentId = response.appointmentId;
            // this.visitIdFromPrescription = response.visitId
            sessionStorage.setItem("currentPreviewPrescriptionObject", JSON.stringify(obj));
            console.log("currentPreviewPrescriptionObject object ", obj);

            if (actionparam == 'saveonly') {
              console.log(" return saveonly 22222222222");

              this.getPrescriptionMedicines();
              this.allMedicienOrg.forEach(ins => {
                if (ins.selected) {
                  ins.selected = false;
                }
              });
            } else {
              this.router.navigate([`/doctor/patients/prescriptions/preview`], { queryParams: { prescrip_url: url, patientId: this.patientId, appointmentId: appointmentId } });
            }

            resolve(); // Resolve the promise when the process is complete
          } else {
            this.showLoading = false;
            reject(); // Reject the promise if there was an error
          }
        }, error => {
          reject(error); // Reject the promise if there was an error
        });
      }
    });
  }

  selectModalFieldValue = (item) => {
    let selectedFieldsArray = selectFields
    selectedFieldsArray.map(ins => ins.selected = false)
    selectedFieldsArray.map(ins => {
      item.map(itr => {
        if (ins.name == itr.name) {
          ins.selected = true
        }
      })
    })
    var data = selectedFieldsArray;

    this.selectFieldsArray = selectedFieldsArray
    console.log("item", selectedFieldsArray)
    var arr = data.filter(field => { if (field.selected) { return field } });
    this.printingFields = arr.map(field => { return field.value });

  }

  parseDate(dateObject) {
    var date = new Date();
    date.setDate(dateObject.day);
    date.setMonth(dateObject.month - 1);
    date.setFullYear(dateObject.year);
    return moment(date).format("YYYY-MM-DD HH:mm:ss");
  }

  saveVisit(): Promise<void> {
    console.log(" return 111111111111111");
    return new Promise((resolve, reject) => {
      console.log("saveVisit entered");

      if (this.selectedVisit.lmp && this.selectedVisit.lmp.includes("GMT")) {
        var lmpDate: any = this.reverseDate(this.selectedVisit.lmp);
        lmpDate = moment(new Date(this.parseDate(lmpDate))).format("YYYY-MM-DD");
        this.selectedVisit.lmp = lmpDate;
      }
      console.log("this.selectedVisit saveVisit", this.selectedVisit);
      // this.selectedVisit['_id'] = this.visitIdFromPrescription;

      let recordData = {
        patientId: this.patientInfo.id ? this.patientInfo.id : this.patientInfo._id,
        dob: this.patientInfo.DOB,
        visit: this.selectedVisit,
      };
      var selectedVisitdate = this.reverseDate(this.selectedVisitdate);
      console.log("patientId", this.patientInfo.id, this.patientInfo._id);
      console.log("this.selectedVisit123", this.selectedVisit);
      console.log("recordData saveVisit", recordData);

      recordData.visit.date = moment(new Date(this.parseDate(selectedVisitdate))).format("YYYY-MM-DD HH:mm:ss");

      this.patientService.savePatientVisit(recordData, this.patientInfo._id).subscribe(
        response => {
          console.log(" return response 111111111111111");
          console.log("saveVisit response", response);
          this.selectedVisit = response;
          console.log("this.selectedVisit by save and preview ",this.selectedVisit);
          
          setTimeout(() => {
            this.refreshservice.vitalsRefreshed();
          }, 200);
          resolve();  // Resolve the Promise on success
        },
        error => {
          console.error('Error in saveVisit:', error);
          reject(error);  // Reject the Promise on error
        }
      );
    });
  }

  // async saveAndPreview() {
  //   console.log("saveAndPreview started");
  //   try {
  //     // await this.debouncedSaveVisit();
  //     await this.saveVisit();

  //     console.log("saveVisit completed,   await this.previewPrescriptionPDF('1');now calling previewPrescriptionPDF");
    
  //     console.log("previewPrescriptionPDF completed");
  //   } catch (error) {
  //     console.error('Error in saveAndPreview:', error);
  //   }
  // }
  async saveAndPreview() {
    console.log("saveAndPreview started");
    try {
      this.savePreviewButtonFlag= true;

      await this.saveVisit();
      console.log("saveVisit completed");


      await this.previewPrescriptionPDF('1');
      console.log("previewPrescriptionPDF completed, now calling saveVisit"); 
     // await this.saveVisit();
     setTimeout(() => {
      this.savePreviewButtonFlag = false;
      console.log("this.saveButtonFlag after settime out", this.saveButtonFlag);
    }, 2000);
    } catch (error) {
      console.error('Error in saveAndPreview:', error);
    }
  }




  async saveVisitCommanFun() {
    try {
      this.saveButtonFlag= true;


           // Call previewPrescriptionPDF first if necessary
           await this.saveVisit();


           console.log("this.saveButtonFlag in start saveVisitCommanFun",this.saveButtonFlag);
await this.previewPrescriptionPDF('saveonly');
      
      // Then save the visit
      setTimeout(() => {
        this.saveButtonFlag = false;
        console.log("this.saveButtonFlag after settime out", this.saveButtonFlag);
      }, 2000);
     
      
    } catch (error) {
      console.error('Error in saveVisitCommanFun:', error);
    }
  
  }

 
  openImmunizationRX() {
    // this.showLoading = true;
    // this.openImmunizationFlag = true;
    this.selectedIndex = 2;

    this.onClickOverview();

    setTimeout(() => {
      this.refreshservice.OnPatTopNav_MenuSelected(this.patientInfo.id, this.refreshservice.EventName_Immunization);
      console.log('Custom  Switch Caseeeeeeeeeeeeeeee function executed.');
    }, 500);

  }

  openMilestoneRX() {
    console.log("openImmunization function called");
    this.selectedIndex = 3;
    this.onClickOverview();
    setTimeout(() => {
      this.refreshservice.OnPatTopNav_MenuSelected(this.patientId, this.refreshservice.EventName_Milestone);
      console.log("Runnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn");

    }, 500);

  }
  isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
  getPatientsVaccination = () => {

    // let patientMoreData: any = JSON.parse(localStorage.getItem("patientMoreData"));
    // let joiningDate = moment(patientMoreData.createdAt).format('DD MMM, YYYY')

    this.patientService.getPatientsVaccination(this.patientId).subscribe(response => {

      let vaccineList = []
      if (response) {
        vaccineList = response;
        for (let i = 0; i < vaccineList.length; i++) {
          if (this.isJson(vaccineList[i].name)) {
            let scheduleNames = JSON.parse(vaccineList[i].name);
            if (scheduleNames && scheduleNames.length > 0) {
              scheduleNames.forEach(langpart => {
                if (langpart.lang == 'en') {
                  vaccineList[i].name = langpart.text;
                }
              });
            }
          }
        }

        console.log('VACCCC', vaccineList)

        let vaccineArray = [];
        let newArray = [];
        let tempArr = [];
        let administeredVaccines = [];


        vaccineList.map(ins => {
          ins.data.map((item, idx) => {
            if (!tempArr.includes(ins.name)) {
              tempArr.push(ins.name)
              item['vaccineVisit'] = ins.name;
            } else {
              item['vaccineVisit'] = "";
            }

            item['visitName'] = ins.name;
            item['index'] = idx;
            item['catchupDate'] = item.catchUpPeriod && item.catchUpPeriod != "" && item.dueDate && item.dueDate != "" ? moment(item.dueDate).add(item.catchUpPeriod, 'w') : 'NA';
            item['catchupDate'] = item.catchupDate == 'NA' ? 'NA' : item.catchupDate.format('DD-MM-YYYY');

            //overdue logic
            if (item.catchupDate && item.catchupDate != "NA") {
              var todayDate: any = moment().format('YYYY-MM-DD');
              var pastDate: any = moment(item.catchupDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
              var bool = moment(todayDate).isAfter(moment(pastDate))
              if (bool && !item.administeredStatus) {
                item['isOverdue'] = true;
              }
            }

            //all administeredVaccines for today
            if (item.administeredStatus && moment().isSame(item.administeredDate, 'day')) {
              administeredVaccines.push(item.name);
            }

            if (moment(item.dueDate).isBefore(moment())) {
              item['isPending'] = true
            }
            vaccineArray.push(item);
          });

        });

        // this.unformattedVaccineList = vaccineList;
        vaccineList = newArray.map((ins, index) => { return { name: ins.name, selected: false, id: index } });
        vaccineList = vaccineArray;
        this.vaccineList = vaccineList
        console.log('Administered Vaccines', administeredVaccines);
        console.log('Vaccine List', vaccineList);

        this.formatVaccines();

        // if (administeredVaccines.length) {

        //   this.selectedGeneralInstructions.map((ins, ind) => {
        //     if (ins.includes('Vaccines given')) {
        //       this.selectedGeneralInstructions.splice(ind, 1)
        //     }
        //   });

        //   this.selectedGeneralInstructions.push('Vaccines given - ' + administeredVaccines.join(', '))

        // }

      }
    });
  }
  selectedRowIndex = -1;

  selectedRows(item) {
    this.selectedRowIndex = item.id;

  }


  onDateChangeForVacc(event) {
    this.vacDate = event.value
    this.isDateFilledAddVac = !!event.target.value;
  }
  getVaccData(element) {
    this.selectedVaccination.push(element)
    console.log("may26", this.selectedVaccination)

  }

  savePendingVaccination() {
    console.log("Dec7", this.selectedVaccination);
    var mastervaccId: any = [];
    this.vaccinationFlag = true;
    var vaccinationName: any = "";
    var data = this.langFollowupMessage.getValue();
    console.log("apr4", data);

    // Check if vacDate is defined and data has not been sent previously
    if (this.vacDate != undefined && !this.dataSent) {
      var vaccDate: any = this.reverseDate(this.vacDate);
      vaccDate = moment(new Date(this.parseDate(vaccDate))).format("DD/MM/YYYY");
      this.langFollowupMessage.appendTexttoBox(vaccDate + " " + "-" + " ");
      var vaccText = vaccDate + " " + "-" + " "
      this.nextFolloupPreview.push(vaccText)

      this.selectedVaccination.map(ins => {
        if (ins.name) {
          mastervaccId.push(ins.id);
          this.langFollowupMessage.appendTexttoBox(' ' + " " + ins.name + "");
          this.nextFolloupPreview.push(' ' + " " + ins.name + "")

        }
      });

      // Update flag to indicate data has been sent
      this.dataSent = true;

      // Call a method to get the language textbox data (if needed)
      this.getLangTextBoxData();

      // Delayed operations or opening dialogs can be placed here
      setTimeout(() => {
        // this.openDialog(this.followup_modal, 'followup_modal')
      }, 500);
    }

    var nextVaccDate: any = this.reverseDate(this.vacDate);
    var params = {
      nextVaccinationDate: moment(new Date(this.parseDate(nextVaccDate))).format("DD-MM-YYYY"),
      patientid: this.patientId,
      masterVaccId: mastervaccId
    };

    this.patientService.updateVaccinationRecord(params).subscribe(response => {
      if (response) {
        console.log("updatevacc", response);
      }
    });
  }


  // savePendingVaccination() {
  //   this.vaccinationFlag = true
  //   var vaccinationName: any = ""
  //   var data = this.langFollowupMessage.getValue();
  //   console.log("apr4", data)
  //   if (this.vacDate != undefined) {
  //     var vaccDate: any = this.reverseDate(this.vacDate);
  //     vaccDate = moment(new Date(this.parseDate(vaccDate))).format("DD/MM/YYYY");
  //     this.langInstructions.appendTexttoBox(vaccDate + " " + "-" + " ")
  //     this.selectedVaccination.map(ins => {
  //       if (ins.name) {
  //         this.langInstructions.appendTexttoBox(' ' + " " + ins.name + "");

  //       }
  //     })

  //     this.getLangTextBoxData();
  //     // setTimeout(() => {

  //       // this.openDialog(this.followup_modal, 'followup_modal')
  //     // }, 500)

  //   }

  // }
  getLangTextBoxData() {

    // this.langFollowupMessage.clearTextBox()

    this.nextFolloupPreview = [];


    if (this.backToEdit) {
      //this.langFollowupMessage.clearTextBox()


      var followupMessage = localStorage.getItem("followUpMessage") ? localStorage.getItem("followUpMessage") : ""
      setTimeout(() => {
        this.langFollowupMessage.appendTexttoBox(followupMessage)
      }, 200)
      this.nextFolloupPreview.push(followupMessage)
      this.followupType = localStorage.getItem("selectApptfollowupType") ? localStorage.getItem("selectApptfollowupType") : ""
      this.templateName = localStorage.getItem("selectDoctorsTemplate") ? localStorage.getItem("selectDoctorsTemplate") : this.templateName
      this.selectedLanguage = JSON.parse(localStorage.getItem("doctorsLanguage")) ? JSON.parse(localStorage.getItem("doctorsLanguage")) : this.selectedLanguage;
      var followUpdate = localStorage.getItem("followupDate") ? localStorage.getItem("followupDate") : this.followupDateNew

      this.followupDateNew = new Date(followUpdate)
    }
    if (this.vaccinationFlag) {
      var data = this.langFollowupMessage.getValue();
      // setTimeout(() => {
      //   this.langFollowupMessage.appendTexttoBox(data)
      // }, 1000)

      console.log("apr4", data)
    }
  }
 
  formatVaccines() {
    this.vaccineList.map(ins => {
      this.pendingVaccination.push(ins);
      if (!ins.administeredStatus) {
        this.upcomingVaccineList.push(ins);
      }
    });

    // Remove duplicates from pendingVaccination and upcomingVaccineList arrays
    this.pendingVaccination = this.removeDuplicates(this.pendingVaccination, 'name');
    this.upcomingVaccineList = this.removeDuplicates(this.upcomingVaccineList, 'name');

    this.vaccinesLoading = false;
  }

  async selectFollowUpMessage(event) {
    console.log("eventeventevent", event);

    // Get the value of langFollowupMessage
    var followupmessage = this.langFollowupMessage.getValue();


    // Push the selected value into nextFolloupPreview array
    if (!this.nextFolloupPreview.includes(event.value)) {
      console.log("nextFolloupPreviewnextFolloupPreview", this.nextFolloupPreview);

      this.nextFolloupPreview.push(event.value);
      console.log("nextFolloupPreviewnextFolloupPreview   111", this.nextFolloupPreview);

    }

    // Check if the follow-up message is different from the event value
    if (followupmessage !== event.value) {
      // Check if the event value is not already included in followupmessage
      if (!followupmessage.includes(event.value)) {
        //   // Append the selected value to langFollowupMessage
        this.langFollowupMessage.appendTexttoBox(event.value);
      }
    }
    // Handle special case if the event value is "Next Vaccination due:"
    if (event.value == "Next Vaccination due:") {
      // Push langFollowupMessage into nextFolloupPreview

      this.getPatientsVaccination();
      // Open the pendingVaccination_modal dialog
      this.openDialog(this.pendingVaccination_modal, 'pendingVaccination_modal');

      if (this.nextFolloupPreview.includes(followupmessage)) {
        const index = this.nextFolloupPreview.lastIndexOf(followupmessage);
        // this.nextFolloupPreview.splice(index, 1);
        console.log("Followup message removed:", followupmessage);
      } else {
        console.log("Followup message is not present:", followupmessage);
      }

      // Process vaccination-related operations

    }
  }

  // Function to remove duplicates from an array based on a specific property
  removeDuplicates(array, key) {
    return array.filter((item, index, self) =>
      index === self.findIndex((t) => (
        t[key] === item[key]
      ))
    );
  }


  onDropdownSelectTemplate(index, item, indx) {
    console.log("ind", indx)
    var data = []

    item.selected = item.moreSymptoms[index];
    data.push(item.selected)
    console.log('Item', data);
    var indexValue = index

    this.finalSelectedComplaints.forEach((ins, ind) => {
      if (indx == ind) {
        if (ins.includes(' -')) {
          console.log(ins)
          var str1 = ins.split(' -');
          str1.shift();
          var strValue = str1.join()
          sinceDayText = item.selected + " -" + strValue;
          this.finalSelectedComplaints[ind] = sinceDayText;
        }
        else {
          var sinceDayText = item.selected + " -" + ins;

          this.finalSelectedComplaints[ind] = sinceDayText;
        }


      }






    });


    console.log("allSelectedComplaintsAndMoreSym", this.allSelectedComplaintsAndMoreSym);
    console.log("finalSelectedComplaints", this.finalSelectedComplaints);
    this
    // Remove any existing entry with the same item.name
    // this.finalSelectedComplaints = this.finalSelectedComplaints.filter(existingItem => !existingItem.includes(item.name));

    // Add the new complaints
    // this.finalSelectedComplaints.push(complaints);

    console.log("final", this.finalSelectedComplaints);
  }

  onchangeSinecDays(e, item, i) {
    console.log("itejjshsj", this.complaintsObj)
    console.log("item", item)
    var ind = i
    var sinceDayText = ""
    this.sinceDay = e
    console.log(this.finalSelectedComplaints)
    this.finalSelectedComplaints.forEach((ins, index) => {
      if (i === index) {
        if (ins.includes('Since')) {
          console.log("ins", ins)
          var str1 = ins.split('Since');
          str1.pop();
          var strValue = str1.join()
          sinceDayText = `${strValue}Since ${this.sinceDay} day`;
          // sinceDayText = ins + "-" + " " + "Since" + " " + this.sinceDay + " " + "day";
          this.finalSelectedComplaints[i] = sinceDayText;
        }
        else {
          sinceDayText = `${ins}-Since ${this.sinceDay} day`;
          // sinceDayText = ins + "-" + " " + "Since" + " " + this.sinceDay + " " + "day";
          this.finalSelectedComplaints[i] = sinceDayText;
        }


      }
    });
    console.log(this.finalSelectedComplaints);

    

  }



  getPrescriptionMedicines() {
    console.log("return 333333")
    var obj = {
      "patientId": this.patientId,
      "selectfield": this.selectFieldsArray
    }
    console.log("1111111111111111111233");

    // this.finalSelectedComplaints = []
    this.selectedDignosisName = []
    this.selectedGeneralInstructionsName = []
    this.selectedDietName = []
    this.allMedicines = []
    this.nextFolloupPreview = [];
    this.complaintDetailsData = []
    //  this.allSelectedComplaintsAndMoreSym=[]


    var obj = {
      "patientId": this.patientId,
      "selectfield": this.selectFieldsArray
    }
    console.log("nre rx patientId", this.patientId);
    console.log("getPrescriptionMedicines", obj);


    // this.showLoading = true;
    this.patientService.getPrescriptionMedicines(this.patientId, this.selectFieldsArray).subscribe(response => {
      console.log("return response 333333")

      if (response) {
        console.log("saveVisitCommanFun called 2");

        this.patientGetPrescriptions = response;

        console.log("Prescription response ??", response);
        this.showLoading = false;

        // alert('Response --- ' + JSON.stringify(response));

        if (response.appointment) {
          this.appointmentId = response.appointment.id;

          //  this.editFavName = response.appointment.favApptName
        }
        if (response.visit && response.appointment && response.appointment.prescriptionPDFURL != null && !this.backToEdit) {
          this.showLoading = false;
          this.backToEdit = true



          //  Commenting this as we have now discarded edit prescription screen.
          // this.router.navigate([`/doctor/patients/editprescription/view`], { queryParams: { patientId: this.patientId } });

        }

        if (response.visit) {
          setTimeout(() => {
            this.langFollowupMessage.clearTextBox()
          }, 500)

          // next_vaccination :  "Mon Mar 11 2024:.IMPORTANT:"  response.visit.next_vaccination; from backend
          // this.nextFolloupPreview=[]
          var newStr: any
          var newString = response.visit.next_vaccination;
          console.log("newString", newString)
          if (newString.includes('</br>')) {
            newStr = newString.split('</br>')
            newStr.shift();
            console.log("newStr,newStrnewStr", newStr);




          }
          if (newString.includes(':')) {
            console.log("newString", newString);

            newStr = newString.split(':')
            newStr.shift();
            newStr.shift();



            console.log("wString.includ  is running ", newStr);

          }
          // if(newString.includes(':call:')){
          //   newStr=newString.split(':call:')
          //   newStr.shift();
          // }
          // if(newString.includes(':visit:')){
          //   newStr=newString.split(':visit:')
          //   newStr.shift();
          // }
          else {
            console.log(" else is running ");

            newStr = newString.split(':.')
            newStr.shift();
          }

          //  console.log("newString",newString)
          let nextFolloupPreviewWithoutDate = newStr.join();
          console.log("editedString", nextFolloupPreviewWithoutDate);

          if (!this.nextFolloupPreview.includes(nextFolloupPreviewWithoutDate)) {
            // If not included, push it into the array
            this.nextFolloupPreview.push(nextFolloupPreviewWithoutDate);
            console.log("this.nextFolloupPreview", this.nextFolloupPreview);
          } else {
            // If already included, you might want to handle this case differently or do nothing
            console.log("nextFolloupPreviewWithoutDate already exists in this.nextFolloupPreview");
          }

          setTimeout(() => {

            this.langFollowupMessage.appendTexttoBox(nextFolloupPreviewWithoutDate);
            console.log("nextFolloupPreviewWithoutDate", nextFolloupPreviewWithoutDate);


          }, 500)

          if (response.visit.chief_complaints || response.visit.complaint_examination
            || response.visit.complaint_diagnosis || response.visit.Medicines.length > 0
            || response.visit.nutrition_diet_advice || response.visit.general_instruction) {
            //basically if any of these 6 fields are coming from database, the is stored and we set from 
            // db values else we let original sessions values stay as it is


            var dataForCom: any = []
            this.finalSelectedComplaints = response.visit.chief_complaints ? response.visit.chief_complaints.split(',') : [];
            console.log("finalSelectedComplaints", this.finalSelectedComplaints);
            var moredata = {}

            this.finalSelectedComplaints.map(ins => {
              if (ins) {
                moredata = {
                  complaint: ins,
                  selected: false,
                  name: ins,
                  moreSymptoms: [],
                  sinceDay: "",
                  key: ins,
                  isHistory: true
                }
                this.complaintDetailsData.push(moredata)
              }
            })

            this.allSelectedComplaintsAndMoreSym = this.complaintDetailsData

            this.allSelectedComplaintsAndMoreSym = this.allSelectedComplaintsAndMoreSym.reduce((unique, o) => {
              if (!unique.some(obj => obj.complaint == o.complaint)) {
                unique.push(o);
              }
              return unique;
            }, []);

            console.log("compleMar15", this.allSelectedComplaintsAndMoreSym)
            this.selectedDignosisName = response.visit.complaint_diagnosis ? response.visit.complaint_diagnosis.split(',') : [];
            this.selectedGeneralInstructionsName = response.visit.general_instruction ? response.visit.general_instruction.split(',') : [];
            this.selectedDietName = response.visit.nutrition_diet_advice ? response.visit.nutrition_diet_advice.split(',') : [];
            // this.selectedOnExamination = response.visit.complaint_examination ? response.visit.complaint_examination.split(',') : []
            //  this.nextFollowUp = response.visit.next_vaccination;

            let data = response.visit.Medicines;

            this.allMedicines = [];

            data.map(ins => {

              if (ins.Drug) {



                let obj: any = {}
                if (!this.drugMolecularFlag) { //if no molecule flag set, then no show molecule
                  obj.name = ins.Drug.product_name ? ins.Drug.product_name : ins.Drug.product_name;
                }
                else {
                  obj.name = ins.Drug.product_name && ins.Drug.generic_name ? ins.Drug.product_name + ' (' + ins.Drug.generic_name + ')' : ins.Drug.product_name;
                }

                obj.Drug = ins.Drug;
                obj.dosagePreviewMed = ins.dosage2;
                obj.dosage2 = ins.dosage2;
                obj.duration2 = ins.duration2
                obj.duration3 = ''//this.days;
                obj.duration4 = ins.frequency2;
                obj.finalPreview = ins.dosage2;
                obj.frequency2 = ins.frequency2;
                obj.instructions = ins.instructions;
                obj.whenToTake = ins.whenToTake ? ins.whenToTake : '',
                  obj.dosage = ins.dosage ? ins.dosage : ins.dosage2
                obj.id = ins.id;
                obj.selected = true;

                let element = {
                  whenToTake: ins.duration2,
                  medicineName: ins.Drug.product_name && ins.Drug.generic_name ? ins.Drug.product_name + ' (' + ins.Drug.generic_name + ')' : ins.Drug.product_name,
                  dosage2: ins.dosage2,
                  instructions: ins.instructions,
                  id: ins.id,
                }

                // element.dosage2 = element.whenToTake ? element.dosage2.replace('--', `- ${element.whenToTake} -`) : element.dosage2

                // when we press backto edit delete medicines not getting reflected
                //so updateing medicines from session not by api
                //need to check later 
                this.allMedicines = this.allMedicines ? this.allMedicines : []

                this.allMedicines.push(obj);

                // this.allMedicines = this.allMedicines.reduce((unique, o) => {
                //   if (!unique.some(obj => obj.Drug.id == o.Drug.id)) {
                //     unique.push(o);
                //   }
                //   return unique;
                // }, []);
                // this.allMedicines.map(ins => {
                //   // if (this.drugMolecularFlag == 1) {
                //   //   ins.Drug.generic_name = ""
                //   // }
                // })
                this.prescriptionPreviewArray.push(element);
              }
            });

            // this.saveState();
          }


        }
        else {
          this.showLoading = false;
          //no prescription
          var sess_patid = sessionStorage.getItem("qrx_patientid");
          if (sess_patid && sess_patid == this.patientId) {
          } else {

            //   this.openDialog(this.showPrescription_modalPer, 0)
          }

        }

      }
    });
  }

  getFavName(favName) {
    this.favApptName = favName
  }


  popularMedicineDelete(element) {
    this.currentPopularMedObj = element
    let postData = {
      id: this.currentPopularMedObj.MedGroupDrug_id

    }
    console.log("popularMedicineDelete id ", postData);

    this.patientService.deletePopularDrugId(postData).subscribe(response => {
      if (response) {
        console.log(response)

        this.getMedicinesAsPerDiagnosis();
      }

    })
  }
  // deletePopularMedicine() {

  //   let postData = {
  //     id: this.currentPopularMedObj.MedGroupDrug_id

  //   }
  //   this.patientService.deletePopularDrugId(postData).subscribe(response => {
  //     if (response) {
  //       console.log(response)

  //       this.getMedicinesAsPerDiagnosis();
  //     }

  //   })

  // }
  onDateChangeForLmpDate(event) {
    console.log("date", event)
    var lmpDate: any = this.reverseDate(event.value);
    lmpDate = moment(new Date(this.parseDate(lmpDate))).format("YYYY-MM-DD");
    this.selectedVisit.lmp = lmpDate

  }
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.allMedicines, event.previousIndex, event.currentIndex);
  }
}

<div style="background-color: #fff;
height: 100%;
width: 100%;
z-index: 100;" class="d-flex justify-content-center align-items-center position-fixed" *ngIf="showRequisitionLoading">
    <label for="" class="loader"></label>
</div>
<div style="display: flex; height: 100vh; width: 100%; flex-direction: column; flex: 1;">

    <div
        style="width: 100%; height: 60px; background-color: #fff;padding: 16px; display: flex; justify-content: space-between; align-items: center; margin-top: 16px;">

        <div style="display: flex; flex-direction: column; justify-content: center; ">
            <div class="heading" style="margin-top:0px">Requisitions</div>
            <div class="subheading" style="width: 100%;">You can create patient requisition</div>
        </div>

        <div>
            <button (click)="getAllRequisitions()" style="border: 1px solid #5D86CC;
                padding: 8px 16px;
                border-radius: 6px;
                color: #5D86CC;
                font-size: 18px;
                font-weight: bold;
                background-color: #fff;
                margin-right: 12px;
                outline: none;">Refresh</button>

            <button (click)="openDialog(addRequisitionModal); openRequisitionModal(); " class="new_btn"
                style="margin-right: 30px;">New Requisition</button>

        </div>


    </div>


    <div *ngIf="!allRequisition.length" style="width: 100%;display: flex; flex-direction: column; width: 100%;
    height: 100vh;
    display: flex;
   
    justify-content: center;
    align-items: center;">

        <div style="text-align: center;">
            <img style="justify-content: center;align-items: center; width: 200px;margin-bottom: 0px"
                src="assets/images/placeholder.svg" alt="" />

            <div style="font-size: 20px; margin-top: 16px; color: #333333; font-weight: bold;">No Requisition
            </div>
            <div style="font-size: 18px; color: grey;">No requisition found for selected Patient</div>
        </div>
    </div>


    <div style="display: flex; flex: 1; justify-content: center;">
        <div style=" width: 80%; justify-content:center;">
            <div *ngFor="let requisition of allRequisition; let i = index">
                <div class="card" style="align-self: center;padding: 10px; display: flex;">
                    <div style="display: flex; flex-direction: row; margin-left: 10px; width: 100%; ">
                        <div style="display: flex; flex-direction: row;  width: 100%;">
                            <div *ngIf="requisition.Doctor.User.first_name!='SYSTEM'" class="leftSideTextOnCard"
                                style="font-size:18px"> Dr.
                                {{requisition.Doctor.User.first_name}}
                                {{requisition.Doctor.User.last_name}}</div>
                            <div *ngIf="requisition.Doctor.User.first_name=='SYSTEM'" class="leftSideTextOnCard"
                                style="font-size:18px">
                                self</div>


                        </div>
                    </div>
                    <div class="rightSideTextOnCard" style="margin-top: -24px;"
                        *ngIf="requisition.sharedtolab && requisition.sharedtolab.labName">
                        <span style="font-size: 16px; font-weight: bold; color: #333;">Sharedtolab : &nbsp; </span>
                        <span style="font-size: 16px;">{{requisition.sharedtolab.labName}}</span>
                    </div>
                    <div class="rightSideTextOnCard" style="font-size: 18px; color: grey; margin-top: 0px;">
                        {{requisition.createdAt}}
                    </div>

                    <div style="display: flex; flex-direction: row; margin-left: 10px; ">
                        <div style="display: flex; flex-direction: row;">
                            <div *ngIf="requisition.url" class="leftSideTextOnCard" style="color: #8B8B8B;">Requisition
                                request for:
                            </div>
                            <div *ngIf="requisition.photoURL || requisition.photoURL==''" class="leftSideTextOnCard"
                                style="color: #8B8B8B;">Comments:<br>
                                <span style="font-size: 14px;color: #8B8B8B;"> {{requisition.comments}}</span>
                            </div>
                        </div>

                    </div>
                    <div *ngIf="requisition.tests">
                        <div *ngFor="let tests of requisition.tests.split('|');let i=index"
                            style="margin-top: 10px;margin-left: 15px;">
                            <div style="font-size: 16px;color: #8B8B8B; word-break: break-all !important">
                                {{i+1}}.
                                {{tests}}
                            </div>
                        </div>
                    </div>
                    <div style="display: flex; flex-direction: row; margin-left: 10px; ">
                        <div
                            style="display: flex; flex-direction: row; width:100%; justify-content: space-between; margin-top:8px;">
                            <div *ngIf="requisition.url || requisition.photoURL!=''" class="leftSideTextOnCard"
                                (click)="onClickViewRequisition(requisition);closeSelectedData()"
                                style="color:#4687BF; font-size: 18px;">
                                Preview
                            </div>

                            <div *ngIf="requisition.doctorId===doctorObj.id" class="leftSideTextOnCard"
                                style="margin-right: 12px !important"
                                (click)="openDialog(addDeleteModal,'requisition'); onClickDeleteRequisition(requisition);"
                                style="color: #ff0000; margin-right: 12px; font-size: 18px;">Delete</div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<!--*************addRequisitionModal****************-->



<ng-template #addRequisitionModal let-modal>
 <div style="width: 100%; padding: 25px;">
    <div class="modal-header"
    style="display: flex; flex-direction: row; flex: 1; width: 100%; height: 100%; justify-content: space-between; align-items: center; border-bottom: 0px; padding: 0px; padding-bottom: 16px;">
    <!-- <h4 class="modal-title" id="modal-basic-title">New Requisition</h4> -->
    <div class="heading" style="margin-top:0px; margin-left: 0px;">New Requisition</div>
    <div style="display:flex; flex-direction: row;  margin-right: 30px;">

        <div ngbDropdown class="d-inline-block">
            <button class="btn btn-outline-primary" id="dropdownBasic1" style="border: 0px;
            border-bottom: 1px solid #ccc;
            padding-right: 0px;
            color: #333;
            background-color: #fff;
            margin-right: 4px;
            " ngbDropdownToggle>{{templateName}} </button>

            <div ngbDropdownMenu aria-labelledby="dropdownBasic1" style="top:-11px">
                <button (click)="onDropdownSelectTemplate(item)" style="margin: 0px 16px 0px 0px !important"
                    *ngFor="let item of selectedTemplate; let i = index" ngbDropdownItem>{{item}}</button>
            </div>
        </div>

        <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
            (click)="closeDialog()">
            <span aria-hidden="true" style="font-size:40px; color: #333;">&times;</span>
        </button>
    </div>
</div>
<div class="modal-body">

   

    <form>

        <div class="form-group">

            <div class="input-group">
                <input id="searchbox" matInput (input)="onSearchChange($event.target.value)" style="border-radius:6px; padding: 0px 8px 0px 8px; height: 40px; margin-top: px;
                    font-size: 16px;" class="form-control" placeholder="{{requisitionPlaceholder}}" name="sb" [(ngModel)]="searchBox">
            </div>

         <mat-tab-group mat-stretch-tabs animationDuration="0ms" (selectedTabChange)="tabClick($event)">
                <mat-tab label="All Tests">

                    <div class="scrollDiv" style="height: 40vh; ">
                        <div
                            style="display: flex; flex-direction: column; flex-wrap: wrap; margin-top: 0px; margin-bottom: 16px; width: 45vw;">
    
                            <div *ngFor="let item of sectionWiseRequisitionTests; let i = index">
    
                                <label style="margin-top: 4px !important; margin-bottom: 4px !important;"
                                    class="newInputStyleLabel">{{item.section}}</label>
    
    
                                <div *ngFor="let item1 of item.data; let j = index" class="searchable-component-style testCardUi"
                                    (click)="selectModalTestValue(i, j)">
    
                                    <img style="margin: 0px 8px 0px 0px;height:16px;width:16px;"
                                        src="assets/images/add_button.svg" class="tick-icons" />
    
    
                                    <div style="font-size: 16px;">{{item1.name}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
    
                </mat-tab>
                <mat-tab label="Packages" >
                    <div class="scrollDiv" style="height: 40vh; ">
                        <div
                        style="display: flex; flex-direction: column; flex-wrap: wrap; margin-top: 0px; margin-bottom: 16px; width: 45vw;">
                    <div *ngFor="let key of objectKeys(packageWiseTests) | filter:searchTest;  " style="margin-top: 4px !important; margin-bottom: 4px !important;"
                    class="newInputStyleLabel">
                      <div  style="font-size:16px; font-weight: 600;">{{ key }}<mat-checkbox *ngIf="objectKeys(packageWiseTests).length" (change)="getSelctedTest(key,$event)" style="margin-left: 10px;"></mat-checkbox></div>   
                     
                       <div *ngFor="let obj of packageWiseTests[key] "
                       class="searchable-component-style"
                       style="display: flex;
                   flex-direction: row;
                   border: 1px solid #eee;
                   border-radius: 6px;
                   padding: 6px;
                   background-color: #F3F6FA;
                   align-items: center;
                   margin: 8px;
                   height: 40px;
                   cursor: pointer;" (click)="selectPackageTestValue(obj)">
                      <img style="margin: 0px 8px 0px 0px;height:16px;width:16px;"
                      src="assets/images/add_button.svg" class="tick-icons" />

                         {{ obj.test }}
                       </div>
                    </div>
                     
                     </div>
                     </div>
                </mat-tab>
              
              </mat-tab-group> 

        
            <div  style="height: fit-content;  padding-left: 7px; margin-top: 15px; width: 50vw; ">

                <div class="selectedComplaintsBox">

                    <div
                        style="display: flex; flex-direction: row; flex-wrap: wrap; margin-top: 0px; margin-bottom: 0px;">

                        <div *ngFor="let item of selectedTest; let i = index" class="searchable-component-style"
                            class="selectComplanits tagsContainer">


                            {{item}} <span (click)="removeSelectedTest(item)" class="crossButton">&times;</span>
                        </div>
                    </div>
                </div>
            </div>

            <div style="width:100%;padding-left: 5px;">
                <label style="margin-top: 4px !important; margin-bottom: 4px !important;"
                    class="newInputStyleLabel">Comments</label>
                <textarea name="comments" type="text" class="form-control newInputBox " maxlength="499"
                    style="border-radius: 4px !important; color: #656565 !important; font-size: 1rem !important;"
                    placeholder="Comments" [(ngModel)]='comments'>{{comments}}</textarea>

                <div style="display: flex; justify-content: flex-end; margin-top: 16px;">
                    <button (click)="printRequisitions('preview');closeDialog(); " class="new_btn">Save & Preview</button>
                </div>

            </div>
        </div>
    </form>
</div>
 </div>
</ng-template>

<!-- viewRequisitionModal-->
<ng-template #viewRequisitionModal let-modal>
    <div>
        <div class="modal-header" style="border-bottom: 0px; padding: 16px 16px 16px 0px; width: 76vw;">
            <h4 class="modal-title" id="modal-basic-title">View Requisition</h4>
            <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
                (click)="closeDialog();">
                <span aria-hidden="true" style="font-size: 40px; color: #000;">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div *ngIf="requisitiontPdfURL" style="display: flex;  height: 80vh;">
                <embed [attr.src]="requisitiontPdfURL" type="application/pdf" width="100%" height="100%">
            </div>
            <div *ngIf="requisitiontPhotoURL" style="display: flex;  height: 80vh;">
                <img [attr.src]="requisitiontPhotoURL" type="application/jpg" width="100%" height="100%">
            </div>

            <div style="display: flex;">
                <div (click)="closeDialog();editRequition();openDialog(addRequisitionModal,'addRequisitionModal');"
                    style="display: flex; justify-content: flex-end; align-items: center; padding: 16px; width: 55vw;">
                    <button *ngIf="showEditRequitionButton" class="new_btn">Edit Requisition</button>
                </div>

                <div (click)="onClickRequisitionShare(requisitionURL);shareRequisitionModalOpen(sharerequisition,'sharerequisition');sharerequisitionWithFavLab();"
                    style="display: flex; justify-content: flex-end; align-items: center; padding: 16px; width: 20vw;">
                    <button class="new_btn">Share Requisition</button>
                </div>
            </div>
        </div>

    </div>
</ng-template>

<!--************** SHARE REQUISTION MODAL-->
<ng-template #sharerequisition let-modal>

<div style="width: 100%; padding: 25px;">
    <div style="display: flex; flex-direction: column; justify-content: center; ">
        <div class="heading" style="margin-top:0px; margin-left: 0px;">Share Requisitions</div>
        <div class="subheading" style="width: 100%; margin-left: 0px;">We successfully shared the requisition with
            the
            patient.<br />If
            you want to share it any further use the following.</div>
    </div>

    <div style="display: flex;flex-direction: column; margin-top: 16px;">
        <div style="font-size: 16px; font-weight: 600; padding: 5px 0px;"> Share with Favourite Lab</div>
        <mat-form-field>
            <mat-label>Choose Lab</mat-label>
            <mat-select (selectionChange)="onDropdownSelectLab('item',$event.value)">
                <mat-option *ngFor="let item of favLabs" [value]="item">
                    {{item.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div *ngIf='!selectedLab' style="font-size: 12px; display: flex;flex-direction: row; ">
            <div *ngIf="emailFlag" class="iconStyle"
                style="font-size: 12px;width: 152px; margin: 8px 9px;  cursor: pointer;">
                <img style="height: 25px; margin-left: 0x; width: 20px;" src="assets/images/whatsapp_icon_grey.svg"
                    alt="" /><span style="margin-left: 5px;">WhatsApp</span>
            </div>
            <div class="iconStyle" style="font-size: 12px;width: 152px; cursor: pointer;">
                <img style="height: 25px; margin-left: 0x; width: 20px; " src="assets/images/gmail_icon_grey.svg"
                    alt="" /><span style="margin-left: 5px;">Email</span>
            </div>
        </div>

        <div *ngIf='selectedLab ' style="font-size: 12px; display: flex;flex-direction: row; ">
            <div *ngIf="emailFlag" (click)="share()" class="iconActiveStyle"
                style="font-size: 12px;width: 152px; margin: 8px 9px; cursor: pointer;">
                <img style="height: 25px; margin-left: 0x; width: 20px;" src="assets/images/whatsapp_icon_color.svg"
                    alt="" /><span style="margin-left: 5px;">WhatsApp</span>
            </div>
            <div *ngIf="!emailFlag" (click)="onClickRequisitionShareToFavLab()" class="emailIconActiveStyle"
                style="font-size: 12px;width: 152px; cursor: pointer;">
                <img style="height: 25px; margin-left: 0x; width: 20px;" src="assets/images/gmail_icon_color.svg"
                    alt="" /><span style="margin-left: 5px;">Email</span>
            </div>
            <div *ngIf="emailFlag" class="emailIconActiveStyle"
                style="font-size: 12px;width: 152px; display: flex; justify-content: space-between;">
                <span style="margin-left: 5px; cursor: pointer; align-self: center;">Email Sent</span>
                <img style="height: 30px; margin-left: 0x; width: 30px;" src="assets/images/tick_maroon.svg" alt="" />
            </div>

        </div>

        <div *ngIf="emailFlag" style="font-size: 16px; font-weight: 600; margin: 9px 4px;"> Share with Other</div>
        <div style="font-size: 12px; display: flex;flex-direction: row;">
            <div *ngIf="emailFlag" (click)="shareWithOther(); modal.dismiss();" class="iconActiveStyle"
                style="font-size: 12px;width: 152px; cursor: pointer;">
                <img style="height: 25px; margin-left: 0x; width: 20px;" src="assets/images/whatsapp_icon_color.svg"
                    alt="" /><span style="margin-left: 5px;">WhatsApp</span>
            </div>
        </div>

        <div style="font-size: 12px; display: flex;flex-direction: row;margin-top: 7px;"
            (click)="closeDialog();shareReciept()">
        </div>

    </div>

    <div style="display: flex;flex-direction: row;justify-content: space-between;">
        <div style=" padding-top: 28px; color: #6975d7; font-size: 16px;font-weight: 600; text-decoration: underline;">
            <span style="cursor: pointer;" *ngIf="appointmentBook" (click)="bookAppointment()"> Book
                Appointment</span>
        </div>
        <div style="padding-top: 16px; display: flex; justify-content: flex-end;">
            <button (click)="closeDialog()"
                style="background-color: #eee; padding: 8px 16px; border-radius: 6px; color: #fff; font-weight: bold; border: 0px; margin: 8px; color: #444; outline: none; font-size: 18px;">Cancel</button>


        </div>
    </div>
</div>
</ng-template>


<ng-template #addDeleteModal>
  <div style="padding: 20px;">
    <h4>Delete Requisition?</h4>
    <div style="color: #555; font-size: 16px;"> Are you sure you want to delete the selected records?</div>
    <div style="padding-top: 16px; display: flex; justify-content: flex-end;">
        <button (click)="closeDialog()" style="background-color: #eee;
                  padding: 8px 16px;
                  border-radius: 6px;
                  color: #fff;
                  font-weight: bold;
                  border: 0px;
                  margin: 4px;
                  color: #444;
                  outline: none;">Cancel</button>

        <button (click)="onClickRequisitionDelete();closeDialog()" style="background-color: #f44336;
              padding: 8px 16px;
              border-radius: 6px;
              color: #fff;
              font-weight: bold;
              border: 0px;
              margin: 4px;
            outline: none;">Delete</button>
    </div>
  </div>
</ng-template>

<p-toast position="top-right" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class=" p-flex p-flex-column"
            style="flex: 1; background-color:#BAE8FC; opacity: 0.95; display: flex; flex-direction: row; align-items: center; border-left: 8px solid #0D8CCD; border-radius: 8px;">
            <div>
                <img (click)="goBack()" style="height: 36px; margin-left: 12px; width: 36px; align-self: center;"
                    src="assets/images/exclamation-circle-blue.svg" alt="" />
            </div>
            <div class=" p-text-center">
                <div style="font-size: 18px;
                font-weight: bold;
                padding: 12px 0px 0px 12px;
                
                color: #034A70;">{{message.summary}}</div>
                <div
                    style="padding-left: 12px; padding-bottom: 12px; color: #034A70; font-size: 16px; margin-top: -6px;">
                    {{message.detail}}</div>
            </div>
        </div>
    </ng-template>
</p-toast>
<div class="searchBox" [style.width.%]="width" [style]="stylesFor" 
     style="display: flex; flex-direction: row; padding: 6px; position: relative;">
     

  <input type="text" [ngStyle]="styles" name="languageText" ngDefaultControl 
         [placeholder]="placeholder" [value]="langtextboxvalue" [resultTemplate]="searchDrugResultFormatterTemplate" 
         [(ngModel)]="languageText" [ngbTypeahead]="searchLanguageByTyping" [inputFormatter]="formatMatches" 
         [resultFormatter]="formatter" (keyup)="languageTranslatore($event,languageText); onInputChange();" 
         (selectItem)="selectedItem($event)" 
         style="padding: 4px 60px 4px 16px; margin-left: 16px; border: none; outline: none; width: 100%; background-color: #fff; 
         border-radius: 16px;" 
         (input)="onInputChange(); languageTranslatore($event, languageText);">

  <!-- Dropdown positioned inside the input field, aligned to the right -->
  <div style="position: absolute; right: 0%; top: 70%; transform: translateY(-50%);">
    <mat-form-field appearance="fill" class="no-outline" style="margin: 0;">
      <mat-select style="width: 68px; border: none; background-color: #f4f4f8; border-radius: 8px; height: 34px; padding: 0px 10px;"
                  [value]="lanuageForName" required (selectionChange)="onDropdownSelectLanguage($event.value)">
        <mat-option style="text-align: center;" *ngFor="let item of selectedLanguage" [value]="item.name">
          {{ item.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

</div>

<ng-template #searchDrugResultFormatterTemplate let-r="result" let-t="term">
  <ngb-highlight style="font-weight: bold;" [result]="r" [term]="t"></ngb-highlight>
</ng-template>

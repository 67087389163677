<div class="container-ms">
    <div class="headerSection">
        <div style="flex: 1.5; display: flex; flex-direction: column;">
            <div class="heading">Developmental Milestones ({{selectedMilestoneType == "1" ? "International" : "CDC
                Kerala"}})</div>
            <div class="subheading">Developmental milestones are behaviors or physical skills seen in infants and
                children as they grow and develop.</div>
        </div>
        <div
            style="display: flex; flex: 1; align-items: center; justify-content: flex-end; position: relative; padding-right: 16px;">
            <div
                style="height: 70%; width: 50%; border-radius: 6px; border: 0.4px solid #005CAF; display: flex; flex-direction: row; margin-right: 16px;">
                <div style="width: 90%; height: 100%;">
                    <input type="text" placeholder="Search Developmental Milestones" class="form-control" #searchInput
                        [(ngModel)]="searchMilestones" (keyup)="onSearchMilestones($event)"
                        style="height: 100% !important; font-size: 16px; border: 1px solid transparent !important; background-color: #fff !important; border-radius: 8px !important;" />
                </div>

                <div style="width: 10%; height: 100%; display: flex; justify-content: center; align-items: center;">
                    <button style="border: 0px; background-color: transparent; outline: none;"><img
                            src="assets/images/search_icon_blue.svg" alt="" /></button>
                </div>
            </div>
            <button (click)="openDialog(graphModal,'graphModal');" class="btn">View Graphs</button>
        </div>
    </div>

    <!-- Tabs Section -->
    <mat-tab-group #tabGroup (selectedTabChange)="tabChanged($event)" animationDuration="0ms" class="remove-border-bottom">
        <mat-tab>
            <ng-template mat-tab-label>
                All
            </ng-template>

            <div style="height: 88vh; width: 100%;">
                <!-- MilestoneByType : All -->
                <div style="height: 80vh; width: 100%;">
                    <ng-container [ngTemplateOutlet]="renderMilestones"
                        [ngTemplateOutletContext]="{value:milestoneByType}">
                    </ng-container>
                </div>
                <!-- MilestoneByType : All -->Î
            </div>
        </mat-tab>

        <mat-tab *ngFor="let milestone of milestoneTabs; let index = index" [label]="tab">
            <ng-template mat-tab-label>
                {{milestone}}
            </ng-template>

            <!-- DEVELOPMENTAL -->
            <div style="height: 88vh; width: 100%;" *ngIf="milestone == 'Developmental'">
                <ng-container [ngTemplateOutlet]="renderMilestones"
                    [ngTemplateOutletContext]="{value:developmentalMilestones}">
                </ng-container>
            </div>
            <!-- DEVELOPMENTAL -->

            <!-- LANGUAGE -->
            <div style="height: 88vh; width: 100%;" *ngIf="milestone == 'Language' || milestone == 'LANGUAGE'">
                <ng-container [ngTemplateOutlet]="renderMilestones"
                    [ngTemplateOutletContext]="{value:languageMilestones}">
                </ng-container>
            </div>
            <!-- LANGUAGE -->

            <!-- GROSS MOTOR -->
            <div style="height: 88vh; width: 100%;" *ngIf="milestone == 'GROSS MOTOR'">
                <ng-container [ngTemplateOutlet]="renderMilestones"
                    [ngTemplateOutletContext]="{value:grossMotorMilestones}">
                </ng-container>
            </div>
            <!-- GROSS MOTOR -->

            <!-- FINE MOTOR -->
            <div style="height: 88vh; width: 100%;" *ngIf="milestone == 'FINE MOTOR-ADAPTIVE'">
                <ng-container [ngTemplateOutlet]="renderMilestones"
                    [ngTemplateOutletContext]="{value:fineMotorAdaptiveMilestones}">
                </ng-container>
            </div>
            <!-- FINE MOTOR -->

            <!-- PERSONAL SOCIAL -->
            <div style="height: 88vh; width: 100%;" *ngIf="milestone == 'PERSONAL-SOCIAL'">
                <ng-container [ngTemplateOutlet]="renderMilestones"
                    [ngTemplateOutletContext]="{value:personalSocialMilestones}">
                </ng-container>
            </div>
            <!-- PERSONAL SOCIAL -->
        </mat-tab>

    </mat-tab-group>

    <!-- RESUSABLE TABLE -->
    <ng-template #renderMilestones let-value="value">
        <table mat-table [dataSource]="value" style="width: 100%; height: 30%; overflow: scroll;">
            <ng-container>

                <!-- AGE -->
                <ng-container matColumnDef="age">
                    <th mat-header-cell *matHeaderCellDef> Age (In Months) </th>
                    <td mat-cell *matCellDef="let element" style="width:10%"> {{element.start_month}} to
                        {{element.end_month}}
                    </td>
                </ng-container>

                <!-- ACTION -->
                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef> Action </th>
                    <td mat-cell *matCellDef="let element" style="text-align: start !important; width: 30% !important;">
                        {{element.name}}
                        <span>({{element.sub_type.charAt(0)}})</span>
                    </td>
                </ng-container>

                <!-- STATUS -->
                <ng-container matColumnDef="status" >
                    <th mat-header-cell *matHeaderCellDef> Status </th>
                    <td mat-cell *matCellDef="let element" style="width: 10%;">
                        <!-- Overdue -->
                        <div *ngIf="element.status == 1"
                            style="background-color:#F8D7DA; color: #721C23; text-align: center; margin: 0px 12px; font-size: 17px; font-weight: bold;">
                            Overdue
                        </div>
                        <!-- Completed -->
                        <div *ngIf="element.status == 2"
                            style="background-color:#D4EDDA; color: #165724; text-align: center; margin: 0px 12px;  font-size: 17px; font-weight: bold;">
                            Done
                        </div>
                        <!-- Due -->
                        <div *ngIf="element.status == 3"
                            style="background-color:#FFF3CD; color: #8B6A0F; text-align: center; margin: 0px 12px; font-size: 17px; font-weight: bold;">
                            Due
                        </div>
                        <!-- Upcoming -->
                        <div *ngIf="element.status == 4 || element.status==null"
                            style="background-color:#E2E3E5; color: #383D42; text-align: center; margin: 0px 12px; font-size: 17px; font-weight: bold;">
                            Upcoming
                        </div>
                    </td>
                </ng-container>

                <!-- RECORDED ON -->
                <ng-container matColumnDef="recordedOn">
                    <th mat-header-cell *matHeaderCellDef> Recorded On </th>
                    <td mat-cell *matCellDef="let element" style="width: 10%; text-align: center !important;">
                        <span style="font-size: 18px; " *ngIf="!element.edit">{{element.date |
                            date:"dd-MM-YYYY"}}</span>
                    </td>
                </ng-container>

                <!-- COMMENT -->
                <ng-container matColumnDef="comments">
                    <th mat-header-cell *matHeaderCellDef> Comments </th>
                    <td mat-cell *matCellDef="let element" style="width: 30%; text-align: center !important;">
                        <span style="font-size: 17px;" *ngIf="!element.edit">{{element.description}}</span>
                    </td>
                </ng-container>

                <!-- RECORD -->
                <ng-container matColumnDef="record">
                    <th mat-header-cell *matHeaderCellDef> Record </th>
                    <td mat-cell *matCellDef="let element" style="width: 10% !important;">
                        <div class="actionBtn"
                            (click)="setSelectedMilestone(element); openDialog(addMilestoneModal,'addMilestoneModal');">
                            {{element.status === "2" ? "Edit" : "Record"}}

                        </div>
                    </td>
                </ng-container>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true" style="background-color: #eee;"></tr>
            <tr id="{{i}}" mat-row *matRowDef="let row;  let i = index; columns: displayedColumns;"
           ></tr>
        </table>
    </ng-template>


    <ng-template #addMilestoneModal let-modal>
     <div style="padding: 20px;">
        <div style="display: flex;flex-direction: column;">
            <div class="modalTitle">{{selectedMilestone.status == "2" ? "Edit Milestone" : "Save Milestone"}}
                <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
                    (click)="closeDialog();">
                    <span aria-hidden="true" style="font-size: 40px;color: #000;" (click)="closeDialog()">&times;</span>
                </button>
            </div>
            <div class="modalSubtitle">{{selectedMilestone.status == "2" ? "Update" : "Add"}} milestone
                information for {{patientDataForBirthDate.firstName}}
                {{patientDataForBirthDate.lastName}}</div>
        </div>

        <div style="margin-top: 20px;">
            <div style="font-size: 16px; color: grey;">Milestone</div>
            <div style="font-size: 18px; color: #000;">{{selectedMilestone.name}}</div>
            <div style="display: flex; justify-content: space-between; margin-top: 12px;">
                <div>
                    <div style="font-size: 16px; color: grey;">Age</div>
                    <div style="font-size: 18px; color: #000;">{{selectedMilestone.start_month}} to
                        {{selectedMilestone.end_month}} Months</div>
                </div>
                <div style="color: #333; text-align: center; width: 100px; font-weight: bold; padding: 6px; align-self: center; font-size: 16px;"
                    [ngStyle]="{'background-color': (selectedMilestone.status=='1') ? '#F8D7DA' : (selectedMilestone.status=='2') ? '#D4EDDA' : (selectedMilestone.status=='3') ? '#FFF3CD' : '#E2E3E5' }">
                    {{selectedMilestone.status == "1" ? "Overdue" : selectedMilestone.status == "2" ? "Done" :
                    selectedMilestone.status == "3" ? "Due" : "Upcoming"}}
                </div>
            </div>
            <div style="font-size: 14px; color: grey; font-weight: bold; margin-top: 12px;">Recorded Date</div>
            <div style="display: flex; flex-direction: row; cursor: pointer;">
                <input placeholder="DD/MM/YYYY" name="dp" [(ngModel)]="selectedDate" ngbDatepicker #d="ngbDatepicker"
                    style="font-size: 16px; color: #000; border: 0px; width: 100px; outline: none;">
                <img (click)="d.toggle()" style="height: 30px; width: 30px;" src="assets/images/calendarIcon.svg"
                    alt="" />
            </div>
            <div style="font-size: 14px; color: grey; font-weight: bold; margin-top: 12px;">Comments</div>
            <input placeholder="Type a comment here.." [(ngModel)]="comments" class="commentBox">
            <div style="padding-top: 16px; display: flex; justify-content: flex-end;">
                <button (click)="closeDialog()" class="cancelBtn">Cancel</button>
                <button class="submitBtn" *ngIf="comments!='' && selectedDate!=''" (click)="savePatientMilestone()">Save
                    Milestone </button>
                <button class="submitBtnDisabled" *ngIf="comments=='' || selectedDate == ''">Save Milestone </button>
            </div>
        </div>
     </div>
    </ng-template>

    <ng-template #graphModal let-modal>
        <div style="display: flex;flex-direction: column; width: 100vw;">
            <div class="modalTitle" style="width: 75vw;">Graphical Representation
                <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
                    (click)="closeDialog();">
                    <span aria-hidden="true" style="font-size: 40px;color: #000;" (click)="closeDialog()">&times;</span>
                </button>
            </div>
        </div>

        <div [chart]="chart" style="margin: 20px; padding-top: 20px; height: 80vh; width: 75vw;"></div>
    </ng-template>
</div>
<div class="main-content-loader d-flex justify-content-center align-items-center position-fixed" *ngIf="showLoading">
    <label for="" class="loader"></label>
</div>
<div
    style="height: 106vh; width: 100%; display: flex;  flex-direction: row; justify-content: space-around; background-color: #fff;">

    <div style="width: 55%;">

        <div style="display: flex; flex-direction: row; align-items: center; margin-top: 6px;">
            <button class="btn-back" (click)="backToEdit()">
                <img style="height: 16px; width: 16px;" src="assets/images/arrow-back.svg" />
                Back to Edit</button>

            <div
                style="padding: 0px; font-size: 22px; font-weight: bold; margin-top: 8px;margin-left: 8px;margin-bottom: 0px;">
                Confirm Prescription <span style="font-size: 16px; font-weight: 400px; color: grey;">(Tap fields in case
                    of
                    edit/add)</span></div>

        </div>

        <div style="width: 100%; align-self: center; height: 100%; margin-top: 0px;" >
            <app-prescription-live-preview #prescritionpreviewcomponent [editMode]="true"
                selectedComplaints="{{selectedComplaints}}" selectedDiagnosis="{{selectedDiagnosis}}"
                [allMedicines]="allMedicines" selectedOnExamination="{{selectedOnExamination}}"
                selectedDietaryAdvices="{{selectedDietaryAdvices}}"
                selectedGeneralInstructions="{{selectedGeneralInstructions}}" nextFollowUp="{{nextFollowUp}}"
                [callBack]="setComponentValue">
            </app-prescription-live-preview>
        </div>

    </div>

    <div style="display: flex;flex-direction: column; width: 45%; padding: 6px;
    padding-right: 25px;">
        <div style="text-align: right;">
            <!-- <button class="btn-back" (click)="backToEdit()" >Back to Edit</button>&nbsp;&nbsp;&nbsp;&nbsp; -->
            <button class="btn-back" (click)="toggleFavouriteBox()">
                <img style="height: 24px; width: 24px;" src="assets/images/favourite_heart_red.svg" />
                Add as Favourite</button>&nbsp;&nbsp;
            <button class="btn-confirm" (click)="previewPrescriptionPDF(1)">Confirm and Print</button>
        </div>
        <div style="display: flex;flex-direction: row; font-size: 16px; font-weight: 600;">
            Next Followup
            <div style="margin-top: 3px;"><input type="checkbox"[checked]="addFollowUpFlag" class="largerCheckbox" (change)="addFollowUp($event)"></div>
        </div>
          
        <div
            style="display: flex;flex-direction: column;  background-color: #ffffff; padding: 8px; border-radius: 8px;">
            <div style="display: flex;flex-direction: row; justify-content: space-between;">
                <mat-form-field style="width: 47%;">
                    <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="picker" (dateChange)="onDateChange($event)"
                        [(ngModel)]="followupDateNew" placeholder="Choose a date">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>

                </mat-form-field>


                <mat-form-field *ngIf="appointmentFlag==1" style="width: 47%; margin: 0px 6px;">
                    <mat-label style="font-size: 16px;">Followup Types
                    </mat-label>

                    <mat-select [value]="followupType"
                        (selectionChange)="onDropdownSelectFollowUpTypes('item',$event.value)">
                        <mat-option style="font-size: 15px;" *ngFor="let item of  followupArray" [value]="item.name">
                            {{item.name}}
                        </mat-option>
                    </mat-select>

                </mat-form-field>

                <mat-form-field *ngIf="appointmentFlag==2" style="width: 47%; margin: 0px 6px;">
                    <mat-label style="font-size: 16px;">Followup Types
                    </mat-label>
                    <mat-select [value]="followupType" (selectionChange)="onDropdownSelectFollowUpForQ($event.value)">
                        <mat-option style="font-size: 15px;" *ngFor="let item of  followupArrayForQ"
                            [value]="item.name">
                            {{item.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <app-language-select-textbox #langFollowupMessage [width]="100" [styles]="{'width': '100%'}"
                styleForWidth="instructions" [callBack]="onInputCustomComponent" placeholder="Followup Message"
                style="padding-left: 0px; width: 100%;"></app-language-select-textbox>
            <div style="display: flex; align-items: center;">

                <mat-chip-list aria-label="Fish selection" [multiple]="true" style="margin-top:10px">
                    <mat-chip class="price-filter" style="font-size: 16px;" #ref="matChip"
                        *ngFor="let item of followUpMessageForLanguage;let i=index"
                        (click)='selectFollowUpMessage(item);' [value]="item">
                        {{item.value}}
                    </mat-chip>

                </mat-chip-list>

            </div>

            <div style="margin-top: 20px; font-size: 16px; font-weight: 600;">Customise Prescription</div>
            <div style="background-color: #ffffff; padding: 8px; border-radius: 8px; width: 100%;">
                <!-- <mat-form-field *ngIf="!selectedFielfFlag" style="width: 100%; ">
                    <mat-label style="font-size: 15px;">Selected Fields</mat-label>
                    <mat-select style="font-size: 16px;" multiple 
                        (selectionChange)="!selectModalFieldValue('selectFieldsArray',$event.value)">
                        <mat-option style="font-size: 16px;" *ngFor="let item of selectFieldsArray" [value]="item">
                            {{item.name}}
                           
                        </mat-option>
                    </mat-select>
                </mat-form-field> -->
                <mat-form-field style="width: 100%; ">
                    <mat-label style="font-size: 15px;">Selected Fields</mat-label>
                    <mat-select  [value]="selectFieldsArray"  style="font-size: 16px;" multiple (openedChange)="openPanel($event)"
                        (selectionChange)="selectModalFieldValue($event.value)">
                        <mat-option style="font-size: 16px;" *ngFor="let item of selectFieldsArray" [value]="item">
                            {{item.name}}
                            <!-- <mat-checkbox [checked]="item.selected" >{{item.name}}</mat-checkbox> -->
                        </mat-option>
                    </mat-select>
                </mat-form-field>


                <div style="display: flex; flex-direction: row; justify-content: space-between;">
                    <mat-form-field style="width: 47%;font-size: 16px;">
                        <mat-label style="font-size: 15px;">Speciality/Location</mat-label>
                        <mat-select [value]="templateName"
                            (selectionChange)="onDropdownSelectTemplate('item',$event.value)">

                            <mat-option style="font-size: 16px;" *ngFor="let item of selectedTemplate" [value]="item">
                                {{item}}
                            </mat-option>
                        </mat-select>

                    </mat-form-field>

                    <mat-form-field style="width: 47%;">
                        <mat-label style="font-size: 15px;">Rx Language</mat-label>
                        <mat-select style="font-size: 16px;" [value]="selectedLanguage.name"
                            (selectionChange)="onDropdownSelectLanguage('language',$event.value)">
                            <mat-option style="font-size: 16px;" *ngFor="let language of languageArray"
                                [value]="language.name">
                                {{language.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                </div>


            </div>



            <div *ngIf="favaddFavPrescriptionTextBox"
                style="background-color: #ffffff; padding: 8px; border-radius: 8px; border: 1px dashed gray; position: relative;">
                <div style="flex-direction: row; display: flex; align-items: center;">
                    <img style="height: 30px; width: 30px;" src="assets/images/favourite_heart_red.svg" />

                    <div style="margin-left: 8px;">
                        <div style="font-size: 17px; font-weight: 600;">Do you want to add this prescription as
                            Favourite ?
                        </div>
                        <div style="color: grey; font-size: 15px; margin-top: -4px;">(Specify name of the diagnosis e.g.
                            Flu, Indigestion
                            )
                            and
                            few words to mark it.</div>
                    </div>
                </div>


                <div style="display: flex;flex-direction: row; align-items: center; padding: 0px;">
                    <!-- <input *ngIf="favaddFavPrescriptionTextBox" type="checkbox" [(ngModel)]="favMed"
                        (change)="addFavPrescription($event)" class="largerCheckbox"> -->

                    <!-- <mat-form-field *ngIf="favaddFavPrescriptionTextBox || true" style="width: 100%; padding: 0px 5px;"> -->
                    <!-- <mat-label style="font-size: 16px; font-weight: 600;">{{titleOfSelectFav}}</mat-label> -->
                    <input style="padding: 8px; border: 1px solid #a4c2de; border-radius: 6px;" #favInput id="favInput"
                        matInput [(ngModel)]="favApptName">
                    <!-- </mat-form-field> -->

                    <!-- <div style="display: flex;flex-direction: row; align-items: center; padding: 16px 0px;">
                        <input 
                        *ngIf="!favaddFavPrescriptionTextBox" type="checkbox" [(ngModel)]="favMed"
                            (change)="addFavPrescription($event)" class="largerCheckbox">
                        <div ngbTooltip="Want to save this Prescription to copy easily next time?"
                            *ngIf="!favaddFavPrescriptionTextBox"
                            style="font-weight: 600;font-size: 16px; padding: 0px 5px;"> Add as Favourite</div>

                    </div> -->
                </div>
            </div>

            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
        </div>


        <ng-template #confirm_favourite_modal>
            <h4>Favourite not specified?</h4>
            <div style="color: #555; font-size: 16px;">You have not specified any favourite name for this prescription,
                It will not be saved as favourite.</div>
            <div style="padding-top: 16px; display: flex; justify-content: flex-end;">
                <button (click)="closeDialog()" style="background-color: #eee;
                font-size: 16px;
                          padding: 8px 16px;
                          border-radius: 6px;
                          color: #fff;
                          font-weight: bold;
                          border: 0px;
                          margin: 4px;
                          color: #444;
                          outline: none;">Cancel</button>

                <button (click)="previewPrescriptionPDF(0)" style="background-color: #5D86CC;
                font-size: 16px;
                      padding: 8px 16px;
                      border-radius: 6px;
                      color: #fff;
                      font-weight: bold;
                      border: 0px;
                      margin: 4px;
                    outline: none;">Continue without favourite</button>
            </div>
        </ng-template>




        <ng-template #pendingVaccination_modal>
            <div style="padding: 20px;">

           
            <div style="display: flex;flex-direction: row; justify-content: space-between;">
             <div style="font-size: 20px; font-weight: 600;">Select next vaccination due</div>
             <div >  <mat-form-field >
                <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="picker" (dateChange)="onDateChangeForVacc($event)"
                    [(ngModel)]="vacDate" placeholder="Choose a date" required>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>

            </mat-form-field></div>
            </div>
          
      
         
            <hr *ngIf="pendingVaccination.length">
            <h5 *ngIf="pendingVaccination.length" style="color:#36404D">Pending Vaccinations</h5>
            <div *ngIf="pendingVaccination.length" style="height: 30vh; width: 55vw; overflow: scroll;">
                <div style="height:30vh; width: 55vw; overflow: scroll">
                    <ng-container [ngTemplateOutlet]="renderImmunization" [ngTemplateOutletContext]="{value:pendingVaccination}">
                    </ng-container>
                </div>
            </div>
          
            <ng-template  #renderImmunization let-value="value">
                <table mat-table [dataSource]="value" style="width: 55vw; margin-top: 8px;">
                    <ng-container>
                        <!-- VISIT -->
                        <ng-container matColumnDef="visit">
                            <th mat-header-cell *matHeaderCellDef> Visit </th>
                            <td mat-cell *matCellDef="let element"
                                style="text-align: start !important; width:17%; font-weight: bold;"
                               >
                               <mat-checkbox class="example-margin" (change)="getVaccData(element)"></mat-checkbox> {{element.visitName}}
                               
                            </td>
                        </ng-container>
        
                        <!-- VACCINE -->
                        <ng-container matColumnDef="vaccine">
                            <th mat-header-cell *matHeaderCellDef> Vaccine Name </th>
                            <td mat-cell *matCellDef="let element"
                                style="text-align: start !important; width: 17.5% !important;"
                           >
                                {{element.name}}
        
                            </td>
                        </ng-container>
        
                        <!-- RECOMMENDED DATE -->
                        <ng-container matColumnDef="recommendedDate">
                            <th mat-header-cell *matHeaderCellDef> Recommended Date </th>
                            <td mat-cell *matCellDef="let element" style="width: 10% !important;"
                              >
                                {{element.dueDate | date:"dd-MM-YYYY"}}
        
                            </td>
                        </ng-container>
        
                        <!-- CATCHUP DATE -->
                        <ng-container matColumnDef="catchupDate">
                            <th mat-header-cell *matHeaderCellDef> Catchup Date </th>
                            <td mat-cell *matCellDef="let element" style="width: 10% !important;"
                                >
                                {{element.catchupDate}}
                            </td>
                        </ng-container>
        
                        <!-- STATUS -->
                     
        
                        <!-- RECEIVED DATE -->
               
        
                        <!-- BRAND -->
                       
        
                        <!-- ACTION   -->
                       
        
                    </ng-container>
        
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true" style="background-color: #eee;"></tr>
                    <tr mat-row [ngClass]="{'highlight': selectedRowIndex == row.id}"  *matRowDef="let row; columns: displayedColumns;"  (click)="selectedRows(row)"></tr>
                </table>
            </ng-template>
        

               

              <hr>
              <h5 style="color:#36404D">Upcoming Vaccinations</h5>


              <div style="height: 30vh; width: 55vw; overflow: scroll;">
                <div style="height: 30vh; width: 55vw;overflow: scroll;">
                    <ng-container [ngTemplateOutlet]="renderImmunization" [ngTemplateOutletContext]="{value:upcomingVaccineList}">
                    </ng-container>
                </div>
            </div>
          
            <ng-template #renderImmunization let-value="value">
                <table mat-table [dataSource]="value" style="width: 55vw; margin-top: 8px;">
                    <ng-container>
                        <!-- VISIT -->
                        <ng-container matColumnDef="visit">
                            <th mat-header-cell *matHeaderCellDef> Visit </th>
                            <td mat-cell *matCellDef="let element"
                                style="text-align: start !important; width:17%; font-weight: bold;"
                               >
                               {{element.visitName}}
                               
                            </td>
                        </ng-container>
        
                        <!-- VACCINE -->
                        <ng-container matColumnDef="vaccine">
                            <th mat-header-cell *matHeaderCellDef> Vaccine Name </th>
                            <td mat-cell *matCellDef="let element"
                                style="text-align: start !important; width: 17.5% !important;"
                                [ngStyle]="{'background-color': (element.visitName == 'Special Vaccines') ? '#d1ecf157' : '#fff' }">
                                {{element.name}}
        
                            </td>
                        </ng-container>
        
                        <!-- RECOMMENDED DATE -->
                        <ng-container matColumnDef="recommendedDate">
                            <th mat-header-cell *matHeaderCellDef> Recommended Date </th>
                            <td mat-cell *matCellDef="let element" style="width: 10% !important;"
                                [ngStyle]="{'background-color': (element.visitName == 'Special Vaccines') ? '#d1ecf157' : '#fff' }">
                                {{element.dueDate | date:"dd-MM-YYYY"}}
        
                            </td>
                        </ng-container>
        
                        <!-- CATCHUP DATE -->
                        <ng-container matColumnDef="catchupDate">
                            <th mat-header-cell *matHeaderCellDef> Catchup Date </th>
                            <td mat-cell *matCellDef="let element" style="width: 10% !important;"
                                [ngStyle]="{'background-color': (element.visitName == 'Special Vaccines') ? '#d1ecf157' : '#fff' }">
                                {{element.catchupDate}}
                            </td>
                        </ng-container>

                     
                        <!-- STATUS -->
                     
        
                        <!-- RECEIVED DATE -->
               
        
                        <!-- BRAND -->
                       
        
                        <!-- ACTION   -->
                       
        
                    </ng-container>
        
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true" style="background-color: #eee;"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </ng-template>
                <!-- <div style="display: flex; flex-direction: row; flex-wrap: wrap; margin-top: 16px; margin-bottom: 16px; width: 55vw; height: 30vh; overflow: scroll;">
                    <div *ngFor="let item of upcomingVaccineList; let i = index" (click)="selectModalValue(i)"
                    class="searchable-component-style" [ngStyle]="{'background-color': item.selected ? '#E5F6F4' : '#fff'}">
                        <img *ngIf="item.selected != true" style="margin: 0px 8px 0px 0px; height: 18px; width: 18px;"
                        src="assets/images/tick_mark_grey.svg" class="tick-icon" />
      
                      <img *ngIf="item.selected" style="margin: 0px 8px 0px 0px; height: 18px; width: 18px;"
                        src="assets/images/tick_mark_color.svg" class="tick-icon" />
                      <div style="font-size: 16px;">{{item.name}}</div><br>
                    
                    </div>
                    </div> -->
            <div style="padding-top: 16px; display: flex; justify-content: flex-end;">
                <button (click)="closeDialog()" style="background-color: #eee;
                font-size: 16px;
                          padding: 8px 16px;
                          border-radius: 6px;
                          color: #fff;
                          font-weight: bold;
                          border: 0px;
                          margin: 4px;
                          color: #444;
                          outline: none;">Cancel</button>

                <button (click)="savePendingVaccination();" style="background-color: #5D86CC;
                font-size: 16px;
                      padding: 8px 16px;
                      border-radius: 6px;
                      color: #fff;
                      font-weight: bold;
                      border: 0px;
                      margin: 4px;
                    outline: none;">Add Vaccinations</button>
            </div>
        </div>
        </ng-template>

    </div>
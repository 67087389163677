import { Subscription } from 'rxjs/Subscription';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { fabric } from 'fabric';
import { AuthService } from '../../../services/auth.service'
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { PatientService } from '../../../services/patient.service'
import * as moment from 'moment';
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { HostListener } from "@angular/core";
import { MessageService } from 'primeng/api';
import * as _ from 'lodash'
import { UtilService } from '../../../util/utilService'
import { DomSanitizer } from '@angular/platform-browser';
import { DoctorService } from '../../../services/doctor.service'
import { FormsModule } from '@angular/forms'
import { ToastrService } from "ngx-toastr";
import { patientComponent } from '../patient/patient.component';
import { refreshService } from '../../../services/refersh.service';
import { MatDialog } from '@angular/material/dialog';
import { CdkColumnDef } from '@angular/cdk/table';
import { FormControl } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatTableDataSource } from '@angular/material/table';

import { SelectionModel } from '@angular/cdk/collections';
import { AddVitalsComponent } from '../add-vitals/add-vitals.component';

@Component({
  selector: 'app-new-profile',
  templateUrl: './new-profile.component.html',
  styleUrls: ['./new-profile.component.scss'],
  providers: [MessageService, CdkColumnDef],
})
export class NewProfileComponent implements OnInit {
  displayedColumns: string[] = ['date', 'amount', 'action', 'view'];
  @ViewChild("viewReceiptModal") viewReceiptModal;
  @ViewChild("viewRequisitionModal") viewRequisitionModal;
  @ViewChild("viewReportsModal") viewReportsModal;
  @ViewChild("viewCertiModal") viewCertiModal;
  @ViewChild("addCertificateModal") addCertificateModal;
  @ViewChild("openNotes") openNotes;
  

  @ViewChild("sendRequisitionToLabModal") sendRequisitionToLabModal;
  @ViewChild("addShareWhatsAppModal") addShareWhatsAppModal;
  @ViewChild("addVitalsModal") addVitalsModal;
  @ViewChild("editVitalsModal") editVitalsModal;
  @ViewChild("addUploadModal") addUploadModal;
  @ViewChild("viewHeelReportsModal") viewHeelReportsModal;
  @ViewChild("addReceiptModal") addReceiptModal;
  @ViewChild("AddVitalsComponent") AddVitalsComponent: AddVitalsComponent;
  @ViewChild("cropper")
  @Output() idEmitter = new EventEmitter<number>();
  amountForReason: any
  splVaccFlag:boolean=false;
  allRequistionTest:any=[];
  reasonVistFlag: boolean = false
  dataSource: any = [];
  visitResoncheck: boolean = false
  consultationAmount: any = ""
  teleConsultationAmount: any = ""
  vaccinationAmount: any = ""
  consultationVaccinationAmount: any = ""
  nebulizationAmount = ""
  dressingAmount = ""
  OtherAmount = ""
  medicalConditionArray:any=[]
  reasonVisit: any
  emailFlag: boolean;
  illitext: any = '';
  mytest: any = "";
  illnValue: any = '';
  forValue: any = '';
  comments: any = '';

  highRiskCategory: any = [];
  cropperDisplay: boolean = false;
  myReaderr: any
  fileType: any
  display: boolean = false;
  showLoading = true;
  heelPrickUrl: any = '';
  showRequisitionLoading = true;
  alltest = [];
  files = [];
  currentDate: any;
  chief_test: any = [];
  chief_testVacc: any = [];
  finalVacArr: any = [];
  relationwithcust: any = ''
  alltestorg = [];
  alltestVac = [];
  userId = null
  isPreTerm: any = "";
  patientId = null;
  paramPatientId = null;
  parmpatientId:number ;
  // patientId:any="";
  parmpatientId1:any="";
  parmpatientId2:any="";
  lastvisitIdForAppoinment:any;
  appoinmentPatientId:any;
openvitalsModalFlag:boolean;
appoinmentflag:any
  createdtDate: any;
  tabs: any = ['Overview', 'Visits', 'History', 'Notes', 'Referral', 'Investigations', 'Vaccination'];
  // tabs = ['Overview', 'Visits', 'History', 'Notes'];
  oldOrBirthVitalsArray = [];
  recentVitalsArray = [];
  tab = 'Overview';
  requisitionTab = ["Reports", "Requisition"];
  patientObj: any
  vacDate: any;
  patientResponse: any
  discharge_note = '';
  first_name = '';
  last_name = '';
  middle_name = '';
  patientName = '';
  gender = '';
  genderHe = '';
  ageYears: any = '';
  ageMonths: any = '';
  ageWeeks: any = '';
  mobileNumber = '';
  isPediatric = false;
  isillness = false;
  blood_group = '';
  dob = '';
  totalAmount: any = []
  medicalConditions = '';
  familyMedicalConditions = '';
  allergies = '';
  recurringComplaints = '';
  accidentalHistory = '';
  eDob = '';
  gestationalAge: any;
  typeOfDelivery = '';
  isHighRisk = false;
  birth_weight = '';
  birth_height = '';
  birth_hc = '';
  allHeelPrickTest: any = [];
  birth_cc = '';
  birth_bpsys = '';
  birth_bpdia = '';
  reactionToMeds=""
  closeResult: any;
  scrHeight: any;
  scrWidth: any;
  profilePic = "assets/images/user_default.png";
  allVaccines: any = [];
  pendingVaccineObj: any = {};
  foundPendingVaccines: boolean = false;
  errors: any = {};
  innertab: any = "Requisitions";
  deleteRequisition = {};
  showReportsLoading = false
  fromDate: any;
  fromToDate: any;
  createdOnRaw: any;
  createdOnRawDate: any
  toDate: any;
  isAppointmentEnabled = '0';

  //Visit

  //Flag to check if Visit
  isVisitEdit = false;
  pediatricVisitDate = ""
  adultPreviousVisit = null;
  adultLatestVisit: any;
  adultLatestVisitArray:any=[]
  adultPreviousVisitDate = "";
  adultLatestVisitDate = "";
  adultPreviousVisitBMI: any = "";
  adultPreviousVisitCholRatio = "";
  adultLatestVisitBMI: any = "";
  adultLatestVisitCholRatio = "";
  visits = [];
  visitsForVisitScreen = [];
  adultLatestVisitBloodSugarArray:any=[]
  adultLatestVisitBloodPressureArray:any=[]
  moment: any = moment;
  birthVisit: any;
  selectedVisit: any;
  date: any;
  minDate: any;
  selectedVisitdate: any;
  minMaxPercentile: any = { weight: "", height: "", hc: { min: "", max: "" } };
  visitDateDiff: any
  isEditableVisit = false;
  parseFloat: any = parseFloat;
  currentRequisitionObj: any = {};
  DischargeSummery = 'Discharge Summery'

  //History
  appointmentArr: any = []
  dayWeeksArray: any = [];
  allSummary: any = [];
  // allReports:any=[];
  planModel: any = { start_time: new Date() };
  showNavLoading:boolean=true

  //Notes
  notesArr: any = [];
  notes_message: any = "";
  showDelete = false;
  differnceDate: any
  //Vaccination
  vaccineList: any = []
  selectedLanguage: string = 'en'
  templateName: any = "Speciality";
  favLabName: any = "Choose favourite Lab";
  selectedLab = null;
  favLabsIds: any = [];
  favLabs: any = [];
  waNo: any;
  visitResaonAmount: any = []
  selectedTemplate: any = [];
  //Receipt
  additionalInfo:{}
  selectedDate: any;
  toDatee: any;
  newBornScreening: any
  fromDateVacc: any;
  resumeDateVacc: any;
  toDateVacc: any;
  receiptNo = '';
  amount: Number = 0;
  amountInWords = '';
  receiptPdfURL: any = '';
  requisitiontPdfURL: any = '';
  reportsPdfURL: any = '';
  heelreportsPdfURL: any = '';
  visitDate: any
  certiPdfURL: any = '';

  certisURL: any = ''
  receiptURL: any = '';
  requisitionURL: any = '';
  requisitionForEdit: any = {};
  reportsURL: any = '';
  heelreportsURL: any = '';
  // comments = '';
  currentDoctorObj:any = {};
  allReceiptArr: any = [];
  allRequisition = [];
  allReports = [];
  allDocumentReports = [];
  isViewRecipt = false;
  visitReasonArr = [{ reason: 'Consultation', reasonAmount: "", selected: false }, { reason: 'Tele-Consultation', reasonAmount: "", selected: false }, { reason: 'Vaccination', reasonAmount: "", selected: false }, { reason: 'Consultation+Vaccination', reasonAmount: "", selected: false }, { reason: 'Nebulization', reasonAmount: "", selected: false }, { reason: 'Dressing/Plaster charges', reasonAmount: "", selected: false }, { reason: 'Other', reasonAmount: "", selected: false }]
  selectedDocumentType = '';
  documentTypeSelected = '';
  documentType: any = ['Lab Report', 'Prescription', 'Requisition', 'Discharge Summary', 'Others', 'Covid Vaccine Certificate', 'Heel Prick Test']
  selectedCertiType = 'Certificate Type'
  adultCertificate: any = ['Fit to join work ', 'Advised Rest', 'Fitness Certificate', 'Travel Certificate', 'Admission Advised']
  familyHistory=""
  visitReason: any = [];
  certificateName = '';
  docName = ""
  duration: any = '';
  durationn: any = '';
  hospitalName: any = '';
  hospitalCity: any = '';
  resumeDate: any;
  minDateForAppointment: any;
  minDateFor: any;
  visitReasonInText = '';
  medicalCondtion = '';
  allMilestones:any=[]
patientsMilestones:any=[]
selectedMilestoneType:any
dueMilestoneArray:any=[]
patientAgeInMonths
milestoneFlag:boolean=false
docDate=""
docNotes=""

  showAgeYear = false;
  showTestValue = false;
  report_message = "";
  selectedTest: any = []
  docNamee = '';
  docEmail: any = '';
  docMobNumber = '';
  selectedWhenToTake: any = ''
  durationnn: any = ''
  medCondition: any = ''

  todayDate: any;
  six_month_before_date: any;
  twoDaysBefore: any;
  searchText: any;
  selectedDoctor: any;
  fileInfo: any;
  docType: any;
  notes = ''
  base64: any
  waNumber: any
  sendToQuickRx: any;
  sendToHomePage: any;
  activatedRoute: any;
  patientIdForTopInfo: any;
  billDate: any = new FormControl(new Date());
  selectedReceipt: any = null;
  selectedIndex = 1;
  goToHomePage = false
  selectedVisitAppointment: any
  subsVar: Subscription;
  billReceipt: any;
  milestoneshowFlag:boolean=false
  showGraphs = false;
  maxChestchestcircumference;
  avgChestchestcircumference;
  amountFlag: boolean = true
  graphName = '';
  showEditRequitionButton = false;
  sectionWiseRequisitionTests = [];
  originalSectionWiseRequisitionTests = [];
  latestVisitWithValues = ''
  patientData
  patientDataFromLocal
  unformattedVaccineList = [];
  specialVaccineInfo: any = {};
  specialSituationVaccines = [];
  adultLatestVisitLipidProfileArray:any=[]
  @Input() flagForCertificateVacc
  allAdministeredVaccines = [];
  selectedReport: any = null;
  pramPatientId:any=null
  rxUi:any=""

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    this.scrWidth = this.scrWidth / 1.3;
  }
  constructor(private authService: AuthService, private refreshservice: refreshService, private elementRef: ElementRef, private toastr: ToastrService, private route: ActivatedRoute, private router: Router, private patientService: PatientService, private modalService: NgbModal, private messageService: MessageService, private util: UtilService, private sanitizer: DomSanitizer, private doctorService: DoctorService, public dialog: MatDialog,
  ) {
    this.getScreenSize();
    this.date = new Date();
    // var selectedDate=this.reverseDate(new Date());
    // this.selectedDate= moment(new Date(this.parseDate(selectedDate))).format("DD/MM/YYYY");
    console.log("selecte", this.planModel.start_time)

    this.minDate = this.reverseDate(new Date(this.dob));
    this.selectedDate = new Date();
  }
  ngOnDestroy() {
    if (this.subsVar) {
      this.subsVar.unsubscribe()
    }
  }

 




  ngOnInit() {
    
    this.route.queryParams.subscribe(params => {
      this.patientId = params.patientId;
      this.appoinmentflag=params.appoinmentFlag
      this.parmpatientId1 = params.patientId
      this.parmpatientId2 = params.patientId;
      
      this.parmpatientId = this.parmpatientId1?this.parmpatientId1:this.parmpatientId2;
      
      this.patientId= params.patientId;
console.log("patientId",this.patientId);

      console.log("params",params);
      console.log("profile component patientId", this.patientId);
      
    })
    
    console.log("apr18", this.flagForCertificateVacc)
    this.reasonVistFlag = false
  
    this.selectedIndex = 1;
    this.refreshservice.VitalsRefreshEvent.subscribe(valuesent => {
      console.log("GOT REFRESH EVENT jul 26");
      this.getPatientInfo('Overview');
    })

    this.subsVar = this.refreshservice.PatTopNav_MenuSelected.subscribe((valuesent) => {


      switch (valuesent.EventName) {
        case this.refreshservice.EventName_Visits:
          setTimeout(() => {
            this.tab = "Visits";
          },300)
          this.selectedIndex = 3;
          this.graphName = '';
          this.showGraphs = false;
          break;
        case this.refreshservice.EventName_Overview:
          
          this.tab = "Overview";
          this.selectedIndex = 1;
          this.graphName = '';
          this.showGraphs = false;
          break;

        case this.refreshservice.EventName_Requisitions:
          setTimeout(() => {
            this.tab = "Investigations";
           
          },400)
          this.innertab = "Requisitions";
          this.selectedIndex = 2;
          this.graphName = '';
          this.showGraphs = false;
          break;

        case this.refreshservice.EventName_Reports:
          setTimeout(() => {
            this.tab = "Investigations";
        
            
          },400)
          this.innertab = "Reports";
           
          this.selectedIndex = 2;
          this.graphName = '';
          this.showGraphs = false;
          break;

        case this.refreshservice.EventName_Immunization:
          setTimeout(() => {
            this.tab = "Vaccination"
          },200)
         
          this.selectedIndex = 3;
          this.graphName = '';
          this.showGraphs = false;
          break;

        case this.refreshservice.EventName_Milestone:
          this.tab = "Milestone"
          this.selectedIndex = 3;
          this.graphName = '';
          this.showGraphs = false;
          break;


        case this.refreshservice.EventName_Referral:
          setTimeout(() => {
            this.tab = "Referral"
          },200)
          
          this.selectedIndex = 4;
          this.graphName = '';
          this.showGraphs = false;
          break;

        case this.refreshservice.EventName_Certificate:
          setTimeout(() => {
            this.tab = "Certificate"
          },200)
         
          this.selectedIndex = 4;
          this.graphName = '';
          this.showGraphs = false;
          this.refreshservice.openCertificate()
          // this.openDialog(this.addCertificateModal, 'addCertificateModal');
          //   this.openCertiModal();

          break;

        case this.refreshservice.EventName_Receipt:
          setTimeout(() => {
            this.tab = "Receipts"
          },200)
         
          this.selectedIndex = 4;
          this.graphName = '';
          this.showGraphs = false;
          break;
        case this.refreshservice.EventName_Notes:
          setTimeout(() => {
            this.tab = "Notes"
          },200)
         
          this.selectedIndex = 4;
          this.graphName = '';
          this.showGraphs = false;
          break;
        case this.refreshservice.EventName_AllDocuments:
        
          setTimeout(() => {
            this.tab = "History"
          },200)
          
          this.selectedIndex = 4;
          this.graphName = '';
          this.showGraphs = false;
          break;
        case this.refreshservice.EventName_EditPatient:
          this.graphName = '';
          this.showGraphs = false;
          this.editPatient();
          break;

        case this.refreshservice.EventName_Upload:
          console.log('Called Once', '1')
          setTimeout(() => {
            this.tab = "History"
          },200)
          this.selectedIndex = 4;
          this.graphName = '';
          this.showGraphs = false;
          // this.closeUploadModal()

          this.openDialog(this.addUploadModal, 'addUploadModal');
          //   this.modalService.dismissAll();

          break;
          case this.refreshservice.EventName_Immunization:
            setTimeout(() => {
              this.tab = "Vaccination"
            },200)
           
            this.selectedIndex = 2;
            this.graphName = '';
            this.showGraphs = false;
            break;
  

        default:
          break;
      }
    });

    this.isHighRisk = false;
   // this.addVitals();
   
   
    //  this.modalService.open(this.addVitalsModal)
    this.selectedDate = this.reverseDate(new Date());
    this.date = new Date();



    setTimeout(() => {
      this.calculateNextVaccination();
    })
    this.userId = this.authService.getUser()

    this.patientData = JSON.parse(localStorage.getItem('patientData'));
    console.log("Mar13",this.patientId)
    this.rxUi= (localStorage.getItem('RxUi'));
    // this.patientData = this.patientId
    this.patientObj = history.state.data;
    
      this.getPatientInfo("Overview")
   
  

    this.route.queryParams.subscribe(params => {
      this.showLoading=true
      console.log("logOct5", params); // { orderby: "price" }
      this.openvitalsModalFlag = params.visit;
      console.log("openvitalsModalFlag", this.openvitalsModalFlag);
      
      var upDoc: any = params.uploadDocument
      this.patientId = params.patientId;
      this.paramPatientId = params.patientId;
     // this.patientId["id"]=params.patientId
      console.log("receiptBugMarch13",this.patientId)
      if(params.navgationValue){
        this.showNavLoading=true
      }
      else{
        this.showNavLoading=false
      }
      this.sendToQuickRx = params.RxFlag;
      this.sendToHomePage = params.isFromAppointment
      this.goToHomePage = params.isAppointment
      this.billReceipt = params.billFlag
      this.pramPatientId=params.patientId ? params.patientId:null
      if(params.navgationValue=="Receipts") {
        this.showNavLoading=true
        setTimeout(() => {
          this.tab = "Receipts"
         this.showNavLoading=false
        },500)
      }
    
       if(params.navgationValue=="Immunization") {
        this.showNavLoading=true
        setTimeout(() => {
          this.tab = "Vaccination"
          this.showNavLoading=false
        },500)
       
        this.selectedIndex = 3;
        this.graphName = '';
        this.showGraphs = false;
       // this.showLoading=true
       }

       if(params.navgationValue=="splVacc") {
        this.showNavLoading=true
        setTimeout(() => {
          this.tab = "Vaccination"
          this.splVaccFlag=true
          this.showNavLoading=false
        },1000)
       
        this.selectedIndex = 3;
        this.graphName = '';
        this.showGraphs = false;
       }
       if(params.navgationValue=='Referral'){
        this.showNavLoading=true
        setTimeout(() => {
          this.tab = "Referral"
          this.showNavLoading=false
        },1000)
        
        this.selectedIndex = 4;
        this.graphName = '';
        this.showGraphs = false;
       }
       if(params.navgationValue=='Requisition'){
        this.showNavLoading=true
        setTimeout(() => {
          this.tab = "Investigations";
          this.innertab = "Requisitions";
        this.selectedIndex = 2;
        this.graphName = '';
        this.showGraphs = false;
        this.showNavLoading=false
        },1000)
       
       }
       if(params.navgationValue=='History'){
        this.showNavLoading=true
        setTimeout(() => {
          this.tab = "History"
          this.showNavLoading=false
        },1000)
        this.selectedIndex = 4;
        this.graphName = '';
        this.showGraphs = false;
       
       }
      
      if(params.navgationValue=='Certificate'){
        this.showNavLoading=true
        setTimeout(() => {
          this.tab = "Certificate"
          this.showNavLoading=false
        },1000)
       
        this.selectedIndex = 4;
        this.graphName = '';
        this.showGraphs = false;
        
      }
       if(params.navgationValue=='Visits'){
         this.showNavLoading=true
        setTimeout(() => {
          
          this.tab = "Visits";
          this.showNavLoading=false
        },1000)
        this.selectedIndex = 3;
        this.graphName = '';
        this.showGraphs = false;
       
       }

      console.log("msl", this.goToHomePage);
      // if(params.patientId){

      //   setTimeout(() => {

      //     if(this.addVitalsModal){
      //       this.open(this.addVitalsModal, 'addVitalsModal');
      //     }}, 500);

      //   }
      if (params.isVaccination) {

        setTimeout(() => {
          this.tab = "Vaccination"
          this.selectedIndex = 3;
          this.graphName = '';
          this.showGraphs = false;
        }, 600)

      }

      if (upDoc) {

        setTimeout(() => {
          this.openDialog(this.addUploadModal, 'addUploadModal');

        })

      }
      if (this.billReceipt) {
        setTimeout(() => {
          this.tab = "Receipts"
          this.selectedIndex = 4;
        }, 600)
      }

      else if (params.visit) {

        if (params.action || params.visit) {
          var patData: any = localStorage.getItem('patientDataForVitals');
          console.log("patData", JSON.parse(patData))
          patData = JSON.parse(patData);
          this.isPediatric = patData.patientTypes.length == 2 ? true : false;
          this.gestationalAge = patData.gestationalAge ? patData.gestationalAge : null;
          this.discharge_note = patData.discharge_note;
          this.patientData = patData
          this.patientResponse = patData;
          console.log("patData",this.patientResponse);
          
          if (params.action == 'edit') {
            var visits: any = patData.visits && patData.visits.length ? patData.visits : [];

            var visit = "";
            visits.map((element, index) => {
              if (element._id && element._id === "synthetic_record") {
              console.log("log in synthetic_record3");

                visits.splice(index, 1);
              }
              // if (new Date(element.date).getDate() == new Date().getDate() && new Date(element.date).getMonth() == new Date().getMonth() && new Date(element.date).getFullYear() == new Date().getFullYear()) {
              visit = element
              // }
            });
            // this.openvitalsModalFlag= false;
            // console.log(
            //   "visit3333",visit
            // );
            
            if (visit && this.openvitalsModalFlag) {
              console.log("11111111");
              
              setTimeout(() => {
                this.visitDate = visit
                this.onSelectVisit(visit);
                this.opensDialog(this.editVitalsModal);
              }, 500);
            } else {
              console.log("2222222");

              setTimeout(() => {
              console.log("3333333");

                this.addVitals();
                this.openDialog(this.addVitalsModal,'addVitalsModal',);
              }, 500);
            }

          } else {
            setTimeout(() => {
              console.log("44444444");

              this.addVitals();
              this.openDialog(this.addVitalsModal,'addVitalsModal',);
            }, 500);
          }
        }
        else {
          setTimeout(() => {
            console.log("5555555");

            this.addNewVitalsLink();
            this.opensDialog(this.addVitalsModal);
          }, 500);
        }



      }
      else if (params.shareReciept) {
        var patData: any = localStorage.getItem('patientData');
        patData = JSON.parse(patData);
        this.isPediatric = patData.patientTypes.length === 2 ? true : false;
        this.gestationalAge = patData.gestationalAge;
        this.discharge_note = patData.discharge_note;
        this.patientData = patData
        this.patientResponse = patData;
        console.log("patData",patData);
        
        setTimeout(() => {
          this.tab = 'Receipts';
          this.open(this.addReceiptModal, 'addReceiptModal')
        }, 500);
      }
      else {
        setTimeout(() => {
          this.addVitals();
        }, 10);
      }
    //  this.getPatientInfo("Overview")

    })

  

    this.createdOnRaw = this.reverseDate(new Date(this.dob));
    this.templateName = 'default';
    this.duration = "(Duration)";
    this.fromDateVacc = '(FromDate)'
    this.durationnn = '(Duration)';
    this.medCondition = '(MedicalCondtion)'

    // this.six_month_before_date = this.getSixMonthBeforeDate();

    // var txtboxvalue=document.getElementById("forValue")
    // this.elementRef.nativeElement.style.backgroundColor = 'red';
    //let myCompOneObj = new patientComponent();
    this.onClickPastHistory();


    

    //pick identifier from here
    this.selectedVisit = {
      weight: '',
      height: '',
      Temp: '',
      spo2: '',
      Bloodhaemoglobin: '',
      bpSys: '',
      bpDia: '',
      Heartrate: '',
      Pulse: '',
      BloodSugarRandom: '',
      Bloodsugar_F: '',
      Bloodsugar_PP: '',
      Blood_Hba1c: '',
      Chol_HDL: '',
      Chol_LDL: '',
      TotalCholesterol: '',
      Triglycerides_LFT: '',
      addedBy: 'doctor',
      date: this.reverseDate(new Date())
    };
    let today: any = new Date()
    // this.todayDate=today;
    // console.log("today",this.todayDate)
    let today1 = today.toString()
    //  var date = moment(new Date()).format("YYYY-MM-DD")
    // this.createdOnRaw= this.reverseDate(new Date());
    // this.createdOnRaw=moment(new Date(this.parseDate(createdOnRaw))).format("YYYY-MM-DD"),
    // console.log("selectdate", this.createdOnRaw)
    //this.twoDaysBefore = this.todayDate.addDays(-2);
    this.todayDate = new Date();
    // this.todayDate.setHours(this.todayDate.getHours() + 48);
    console.log("today", this.todayDate)
    this.selectedDate = this.reverseDate(new Date());


    this.minDateFor = this.reverseDate(new Date(this.selectedDate))
    this.minDateForAppointment = this.reverseDate(new Date());
    this.toDatee = this.reverseDate(new Date());
    //  this.createdOnRaw=this.resumeDateVacc(new Date())
    //  console.log("createON",this.createdOnRaw)
    this.resumeDate = this.reverseDate(new Date());
    this.fromDate = this.reverseDate(today1)
    // this.fromDate.toString();
    this.toDate = this.reverseDate(today1)
    this.fromToDate = this.reverseDate(today1)
   
    this.getPatientsVisitInfo();
    console.log('CASIO', '1')
    
    this.getAllDoctorPrescriptions();
  
    this.getPatientsVaccination();
    // this.getAllReceipt();
    this.getDoctorDetails();
    this.getAllRequisitions();
    this.getAllRequiredConstants();

    //this.openRequisitionModal();
    this.getAllLabReportOfPatient();
    this.getAllDocumentsOfPatient();
    this.getMasterMilestones();
    this.getAgeInMonths();

    // this.switchDaysWeeks(0);

    // this.gettwoDays();


    //this.replaceValue();
    this.refreshservice.DocumentRefreshEvent.subscribe(valuesent => {

      this.getAllDoctorPrescriptions();
      console.log('CASIO', '2')
      setTimeout(() => {
        this.tab = "Certificate"
      }, 500)



    })
  

   
   

  }
  // onDropdownSelectLanguage = (item) => {
  //   this.selectedLanguage = item
  // }
  // toggleChild() {
  //   this.tab='Receipts'
  // }

  showGraph() {

    localStorage.setItem('patientData', JSON.stringify(this.patientResponse));
    this.showGraphs = true;
  }

  hideGraph() {
    this.showGraphs = false;
  }



  modelChangeFn(e) {
    this.duration = e;
    this.durationnn = this.duration
  }

  onInputCustomComponent = (value, identifier) => {
    this.selectedVisit[identifier] = value;
    this.selectedVisit.value.weight = identifier;
    this.selectedVisit.value.height = identifier;
    this.selectedVisit.value.Temp = identifier;
    this.selectedVisit.value.sop2 = identifier;
    this.selectedVisit.value.Bloodhaemoglobin = identifier;
    this.selectedVisit.value.bpSys = identifier;
    this.selectedVisit.value.bpDia = identifier;
    this.selectedVisit.value.Heartrate = identifier;
    this.selectedVisit.value.Pulse = identifier;
    this.selectedVisit.value.BloodSugarRandom = identifier;
    this.selectedVisit.value.Bloodsugar_F = identifier;
    this.selectedVisit.value.Bloodsugar_PP = identifier;
    this.selectedVisit.value.Blood_Hba1c = identifier;
    this.selectedVisit.value.Chol_HDL = identifier;
    this.selectedVisit.value.Chol_LDL = identifier;
    this.selectedVisit.value.TotalCholesterol = identifier;
    this.selectedVisit.value.Triglycerides_LFT = identifier;


  }
  openDialog(content, index) {
    //this.selectedIndexOnTab = index;
    // this.selectedIndex = index;
    this.dialog.open(content);
  }




  onDropdownSelectTemplate = (item) => {
    this.templateName = item
  }

  onDropdownSelectLab = (itemstr, item) => {
    this.favLabName = item.name;
    this.selectedLab = item;
  }
  onDropdownSelectDocType = (item) => {
    this.selectedDocumentType = item;
    console.log("doctype", this.selectedDocumentType)
  }

  //    getSixMonthBeforeDate() {
  //     const currentDate = new Date(); 
  //     currentDate.setHours(currentDate.getHours() - 48);

  //     return currentDate;

  // }

  onClickVitalToShowGraph = (graphName) => {
    if (graphName == 'Weight' || graphName == 'Height' || graphName == 'HC' || graphName == 'BP (Sys)' || graphName == 'BP (Dia)') {
      localStorage.setItem('patientData', JSON.stringify(this.patientResponse));
      this.selectedIndex = 3;
      this.tab = 'Visits';
      this.showGraphs = true;
      this.graphName = graphName;
    }

  }

  getDoctorDetails = () => {
    let params = {
      user_id: this.userId
    }
    this.doctorService.getDetails(params).subscribe(response => {
      console.log('Current Doctor', response)
      this.currentDoctorObj = response.doctor
      if (response.success) {
        this.isAppointmentEnabled = response.doctor && response.doctor.isAppointmentEnabled ? response.doctor.isAppointmentEnabled : 0;
        if (response.doctor.services && response.doctor.services !== null) {
          var services = JSON.parse(response.doctor.services);
          if (services.enableBillingModule) {
            this.tabs.push('Receipts');
            this.tabs.push('Certificate');
          }
          if (services.enableBillingModule == 1) {
            this.tabs.push('Receipts');
            this.tabs.push('Certificate');
          }
          if (services.template_names) {
            this.templateName = 'Speciality';
            this.selectedTemplate = services.template_names;
          }

          if (services.favLabs) {
            this.favLabsIds = services.favLabs;
            this.getFavLabs();
          }

          else {
            //this.templateName='default';
            this.selectedTemplate.push('default');

          }
        }
        else {
          this.tabs.push('Receipts');
        }
      }
    })
  }
  goBack = () => {
    this.router.navigate([`/doctor/homepage`], {});
  }
  reverseDate(date) {
    let d = new Date(date);
    return { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() };
  }

  changeNumberToWord(item) {

    this.amount = Number(item)
    if (this.amount) {

    }
    console.log("march14", this.visitReason)
    // this.amountInWords = this.util.numberToWord(this.amount);
  }

  closeAddReceiptModal() {

    this.amount = 0;
    this.receiptNo = '';
    this.visitReason = '';
    this.visitReasonInText = '';
    this.isViewRecipt = false;
    //  this.showOtherTextBox = false;
    this.date = new Date();
    this.selectedDate = this.reverseDate(new Date());
    // this.closeDialog();

    let visits = this.visitReasonArr
    visits.map(ins => {
      if (ins.selected = true) {
        ins.selected = false
        ins.reasonAmount = ""
      }



    })
    this.visitReasonArr = visits
  }
  closeAddRequisitionModal() {
    this.comments = '';
    this.alltest.map(ins => ins.selected = false);

  }

  closeCalender(e, d) {
    if (e.target.offsetParent == null) d.close();

    else if (
      e.target.offsetParent &&
      e.target.offsetParent.nodeName != "NGB-DATEPICKER" &&
      e.target.offsetParent.className.indexOf("calender") == -1 &&
      e.target.offsetParent.className.indexOf("custom-select") == -1
    )
      d.close();

    if (d._elRef.nativeElement.parentElement.contains(e.target)) {

    } else {
      d.close()
    }
    if (e.target.offsetParent == null)
      d.close()
    else if (e.target.offsetParent && e.target.offsetParent.nodeName != "NGB-DATEPICKER" && (e.target.offsetParent.className.indexOf('calender') == -1 && e.target.offsetParent.className.indexOf('custom-select') == -1))
      d.close()
  }

  selectedTab(e) {
    this.tab = e
    console.log("tabs", this.tab)

  }
  selectTabrequisition(m) {
    //this.showLoading = true
    this.requisitionTab = m;
    console.log("requisitionTab", this.requisitionTab);

  }
  onChangeInnerTab(s) {
    this.innertab = s;
  }
  onDateChange() {
    var date = moment(this.parseDate(this.selectedVisitdate)).format("YYYY-MM-DD HH:mm:ss")
    if (this.isPediatric) {
      this.getMinMaxPercentile(date);
    }
  }

  onSelectVisit(visit) {

    this.errors = {};
    var obj = visit ? visit : '';
    this.selectedVisit = obj;
    this.selectedVisitdate = this.reverseDate(new Date(visit.date));
    if (new Date(visit.date).getDate() === new Date().getDate() && new Date(visit.date).getMonth() === new Date().getMonth() && new Date(visit.date).getFullYear() === new Date().getFullYear()) {
      this.isEditableVisit = true
    }
    if (this.isPediatric) {
      this.getMinMaxPercentile(visit.date);
    }
  }

  addNewVitalsLink() {
    this.isVisitEdit = false;
    delete this.selectedVisit["_id"];

    this.errors = {};
    var date = moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
    this.selectedVisitdate = this.reverseDate(date);
    if (this.isPediatric) {
      this.getMinMaxPercentile(date);
    }
    this.selectedVisit.weight = "";
    this.selectedVisit.height = "";
    this.selectedVisit.hc = ""
    this.selectedVisit.cc = "";
  }


  addVitals() {
    console.log("add vitals called for showing Selected Vitals");
    

    if (this.visitsForVisitScreen.length) {
      // console.log( 'ALL-VISITS',  this.visitsForVisitScreen[0])
      if (this.visitsForVisitScreen[0].data.length) {
        console.log('ALL-VISITS', this.visitsForVisitScreen[0].data[0]);

        var element = this.visitsForVisitScreen[0].data[0];
        this.isVisitEdit = false;

        var todaysDate = new Date().toISOString().slice(0, 10); if (element.date) {
          var visitDate = element.date
          var visitDateNew = new Date(visitDate).toISOString().slice(0, 10);
        } if (todaysDate == visitDateNew) {
          //EDIT VISIT
          this.isVisitEdit = true;
          this.selectedVisit = this.visitsForVisitScreen[0].data[0];
          this.onSelectVisit(this.selectedVisit);
        }
        else {
          //NEW VISIT
          this.isVisitEdit = false;
          delete this.selectedVisit["_id"];

          this.errors = {};
          var date = moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
          this.selectedVisitdate = this.reverseDate(date);
          if (this.isPediatric) {
            this.getMinMaxPercentile(date);
          }
          this.selectedVisit.weight = "";
          this.selectedVisit.height = "";
          this.selectedVisit.hc = ""
          this.selectedVisit.cc = "";
        }

        console.log('ALL-VISITS Visit Exists......?', this.isVisitEdit)
      }
    }


    //for fresh adding of vitals
    // delete this.selectedVisit["_id"]; 

    console.log('Selected Vitals', this.selectedVisit)
  }



  getMinMaxPercentile(date) {
    let momentVd = moment(date);
    let momentDob = moment(this.patientResponse.DOB ? this.patientResponse.DOB : '', "DD-MMM-YYYY");
    let diff = moment.duration(momentVd.diff(momentDob));
    this.visitDateDiff = diff ? diff : {};
    console.log("minPer", this.visitDateDiff)
    this.calculateMinMax(moment(date).format("YYYY-MM-DD HH:mm:ss"), diff.years());
  }

  validateNumber(e, usedFor) {

    if (e.charCode === 13 && usedFor === 'receipt') {
      this.totalAmount.push(e.key)

    }
    console.log("amount", this.totalAmount)
    if (usedFor === 'receipt') {
      return ((e.charCode > 47 && e.charCode < 58)) || (e.which == 8) || (e.which == 13) || (e.keyCode == 9)
    } else {
      return ((e.charCode > 47 && e.charCode < 58)) || (e.which == 8) || (e.which == 13) || (e.keyCode == 9) || (e.keyCode == 110) || (e.keyCode == 46)
    }
  }

  setReceiptItem(receipt) {
    this.selectedReceipt = receipt;
  }

  onClickViewReceipt(receipt) {

    this.receiptPdfURL = this.sanitizer.bypassSecurityTrustResourceUrl(receipt.billUrl);
    this.receiptURL = receipt.billUrl;
    this.openDialog(this.viewReceiptModal, 'viewReceiptModal');
    this.isViewRecipt = true;
  }
  onClickViewRequisition(requisition) {
    this.requisitiontPdfURL = this.sanitizer.bypassSecurityTrustResourceUrl(requisition.url);
    this.requisitionURL = requisition.url;
    this.openDialog(this.viewRequisitionModal, 'viewRequisitionModal');
    this.isViewRecipt = true;
  }

  onClickSendRequisitionToLabModal() {
    this.open(this.sendRequisitionToLabModal, 'sendRequisitionToLabModal');
  }

  onClickViewReports = (reports) => {
    this.reportsPdfURL = this.sanitizer.bypassSecurityTrustResourceUrl(reports.url);
    this.reportsURL = reports.url;
    this.openDialog(this.viewReportsModal, 'viewReportsModal');
    this.isViewRecipt = true;
  }

  setSelectedReport(report) {
    this.selectedReport = report;
  }
  unlockReportForPatient = (report, index) => {
    if (report) {
      //new unlock api call here
      let postData = {
        id: report.id
      };
      this.patientService.unlockLabReportOfPatient(postData).subscribe(response => {
        if (response.success) {
          this.getAllDocumentsOfPatient(0);
        }
      })
    }else{
      alert('Something went wrong')
    }
  }

  onClickViewHeelReports = (reports) => {
    this.heelreportsPdfURL = this.sanitizer.bypassSecurityTrustResourceUrl(reports);
    this.heelreportsURL = reports;
    this.openDialog(this.viewHeelReportsModal, 'viewHeelReportsModal');
    this.isViewRecipt = true;
  }
  onClickViewCert = (certificates) => {
    this.certiPdfURL = this.sanitizer.bypassSecurityTrustResourceUrl(certificates.url);
    this.certisURL = certificates.url;
    this.openDialog(this.viewCertiModal, 'viewCertiModal');
    this.isViewRecipt = true;
  }


  onClickDischarge() {
    this.messageService.add({ severity: 'custom', summary: 'Discharge Summary', detail: 'No discharge summary added' });
  }
  onClickPastHistory() {
    this.messageService.add({ severity: 'custom', summary: 'Past History', detail: 'No past history added' });
  }
  onClickPrescription(url, isFrom) {
    if (isFrom === 'isForCertificate') {
      window.open(JSON.parse(url)[0].url);
    }
    else {
      window.open(url);
    }
  }


  parseDate(dateObject) {
    var date = new Date();
    date.setDate(dateObject.day);
    date.setMonth(dateObject.month - 1);
    date.setFullYear(dateObject.year);
    return moment(date).format("YYYY-MM-DD HH:mm:ss");
  }

  saveNotes = () => {
    let notes = this.notes_message;
    if(notes==""){

    }
    else{

  

    let obj = {
      prescription_instruction: notes,
      patientId: this.parmpatientId? this.parmpatientId:this.patientId
    };
    console.log("obj121", obj);
    

    this.patientService.createAppointmentForNewNote(obj).subscribe(response => {
      if (response) {
        console.log("rffff",response)

        this.getAllDoctorPrescriptions();
        console.log('CASIO', '3')
        this.modalService.dismissAll();
        this.notes_message=""
      }
    })
  }
  }
  validateField = (field) => {
    if (field && field != '0' && field != '' && field != 'undefined' && field != 'null' && field != '-') {
      return true;
    } else {
      return false;
    }
  }

  getPatientInfo(tab) {
    console.log("tabtabtab",tab);
    
    this.showLoading=true
    console.log("???????????MMMMM",this.patientData.id)
    console.log("???????????MMMMM???????",this.patientId)
    console.log("<<<<<<<<<<<<<111111111???",this.patientData.id ? this.patientId : this.pramPatientId)
    this.adultLatestVisitArray=[]
    this.adultLatestVisitBloodPressureArray=[];
    this.adultLatestVisitBloodSugarArray=[];
    this.adultLatestVisitLipidProfileArray=[]
    var patietID = this.parmpatientId;
    console.log("patietID666", patietID);
    // var patientID = this.patientDataFromLocal.id;
    // console.log("patientIDpatientID",patientID);
    
    this.patientService.getPatientInfo(patietID  ? this.parmpatientId : this.patientId).subscribe(response => {
      console.log("t  Id", patietID, this.patientId);
      

      if (response) {
        this.visits = response.visits && response.visits.length ? response.visits : [];
        console.log("allVisit", this.visits)
        this.visits.map((element, index) => {
          if (element._id === "synthetic_record") {
            console.log("element._id appoinment adult newProfile getPatientInfo", element._id);
            
            console.log("log in synthetic_record add vitals");

            this.visits.splice(index, 1);
console.log("this.visit",this.visits);
this.adultLatestVisitDate = moment(this.visits[this.visits.length - 1]["date"]).format("DD-MMM-YYYY");
console.log("adultLatestVisitDate",this.adultLatestVisitDate);

          }


        });
      //  this.showLoading=false
        this.patientResponse = response;
       console.log("dateOfBirthdateOfBirth",this.patientResponse);
       
        if(this.patientResponse.visits){
          this.patientResponse.visits.map((element, index) => {
            if (element._id === "synthetic_record") {
              console.log("log in synthetic_record1");
  
              // this.patientResponse.visits.splice(index, 1);
          }})
        }
        // this.lastvisitIdForAppoinment = this.patientResponse.visits[0]._id;
        // console.log("lastvisitIdForAppoinment",this.lastvisitIdForAppoinment);

        if(this.lastvisitIdForAppoinment){
          this.sendVisitIdToChild()

        }
        // this.saveAppoinmentVisitID()
        console.log("patientResponsepatientResponse",this.patientResponse);
        
        console.log("hsdbhsdh",this.patientResponse)
        this.accidentalHistory=this.patientResponse.accidentalHistory
        this.familyHistory=this.patientResponse.familyHistory

        localStorage.setItem("patientMoreData", JSON.stringify(this.patientResponse))
        this.discharge_note = response.discharge_note;
        this.createdtDate = response.createdAt
        //   this.AddVitalsComponent.loadPatientData(this.patientResponse._id);
        this.gettwoDays();
        this.first_name = response.firstName;
        this.last_name = response.lastName;
        this.middle_name = response.middleName;
        this.patientName = response.firstName + " " + response.middleName + " " + response.lastName;
        this.gender = response.gender.toLowerCase() === 'male' ? 'M' : "F";
        if (this.gender == 'F') {
          this.genderHe = "She";
        }
        else {
          this.genderHe = 'He';
        }
        this.relationwithcust = response.relationWithCustomer
        this.ageYears = response.age.years;
console.log("this.ageYears", this.ageYears);
        
        if (this.ageYears < 1) {
          this.showAgeYear = true
          // this.ageYears = "Under 1 Year"

        }
        
        else {
          this.showAgeYear = false
          this.ageYears
        }
        if(this.ageYears < 8){
          this.milestoneshowFlag=true;
          console.log("milestoneshowFlag", this.milestoneshowFlag);
          
        }
        this.ageMonths = response.age.months;

        this.ageWeeks = response.age.days;
        this.isPediatric = response.patientTypes.length === 2 ? true : false;
        this.mobileNumber = response.parent["contactNo"].replace("91", "");
        this.blood_group = response.blood_group;
        this.dob = response.DOB;
        // this.vacDate=response.vaccinationStartDate

        this.medicalConditions = response.affected_comment;
        this.familyMedicalConditions = response.familyHistory;
        this.allergies = response.allergies;
        this.recurringComplaints = response.comment;
        this.accidentalHistory = response.accidentalHistory;
        // this.tabs = this.isPediatric ? ['Overview', 'Visits', 'History', 'Vaccination', 'Notes', 'Receipts'] : ['Overview', 'Visits', 'History', 'Notes', 'Receipts'];
        this.eDob = response.eDOB;
        this.gestationalAge = response.gestationalAge ? response.gestationalAge : null;
        console.log("Apri11", this.gestationalAge)
        this.typeOfDelivery = response.type_of_delivery;
        this.isHighRisk = response.isHighRisk;
        var additionalInfo = response.additionalInfo ? JSON.parse(response.additionalInfo) : {};
        console.log("may311111",additionalInfo)
        this.reactionToMeds=additionalInfo.reactionToMeds
        this.additionalInfo=additionalInfo ? additionalInfo:{}
        this.newBornScreening = additionalInfo.newBornScreening ? additionalInfo.newBornScreening : null;
        var highRisk: any = additionalInfo.highRiskType;
        this.highRiskCategory = highRisk ? highRisk.split(',') : {}


        this.profilePic = response.profilePic ? response.profilePic : "assets/images/user_default.png";
        this.selectedTab(tab);
        this.selectTabrequisition(this.requisitionTab);
        this.minDate = this.reverseDate(new Date(this.dob));
        this.selectedLanguage = response.parent.selectedLanguage;

        if (this.isPediatric) {
          //  this.selectedVisit.date
          // this.visits = response.visits ? response.visits : []? response.visits:null;
          this.visits = response.visits;



          this.oldOrBirthVitalsArray = [

            {
              name: 'Weight',
              value: response.visits[0]["weight"] == 0 ? 'NA' : response.visits[0]["weight"],
              unit: 'kg',
              date: moment(response.visits[0]["date"]).format("DD-MMM-YYYY"),
            },
            {
              name: 'Height',
              value: response.visits[0]["height"] == 0 ? 'NA' : response.visits[0]["height"],
              unit: 'cm',
              date: moment(response.visits[0]["date"]).format("DD-MMM-YYYY"),
            },
            {
              name: 'HC',
              value: response.visits[0]["hc"] == 0 ? 'NA' : response.visits[0]["hc"],
              unit: 'cm',
              date: moment(response.visits[0]["date"]).format("DD-MMM-YYYY"),
            },
            {
              name: 'CC',
              value: response.visits[0]["cc"] == 0 ? 'NA' : response.visits[0]["cc"],
              unit: 'cm',
              date: moment(response.visits[0]["date"]).format("DD-MMM-YYYY"),
            },
            {
              name: 'BP (Sys)',
              value: response.visits[0]["bpSys"] == 0 ? 'NA' : response.visits[0]["bpSys"],
              unit: '',
              date: moment(response.visits[0]["date"]).format("DD-MMM-YYYY"),
            },
            {
              name: 'BP (Dia)',
              value: response.visits[0]["bpDia"] == 0 ? 'NA' : response.visits[0]["bpDia"],
              unit: '',
              date: moment(response.visits[0]["date"]).format("DD-MMM-YYYY"),
            },

          ];
          var Temp=""
          var temp=response.visits[response.visits.length - 1]["Temp"] == null ? 'NA' : response.visits[response.visits.length - 1]["Temp"]
         console.log("temp1",temp)
          if(temp==null||temp=="null" ){
            Temp="NA"
          }
          else{
            Temp=temp
          }
          console.log("temp",Temp)
          var lmpDate=response.visits[response.visits.length - 1]["lmp"] == 0 ? 'NA' : response.visits[response.visits.length - 1]["lmp"]
         lmpDate =  this.reverseDate(lmpDate);
     lmpDate = moment(new Date(this.parseDate(lmpDate))).format("DD-MMM-YY");
     if(lmpDate=="Invalid date"){
      lmpDate="NA"
     }
          this.recentVitalsArray = [

            {
              name: 'Weight',
              value: response.visits[response.visits.length - 1]["weight"] == 0 ? 'NA' : response.visits[response.visits.length - 1]["weight"],
              unit: 'kg',
              date: moment(response.visits[response.visits.length - 1]["date"]).format("DD-MMM-YYYY"),
            },
            {
              name: 'Height',
              value: response.visits[response.visits.length - 1]["height"] == 0 ? 'NA' : response.visits[response.visits.length - 1]["height"],
              unit: 'cm',
              date: moment(response.visits[response.visits.length - 1]["date"]).format("DD-MMM-YYYY"),
            },
            {
              name: 'HC',
              value: response.visits[response.visits.length - 1]["hc"] == 0 ? 'NA' : response.visits[response.visits.length - 1]["hc"],
              unit: 'cm',
              date: moment(response.visits[response.visits.length - 1]["date"]).format("DD-MMM-YYYY"),
            },
            {
              name: 'CC',
              value: response.visits[response.visits.length - 1]["cc"] == 0 ? 'NA' : response.visits[response.visits.length - 1]["cc"],
              unit: 'cm',
              date: moment(response.visits[response.visits.length - 1]["date"]).format("DD-MMM-YYYY"),
            },
            {
              name: 'BP (Sys)',
              value: response.visits[response.visits.length - 1]["bpSys"] == 0 ? 'NA' : response.visits[response.visits.length - 1]["bpSys"],
              unit: '',
              date: moment(response.visits[response.visits.length - 1]["date"]).format("DD-MMM-YYYY"),
            },
            {
              name: 'BP (Dia)',
              value: response.visits[response.visits.length - 1]["bpDia"] == 0 ? 'NA' : response.visits[response.visits.length - 1]["bpDia"],
              unit: '',
              date: moment(response.visits[response.visits.length - 1]["date"]).format("DD-MMM-YYYY"),
            },
            {
              name: 'SPO2',
              value: response.visits[response.visits.length - 1]["spo2"] == null  ? 'NA' : response.visits[response.visits.length - 1]["spo2"],
              unit: '',
              date: moment(response.visits[response.visits.length - 1]["date"]).format("DD-MMM-YYYY"),
            },
           
            {
              name: 'Temp',
              value: Temp,
              unit: '',
              date: moment(response.visits[0]["date"]).format("DD-MMM-YYYY"),
            }
          ];
          if(this.ageYears >=8 && response.gender=="Female"){
           this.recentVitalsArray.push( {
            name: 'LMP',
            value: lmpDate ? lmpDate:'NA',
            unit: '',
            date: moment(response.visits[response.visits.length - 1]["date"]).format("DD-MMM-YYYY"),
          },)
           }
          //this.pediatricVisitDate  = this.visits[this.visits.length - 1];
          //this.pediatricVisitDate  = moment(this.visits[this.visits.length - 1]["date"]).format("DD-MMM-YYYY");

          this.pediatricVisitDate = moment(response.visits[response.visits.length - 1]["date"]).format("DD MMM YYYY")
          console.log("pediatricVisitDate",this.pediatricVisitDate);
          
          if (new Date().getTime() < new Date(this.pediatricVisitDate).getTime()) {
            //this.selectedVisit = [];
            //  this.oldOrBirthVitalsArray=[];
          }
         

        }
       
        else {
          //  this.tabs.push('Vaccination')
          this.visits = response.visits ? response.visits : []
          //this.populateLogic(this.visits);
          for (let index = 0; index < this.visits.length; index++) {
            const element = this.visits[index];
            if (element._id === "synthetic_record") {
              console.log("log in synthetic_record4");

              this.visits.splice(index, 1);
              // commented by vitalsVisitId for birthvitalsNot Printing For Adult Patient
            }
          }
          if (this.visits.length) {
            if (this.visits.length > 1) {
              this.adultPreviousVisit = this.visits[this.visits.length - 2];
              this.adultPreviousVisitDate = moment(this.visits[this.visits.length - 2]["date"]).format("DD-MMM-YYYY");
              const w = Number(this.adultPreviousVisit.weight);
              const h = Number(this.adultPreviousVisit.height);
              const ldlc = Number(this.adultPreviousVisit.Chol_LDL);
              const hdlc = Number(this.adultPreviousVisit.Chol_HDL);

              let bmi: any = Number(w / Math.pow(h / 100, 2));

              let Bmi = ""
              if (bmi == Number(NaN)) {
                bmi = Bmi;
              }
              let r: any = ldlc / hdlc



              this.adultPreviousVisitBMI = bmi === Infinity || bmi === '' ? '' : bmi.toFixed(2);
              console.log("bmi", this.adultLatestVisitBMI)

              this.adultPreviousVisitCholRatio = r === Infinity || r === '' ? '' : r.toFixed(2);
            }
            this.adultLatestVisit = this.visits[this.visits.length - 1];
            console.log("",this.adultLatestVisit)
            this.adultLatestVisitDate = moment(this.visits[this.visits.length - 1]["date"]).format("DD-MMM-YYYY");
            console.log("adultLatestVisitDate",this.adultLatestVisitDate);
            console.log("this.visits",this.visits);
            
         
            
            const w = Number(this.adultLatestVisit.weight);
            console.log("wAUG",w)
            if(w!=0){ 
              // this.adultLatestVisitArray.push({name:"Weight",value:w,date:this.adultLatestVisitDate,symbol:"Kg"})
              }

            const h = Number(this.adultLatestVisit.height);
            // if(h!=0){
            //   this.adultLatestVisitArray.push({name:"Height",value:h,date:this.adultLatestVisitDate,symbol:"Cm"})
            //   }
            //   if(this.adultLatestVisit.Temp!="null"){
            //     this.adultLatestVisitArray.push({name:"Temp",value:this.adultLatestVisit.Temp,date:this.adultLatestVisitDate,symbol:"F"})
            //    }
            //    if(this.adultLatestVisit.Bloodhaemoglobin){
            //     this.adultLatestVisitArray.push({name:"Hb+",value:this.adultLatestVisit.Bloodhaemoglobin,date:this.adultLatestVisitDate,symbol:"g/gl"})
            //    }
               if(this.adultLatestVisit.lmp && this.gender=="F"){
                lmpDate =  this.reverseDate(this.adultLatestVisit.lmp);
                lmpDate = moment(new Date(this.parseDate(lmpDate))).format("DD-MMM-YY");
                if(lmpDate=="Invalid date"){
                 lmpDate="NA"
                }
      //  commented by vitalsVisitId

                // this.adultLatestVisitArray.push({name:"LMP",value:lmpDate,date:this.adultLatestVisitDate,symbol:""})
               }
            const ldlc = Number(this.adultLatestVisit.Chol_LDL);
           
            const hdlc = Number(this.adultLatestVisit.Chol_HDL);
           
            let bmi: any = Number(w / Math.pow(h / 100, 2));

            let r: any = ldlc / hdlc

            this.adultLatestVisitBMI = bmi === Infinity || bmi === '' || isNaN(bmi) ? 'NA' : bmi.toFixed(2);
console.log("adultLatestVisitBMI",this.adultLatestVisitBMI);

            if (!Number.isNaN(this.adultLatestVisitBMI)) {
              //  this.adultLatestVisitBMI = ""
            }
            else {
              this.adultLatestVisitBMI;
            }
            this.adultLatestVisitCholRatio = r === Infinity || r === '' || isNaN(r) ? 'NA' : r.toFixed(2);
            console.log("1234567");
            

            if (!Number.isNaN(this.adultLatestVisitCholRatio)) {
              this.adultLatestVisitCholRatio;
            console.log("1234567 if" ,this.adultLatestVisitCholRatio);

            }
            else {
              this.adultLatestVisitCholRatio;
            console.log("1234567 else",this.adultLatestVisitCholRatio);

            }
            if (new Date().getTime() < new Date(this.adultLatestVisitDate).getTime()) {
              this.adultLatestVisit
            }
        
        
     
            }
       // this.adultLatestVisitArray.push(this.adultLatestVisit)
       console.log("recentVitalsArray1",this.adultLatestVisit)
          }
        console.log("recentVitalsArray", this.adultLatestVisitBloodPressureArray.length)
        // this.getPatientsVisitInfo();
        this.getPatientsVaccination();
      } else {
        alert('Something went wrong')
      }
    });
    // this.lastvisitIdForAppoinment =  this.patientData.visits[0]._id; ;
 console.log("lastvisitIdForAppoinment",this.lastvisitIdForAppoinment);
 
  }
 
  sendVisitIdToChild(){
    // this.idEmitter.emit(this.lastvisitIdForAppoinment);
  }

  gettwoDays() {

    const currentDate = new Date();

    var date1: any = new Date(this.createdtDate);
    var date2: any = new Date(currentDate);

    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    this.differnceDate = diffDays;


    // return currentDate;
    // 
    if (this.differnceDate < 2) {
      this.showDelete = true
    }
    else {
      this.showDelete = false
    }

   
  }


  getPatientsVisitInfo() {
    var patientID = this.parmpatientId
    this.showLoading = true;
    this.patientService.getPatientsVisitInfo(this.patientData.id ? this.patientData.id : patientID).subscribe(response => {
     // 
      if (response) {
        this.showLoading = false;
        const visits = response ? response : {};
       setTimeout(() => {
        this.populateLogic(visits);
       }, 1000);
        const lastVisit = visits[visits.length - 1] ? visits[visits.length - 1] : '';
        if (this.isPediatric) {

          this.birthVisit = lastVisit.data[lastVisit.data.length - 1] ? lastVisit.data[lastVisit.data.length - 1] : '';
          console.log("birthVisit",this.birthVisit);
          
        }
        if (lastVisit && lastVisit.data.length === 1) {
          visits.pop();
        }

        var arr = [];
        for (let index = 0; index < visits.length; index++) {
          const element = visits[index];

          for (let j = 0; j < element.data.length; j++) {
            const elementForMonth = element.data[j];

            if (new Date().getTime() < new Date(elementForMonth.date).getTime()) {
              //commenting here - condition unknown
              // element.data.splice(j, 1);
            }

          }

        }
        if (visits.length) {
          this.latestVisitWithValues = '';
          var visit = visits[0].data[0];
          var keys = Object.keys(visit);
          var strToShowNonNullValues = '';
          for (let m = 0; m < keys.length; m++) {
            const elementKey = keys[m];

            if (visit[elementKey] && visit[elementKey] !== '0' && visit[elementKey] !== 0 && visit[elementKey] !== null && visit[elementKey] !== 'null' && visit[elementKey] !== ''
              && elementKey !== '_id' && elementKey !== 'chief_complaints' && elementKey !== 'date'
              && elementKey !== 'addedBy' && elementKey !== 'updatedByName' && elementKey !== 'DoctorId' && elementKey !== 'patientid' && elementKey !== 'formattedDate' && elementKey !== 'newDate' && elementKey !== 'strToShowNonNullValues') {
              strToShowNonNullValues = strToShowNonNullValues + " " + elementKey + ' : ' + visit[elementKey];
            }
          }
          this.latestVisitWithValues = strToShowNonNullValues;
        }

        this.visitsForVisitScreen = visits;
        console.log("visitApril", this.visitsForVisitScreen)
        this.addVitals()
      }
    });
  }

  editPatient() {
    // this.patientResponse.vaccinationStartDate= this.vacDate
    // localStorage.setItem('patientDataNew', JSON.stringify(this.patientResponse));

    localStorage.setItem('patientTab', JSON.stringify(this.patientResponse));

    this.router.navigate([`/doctor/patients/patientOnboard`], { state: { data: this.patientResponse } });
  }

  openPrescriptions() {
    // alert(JSON.stringify(patient))
    localStorage.setItem('patientData', JSON.stringify(this.patientResponse));
    //ashwin commented
    this.router.navigate([`/doctor/patients/quickprescription/view`], { queryParams: { patientId: this.patientResponse._id } });
    //new nav
    // this.router.navigate([`/doctor/patients/quickprescription/view`], { queryParams: { patientId: this.patientResponse._id } });
    // localStorage.setItem('patientData', JSON.stringify(this.patientResponse));
    // this.router.navigate([`/doctor/patients/prescriptions/view`], { queryParams: { patientId: this.patientResponse.id } });
    // this.router.navigate([`/doctor/patients/prescriptions/view`])
  }
  openAppointment() {
    localStorage.setItem('patientData', JSON.stringify(this.patientResponse));
    //this.patientName=this.patientResponse.first_name+" "+this.patientResponse.middle_name+" "+this.patientResponse.last_name
    this.router.navigate(['/doctor/homepage/'], { queryParams: { patientId: this.patientResponse._id } });

  }

  open(content, type) {
    this.modalService.open(content, { backdrop: false, size: 'xl', centered: false, windowClass: 'dark-modal' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      console.log("dismissed")
      this.isEditableVisit = false

    });
  }

  openReceiptModal() {
    this.selectedDate = this.reverseDate(new Date());
  }
  saveMoreInfo() {
    var postData: any = {};
    postData.allergies = this.allergies;
    postData.affected_comment = this.medicalConditions;
    postData.comment = this.recurringComplaints;
    postData.accidentalHistory = this.accidentalHistory;
    // postData.familyHistory = this.familyMedicalConditions;
    this.patientService.updatePatientMoreInfo(postData, this.patientResponse._id).subscribe(response => {
      if (response) {

        this.getPatientInfo('Overview');
      }
    })
  }


  clickDisModal() {
    this.selectedDocumentType = "Discharge Summary"
  }
  clickDisModalDoc() {
    this.selectedDocumentType = "";
  }
  clickReportDoc() {

    this.selectedDocumentType = "Lab Report";
  }
  clickHeelDoc() {

    this.selectedDocumentType = "Heel Prick Test";
  }
  onChangeDoc(event) {

    if (event === 'Lab Report') {
      this.selectedDocumentType = event
      console.log("Lab", this.selectedDocumentType)
      // this.documentTypeSelected=this.selectedDocumentType
    }
    else if (event == 'Prescription') {
      this.selectedDocumentType = event
      console.log("Lab", this.selectedDocumentType)
      // this.documentTypeSelected=this.selectedDocumentType
    }
    else if (event == 'Requisition') {
      this.selectedDocumentType = event
      console.log("Lab", this.selectedDocumentType)
      // this.documentTypeSelected=this.selectedDocumentType
    }
    this.selectedDocumentType = event
    console.log("Lab", this.selectedDocumentType)
    //  this.documentTypeSelected=this.selectedDocumentType
  }


  calculateMinMax(vDate, ageYears) {
    var patientID = this.parmpatientId;
    var postData = {
      patientId: this.patientData.id ? this.patientData.id : patientID,
      visitDate: vDate
    }
    let gender = this.patientResponse.gender === "Male" ? "Boys" : "Girls";
    this.patientService.calculateMinMax(postData, this.patientResponse._id).subscribe(response => {
      if (response) {
        var _minMaxPercentile = {};
        _minMaxPercentile["weight"] = response["weight" + gender];
        _minMaxPercentile["height"] = response["height" + gender];
        if (ageYears < 3) {
          _minMaxPercentile["hc"] = response["hc" + gender];
        } else {
          _minMaxPercentile["hc"] = { min: "", max: "" };
        }
        this.minMaxPercentile = _minMaxPercentile;

      }
    });
  }




  getAllDoctorPrescriptions = () => {
    console.log("patientDataFromLocal", this.patientDataFromLocal);
    this.route.queryParams
    .subscribe(params => {
      console.log("params33",params);
      
    //  this.appoinmentPatientId = params.patientId;

    }) 
    
var patientID = this.paramPatientId;

    var obj = {
      "patientId": this.patientId ? this.patientId : patientID,
      "isForHistory": true,
    }
    console.log("this.patientId patientId", this.patientId);
    console.log("getAllDoctorPrescriptions patientId", obj);
    
    var arr = [];
    var arrNotes = [];
    var arrinstr = [];
    var arrivest = [];
    var arrcomply = []

    this.patientService.getAppointmentsByPatientId(obj).subscribe(response => {
      const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      if (response.success) {
        
        if (response.historyArr.length) {
          console.log("response.historyArr",response.historyArr)
          for (let index = 0; index < response.historyArr.length; index++) {
            const element = response.historyArr[index];

            var obj: any = {}
            obj.createdAt = element.from;
            obj.from = element.from;
            obj.to = element.to;
            obj.id = element.id;
            obj.docName = element.Doctor.User?.first_name + " " + element.Doctor.User?.last_name;
            this.docName = obj.docName;
            obj.Visit = element.Visit && element.Visit != null ? element.Visit : {};
            obj.Medicines = element.Visit && element.Visit != null ? element.Visit.Medicines && element.Visit.Medicines.length > 0 ? element.Visit.Medicines : null : null;
            obj.prescriptionPDFURL = element.prescriptionPDFURL && element.prescriptionPDFURL != null ? element.prescriptionPDFURL : null;
            obj.Certificates = element.Certificates && element.Certificates != null ? element.Certificates : null;
            if (element.Visit && element.Visit != null) {
              //additionalInfo.newBornScreening ? additionalInfo.newBornScreening : null;
              obj.chief_complaints = element.Visit.chief_complaints && element.Visit.chief_complaints != null ? element.Visit.chief_complaints : ''
              if (element.Visit.prescription_instruction && element.Visit.prescription_instruction != null) {
                var note: any = {};
                note.date = `${days[new Date(element.from).getDay()]}, ${moment(element.from).format('DD MMM YYYY')}`
                note.note = element.Visit.prescription_instruction;
                note.docName = element.Doctor && element.Doctor.User!=null ? element.Doctor.User.first_name + " " + element.Doctor.User.last_name:'';
                note.visitId = element.VisitId;
                arrNotes.push(note);
              }
              // if (element.Visit.chief_complaints && element.Visit.chief_complaints != null) {
              //   var complaints : any = {};
              //   complaints .value = `${days[new Date(element.from).getDay()]}, ${moment(element.from).format('DD MMM YYYY')}`
              //   complaints .complaints = element.Visit.chief_complaints;
              //   arrcomply.push(complaints);
              // }
              if (element.Visit.general_instruction && element.Visit.general_instruction != null) {
                var instruction: any = {};
                instruction.value = `${days[new Date(element.from).getDay()]}, ${moment(element.from).format('DD MMM YYYY')}`
                instruction.instruction = element.Visit.general_instruction;
                arrinstr.push(instruction);
              }

              if (element.Visit.suggested_investigation && element.Visit.suggested_investigation != null) {
                var investigation: any = {};
                investigation.value = `${days[new Date(element.from).getDay()]}, ${moment(element.from).format('DD MMM YYYY')}`
                investigation.investigation = element.Visit.suggested_investigation;
                arrivest.push(investigation);
              }
            }
            arr.push(obj);

          }
          this.appointmentArr = arr.reverse();
          console.log("sep23333333",this.appointmentArr)
          arrNotes = _.uniqBy(arrNotes, 'visitId');

          this.notesArr = arrNotes.reverse();
        }

      }
    });
  }


  isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  calculateNextVaccination = () => {
    console.log("calculateNextVaccination called");
    
    this.allVaccines = this.allVaccines ? this.allVaccines : []

    loop1: for (let index = 0; index < this.allVaccines.length; index++) {
      const element = this.allVaccines[index];
      // console.log("elementelement", element);
      // console.log(", this.allVaccines",  this.allVaccines);
      
      loop2: for (let ind = 0; ind < element.data.length; ind++) {
      
        
        const item = element.data[ind];
        console.log("item.administeredStatus",item);
      
        console.log("434343",!item.administeredStatus);
        
        if (!item.administeredStatus) {
          console.log("if working for calculateNextVaccination");
          
          this.pendingVaccineObj = element;
          console.log("1234pendingVaccineObj",this.pendingVaccineObj);
          
          console.log("March28", this.pendingVaccineObj.data)
          localStorage.setItem("pendingVaccination", JSON.stringify(this.pendingVaccineObj.data))
          var date: any = new Date(this.pendingVaccineObj.data[0].dueDate);
          // date?date:'';
          // var duedate:any = date.toDateString();
          if (date == 'Invalid Date') {

          }
          else {
            this.vacDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
              .toISOString()
              .split("T")[0];
          }


          //this.vacDate=this.vacDate=moment(this.parseDate(this.vacDate)).format("YYYY-MM-DD");

          this.foundPendingVaccines = true;
          break loop1;
        }
      }
    }

  }

  getPatientsVaccination = () => {

    this.patientService.getPatientsVaccination(this.patientId ? this.patientId : this.paramPatientId).subscribe(response => {
      if (response) {
        this.openvitalsModalFlag =false;
        this.allVaccines = response;
        this.calculateNextVaccination();
        this.vaccineList = response;
        for (let i = 0; i < this.vaccineList.length; i++) {
          if (this.isJson(this.vaccineList[i].name)) {
            let scheduleNames = JSON.parse(this.vaccineList[i].name);
            if (scheduleNames && scheduleNames.length > 0) {
              scheduleNames.forEach(langpart => {
                if (langpart.lang == this.selectedLanguage) {
                  this.vaccineList[i].name = langpart.text;
                }
              });
            }
          }
        }

        let vaccineArray = [];
        let newArray = [];

        let tempArr = [];

        console.log('Experimental', this.vaccineList)

        this.vaccineList.map(ins => {

          ins.data.map((item, idx) => {

            if (!tempArr.includes(ins.name)) {
              tempArr.push(ins.name)
              item['vaccineVisit'] = ins.name;
            } else {
              item['vaccineVisit'] = "";
            }

            item['visitName'] = ins.name;
            item['index'] = idx;
            item['catchupDate'] = item.catchUpPeriod && item.catchUpPeriod != "" ? moment(item.dueDate).add(item.catchUpPeriod, 'w') : 'NA';
            item['catchupDate'] = item.catchupDate == 'NA' ? 'NA' : item.catchupDate.format('DD-MM-YYYY');

            if (moment(item.dueDate).isBefore(moment())) {
              item['isPending'] = true
            }


            vaccineArray.push(item);
          });
        });

        this.unformattedVaccineList = this.vaccineList;
        this.vaccineList = newArray.map((ins, index) => { return { name: ins.name, selected: false, id: index } });
        this.alltestVac = newArray.map((ins, index) => { return { name: ins.name, selected: false, id: index } });
        this.vaccineList = vaccineArray;

      }
    });
  }


  getAllpageRefresh() {
    if (this.tab == 'Visits') {
      // window.location.reload()
      this.getPatientsVisitInfo();

    }
    else {
      this.getPatientInfo('Overview');
      this.getPatientsVisitInfo();
      // this.getPatientsVaccination();
      //this.getPatientInfo(0)
    }

  }

  addVaccRemove() {
    this.duration = "(Duration)";
    this.alltestVac.map(ins => ins.selected = false);
    // this.chief_testVacc = ""
  }






  editCert() {
    this.openDialog(this.addCertificateModal, 'addCertificateModal');

  }



  getFavLabs() {
    var postData = {
      ids: this.favLabsIds,
    }
    this.patientService.getFavLabs(postData).subscribe(response => {
      if (response) {
        var testArray: any = [];
        this.favLabs = response;

        console.log("FavLab", this.favLabs)
      }
      for (let index = 0; index < this.favLabs.length; index++) {
        const element = this.favLabs[index]
        if (element.waNo.includes('+91')) {
          testArray.push(element)
        }
        else {
          element.waNo = "+91" + element.waNo
          testArray.push(element)
          console.log("waNo", testArray)
        }
      }
      this.favLabs = testArray;
      this.favLabs.map(ins => {
        if (ins.waNo) {
          let wNumbers = ins.waNo;
          this.waNumber = wNumbers
          console.log("wNumbers", this.waNumber)
        }
      })

      console.log("newfavLabs", this.favLabs)
    });
  }



  getAllRequisitions() {

    var arr1 = [];
    this.showRequisitionLoading = true;
    var postData = {
      patient_id: this.patientData.id ? this.paramPatientId : this.patientData._id,

    }
    console.log("getAllRequisitionspostData ",this.patientData._id,postData);
    

    this.patientService.getAllRequisitions(postData).subscribe(response => {


      //this.showRequisitionLoading = false;
      if (response) {

        console.log('Requisitions', response)
        this.showRequisitionLoading = false;
        for (let index = 0; index < response.requisition.length; index++) {
          const element = response.requisition[index];


          let today = new Date(element.createdAt)
          let month = null
          month = today.getMonth() + 1
          if (month < 10) {
            { month = '0' + month }
          }
          element.createdAt = `${today.getDate()}/${month}/${today.getFullYear()}`

        }

        this.allRequisition = response.requisition;
        console.log("Newdata", this.allRequisition);
      }

    })


  }


  searchDoctors = () => {


    const postData = {
      searchString: this.searchText,
      searchAllDoctor: true
    };

    if (this.searchText.length > 9 && this.searchText.length < 11 && /^[0-9]*$/.test(this.searchText)) {
      this.patientService.getAllDoctorsByNameOrContact(postData).subscribe(response => {
        if (response) {
          console.log("search data", response)
          //  this.docName=response.firstName+" "+response.lastName
          this.selectedDoctor = response.doctors[0]._id
          this.docName = response.doctors[0].firstName + " " + response.doctors[0].lastName
          this.docEmail = response.doctors[0].email;

          console.log("search data1", this.selectedDoctor)
          // this.setState({
          //     selectedDoctor: response.data.doctors[0],
          // })
        }
      })
      // .catch(error => {
      //     Alert.alert('Something went wrong', 'Please try again later')
      //     this.setState({
      //         isLoading: false,
      //     })
      //     console.warn('Error:   ' + JSON.stringify(error));
      // });
    }
    else {
      // this.setState({
      //     isInvalidNumber: true,
      // })
    }
  }

  closeLinkData() {
    this.docName = "";
    this.searchText = "";
    this.docEmail = "";
    this.selectedDoctor = "";
  }

  // linkDoctorPatient() {
  //   let postData = {
  //     doctorId: this.selectedDoctor,
  //     patientId: this.patientData.id ? this.patientData.id : this.patientId._id,
  //   }
  //   this.patientService.saveLinkDoctorAndPatient(postData).subscribe(response => {
  //     // this.messageService.add({ severity: 'custom', detail: 'Linked Patient to Doctor' });
  //     if (response) {
  //       console.log("responselog", response)

  //       this.messageService.add({ severity: 'custom', detail: response });
  //     }

  //   })
  // }

  linkDoctorPatient() {
    let postData = {
      doctorId: this.selectedDoctor,
      patientId: this.patientData.id ? this.patientData.id : this.patientId._id,
    };
  
    this.patientService.saveLinkDoctorAndPatient(postData).subscribe(response => {
      if (response && response.message) {
        console.log("123Patient is already linked to this doctor");
        
        // Patient is already linked to this doctor
        this.messageService.add({ severity: 'custom', detail: response.message });
        console.log("123Patient is already linked to this doctor" , response.message);


      } else {
        console.log("456Linked Patient to Doctor successfully");

        // Linked Patient to Doctor successfully
        this.messageService.add({ severity: 'custom', detail: 'Linked Patient to Doctor' });
      }
    });
  }
  
  //--------*******allDocumentsReports********----------------//
  getAllDocumentsOfPatient = (optionalParam = 1) => {
    var postData = {
      patient_id: this.patientData.id ? this.paramPatientId : this.patientData._id,
    }
    console.log("this.patientData.id", this.patientData.id, this.patientId);
    
    this.patientService.getAllDocumentsOfPatient(postData).subscribe(response => {

      if (response) {

        for (let index = 0; index < response.documents.length; index++) {

          const element = response.documents[index];
          //  const docDate=response.documents[index].documentDate
          var dateTime = moment(response.documents[index].documentDate).format('YYYY-MM-DD');
          // console.log("docDatee", dateTime);
          let today = new Date(element.createdAt)
          let month = null
          month = today.getMonth() + 1
          if (month < 10) {
            { month = '0' + month }
          }
          element.createdAt = `${today.getDate()}/${month}/${today.getFullYear()}`

        }
        this.allDocumentReports = response.documents;
        console.log("allReMay6", this.allDocumentReports)

        var testArry: any = [];
        var testReport: any = [];
        var testHeelPrick: any = [];
        var allTranscripts: any = [];

        this.allDocumentReports.map(ins => {
          if (ins.documentType == 'Discharge Summary') {
            testArry.push(ins);
            console.log("summaryDis", testArry)
            this.allSummary = testArry
          }
          else if (ins.documentType == 'Lab Report') {
            testReport.push(ins);
            this.allReports = testReport

          }
          else if (ins.documentType == "Transcript") {

            let arr = ins.url.split(".");
            let ext = arr[arr.length - 1];

            allTranscripts.push({
              id: ins.id,
              createdAt: ins.documentDate ? ins.documentDate : ins.createdAt,
              docName: ins.documentType,
              type: ins.notes ? ins.notes : 'Vaccination Transcript',
              labReportUrl: ins.url,
              // patientUploaded: true,
              fileExtension: ext,
              documentDate: ins.documentDate ? ins.documentDate : null
            });
          }
          else if (ins.documentType == 'Heel Prick Test') {
            testHeelPrick.push(ins);
            this.allHeelPrickTest = testHeelPrick
            var arr = '';
            this.allHeelPrickTest.map(ins => {

              if (ins.url) {
                this.heelPrickUrl = ins.url;

              }
            })


          }
        })

        localStorage.setItem('allTranscripts', JSON.stringify(allTranscripts));
        this.allDocumentReports.map(ins => {
          return this.allDocumentReports.sort((a, b) => {
            return <any>new Date(b.documentDate) - <any>new Date(a.documentDate);
          });
        })
      }
    })
  
    if(optionalParam){
      this.refreshservice.documentRefershed();
    }

  }

  //--------*******allRequisitionReports********----------------//
  getAllLabReportOfPatient() {
    var postData = {
      patient_id: this.patientId ? this.patientId : this.paramPatientId,

    }
    console.log("paramPatientId in getAllLabReportOfPatient",this.paramPatientId, this.patientId);
    
  
    
    this.patientService.getAllLabReportOfPatient(postData).subscribe(response => {

      if (response) {
        console.log("Reportslab", response);
        //   this.selectedDocumentType=response.documentType;
        for (let index = 0; index < response.reports.length; index++) {
          const element = response.reports[index];
          let today = new Date(element.createdAt)
          let month = null
          month = today.getMonth() + 1
          if (month < 10) {
            { month = '0' + month }
          }
          element.createdAt = `${today.getDate()}/${month}/${today.getFullYear()}`

        }

        this.allReports = response.reports;
        console.log("ReportsMRU", response);
      }
    })

  }
  //-------***Getpdf**------//


  selectModalTestVaccValue = (item) => {

    // this.alltestVac = this.vaccineList
    this.alltestVac = this.allAdministeredVaccines
    let data = this.alltestVac;
    let selectedDataArr = [];
    data.map((ins, index) => {
      if (ins.name == item.name) {
        if (ins.selected) {
          ins.selected = false;

          let selected = selectedDataArr;
          if (selected.includes(ins.name)) {

          }

        } else {
          ins.selected = true;
          selectedDataArr.push(ins.name);
        }
      }
    });

    this.allAdministeredVaccines = data;
  }

  //------checkbox----------------------//
  getAllRequiredConstants = () => {
    let newArray = [];
    this.patientService.getAllRequiredConstants('').subscribe(response => {

      if (response) {
        console.log('CONSTANTS', response);

        this.specialVaccineInfo = response.data.specialVaccineInfo ? response.data.specialVaccineInfo : [];
        this.medicalConditionArray = response.data.medicalConditions.map(ins => { return { name: ins.name, selected: false } });

        // this.specialSituationVaccines = response.data.specialSituationVaccines ? response.data.specialSituationVaccines : [];

        let localSpecialVaccines = []
        response.data.specialSituationVaccines.map(ins => {
          let obj = {
            "name": ins.name,
            "description": this.specialVaccineInfo[ins.name]
          }

          localSpecialVaccines.push(obj)

        });

        this.specialSituationVaccines = localSpecialVaccines;
        console.log("LOC", localSpecialVaccines)



        newArray = response.data.requisitionTests

        if (newArray.length) {

          newArray.map((ins, index) => {
            newArray[index].name = ins.name.charAt(0).toUpperCase() + ins.name.slice(1)
          })
        }

        this.alltest = newArray.map((ins, index) => { return { name: ins.name, selected: false, id: index } });
        this.alltestorg = newArray.map((ins, index) => { return { name: ins.name, selected: false, id: index } });

        var arr = response.data.requisitionTestsFromDB;
        this.allRequistionTest= response.data.requisitionTestsFromDB;
        var sections = [];
        var otherTestArr = [];
        for (let index = 0; index < arr.length; index++) {
          const element = arr[index];
          if (element.section != null) {
            sections.push(element.section);
          } else {
            otherTestArr.push({ name: element.testname, selected: false });
          }
        }
        sections = sections.filter(function (item, pos, self) {
          return self.indexOf(item) == pos;
        });
        var finalArr = [];
        for (let index = 0; index < sections.length; index++) {
          var element = sections[index];
          var testArr = [];
          for (let j = 0; j < arr.length; j++) {
            const test = arr[j];
            if (test.section === element) {
              testArr.push({ name: test.testname, selected: false });
            }
          }
          finalArr.push({ section: element, data: testArr });
        }
        finalArr.push({ section: 'Others', data: otherTestArr });

        this.sectionWiseRequisitionTests = finalArr;
     
        this.originalSectionWiseRequisitionTests = finalArr;
        console.log("Noveee7",this.originalSectionWiseRequisitionTests)
      }

    })

  }

  editRequition() {
    this.currentRequisitionObj = this.requisitionForEdit;
    this.sectionWiseRequisitionTests = JSON.parse(JSON.stringify(this.originalSectionWiseRequisitionTests));
    this.onClickRequisitionDelete();
  }

  onClickDeleteRequisition(reqObj) {
    //alert(JSON.stringify(reqObj));
    this.currentRequisitionObj = reqObj;
  }
  onClickDelete(reqpatObj) {
    this.patientResponse = reqpatObj;

  }
  onClickDeletePatient() {


    let postData = {
      patientId: this.parmpatientId ? this.paramPatientId : this.paramPatientId,

    }
    console.log("paramPatientIdpostData",this.paramPatientId,postData);
    
    // this.showLoading=true
    this.patientService.deletePatient(postData).subscribe(response => {
      if (response) {
        console.log("respos",response)

        var test: any = []
        let localacessedpatientsstr = localStorage.getItem("localacessedpatients");
        let localacessedpatients = [];
        if (localacessedpatientsstr) {
          localacessedpatients = JSON.parse(localacessedpatientsstr);
        }

        for (var i = 0; i < localacessedpatients.length; i++) {
          if (localacessedpatients[i].Patient.User.id == this.patientId.User.id) {
            localacessedpatients.splice(i, 1);
            break;
          }
        }
        localStorage.setItem("localacessedpatients", JSON.stringify(localacessedpatients));
        this.router.navigate(['/doctor/homepage'],);

      }
    })
  }
  //---*******onClickRequisitionDelete*****--------//
  onClickRequisitionDelete() {
    let postData = {
      id: this.currentRequisitionObj.id
    }

    this.patientService.deleteRequisition(postData).subscribe(response => {
      // if (confirm("Are you sure to delete ")) {
      console.log("delete", response);
      this.getAllRequisitions();

      // }
    });

  }

  onClickCertiShare(url) {

    var postData = {
      patient_id: this.patientData.id ? this.patientData.id : this.patientId._id,
      certisURL: url
    }
    this.patientService.printNewCertificate(postData).subscribe(response => {
      if (response) {
        console.log("certires", response)
        this.modalService.dismissAll();
      }
    })
  }


  //------*******************************ReportsUpload*********************************//

  onFileDropped($event) {


    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    let file = files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      console.log(reader.result);
      this.base64 = reader.result
      console.log("base64", this.base64)
    };

    this.prepareFilesList(files);
  }




  // Check for the File API support.
  // if (window.File && window.FileReader && window.FileList && window.Blob) {
  //   document.getElementById('files').addEventListener('change', handleFileSelect, false);
  // } else {
  //   alert('The File APIs are not fully supported in this browser.');
  // }


  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 200);
      }
    }, 1000);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */

  prepareFilesList = (files: Array<any>) => {

    var MAX_FILE_SIZE = 10 * 1024 * 1024;
    const file = files[0]
    this.fileInfo = `${file.name}`;
    this.fileType = `${file.type}`;

    for (var item of files) {
      if (item.type == "application/pdf" && item.type == 'image/jpeg' && item.size < MAX_FILE_SIZE) {
        let file: File = files[0];
        var myReader = new FileReader();
        myReader.readAsDataURL(file);

      }
      else {
        if (item.size > MAX_FILE_SIZE) {
          this.report_message = "File must not exceed 10 MB";
          this.showAgeYear = false
          this.fileInfo = "";
        }
        if (item.type != "application/pdf" && item.type != "image/jpeg") {

          this.report_message = "File format is not Correct";
          this.fileInfo = "";

        }
        else {
          this.report_message = "";
        }

      }
    }

  }

  openUploadModal = () => {
    this.report_message = ""
  }
  /**
   * 
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */

  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  closeUploadModal() {
    // this.documentType=[];
    this.selectedDocumentType = ""
    this.createdOnRaw = this.reverseDate(new Date());
    this.docType = ""
    this.notes = "";
    this.fileInfo = "";
    // this.refreshservice.documentRefershed();
  }

  uploadDocument() {
    var date: any = new Date(this.createdOnRaw);
    console.log("date", date)
    date = date ? date : ''
    var DocDate: any = date.toDateString();
    if (this.selectedDocumentType === '') {
      this.messageService.add({ severity: 'custom', detail: 'No Document Type selected' });

    }
    else if (this.fileInfo === '') {
      this.messageService.add({ severity: 'custom', detail: 'No File selected' });

    }
    else if (DocDate == "Invalid Date") {
      this.messageService.add({ severity: 'custom', detail: 'No Date selected' });

    }
    else {
      var postData = {
        patientId: this.patientData.id ? this.patientData.id : this.paramPatientId, // Patient id
        fileData: this.base64, // base 64 string
        documentType: this.selectedDocumentType, // Lab Report or Prescription or Requisition...etc
        fileExtension: this.fileType, //pdf or jpg etc
        notes: this.notes, //createdBy
        uploadedBy: 'Doctor',
        documentDate: DocDate, // date selection
      }
      this.patientService.uploadPatientDocuments(postData).subscribe(response => {

        if (response) {
          console.log("data upload sucessfully")
          this.DischargeSummery = "View"
          this.messageService.add({ severity: 'custom', detail: 'Your document has been uploaded successfully' });

          this.getAllDocumentsOfPatient();

          console.log("Jan5",this.selectedDocumentType)
          if(this.selectedDocumentType=="Lab Report"){
            setTimeout(()=>{
              this.tab = "Investigations";
              this.selectedIndex = 2;
              this.innertab = "Reports";
              this.closeUploadModal()
            },500)
          }
          else{
            setTimeout(()=>{
              this.selectedIndex = 4;
              this.tab = "History"
              this.closeUploadModal()
            },500)
          }
         
       
          

          
        
          this.closeDialog();
        }

      })
    }

  }


  share() {

    const url = this.requisitionURL;
    let encoded = encodeURI(url);
    let text = "Requisition for" + " " + this.patientName
    encoded = text + " " + encoded
    console.log("encodeUrl", encoded)

    var waNo = this.waNumber

    // Opening URL

    window.open(
      "https://web.whatsapp.com/send?phone=" + waNo + "&text=" + encoded,


      //   `https://web.whatsapp.com/send?text=${text} ${encoded} `,
      //https://web.whatsapp.com/send?phone=+9198xxxxxxx&text=urlencodedtext

      // This is what makes it 
      // open in a new window.
      '_blank'
    );
  }


  shareWithOther() {

    const url = this.requisitionURL;
    let encoded = encodeURI(url);
    let text = "Requisition for" + " " + this.patientName
    encoded = text + " " + encoded
    console.log("encodeUrl", encoded)
    var waNo = this.waNumber

    console.log('Pull Request', "changed the logic")
    window.open(
      `https://web.whatsapp.com/send?text=${text} ${encoded} `,
      '_blank'
    );
  }

  shareWithOtherHeelPrick() {

    const url = this.heelreportsURL;
    let encoded = encodeURI(url);
    let text = "Requisition for" + " " + this.patientName
    encoded = text + " " + encoded
    console.log("encodeUrl", encoded)
    var waNo = this.waNumber

    window.open(

      `https://web.whatsapp.com/send?text=${text} ${encoded} `,

      '_blank'
    );
  }
  //--**********shareRequisitions*****----------//

  //----------Investagation------//
  opensDialog(content) {
    // this.selectedIndex = index;
    this.dialog.open(content);
  }

  closeDialog() {
    this.showEditRequitionButton = false;
    this.dialog.closeAll();
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }

  calculateChestCircum(headcircumference, chestcircumference) {
    this.avgChestchestcircumference = (headcircumference / chestcircumference).toFixed(2);
    if (this.avgChestchestcircumference > 0 && chestcircumference != 0) {
      this.maxChestchestcircumference = this.avgChestchestcircumference;
    } else if (chestcircumference == 0 || chestcircumference == " ") {
      this.maxChestchestcircumference = 0;
    }
  }
  selectmedicalConditions(item){
    if(this.medicalConditions){
      this.medicalConditions= this.medicalConditions+","+" "+item.name
    }
    else{
      this.medicalConditions= item.name
    }
  
  }



  getMasterMilestones = () => {
    const params = {}
    this.patientService.getMasterMilestones(params).subscribe(response => {
      if (response && response.masterMilestones.length) {
        this.allMilestones = response.masterMilestones
        this.getMilestonesOfPatient();
      }
    })
  }

  getMilestonesOfPatient() {
    var patienID = this.paramPatientId?this.paramPatientId:this.patientId;
    var patientId2 =  this.selectedVisit.patientid? this.selectedVisit.patientid: this.patientDataFromLocal.id;
    const params = {
      patientId: patienID ? patienID :patientId2
    }
    console.log("paramsparams123", params,this.patientDataFromLocal.id , this.selectedVisit.patientid);
    
    this.patientService.getPatientMilestones(params).subscribe(response => {
      if (response && response.milestones.length) {
        this.patientsMilestones = response.milestones;
        let masterMilestoneId = response.milestones[0].MasterMilestoneId;
        let type = 1
        this.allMilestones.map(ins => {
          if (ins.id == masterMilestoneId) {
            type = ins.type
          }
        })
        this.selectedMilestoneType = type;
        this.formatMilestoneByType();
      
      }
      else{
        this.selectedMilestoneType = this.currentDoctorObj.milestone_type ? this.currentDoctorObj.milestone_type : 2;
        this.formatMilestoneByType();
      }
    })
    //this.getAgeInMonths();
  }
  formatMilestoneByType(){
    let formattedMilestones = [];
    this.allMilestones.map(item => {
      if (item.type == this.selectedMilestoneType) {
        //Getting sub type of milestone (to show in tab)
        // if (!tabs.includes(item.sub_type)) {
        //   tabs.push(item.sub_type)
        // }
        //adding object-data from patient milestones into main milestone-by-type list
        console.log("this.patientsMilestones ",this.patientsMilestones );
        
        this.patientsMilestones.map(element => {
          if (element.MasterMilestoneId == item.id) {
            item['condition_met'] = element.condition_met
            item['date'] = element.date
            item['description'] = element.description
            item['status'] = element.status
          }
        });
        item.status = item.status ? item.status : this.getMilestoneStatus(item.start_month, item.end_month)

        item.edit = false;
        // this.updateMilestoneInTabs(item);
            
          item['forAllMilestones'] = true;
      
        formattedMilestones.push(item);
        console.log("formattedMilestones", formattedMilestones);
        
      }
     
     
      // console.log("jkjhdashdj",formattedMilestones)
    });
    console.log("formattedMilestones", formattedMilestones);
    
    formattedMilestones.map(ele=>{
      console.log("ele333",ele.status);
      
      if(ele.status=="3" || ele.status=="2"){
        this.dueMilestoneArray.push(ele)
        console.log("dueMilestoneArray", this.dueMilestoneArray);
        
      }
    })
    // this.dueMilestoneArray=dueMilestone
    console.log("dueMilestone", this.dueMilestoneArray)
  }
  getMilestoneStatus = (start_month, end_month) => {
console.log("start_month, end_month",start_month, end_month);

    //1 - overdue, 2 - prompt, 3 - due, 4 - upcoming
    let birth = this.patientAgeInMonths;
    console.log("patientAgeInMonths",this.patientAgeInMonths);
    
    let status = "4";
    if (birth >= start_month && birth <= end_month) {
      status = "3" //"Due"
    }
    else if (birth > start_month && birth > end_month) {
      status = "1" //"Overdue"
    } else {
      status = "4" //"Upcoming"
    }
    return status;
  }
  getAgeInMonths() {
    this.patientDataFromLocal = JSON.parse(localStorage.getItem("patientData"));
    console.log("patientDataFromLocal",this.patientDataFromLocal);

    
   console.log("666666666666",this.patientObj,this.patientId, this.patientResponse )
    var today = new Date();
    console.log("this.patientDataFromLocal.User.dob",this.patientDataFromLocal.User.dob);
    var patientDataForBirthDate=  JSON.parse(localStorage.getItem('patientMoreData'));
    console.log("patientDataForBirthDate",patientDataForBirthDate);
    var birthDateFromLocal = patientDataForBirthDate.DOB;
    console.log("birthDateFromLocal",birthDateFromLocal);
    var birthFromLocalPatientData = this.patientDataFromLocal.User.dob;
    var birthDate = new Date( birthDateFromLocal);
    console.log("birthDate te11", birthDate);
    console.log("birthDateFromLocal and birthFromLocalPatientData",birthDateFromLocal , birthFromLocalPatientData);
    
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    console.log("age in yesrs", age);

    age = age * 12 + m;
    console.log("age in months", age);
    
    this.patientAgeInMonths = age;
    console.log("ageMay11",this.patientAgeInMonths)
  }
  openMilestoneTab(){
    this.tab = "Milestone"
    this.selectedIndex = 3;
    this.milestoneFlag=true
  }
  gotTovaccTab(){
    this.tab = "Vaccination"
    this.selectedIndex = 3;
  }
  openHistoryTab(){
    this.tab = "History"
    this.selectedIndex = 4;
  }
  gotToVisit(){
    this.tab = "Visits";
    this.selectedIndex = 4;
  }
  getNotes(notes){
    this.docName=notes.docName
    this.docDate=notes.date
    this.docNotes=notes.note
   // this.openDialog(this.openNotes,"openNotes")


  }





populateLogic = (data) => {
  console.log(" this.populateLogic(visits);");
  
  this.adultLatestVisitArray=[]
 this.adultLatestVisitBloodSugarArray=[]
 this.adultLatestVisitBloodPressureArray=[]
 this.adultLatestVisitLipidProfileArray=[]
  console.log("dataAug16",data)
  let newPatientObj = {}
  let patientObj = {
  // "_id": null,
  "weight": null,
  "height": null,
  "Temp": null,
  // "hc": null,
  // "cc": null,
  "bpSys": null,
  "bpDia": null,
  "bmi": null,
  "chief_complaints": null,
  "Bloodsugar_F": null,
  "BloodSugarRandom": null,
  "Bloodsugar_PP": null,
  "Blood_Hba1c": null,
  "Bloodhaemoglobin": null,
  "Chol_LDL": null,
  "Chol_HDL": null,
  "Triglycerides_LFT": null,
  "Triglycerides_RFT": null,
  "TotalCholesterol": null,
  "Ratio (LDL/HDL)": null,
  "Heartrate": null,
  "Pulse": null,
  "lmp": null
 
  };
  
  let defaultObj = {
 
  "weight": {
  "value": "NA",
  "date": moment()
  },
  "bpSys": {
  "value": "-",
  "date": moment()
  },
  "bpDia": {
  "value": "-",
  "date": moment()
  },
  "Bloodsugar_F": {
  "value": "NA",
  "date": moment()
  },
  "Heartrate": {
  "value": "NA",
  "date": moment()
  },
  "Pulse": {
  "value": "NA",
  "date": moment()
  },
  "Temp": {
  "value": "NA",
  "date": moment()
  },
  "BloodSugarRandom": {
  "value": "NA",
  "date": moment()
  },
  "lmp": {
  "value": "NA",
  "date": moment()
  },
  
  "height": {
  "value": "NA",
  "date": moment()
  },
  "Bloodsugar_PP": {
  "value": "NA",
  "date": moment()
  },
  "Blood_Hba1c": {
  "value": "NA",
  "date": moment()
  },
  "Bloodhaemoglobin": {
  "value": "NA",
  "date": moment()
  },
  "Chol_LDL": {
  "value": "NA",
  "date": moment()
  },
  "Chol_HDL": {
  "value": "NA",
  "date": moment()
  },
  "TotalCholesterol": {
  "value": "NA",
  "date": moment()
  },
  "Triglycerides_LFT": {
  "value": "NA",
  "date": moment()
  },
  "Triglycerides_RFT": {
  "value": "NA",
  "date": moment()
  }
  }
  
  try {
  let count = 0;
  const conditionArray = ['null', 'undefined', '0'];
  
  // if(data.length > 1){
  for (const month of data) {
  for (const visit of month.data) {
  for (const element in patientObj) {
  if (!patientObj) {
  break;
  }
  if (visit[element] && !conditionArray.includes(visit[element])) {
  newPatientObj[element] = {
  value: visit[element],
  date: visit['date']
  };
  patientObj = _.omit(patientObj, [element]);
  }
  }
  }
  }
 
  // 
  let finalObj:any = {  ...defaultObj,...newPatientObj };
  console.log("finalObj", finalObj);
  
  // this.setState({

  // selectedPatient: finalObj
  // // selectedPatient: Object.keys(newPatientObj).length ? newPatientObj : defaultObj
  // })
  console.log("finalObj",finalObj, finalObj.weight)
  
  console.log('New...', JSON.stringify(newPatientObj))
  if(finalObj.weight.value!='NA'){
    this.adultLatestVisitArray.push({name:"Weight",value:finalObj.weight.value,date:finalObj.weight.date,symbol:"Kg"})

  }
  if(finalObj.height.value!='NA'){
    this.adultLatestVisitArray.push({name:"Height",value:finalObj.height.value,date:finalObj.weight.date,symbol:"Cm"})
  }
  // if(finalObj.height){
  //   this.adultLatestVisitArray.push({name:"Height",value:finalObj.height.value,date:finalObj.height.date,symbol:"Cm"})
  // }
  if(finalObj.bmi && finalObj.bmi.value!='NA'){
    this.adultLatestVisitArray.push({name:"BMI",value:finalObj.bmi.value,date:finalObj.bmi.date,symbol:"F"})
   }
   if(finalObj.Bloodhaemoglobin.value!='NA'){
    this.adultLatestVisitArray.push({name:"Hb+",value:finalObj.Bloodhaemoglobin.value,date:finalObj.Bloodhaemoglobin.date,symbol:"g/dl"})

    
   }
   if(finalObj.Temp.value!='NA'){
    this.adultLatestVisitArray.push({name:"Temp",value:finalObj.Temp.value,date:finalObj.Temp.date,symbol:"F"})
   }
   if(finalObj.lmp && finalObj.lmp.value){
    var lmpDate=finalObj.lmp.value
    lmpDate =  this.reverseDate(lmpDate);
lmpDate = moment(new Date(this.parseDate(lmpDate))).format("DD-MMM-YY");
if(lmpDate=="Invalid date"){
 lmpDate="NA"
}
this.adultLatestVisitArray.push({name:"LMP",value:lmpDate,date:finalObj.lmp.date,symbol:""})

   }
  console.log("finalObj.BloodSugarRandom", finalObj.BloodSugarRandom);
  
   if(finalObj.BloodSugarRandom.value!='NA'){
    this.adultLatestVisitBloodSugarArray.push({name:"Random",value:finalObj.BloodSugarRandom.value,date:finalObj.BloodSugarRandom.date,symbol:"mg/dL"})
    console.log("length",this.adultLatestVisitBloodSugarArray);
    
  }
  if(finalObj.Blood_Hba1c.value!='NA'){
    this.adultLatestVisitBloodSugarArray.push({name:"HbA1c",value:finalObj.Blood_Hba1c.value,date:finalObj.Blood_Hba1c.date,symbol:"%"})
  }
  if(finalObj.Bloodsugar_F.value!='NA' || finalObj.Bloodsugar_PP.value!='NA'){
    var sugarValue=finalObj.Bloodsugar_F.value+"/"+finalObj.Bloodsugar_PP.value

    this.adultLatestVisitBloodSugarArray.push({name:"FF/PP",value:sugarValue,date:finalObj.Bloodsugar_F.date,symbol:"mg/dL"})
  }
  console.log("adultLatestVisitBloodSugarArray", this.adultLatestVisitBloodSugarArray);
  

  if(finalObj.bpDia.value!= '-'|| finalObj.bpSys.value!= '-'){
    var BpValue=finalObj.bpDia.value+"/"+finalObj.bpSys.value
    this.adultLatestVisitBloodPressureArray.push({name:"Sys/Dia",value:BpValue,date:finalObj.bpSys.date,symbol:"mmHg"})
  }
  if(finalObj.Pulse.value!='NA'){
   
    this.adultLatestVisitBloodPressureArray.push({name:"Pulse",value:finalObj.Pulse.value,date:finalObj.Pulse.date,symbol:"/min"})
  }
  if(finalObj.Heartrate.value!='NA'){
   // console.log("plusHeartrate",this.adultLatestVisit.Heartrate)
    this.adultLatestVisitBloodPressureArray.push({name:"HR/min",value:finalObj.Heartrate.value,date:finalObj.Heartrate.date,symbol:"/min"})
  }


  if(finalObj.Chol_LDL.value!='NA'){
    this.adultLatestVisitLipidProfileArray.push({name:"Chol_LDL",value:finalObj.Chol_LDL.value,date:finalObj.Chol_LDL.date,symbol:"mg/dL"})
    }
    console.log("finalObj.Chol_HDL.value",finalObj.Chol_HDL.value);
    
    if(finalObj.Chol_HDL.value!='NA'){
      this.adultLatestVisitLipidProfileArray.push({name:"Chol_HDL",value:finalObj.Chol_HDL.value,date:finalObj.Chol_HDL.date,symbol:"mg/dL"})
   
      
      }
      if(finalObj.TotalCholesterol.value!='NA'){
        this.adultLatestVisitLipidProfileArray.push({name:"Total",value:finalObj.TotalCholesterol.value,date:finalObj.TotalCholesterol.date,symbol:"mg/dL"})
        }
      if(this.adultLatestVisitCholRatio!='NA'){
        console.log("12345 adultLatestVisitCholRatio",this.adultLatestVisitCholRatio);
        
        console.log("1234567 condition");
      //  commented by vitalsVisitId
        this.adultLatestVisitLipidProfileArray.push({name:"(LDL/HDL)",value:this.adultLatestVisitCholRatio,date:this.adultLatestVisitDate,symbol:"mg/dL"})
        console.log("1234567 this.adultLatestVisitCholRatio value in iff",this.adultLatestVisitCholRatio);
        
      }
      if(finalObj.Triglycerides_LFT.value!='NA'){
        this.adultLatestVisitLipidProfileArray.push({name:" Tri",value:finalObj.Triglycerides_LFT.value,date:finalObj.Triglycerides_LFT.date,symbol:"mg/dL"})
        }
        console.log("adultLatestVisitLipidProfileArray111",this.adultLatestVisitLipidProfileArray);
        
  
  } catch (e) {
  console.log('3333333',e)
  }
  }
  
  

}

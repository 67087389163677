

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { PatientService } from '../../../services/patient.service';
import { UtilService } from '../../../util/utilService';
import * as _ from "lodash"
import { MatDialog } from '@angular/material/dialog';
import * as moment from "moment";
import { refreshService } from '../../../services/refersh.service'
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { debounce } from 'lodash';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats, NativeDateAdapter } from '@angular/material/core';
interface patientInterface {
  id: number;
  formattedDOB: string;
  vaccinationStartDate: string;
  createdAt: string;

  User: {
    dob: string,
    gender: string
  }
}
export class AppDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: Object): string {
    if (displayFormat === 'input') {
      let day: string = date.getDate().toString();
      day = +day < 10 ? '0' + day : day;
      let month: string = (date.getMonth() + 1).toString();
      month = +month < 10 ? '0' + month : month;
      let year = date.getFullYear();
      return `${day}/${month}/${year}`;
    }
    return date.toDateString();
  }
}
export const APP_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'numeric' },
    dateA11yLabel: {
      year: 'numeric', month: 'long', day: 'numeric'
    },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  }
};

@Component({
  selector: 'patient-immunization',
  templateUrl: './immunization.component.html',
  styleUrls: ['./immunization.component.css'],
  providers: [ { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }],
})

export class ImmunizationComponent implements OnInit {
  confirmVaccDate: boolean = false
  selectedRowId = null
  vaccineBrandsOrg: any = []
  barndQuantity = null
  barndName = ""
  @Input() patientObj: patientInterface;
  @Input() doctorObj: any = [];
  @Input() specialVaccineInfo: any = {};
  @Input() specialSituationVaccines: any = [];
  @Input() splVaccFlag;
  patientVaccinationDate:any
  patientDoB:Date 

  @ViewChild("previewVaccineModal") previewVaccineModal;
  @ViewChild("specialVaccineModal") specialVaccineModal;
  @ViewChild("newSpecialVaccineModal") newSpecialVaccineModal;
  @ViewChild("bulkVaccineUpdate") bulkVaccineUpdate;
  @ViewChild("confirmVacStartDate") confirmVacStartDate;
  @ViewChild("dateFormat_modal") dateFormat_modal


  selectedVaccIndex: any = ""
  saveSpecialVaccButton = false;
  unformattedVaccineList: any = [];
  vaccineScheduleListWithVaccinedata: any = [];
  splVaccineName = "";
  displayedColumnsGOVT: string[] = ['visit', 'vaccine', 'receivedDate']
  displayedColumns: string[] = ['visit', 'vaccine', 'recommendedDate', 'catchupDate', 'status', 'receivedDate', 'brand', 'action'];
  selectedVacRecord: object = {};
  selectedVacVisit: any = {};

  selectedVacIndex = 0;
  selectedBrandIndex = null;

  selectedDate = "";
  receivedDate = "";
  dateError: boolean = false;
  selectedBrand: any = {};
  selectedVaccine: any = {};

  vaccineBrands = [];
  ageInYear: any = null
  patientInfoArray: any = [];
  patientMoreData: any = {};

  allAdministeredVaccines: any = [];
  flagForCertificateVacc: boolean = false
  maxDate = null;
  minDate = null;

  selectedVaccineLabels = [];
  selectedLanguage: any = "en"
  pendingVaccines = [];

  allTranscripts: any = [];
  transcriptIndex: number = 0;
  govtVaccineList: any = []
  constructor(private router: Router, private messageService: MessageService, private patientService: PatientService, private util: UtilService, public dialog: MatDialog, private refreshservice: refreshService,) { }

  ngOnInit(): void {
    let today = new Date()
    this.maxDate = this.util.reverseDate(today);


    this.getPatientInfoForVac();
    this.getPatientsVaccination();
    this.groupbyscheduleforasha()
console.log("patientMoreData",this.patientMoreData);

    if (this.splVaccFlag) {
      this.confirmVaccDate = true
      this.selectedVacIndex = 0;
      setTimeout(() => {
        this.openDialog(this.newSpecialVaccineModal, 'newSpecialVaccineModal')
      }, 500)

    }
    // setTimeout(() => {
    //   var elmnt = document.getElementById(this.selectedRowId);
    //   elmnt.scrollIntoView({behavior: 'smooth', block: 'center', inline : 'center'});

    // }, 1000);


  }
  groupbyscheduleforasha() {
    this.govtVaccineList = []

    this.patientService.groupbyscheduleforasha(this.patientObj.id? this.patientObj.id : this.patientMoreData._id).subscribe(response => {
      if (response) {
        // this.allVaccines = response;
        console.log("JUNgov30", response)
        this.govtVaccineList = response;
        for (let i = 0; i < this.govtVaccineList.length; i++) {
          if (this.isJson(this.govtVaccineList[i].name)) {
            let scheduleNames = JSON.parse(this.govtVaccineList[i].name);
            if (scheduleNames && scheduleNames.length > 0) {
              scheduleNames.forEach(langpart => {
                if (langpart.lang == 'en') {
                  this.govtVaccineList[i].name = langpart.text;
                }
              });
            }
          }
        }

        let vaccineArray = [];
        let newArray = [];

        let tempArr = [];

        console.log('ExperimentalJun30', this.govtVaccineList)

        this.govtVaccineList.map(ins => {

          ins.data.map((item, idx) => {

            if (!tempArr.includes(ins.name)) {
              tempArr.push(ins.name)
              item['vaccineVisit'] = ins.name;
            } else {
              item['vaccineVisit'] = "";
            }
            if (idx % 2 == 0) {
              item["indexValue"] = "even"
            }
            else {
              item["indexValue"] = "odd"
            }

            item['visitName'] = ins.vaccinationName
              ;
            item['index'] = idx;
            item['brandvaccine'] = item.brandvaccine ? item.brandvaccine : ""

            item['catchupDate'] = item.catchUpPeriod && item.catchUpPeriod != "" ? moment(item.dueDate).add(item.catchUpPeriod, 'w') : 'NA';
            item['catchupDate'] = item.catchupDate == 'NA' ? 'NA' : item.catchupDate.format('DD-MM-YYYY');

            if (moment(item.dueDate).isBefore(moment())) {
              item['isPending'] = true
            }
            vaccineArray.push(item);
          });
        });

        var govtVaccineList: any = []
        //  this.unformattedVaccineList = this.govtVaccineList;
        this.govtVaccineList = newArray.map((ins, index) => { return { name: ins.name, selected: false, id: index } });
        // this.alltestVac = newArray.map((ins, index) => { return { name: ins.name, selected: false, id: index } });
        govtVaccineList = vaccineArray;
        console.log("Govt", this.govtVaccineList)

        for (let i = 0; i < govtVaccineList.length; i++) {
          if (this.isJson(govtVaccineList[i].name)) {
            let scheduleNames = JSON.parse(govtVaccineList[i].name);
            if (scheduleNames && scheduleNames.length > 0) {
              scheduleNames.forEach(langpart => {
                if (langpart.lang == 'en') {
                  govtVaccineList[i].vaccinationName = langpart.text;
                }
              });
            }
          }
        }
        govtVaccineList.map(ins => {
          if (ins.administeredDate != null) {
            this.govtVaccineList.push(ins)
          }
        })

        this.govtVaccineList = govtVaccineList
        console.log("govVacccc", this.govtVaccineList)
      }

    })
  }
  getDob(){
    this.dateError=false
    var patientDoB= moment((this.patientMoreData.DOB)).format('DD/MM/YYYY')
    this.patientDoB=new Date(this.patientMoreData.DOB)
    console.log("DOB",this.patientDoB)
   // let vacDate = moment(this.patientMoreData.vaccinationStartDate).format('DD/MM/YYYY')
    this.patientVaccinationDate=new Date(this.patientMoreData.vaccinationStartDate)
    
  }
  getPatientInfoForVac() {
    var patientMoreData: any = JSON.parse(localStorage.getItem("patientMoreData"));
    console.log("may1555555", patientMoreData)
    var patientDoB= moment((patientMoreData.DOB)).format('DD/MM/YYYY')
    this.patientDoB=new Date(patientDoB)
    console.log("DOB",this.patientDoB)
    let vacDate = moment(patientMoreData.vaccinationStartDate).format('DD/MM/YYYY')
    this.patientVaccinationDate=new Date(vacDate)

    this.ageInYear = patientMoreData.age.years
    this.ageInYear = this.ageInYear + " " + "Year"
    console.log("AgeInYear", this.ageInYear)

    let formattedVacStartDate = moment(patientMoreData.vaccinationStartDate, 'YYYY-MM-DD').format('DD/MM/YYYY');
    patientMoreData['formattedVacStartDate'] = formattedVacStartDate;

    this.selectedLanguage = patientMoreData.parent.selectedLanguage ? patientMoreData.parent.selectedLanguage : "en";

    let additionalInfo: any = patientMoreData.additionalInfo ? JSON.parse(patientMoreData.additionalInfo) : null;

    setTimeout(() => {
      if (additionalInfo?.specialVaccination) {

        additionalInfo.specialVaccination.map((ins, ind) => {
          ins['visitName'] = "Special Vaccines";
          if (ind == 0) {
            ins['vaccineVisit'] = "Special Vaccines";
            ins['index'] = ind
          } else {
            ins['index'] = ind
          }

          if (this.specialVaccineInfo[ins.name]) {
            ins['description'] = this.specialVaccineInfo[ins.name]
          }
        });

        let specialVaccines = { name: "Special Vaccines", data: additionalInfo.specialVaccination }
        this.unformattedVaccineList.push(specialVaccines);
        this.vaccineScheduleListWithVaccinedata = [...additionalInfo.specialVaccination, ...this.vaccineScheduleListWithVaccinedata];

        console.log("Vaccines", this.vaccineScheduleListWithVaccinedata)
      } else {
        let additionalInfo: any = {};
        additionalInfo['specialVaccination'] = [
          {
            administeredDate: "",
            administeredStatus: null,
            name: "",
            vaccineVisit: "Special Vaccines",
            visitName: "Special Vaccines"
          }
        ];
        // this.vaccineList = [...additionalInfo.specialVaccination, ...this.vaccineList]
      }
    }, 500);

    let patInformationArray = [];

    let birth = {
      key: 'Birth Date',
      value: moment(patientMoreData.formattedDOB, 'DD/MM/YYYY').format('DD/MM/YY')
    };

    this.minDate = this.util.reverseDate(moment(patientMoreData.formattedDOB, 'DD/MM/YYYY').format('MM/DD/YY'));
    console.log('MINDATE', this.minDate)

    let vacStartDate = {
      key: 'Vac Start Date',
      value: moment(patientMoreData.vaccinationStartDate).format('DD/MM/YY')
    }

    let joiningDate = {
      key: 'Joining Date',
      value: moment(patientMoreData.createdAt).format('DD/MM/YY')
    }

    var y = moment(patientMoreData.formattedDOB, 'DD/MM/YYYY')
    var x = moment(patientMoreData.createdAt)

    let ageObj = moment.duration(x.diff(y))
    let ageAtJoining = {
      key: 'Age at Joining',
      value: `${Math.floor(ageObj.years())}y ${Math.floor(ageObj.months())}m ${Math.floor(ageObj.days())}d`
    }

    patInformationArray.push(birth, vacStartDate, joiningDate, ageAtJoining);
    this.patientInfoArray = patInformationArray;
    console.log("AUGGG30",this.patientInfoArray)
    this.patientMoreData = patientMoreData;
  }

  getPatientsVaccination = () => {
    this.vaccineScheduleListWithVaccinedata = []
    let patientMoreData: any = JSON.parse(localStorage.getItem("patientMoreData"));
    console.log("Patient More Data", patientMoreData)
    let joiningDate = moment(patientMoreData.createdAt).format('DD MMM, YYYY')

    this.patientService.getPatientsVaccination(this.patientObj.id? this.patientObj.id : this.patientMoreData._id).subscribe(response => {
      if (response) {
        this.vaccineScheduleListWithVaccinedata = response;
        console.log("maayddddd", this.vaccineScheduleListWithVaccinedata)
        for (let i = 0; i < this.vaccineScheduleListWithVaccinedata.length; i++) {
          if (this.isJson(this.vaccineScheduleListWithVaccinedata[i].name)) {
            let scheduleNames = JSON.parse(this.vaccineScheduleListWithVaccinedata[i].name);

            console.log('Schedule Names', scheduleNames)

            if (scheduleNames && scheduleNames.length > 0) {

              scheduleNames.forEach(langpart => {
                if (langpart.lang == 'en') {
                  this.vaccineScheduleListWithVaccinedata[i].name = langpart.text;
                }
              });
            }
          }
        }

        let vaccineArray = [];
        let newArray = [];
        let tempArr = [];
        let administeredVaccines = [];
        let pendingVaccines = []


        this.vaccineScheduleListWithVaccinedata.map((ins, index) => {
          ins.data.map((item, idx) => {
            if (!tempArr.includes(ins.name)) {
              tempArr.push(ins.name)
              item['vaccineVisit'] = ins.name;
            } else {
              item['vaccineVisit'] = "";
            }
            if (index % 2 == 0) {
              item["indexValue"] = "even"
            }
            else {
              item["indexValue"] = "odd"
            }

            item['visitName'] = ins.name;
            item['index'] = idx;
            item['catchupDate'] = item.catchUpPeriod && item.catchUpPeriod != "" && item.dueDate && item.dueDate != "" ? moment(item.dueDate).add(item.catchUpPeriod, 'w') : 'NA';
            item['catchupDate'] = item.catchupDate == 'NA' ? 'NA' : item.catchupDate.format('DD-MM-YYYY');

            if (moment(item.dueDate).isBefore(joiningDate)) {
              item['beforeJoining'] = true;
            }
            //overdue logic
            if (item.catchupDate && item.catchupDate != "NA") {
              var todayDate: any = moment().format('YYYY-MM-DD');
              var pastDate: any = moment(item.catchupDate, 'DD-MM-YYYY').format('YYYY-MM-DD');

              var bool = moment(todayDate).isAfter(moment(pastDate))
              if (bool && !item.administeredStatus) {
                item['isOverdue'] = true;
              }
            }

            //all administeredVaccines
            if (item.administeredStatus) {
              administeredVaccines.push(item);
            }

            if (moment(item.dueDate).isBefore(moment())) {
              item['isPending'] = true;

              if (!item.administeredStatus) {
                pendingVaccines.push(item);
              }

            }
         vaccineArray.push(item);
          });

        });

        this.unformattedVaccineList = this.vaccineScheduleListWithVaccinedata;
        // this.vaccineList = newArray.map((ins, index) => { return { name: ins.name, selected: false, id: index } });
        this.vaccineScheduleListWithVaccinedata = vaccineArray;
        this.pendingVaccines = pendingVaccines;
        this.allAdministeredVaccines = administeredVaccines;

        // if(this.vaccineScheduleListWithVaccinedata.some(itr=>itr.administeredStatus)){}
        let atleastOneVacGiven = this.vaccineScheduleListWithVaccinedata.some(itr => {
          return itr.administeredStatus;
        });

        console.log('patientmoredata', patientMoreData)
        if (!atleastOneVacGiven && patientMoreData.formattedDOB.includes("01/01") && patientMoreData.age.years <= 10) {
          if (this.confirmVaccDate == false) {
            setTimeout(() => {
              this.dialog.open(this.confirmVacStartDate, {});
            }, 500);
          }
        }

        //this.confirmVacStartDate=false


        this.vaccineScheduleListWithVaccinedata.map((ins, idx) => {
          ins["idx"] = idx
        })
        this.vaccineScheduleListWithVaccinedata.map(ele => {
          if (ele.vaccineVisit.includes(this.ageInYear)) {
            this.selectedRowId = ele.idx
          }

        })
        console.log(this.selectedRowId)
        console.log('Vaccine List', this.vaccineScheduleListWithVaccinedata)
      }
    });
  }

  isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }


 

  openNewSpecialVaccine = () => {
    this.confirmVaccDate = true
    this.selectedVacIndex = 0;
    this.openDialog(this.newSpecialVaccineModal, 'newSpecialVaccineModal')
  }

  openBulkVaccineUpdate = () => {
    let allTranscripts = localStorage.getItem("allTranscripts");
    if (allTranscripts) {
      allTranscripts = JSON.parse(localStorage.getItem("allTranscripts"));
    }

    this.allTranscripts = allTranscripts;

    console.log('ALL TRANSCRIPTS', allTranscripts)

    this.openDialog(this.bulkVaccineUpdate, 'bulkVaccineUpdate');
  }

  changeTranscriptIndex(type) {
    let ind = this.transcriptIndex;

    if (type == 1) {

      if (ind >= this.allTranscripts.length - 1) {
        this.transcriptIndex = ind - 1
      }

    } else {

      if (ind < this.allTranscripts.length - 1) {
        this.transcriptIndex = ind + 1
      }

    }
  }

  closeDialog() {
    this.selectedDate = "";
    this.receivedDate = "";
    this.selectedBrand = {};
    this.selectedBrandIndex = null;
    this.selectedVaccIndex = null
    this.selectedVacVisit = {}
    this.dialog.closeAll()
  }

  getBrandsByVaccineId = () => {
    let vaccineIds = [];
    this.selectedVacVisit.data.map(ins => {
      if (!ins.administeredStatus) {
        vaccineIds.push(ins.id)
      }
    })

    let postData = {
      vaccineIds: vaccineIds
    }

    this.patientService.getBrandsByVaccineId(postData).subscribe(response => {
      if (response) {
        this.vaccineBrandsOrg = response;

        this.selectVaccine(0, this.selectedVaccine);
      }
    });
  }

  navigateToCertificate() {
    localStorage.setItem("isForImmunization", "true")
    this.refreshservice.OnPatTopNav_MenuSelected(this.patientObj.id + "", this.refreshservice.EventName_Certificate);
  }

  uploadBulkVaccination = () => {

    let dueVaccineArray = this.pendingVaccines;
    let selectedVacArray = []

    dueVaccineArray.forEach(item => {
      let newObj = {}
      if (item.selected) {
        newObj = {
          "patVacId": item.patVacId,
          "dueDate": item.dueDate,
          "patientId": this.patientObj.id? this.patientObj.id : this.patientMoreData._id,
          "brand": ""
        }

        selectedVacArray.push(newObj);
      }
    });

    if (selectedVacArray.length) {

      console.log('Finally Array', selectedVacArray);

      this.patientService.uploadBulkVaccination(selectedVacArray).subscribe(response => {
        console.log('RESPONSE VACCINE', response)
        if (response) {

          setTimeout(() => {
            // this.getPatientInfoForVac();
            this.getPatientsVaccination();
          }, 500);

          this.closeDialog();
        }
      })

    } else {
      alert("Please select vaccination records to be administered");

    }

  }

  markVaccineAsTaken = debounce(() => {
    let id = this.selectedVaccine.id
    let name = this.selectedVaccine.name
    let description = this.selectedVaccine.description
    let due = this.selectedVaccine.due
    let dueDate = this.selectedVaccine.dueDate

    let postData = {
      "id": id,
      "name": name,
      "administeredDate": moment(this.util.parseDate(this.receivedDate)).format('DD-MMM-YYYY'),
      "administeredStatus": true,
      "description": description,
      "due": due,
      "dueDate": dueDate,
      "imageUrl": "",
      "notes": "",
      "comboVaccineId": this.selectedBrand.id,
    };

    // console.log("Debounce working..")

    this.patientService.markVaccineAsTaken(postData, this.patientObj.id? this.patientObj.id : this.patientMoreData._id).subscribe(response => {
      console.log('RESPONSE VACCINE', response)
      if (response) {
        this.barndQuantity = response.reducedInventory.quantity
        this.barndName = response.reducedInventory.brandName

        this.messageService.add({ severity: 'custom', detail: this.barndName + ":" + this.barndQuantity + " " + "Remaining" });


        setTimeout(() => {
          this.getPatientInfoForVac();
          this.getPatientsVaccination();
        }, 500);

        this.closeDialog();

      }
    })
  }, 500)

  setSelectedSpecialVaccinationRecord = (element) => {
    setTimeout(() => {
      this.openDialog(this.specialVaccineModal, 'specialVaccineModal');
    }, 200);

    this.selectedVaccine = element;

    loopRecord: for (let index = 0; index < this.unformattedVaccineList.length; index++) {
      const visit = this.unformattedVaccineList[index];
      if (element.visitName == visit.name) {
        this.selectedVacVisit = visit;
        this.selectedVacIndex = element.index;
        this.selectedVaccIndex = element.index


        this.selectedDate = this.selectedVacVisit.data[this.selectedVacIndex].administeredDate;
        this.selectedVaccine = this.selectedVacVisit.data[this.selectedVacIndex]
        break loopRecord;
      }
    }
  }

  setSelectedVaccinationRecord = (element) => {
    // aaleen
    this.selectedVacIndex = null

    setTimeout(() => {
      this.openDialog(this.previewVaccineModal, 'previewVaccineModal');
    }, 500);
    this.selectedVacRecord = element;

    loopRecord: for (let index = 0; index < this.unformattedVaccineList.length; index++) {
      const visit = this.unformattedVaccineList[index];
      if (element.visitName == visit.name) {
        this.selectedVacVisit = visit;
        this.selectedVacIndex = element.index;
        //  this.selectedVaccIndex = element.index
        // this.selectedVacIndex = element.idx;
        this.selectedDate = this.selectedVacVisit.data[this.selectedVacIndex].administeredDate;
        this.selectedVaccine = this.selectedVacVisit.data[this.selectedVacIndex];
        this.getBrandsByVaccineId();
        break loopRecord;
      }
    }
    let vaccineLabels = []
    this.selectedVacVisit.data.map(ins => {
      if (ins.imageUrl !== 'https://copious-opd-prod.s3.amazonaws.com/images/vaccines/' && ins.imageUrl !== 'https://copious-opd-prod.s3.amazonaws.com/images/vaccines/null') {
        vaccineLabels.push(ins)
      }
    });

    let newData = _.uniqBy(vaccineLabels, function (e) {
      return e.imageUrl;
    });

    this.selectedVaccineLabels = newData;

  }

  selectVaccine = (idx, vaccine) => {
    //aaleen
    var vaccBarnd: any = []
    this.vaccineBrands = []
    this.selectedVaccine = vaccine;
    this.selectedVaccIndex = vaccine.index
    if (vaccine.index) {
      this.selectedVacIndex = vaccine.index;
    }
    else {
      this.selectedVacIndex = idx;
    }

    this.selectedDate = this.selectedVacVisit.data[idx].administeredDate;



    //logic for highlighting vaccines on the basis of BRANDS
    let brands = this.vaccineBrandsOrg;

    brands.map(item => {
      var vaccines = item.mastervaccineids;
      var vaccinesArray = vaccines.split(',');
      let newArr = [];

      vaccinesArray.map(ins => {
        if (ins != '') {
          newArr.push(Number(ins))
        }
      });

      item['highlighted'] = false;
      vaccinesArray.map(ins => {
        if (ins != "" && ins == this.selectedVaccine.id) {
          item['highlighted'] = true
        }
      })

    });

    this.vaccineBrandsOrg = brands;
    this.vaccineBrandsOrg.map(ins => {
      if (ins.highlighted) {
        vaccBarnd.push(ins)
      }
    })
    console.log(vaccBarnd)
    this.vaccineBrands = vaccBarnd
  }

  selectPendingVaccine = (idx, vaccine) => {
    //aaleen
    let pendingVaccines = this.pendingVaccines;
    if (!pendingVaccines[idx].selected) {
      pendingVaccines[idx].selected = true;
    } else {
      pendingVaccines[idx].selected = false;
    }
  }

  openVaccineInventory() {
    this.router.navigate([`/doctor/vaccineInventory`])
  }

  selectBrand = (idx, brand) => {
    this.selectedBrandIndex = idx;
    this.selectedBrand = brand;


    //logic for highlighting vaccines on the basis of BRANDS
    let pendingVaccines = this.selectedVacVisit.data;
    if (!brand.due) {
      var selectedVaccines = brand.mastervaccineids;
      var arr = selectedVaccines.split(',');
      let coversArray = []
      let covers = false;
      arr.map(ins => {
        if (ins != "") {
          coversArray.push({ id: ins, covers: false })
        }
      })

      let selected = -1;

      loop1: for (let i = 0; i < pendingVaccines.length; i++) {
        loop2: for (let j = 0; j < coversArray.length; j++) {
          if (pendingVaccines[i].id == coversArray[j].id && selected != idx) {
            pendingVaccines[i]['covers'] = true;
            covers = true;
            continue loop1;
          } else {
            pendingVaccines[i]['covers'] = false;
          }
        }
      }

      this.selectedVacVisit.data = pendingVaccines;
      // this.setState({
      // selected = selected == idx ? -1 : idx;
      // let selectedComboVaccineId = brand.id;
    }
  }

  saveVaccineRecord = () => {
    const params = {
      patientId: this.patientObj.id? this.patientObj.id : this.patientMoreData._id,
    };
  }

  saveSpecialVaccine = () => {
    this.saveSpecialVaccButton= true;
    let postData = this.patientMoreData;
    let parsed = null
    if (postData.additionalInfo == "") {
      parsed = null
    }
    else {
      parsed = JSON.parse(postData.additionalInfo);
    }


    let specialVacObj = {
      name: this.specialSituationVaccines[this.selectedVacIndex].name == "Other" ? this.splVaccineName : this.specialSituationVaccines[this.selectedVacIndex].name,
      administeredDate: moment(this.util.parseDate(this.receivedDate)).format('DD-MMM-YYYY'),
      administeredStatus: true
    }

    if (parsed) {
      if (parsed.specialVaccination && parsed.specialVaccination.length) {

        parsed.specialVaccination.push(specialVacObj)
      } else {
        parsed['specialVaccination'] = [specialVacObj]
      }
    } else {
      parsed = {};
      parsed['specialVaccination'] = [specialVacObj]
    }
    if(parsed.specialVaccination){
      this.patientMoreData['addtionalInfo']=parsed.specialVaccination +parsed
    }

    postData.additionalInfo = JSON.stringify(parsed);
    postData.visit = postData.visit ? postData.visit : [];
    this.patientMoreData['additionalInfo'] = JSON.stringify(parsed)

    const data = {
      HealthHistoryAdult: "HealthHistoryAdult",
      blood_group: this.patientMoreData.blood_group,
      additionalInfo: JSON.stringify(parsed)
      // patient: postData,
      // patientCreatedAt: this.patientMoreData.createdAt
    };

    this.patientService.patientpartialupdate(data, this.patientMoreData._id).subscribe(response => {
      if (response) {
        this.saveSpecialVaccButton = false;
        console.log("saveSpecialVaccButtonsaveSpecialVaccButton");
        
        setTimeout(() => {
          this.getPatientInfoForVac();
          this.getPatientsVaccination();
        }, 500);

        localStorage.setItem("patientMoreData", JSON.stringify(this.patientMoreData));


        this.closeDialog();
        // this.getPatientsVaccination();
        //   this.getPatientInfoForVac();





      }

    })
  }
  parseDate(dateObject) {
    if (dateObject?.year) {
      return `${dateObject.year}-${dateObject.month}-${dateObject.day}`;
    } else {
      return `${dateObject}`
    }
  }
  formatDate(event: any) {
    const datePattern = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;

    // Check if the input matches the date pattern
    if (event.match(datePattern)) {
      this.patientDoB = event; // Save formatted date
      this.dateError = false;
    } else {
      this.dateError = true; // Set error if format is incorrect
    }
  }

  formatVaccinationDate(event: any) {
    console.log(" laxus formatVaccinationDate fired");

    const parsedDate = moment(event, 'DD/MM/YYYY', true);
    if (parsedDate.isValid()) {
      this.patientVaccinationDate = parsedDate.toDate();

      // Log in DD/MM/YYYY to confirm format
      console.log("laxus Formatted patientVaccinationDate:", parsedDate.format('DD/MM/YYYY'));
    }
  }
  openDialog(content, index) {
   
    this.dialog.open(content);
  }
  resetVaccination(){
    console.log("docUserIdAug27",this.patientMoreData.doctors[0].userId)
    var patInformationArray=[]
 
    var postData={
      dob: moment(this.parseDate(this.patientDoB)).format("YYYY-MM-DD"),
      vaccinationStartDate:moment(this.parseDate(this.patientVaccinationDate)).format("YYYY-MM-DD"),
      patientid:this.patientMoreData._id,
      docUserId: this.patientMoreData.doctors[0].userId
    }
    console.log("patientresetVaccinationSchedule postdata",postData);
    
   this.patientService.patientresetVaccinationSchedule(postData).subscribe(response => {
    if(response){
      this.dateError=false
      console.log(response)
      this.closeDialog();
      this.getPatientInfoForVac();
      this.getPatientsVaccination();
      this.groupbyscheduleforasha()
      console.log("NOV66666",this.patientMoreData)

      let birth = {
        key: 'Birth Date',
        value: moment(this.parseDate(postData.dob)).format("DD/MM/YYYY")
      };
      let vacStartDate = {
        key: 'Vac Start Date',
        value: moment(this.parseDate(postData.vaccinationStartDate)).format("DD/MM/YYYY")
      }

      let joiningDate = {
        key: 'Joining Date',
        value: moment(this.patientMoreData.createdAt).format('DD/MM/YY')
      }
  
      var y = moment(this.patientMoreData.formattedDOB, 'DD/MM/YYYY')
      var x = moment(this.patientMoreData.createdAt)
  
      let ageObj = moment.duration(x.diff(y))
      let ageAtJoining = {
        key: 'Age at Joining',
        value: `${Math.floor(ageObj.years())}y ${Math.floor(ageObj.months())}m ${Math.floor(ageObj.days())}d`
      }
   
      patInformationArray.push(birth, vacStartDate, joiningDate, ageAtJoining);
    
      // patInformationArray.push(birth, vacStartDate, joiningDate, ageAtJoining);
       this.patientInfoArray = patInformationArray;
       this.patientMoreData = {
        ...this.patientMoreData,
        formattedVacStartDate:postData.vaccinationStartDate,
        formattedDOB: postData.dob
      };


      console.log("AUGGG30",this.patientInfoArray)
      // console.log("patientMoreData",this.patientMoreData);
      // this.refreshservice.immunisationRefreshed();
    }
   })     
  

  }
}


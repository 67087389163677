import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs/Observable'
import { map } from 'rxjs/operators/map'
import { Router } from '@angular/router'
import { Constants } from "../../config/constants";
import { BehaviorSubject } from 'rxjs'
import { shareReplay } from 'rxjs/operators'

@Injectable()
export class PatientService {
    // for getPatientInfo 
    public cachedResponse$: Observable<any>;
    public previousReqVars: string | null = null;
    private patientInfoSubject = new BehaviorSubject<any>(null);
    public patientInfo$ = this.patientInfoSubject.asObservable();
    // for prescriptionMedicines
    private prescriptionMedicinesSubject = new BehaviorSubject<any>(null);
    public prescriptionMedicines$ = this.prescriptionMedicinesSubject.asObservable();


    constructor(private http: HttpClient, private router: Router) {
        if (Constants.APICONFIG.HOST == 'localhost') {
            this.APIURL = 'http://localhost:3000/v1';
            this.PIVOTALAPIURL = 'http://localhost:3200/api';
        }
    }

    APIURL = `${Constants.APICONFIG.PROTOCOL}://${Constants.APICONFIG.HOST}:${Constants.APICONFIG.PORT}/v1`
    PIVOTALAPIURL = `${Constants.APICONFIG.PROTOCOL}://${Constants.APICONFIG.HOST}:${Constants.APICONFIG.PORT}/api`;


    getPrescriptionMedicines(patientId: string, selectFieldsArray: any): Observable<any> {
        const obj = {
          "patientId": patientId,
          "selectfield": selectFieldsArray
        };
        return this.http.post(`${this.APIURL}/getPrescriptions`, obj);
      }

    //function to validate patient details
    public validate(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/validatePatient`, req_vars)
    }

    //function to create patient
    public create(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/createPatient`, req_vars)
    }
    //function to get authorized patients for doctor
    public getAuthorizedPatients(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/getAuthorizedPatients`, req_vars)
    }

    //function to get patients basic info
    public getBasicInfo(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/getBasicInfo`, req_vars)
    }
    public updateBasicInfo(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/updatePatient`, req_vars)
    }

    //function to get all patients in system
    public getAllPatients(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/getAllPatientsList`, req_vars)
    }

    //function to search patient
    public searchPatients(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/searchPatientsList`, req_vars)
    }

    public getPatientsByFullNumberSearch(req_vars): Observable<any> {
        return this.http.get(`${this.PIVOTALAPIURL}/doctor/getAllPatients/` + req_vars)
    };

    //function to search patient
    public authorizePatient(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/authorizePatient`, req_vars)
    }

    //function to update past history
    public updatePasthistory(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/updatePasthistory`, req_vars)
    }

    //funtion to update vitals of patientDetails
    public updateVisit(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/updateVisit`, req_vars)
    }

    //funtion to get all cases and visits of  User
    public getCasesAndVisits(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/getCasesAndVisits`, req_vars)
    }
    //funtion to create new case and visit
    public createCase(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/createCase`, req_vars)
    }
    //funtion to create new visit under one case
    public createVisit(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/createVisit`, req_vars)
    }
    //funtion to create new visit under one case
    public getVisitDetails(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/getVisitDetails`, req_vars)
    }

    //funtion to get visits of patients
    public getVisits(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/getVisits`, req_vars)
    }

    //funtion to get visits of patients
    public getPastVisits(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/getPastVisits`, req_vars)
    }


    //funtion to get visits of patients
    public getPatientVisits(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/getPatientVisits`, req_vars)
    }

    //funtion to update cmplaints of patient
    public updateComplaints(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/updateComplaints`, req_vars)
    }

    //funtion to get complaints details for view complaints.
    public getComplaintDetails(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/getComplaintDetails`, req_vars)
    }
    //funtion to create new visit under one case
    public createInvestigation(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/createInvestigation`, req_vars)
    }

    public getInvestigations(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/getInvestigations`, req_vars)
    }

    public createPrescription(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/createPrescription`, req_vars)
    }

    public getPrescriptions(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/getPrescriptions`, req_vars)
        
    }


    public printPrescription(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/printPrescription`, req_vars)
    }
    public printNewCertificate(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/printNewCertificate`, req_vars)
    }

    public printChart(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/printChart`, req_vars, {
            responseType: "blob",
            headers: new HttpHeaders().append("Content-Type", "application/json")
        })
    }

    public printCertificate(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/printCertificate`, req_vars, {
            // responseType: "blob",
            // headers: new HttpHeaders().append("Content-Type", "application/json")
        })
    }


    public getComplaint(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/getComplaint`, req_vars)
    }
    public getComplaintsOfDoctor(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/getComplaintsOfDoctor`, req_vars)
    }
    public getExaminationOfDoctorAsPerDiagnosis(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/getExaminationOfDoctorAsPerDiagnosis`, req_vars)
    }
    public getDiagnosisOfDoctorAsPerComplaints(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/getDignosisOfDoctorAsPerComplaints`, req_vars)
    }
    public getGeneralInstructionsOfDoctor(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/getGeneralInstructionsOfDoctor`, req_vars)
    }
    public createNewComplaintOfDoctor(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/createNewComplaintOfDoctor `, req_vars)
    }
    public createNewExaminationOfDoctor(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/createNewExaminationOfDoctor `, req_vars)
    }
    public createGeneralInstructionsOfDoctor(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/createGeneralInstructionsOfDoctor `, req_vars)
    }
    public createNewDiagnosisOfDoctor(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/createNewDiagnosisOfDoctor `, req_vars)
    }
    public createNewDietAdviceOfDoctor(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/createNewDietAdviceOfDoctor`, req_vars)
    }
    public getDietAdviceOfDoctorAsPerDiagnosis(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/getDietAdviceOfDoctorAsPerDiagnosis`, req_vars)
    }

    public getMedicinesOfDoctorAsPerDiagnosis(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/getMedicinesOfDoctorAsPerDiagnosis`, req_vars)
    }

    public checkIfAuthorized(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/checkIfAuthorzied`, req_vars)
    }

    public checkIfEditable(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/checkIfEditable`, req_vars)
    }

    public growthNutrition(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/growthNutrition`, req_vars)
    }


    public scanBarcode(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/scanBarcode`, req_vars)
    }

    public getTakenVaccines(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/getTakenVaccines`, req_vars)
    }

    public getPendingVaccines(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/getPendingVaccines`, req_vars)
    }

    public getVaccines(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/getVaccines`, req_vars)
    }

    public bodyPercentiles(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/bodyPercentiles`, req_vars)
    }

    public scheduleNextAppointment(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/scheduleNextAppointment`, req_vars)
    }

    public getNextAppointment(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/getNextAppointment`, req_vars)
    }

    public getPendingAmount(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/getPendingAmount`, req_vars)
    }

    public getDueVaccine(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/getDueVaccine`, req_vars)
    }

    public getBPData(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/getBPData`, req_vars)
    }

    public getAgeSpecificData(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/getAgeSpecificData`, req_vars)
    }

    public getWellBeingInfo(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/getWellBeingInfo`, req_vars)
    }

    public getBirthdayUsers(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/getBirthdayUsers`, req_vars)
    }

    public sendWishes(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/sendWishes`, req_vars)
    }

    public getLabs(req_vars): Observable<any> {
        return this.http.post(`${this.APIURL}/labs`, req_vars)
    }

    public getUtilityDetails(req_vars): Observable<any> {
        return this.http.post(`${this.APIURL}/getUtilityDetails`, req_vars)
    }

    public uploadDischargeNote(req_vars): Observable<any> {
        return this.http.post(`${this.APIURL}/uploadDischargeNote`, req_vars)
    }

    public saveAdditionalInfo(req_vars): Observable<any> {
        return this.http.post(`${this.APIURL}/saveAdditionalInfo`, req_vars)
    }

    public updatePastHistoryAdditionalInfo(req_vars): Observable<any> {
        return this.http.post(`${this.APIURL}/updatePastHistoryAdditionalInfo`, req_vars)
    }

    public addNewPatient(req_vars): Observable<any> {
        return this.http.post(`${this.PIVOTALAPIURL}/doctor/patients`, { data: req_vars })
    }
    public sentParentOTP(req_vars): Observable<any> {
        return this.http.post(`${this.PIVOTALAPIURL}/parent/verify`, { data: req_vars })
    }
    public sendSmsOfAppInvite(req_vars): Observable<any> {
        return this.http.post(`${this.PIVOTALAPIURL}/v2/sendSmsOfAppInvite`, { data: req_vars });
    }
    public saveLinkDoctorAndPatient(req_vars): Observable<any> {
        // const headers = new HttpHeaders({
        //     'content-type' : 'application/json',
        //     'Access-Control-Allow-Origin' : '*'
        //   });
        return this.http.post(`${this.PIVOTALAPIURL}/v2/saveLinkDoctorAndPatient`, { data: req_vars });
    }
    public deletePatient(req_vars): Observable<any> {
        const headers = new HttpHeaders({
            'content-type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        });
        return this.http.post(`${this.PIVOTALAPIURL}/v2/deletePatient`, { data: req_vars }, { headers, responseType: 'text' });
        // return this.http.post(`${this.PIVOTALAPIURL}/v2/deletePatient`,{ data: req_vars });
    }
    public onResendMessage(req_vars): Observable<any> {
        return this.http.post(`${this.PIVOTALAPIURL}/parent/otp`, { data: req_vars })
    }

    public sendImageProfilePic(req_vars): Observable<any> {
        return this.http.post(`${this.PIVOTALAPIURL}/patient/upload/profilePic`, { data: req_vars })
    }
    public getPatientInfo(req_vars: string): Observable<any> {
        // Reset cache if req_vars changes
        if (req_vars !== this.previousReqVars) {
          this.cachedResponse$ = null;
          this.previousReqVars = req_vars;
        }
    
        if (!this.cachedResponse$) {
          this.cachedResponse$ = this.http.get(`${this.PIVOTALAPIURL}/patient/` + req_vars).pipe(
            shareReplay(1) // Cache the response and replay it to new subscribers
          );
        }
    
        return this.cachedResponse$;
      }

    // public getPatientInfo(req_vars): Observable<any> {
    //     return this.http.get(`${this.PIVOTALAPIURL}/patient/` + req_vars)
    // };
    public updatePatientInfo(req_vars, id): Observable<any> {
        return this.http.put(`${this.PIVOTALAPIURL}/patient/updatePatientByDoctor/` + id, { data: req_vars })
    };
    public patientpartialupdate(req_vars, id): Observable<any> {
        return this.http.post(`${this.PIVOTALAPIURL}/patient/patientpartialupdate/` + id, { data: req_vars })
    };
    // public getAllRequiredConstants(): Observable<any> {
    //     return this.http.get(`${this.APIURL}/getAllReuiredConstants`)
    // }
    public getAllRequiredConstants(translationlang: any): Observable<any> {
        if (!translationlang && translationlang == '') { translationlang = 'en'; }
        return this.http.get(`${this.APIURL}/getAllReuiredConstants`, { params: { lang: translationlang } })
    }
    public getFavApptsOfDoctor(): Observable<any> {
        return this.http.get(`${this.APIURL}/getFavApptsOfDoctor`)
    }

    public getDrugs(req_vars): Observable<any> {
        return this.http.post(`${this.APIURL}/getDrugs`, req_vars)
    }

    public patientresetVaccinationSchedule(req_vars): Observable<any> {
        return this.http.post(`${this.APIURL}/patientresetVaccinationSchedule`, req_vars)
    }
    public updateVaccinationRecord(req_vars): Observable<any> {
        return this.http.post(`${this.APIURL}/updateVaccinationRecord`, req_vars)
    }

    public deletePopularDrugId(req_vars): Observable<any> {
        return this.http.post(`${this.APIURL}/deletePopularDrugId`, req_vars)
    }

    public addNewDrug(req_vars): Observable<any> {
        return this.http.post(`${this.APIURL}/addNewDrug`, req_vars)
    }



    public getAppointmentsByPatientId(req_vars): Observable<any> {
        return this.http.post(`${this.APIURL}/getAppointmentsByPatientId`, req_vars)
    }

    public printPrescriptionVersion2(req_vars): Observable<any> {
        return this.http.post(`${this.APIURL}/printPrescriptionVersion2`, req_vars)
    }

    public sharePrescriptionWithPharmacy(req_vars): Observable<any> {
        return this.http.post(`${this.PIVOTALAPIURL}/v3/sharePrescriptionWithPharmacy`, { data: req_vars })
    }

    public updatePatientMoreInfo(req_vars, id): Observable<any> {
        return this.http.post(`${this.PIVOTALAPIURL}/v2/patientEditMoreInfo/` + id, { data: req_vars })
    };
    public savePatientVisit(req_vars, id): Observable<any> {
        return this.http.post(`${this.PIVOTALAPIURL}/patient/${id}/visits`, { data: req_vars })
    };
    public calculateMinMax(req_vars, id): Observable<any> {
        return this.http.post(`${this.PIVOTALAPIURL}/patient/${id}/percentile`, { data: req_vars })
    };
    public getFavLabs(req_vars): Observable<any> {
        return this.http.post(`${this.PIVOTALAPIURL}/v3/getFavLabsOfDoctor`, { data: req_vars })
    };
    public findPatientsByNameDOBNumber(req_vars): Observable<any> {
        return this.http.post(`${this.PIVOTALAPIURL}/v3/findPatientsByNameDOBNumber`, { data: req_vars })
    };
    public uploadPatientDocuments(req_vars): Observable<any> {

        return this.http.post(`${this.PIVOTALAPIURL}/v3/uploadPatientDocuments`, { data: req_vars }, {
            headers: new HttpHeaders()
        }
        )

    };
    public getPatientsVisitInfo(req_vars): Observable<any> {
        return this.http.get(`${this.PIVOTALAPIURL}/patient/${req_vars}/visits`)
    };
    public getPatientsWeightGraph(patId, visitId, req_vars): Observable<any> {
        return this.http.post(`${this.PIVOTALAPIURL}/patient/${patId}/visits/${visitId}/graph`, { data: req_vars })
    };
    public getPatientsTargetHeightGraph(patId, visitId, req_vars): Observable<any> {
        return this.http.post(`${this.PIVOTALAPIURL}/patient/${patId}/visits/${visitId}/graph/targetHeight`, { data: req_vars })
    };
    public getPatientsHeightToWeightGraph(patId, visitId, req_vars): Observable<any> {
        return this.http.post(`${this.PIVOTALAPIURL}/patient/${patId}/visits/${visitId}/graph/heightToWeight`, { data: req_vars })
    };
    public getPatientsVaccination(req_vars): Observable<any> {
        return this.http.get(`${this.PIVOTALAPIURL}/v2/vaccines/groupbyschedule/${req_vars}`)
    };
    public groupbyscheduleforasha(req_vars): Observable<any> {
        return this.http.get(`${this.PIVOTALAPIURL}/v2/vaccines/groupbyscheduleforasha/${req_vars}`)
    };
    public createAppointmentForNewNote(req_vars): Observable<any> {
        return this.http.post(`${this.APIURL}/createAppointmentForNewNote`, req_vars)
    }
    public printReceipt(req_vars): Observable<any> {
        return this.http.post(`${this.APIURL}/printReceipt`, req_vars)
    }

    public shareRequisitionWithLab(req_vars): Observable<any> {
        return this.http.post(`${this.APIURL}/shareRequisitionWithLab`, req_vars)
    }
    // public printNewCertificate(req_vars): Observable<any> {
    //     return this.http.post(`${this.APIURL}/printNewCertificate`, req_vars)
    // }
    public printRequisitions(req_vars): Observable<any> {
        return this.http.post(`${this.APIURL}/printRequisition`, req_vars)
    }

    public getAllReceipt(req_vars): Observable<any> {
        return this.http.post(`${this.APIURL}/getAllReceipt`, req_vars)
    }
    public getAllDoctorsByNameOrContact(req_vars): Observable<any> {
        return this.http.post(`${this.APIURL}/getAllDoctorsByNameOrContact`, req_vars)
    }
    public deleteReceipt(req_vars): Observable<any> {
        return this.http.post(`${this.APIURL}/deleteReceipt`, req_vars)
    }
    public deleteRequisition(req_vars): Observable<any> {
        return this.http.post(`${this.APIURL}/deleteRequisition`, req_vars)
    }
    public removeFavApptOfDoctor(req_vars): Observable<any> {
        return this.http.post(`${this.APIURL}/removeFavApptOfDoctor`, req_vars)
    }
    public shareReceipt(req_vars): Observable<any> {
        return this.http.post(`${this.APIURL}/shareReceipt`, req_vars)
    }
    public shareRequisition(req_vars): Observable<any> {
        return this.http.post(`${this.APIURL}/shareRequisition`, req_vars)
    }
    public getAllRequisitions(req_vars): Observable<any> {
        return this.http.post(`${this.APIURL}/getAllRequisitions`, req_vars)
    }
    public getAllLabReportOfPatient(req_vars): Observable<any> {
        return this.http.post(`${this.APIURL}/getAllLabReportOfPatient`, req_vars)
    }
    public getAllDocumentsOfPatient(req_vars): Observable<any> {
        return this.http.post(`${this.APIURL}/getAllDocumentsOfPatient`, req_vars)
    }
    public uploadLabReport(req_vars): Observable<any> {
        return this.http.post(`${this.APIURL}/uploadLabReport`, req_vars, {
            headers: new HttpHeaders()
        })
    }
    public searchPatient(req_vars): Observable<any> {
        return this.http.get(`${this.PIVOTALAPIURL}/doctor/patients/` + req_vars)
    }
    public searchPatientPostall(req_vars): Observable<any> {
        return this.http.post(`${this.PIVOTALAPIURL}/doctor/searchpatientpostall/`, req_vars)
    }
    public saveMedicineOfDoctorperDiagnosis(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/saveMedicinesOfDoctorAsPerDiagnosis`, req_vars)
    }
    public sendOtpToPatient(req_vars: any): Observable<any> {
        return this.http.post(`${this.PIVOTALAPIURL}/parent/otp`, req_vars)
    }
    public linkDoctorPatient(req_vars): Observable<any> {
        return this.http.get(`${this.PIVOTALAPIURL}/doctor/linkDoctorPatient/` + req_vars)
    }

    public getPatientMilestones(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/getPatientMilestones`, req_vars)
    }

    public getMasterMilestones(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/getMasterMilestones`, req_vars)
    }

    public savePatientMilestone(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/savePatientMilestone`, req_vars)
    }

    public getBrandsByVaccineId(req_vars: any): Observable<any> {
        return this.http.post(`${this.PIVOTALAPIURL}/v2/vaccines/brandsbyvaccineid`, { data: req_vars })
    }

    public markVaccineAsTaken(req_vars, id): Observable<any> {
        return this.http.post(`${this.PIVOTALAPIURL}/patient/vaccines/` + id, { data: req_vars })
    };

    public uploadBulkVaccination(req_vars): Observable<any> {
        return this.http.post(`${this.PIVOTALAPIURL}/v3/bulkUploadVaccination`, { data: req_vars })
    }
    // public getMedicinesOfDoctorAsPerDiagnosis(req_vars: any): Observable<any> {
    //     return this.http.post(`${this.APIURL}/getMedicinesOfDoctorAsPerDiagnosis`, req_vars)
    // }

    // public deleteVisit(req_vars, id): Observable<any> {
    //     return this.http.delete(`${this.PIVOTALAPIURL}/patient/${id}/visits`,{ data: req_vars})
    // };

    public deleteVisit(id, visitId): Observable<any> {

        return this.http.delete(`${this.PIVOTALAPIURL}/patient/${id}/visits/${visitId}`)
    };

    public unlockLabReportOfPatient(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/unlockLabReportOfPatient`, req_vars)
    }
    public printPatinetDischarge(req_vars: any): Observable<any> {
        return this.http.post(`${this.APIURL}/printPatinetDischarge`, req_vars)
    }


}


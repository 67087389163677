<div *ngIf="showGraphs" style="width: 100vw;">

    <!-- <div style="display: flex; flex-direction:row; justify-content: space-between; width: 90%; margin-left: 20px;">
        <div style="
        font-size: 24px;
        color: #333;
        align-self: center;
        font-weight: bold;">Vitals</div>

        <button *ngIf="showGraphs" (click)="hideGraph()" class="new_btn" style="margin-left: 70px;">Hide Graphs</button>

    </div> -->
    <div style="display: flex; width: 100vw; justify-content: space-between; margin: 8px 0px;">


        <div style="
        font-size: 24px;
        color: #333;
        align-self: center;
        font-weight: bold;
        width: 200px;">Vital Trends</div>

        <div style="display: flex; flex-direction: row; width: 100%; justify-content: flex-end;">


            <button *ngIf="showGraphs" (click)="hideGraph()" class="btn btn-secondary"
                style="margin-top: 6px; display: flex; justify-content: center; align-items: center;">
                <mat-icon [ngClass]="'blue-icon'">
                    insights
                </mat-icon>
                <span style="margin-left: 4px;">Vitals</span>
            </button>
        </div>

    </div>


    <app-graph-container [graphName]='graphName'></app-graph-container>
</div>

<div *ngIf="!showGraphs"
    style="display: flex; flex-direction: column; background-color: #ffffff; height: 100vh;width: 100vw; align-items: center;">
    <div style="display: flex; width: 50%; justify-content: space-between; margin: 8px 30px; width: 100vw;">


        <div style="
        font-size: 24px;
        color: #333;
        align-self: center;
        font-weight: bold;">Vitals</div>

        <div style="display: flex; flex-direction: row; width: 100%; justify-content: flex-end;">


            <!-- <button *ngIf="!showGraphs" (click)="showGraph()" class="new_btn">Show
                Graphs</button> -->


            <button (click)="toggleShowEmptyVisits()" class="btn btn-secondary"
                style="margin-top: 6px; display: flex; justify-content: center; align-items: center;">
                <mat-icon [ngClass]="'blue-icon'">
                    segment
                </mat-icon>
                <span style="margin-left: 4px;">{{toggleFormat ? 'Hide' : 'Show'}} All Visits</span>
            </button>

            <!-- <button *ngIf="!showGraphs && !isVisitEdit"
                (click)="addNewVitalsLink();opensDialog(addVitalsModal, 'addVitalsModal');" class="btn btn-secondary"
                style="margin-top: 6px; display: flex; justify-content: center; align-items: center;">
                <mat-icon [ngClass]="'blue-icon'">
                    data_saver_on
                </mat-icon>
                <span style="margin-left: 4px;">Add Vitals</span>
            </button> -->

            <!-- <button *ngIf="!showGraphs && isVisitEdit"
                (click)="addVitals();opensDialog(editVitalsModal,'editVitalsModal');" class="btn btn-secondary"
                style="margin-top: 6px; display: flex; justify-content: center; align-items: center;">
                <mat-icon [ngClass]="'blue-icon'">
                    data_saver_on
                </mat-icon>
                <span style="margin-left: 4px;">Edit Vitals</span>
            </button> -->


            <button (click)="openGraphs()" class="btn btn-secondary"
                style="margin-top: 6px; display: flex; justify-content: center; align-items: center;">
                <mat-icon [ngClass]="'blue-icon'">
                    insights
                </mat-icon>
                <span style="margin-left: 4px;">Vital Trends</span>
            </button>





            <!-- COMMENTING FOR RELEASE -->


        </div>

    </div>


    <div style="width: 100vw; background-color: #fff; overflow: scroll; overflow-y:scroll;">
        <table>

            <tr>
                <td style="position: sticky;
                left: 0;
                z-index: 2;
                background-color: #fff;
               
                ">
                    <table style="width: 250px;" align="center">
                        <tr>
                            <th colspan="2"
                                style="height: 53px; text-align: center; background-color: #EEEEEE; font-size: 18px;">
                                Vitals
                            </th>
                        </tr>
                        <tr>
                            <th colspan="2" style="height: 40px; color: #4E4CDC; text-align: center; font-size: 16px;">
                                Age at Visit
                            </th>
                        </tr>
                        <tr>
                            <th colspan="2" style="height: 40px; color: #4E4CDC; text-align: center; font-size: 16px;">
                                Visit by</th>
                        </tr>


                        <tr>

                            <th
                                style="writing-mode: vertical-lr; transform: rotate(180deg); text-align: center; background-color: #B4CBFF;">
                                Vitals </th>
                            <td>
                                <table>
                                    <tr>
                                        <th class="table-main-th">Weight (kg)</th>
                                        <div (click)="openGraphs('Weight')" class="individual-graph-btn">
                                            Graph
                                        </div>
                                    </tr>
                                    <tr>
                                        <th class="table-main-th">Height (cm)</th>
                                        <div (click)="openGraphs('Height')" class="individual-graph-btn">
                                            Graph
                                        </div>
                                    </tr>
                                    <tr>
                                        <th class="table-main-th">BMI</th>
                                    </tr>
                                    <tr *ngIf="isPediatric">
                                        <th class="table-main-th">Head Circf. (cm)</th>
                                        <div (click)="openGraphs('HC')" class="individual-graph-btn">
                                            Graph
                                        </div>
                                    </tr>
                                    <tr *ngIf="isPediatric">
                                        <th class="table-main-th">CC (cm)</th>
                                        <div (click)="openGraphs('CC')" class="individual-graph-btn">
                                            Graph
                                        </div>
                                    </tr>
                                    <tr>
                                        <th class="table-main-th">Temp (f)</th>
                                    </tr>
                                    <tr>
                                        <th class="table-main-th">SPO2 (%)</th>
                                    </tr>
                                    <tr *ngIf="ageYears>=8 && gender=='Female'" >
                                        <th class="table-main-th">LMP</th>
                                    </tr>
                                </table>
                            </td>
                        </tr>

                        <tr>
                            <th (click)="toggleVitals('bpHeart')"
                                style="writing-mode: vertical-lr; transform: rotate(180deg); text-align: center; background-color: #B4FFE5; padding: 8px; cursor: pointer;"
                                [ngClass]="(showBpHeart)?'expanded-td':'shrinked-td'">
                                {{showBpHeart ? 'Blood Pressure & Heart' : '' }}

                            </th>
                            <td *ngIf="showBpHeart">
                                <table>
                                    <tr>
                                        <th class="table-main-th">BP(Sys) (mmHg)</th>
                                        <div (click)="openGraphs('BP(Sys)')" class="individual-graph-btn">
                                            Graph
                                        </div>
                                    </tr>
                                    <tr>
                                        <th class="table-main-th">BP(Dia) (mmHg)</th>
                                        <div (click)="openGraphs('BP(Dia)')" class="individual-graph-btn">
                                            Graph
                                        </div>
                                    </tr>
                                    <tr>
                                        <th class="table-main-th">Heart Rate(min)</th>
                                    </tr>
                                    <tr>
                                        <th class="table-main-th">Pulse(min)</th>
                                    </tr>
                                </table>
                            </td>
                        </tr>

                        <tr>
                            <th (click)="toggleVitals('bloodAnalysis')"
                                style="writing-mode: vertical-lr; transform: rotate(180deg); text-align: center; background-color: #FCB4FF; padding: 8px; cursor: pointer;"
                                [ngClass]="(showBloodAnalysis)?'expanded-td':'shrinked-td'">
                                {{showBloodAnalysis ? 'Blood Analysis (Sugar)' : ''}} </th>
                            <td *ngIf="showBloodAnalysis">
                                <table>
                                    <tr>
                                        <th class="table-main-th">Haemoglobin</th>
                                    </tr>
                                    <tr>
                                        <th class="table-main-th">Blood Sugar (R)</th>
                                    </tr>
                                    <tr>
                                        <th class="table-main-th">Blood Sugar (F)</th>
                                    </tr>
                                    <tr>
                                        <th class="table-main-th">Blood Sugar (PP)</th>
                                    </tr>
                                    <tr>
                                        <th class="table-main-th">Blood (Hb1ac)</th>
                                    </tr>
                                </table>
                            </td>
                        </tr>

                        <tr>
                            <th (click)="toggleVitals('lipidProfile')"
                                style="text-align: center; background-color: #FFB4B4; padding: 8px; cursor: pointer;"
                                [ngClass]="(showLipidProfile)?'expanded-td':'shrinked-td'">
                                {{showLipidProfile ? 'Lipid Profile' : ''}} </th>
                            <td *ngIf="showLipidProfile">
                                <table>
                                    <tr>
                                        <th class="table-main-th">Cholesterol (HDL) (mg/dL)</th>
                                    </tr>
                                    <tr>
                                        <th class="table-main-th">Cholestrol (LDL) (mg/dL)</th>
                                    </tr>
                                    <tr>
                                        <th class="table-main-th">Total Cholesterol (mg/dL)</th>
                                    </tr>
                                    <tr>
                                        <th class="table-main-th">Tryglycerides (mg/dL)</th>
                                    </tr>

                                </table>
                            </td>
                        </tr>


                    </table>
                </td>


                <!--For Birth Visit-->

                <td *ngIf="birthVisit" style="vertical-align: top;">
                    <table style="width: 100px;" (click)="onSelectVisit(birthVisit, 1)">
                        <tr>
                            <th style="height: 50px; text-align: center; background-color: #EEEEEE; font-size: 17px;">
                                Birth
                                <br> <span style="color:#A7A7A7; font-size: 14px;"> {{birthVisit.date | date :
                                    "dd/MM/YY"}}</span>
                            </th>
                        </tr>
                        <tr>
                            <td class="c_align" style="height: 40px; font-size: 16px; color: grey;">
                                {{birthVisit.ageAtVisit}}
                            </td>
                        </tr>
                        <tr>
                            <td class="c_align" style="height: 40px; font-size: 16px; color: grey;">
                                {{birthVisit.updatedByName ? birthVisit.updatedByName.split(' ')[0] : '' }}
                            </td>
                        </tr>


                        <!-- VITALS SECTION START -->
                        <tr>
                            <td>
                                <table>
                                    <tr>
                                        <th class="table-inner-th"> {{birthVisit.weight}} </th>
                                    </tr>
                                    <tr>
                                        <th class="table-inner-th"> {{birthVisit.height}} </th>
                                    </tr>

                                    <tr>
                                        <th class="table-inner-th">
                                    <tr>
                                        <th class="table-inner-th"> {{birthVisit.bmi}} </th>
                                    </tr>
                                    </th>
                        </tr>
                        <tr *ngIf="isPediatric">
                            <th class="table-inner-th"> {{birthVisit.hc}} </th>
                        </tr>
                        <tr *ngIf="isPediatric">
                            <th class="table-inner-th"> {{birthVisit.cc}} </th>
                        </tr>
                        <tr>
                            <th class="table-inner-th"> {{birthVisit.Temp}} </th>
                        </tr>
                        <tr>
                            <th class="table-inner-th"> {{birthVisit.spo2}} </th>
                        </tr>
                    </table>
                </td>
            </tr>
            <!-- VITALS SECTION END -->


            <!-- BP & Heart SECTION START -->
            <tr *ngIf="showBpHeart">
                <td style="height: 180px;">
                    <table>
                        <tr>
                            <th class="table-inner-th">{{birthVisit.bpSys}}</th>
                        </tr>
                        <tr>
                            <th class="table-inner-th">{{birthVisit.bpDia}}</th>
                        </tr>
                        <tr>
                            <th class="table-inner-th">{{birthVisit.Heartrate}}</th>
                        </tr>
                        <tr>
                            <th class="table-inner-th">{{birthVisit.spo2}}</th>
                        </tr>
                    </table>
                </td>
            </tr>
            <!-- BP & Heart SECTION START -->

            <!-- Blood Analysis SECTION START -->
            <tr *ngIf="showBloodAnalysis">
                <td style="height: 180px;">
                    <table>
                        <tr>
                            <th class="table-inner-th">{{birthVisit.Bloodhaemoglobin}}</th>
                        </tr>
                        <tr>
                            <th class="table-inner-th">{{birthVisit.BloodSugarRandom}}</th>
                        </tr>
                        <tr>
                            <th class="table-inner-th">{{birthVisit.Bloodsugar_F}}</th>
                        </tr>
                        <tr>
                            <th class="table-inner-th">{{birthVisit.Bloodsugar_PP}}</th>
                        </tr>
                        <tr>
                            <th class="table-inner-th">{{birthVisit.Blood_Hba1c}}</th>
                        </tr>
                    </table>
                </td>
            </tr>
            <!-- Blood Analysis SECTION END -->

            <!-- Lipid Profile SECTION START -->
            <tr *ngIf="showLipidProfile">
                <td style="height: 180px;">
                    <table>
                        <tr>
                            <th class="table-inner-th">{{birthVisit.Chol_HDL}}</th>
                        </tr>
                        <tr>
                            <th class="table-inner-th">{{birthVisit.Chol_LDL}}</th>
                        </tr>
                        <tr>
                            <th class="table-inner-th">{{birthVisit.TotalCholesterol}}</th>
                        </tr>
                        <tr>
                            <th class="table-inner-th">{{birthVisit.Triglycerides_LFT}}</th>
                        </tr>
                    </table>
                </td>
            </tr>
            <!-- Lipid Profile SECTION END -->


        </table>
        </td>
        <!-- For Birth Visit-->


        <!--Loop this part this TD should be repeated-->

        <td *ngFor="let visit of allVisits; let i = index" style="vertical-align: top;">
            <table style="width: 100px;" [ngStyle]="{'background-color': i == 0 ? '#e5effa' : '#ffffff'}"
                (click)="onSelectVisit(visit, 1)" class="table-hov">
                <tr>
                    <th style="height: 50px; text-align: center; background-color: #EEEEEE; font-size: 17px;">
                        {{visit.date | date : "dd/MM/YY"}}
                        <br> <span style="color:#A7A7A7; font-size: 14px;">{{visit.time | date: "hh:mm
                            a"}}</span>
                    </th>
                </tr>
                <tr>
                    <td class="c_align" style="height: 40px; font-size: 16px; color: grey;">{{visit.ageAtVisit}}
                    </td>
                </tr>
                <tr>
                    <td class="c_align" style="height: 40px; font-size: 16px; color: grey;">{{visit.visitBy}}
                    </td>
                </tr>


                <!-- VITALS SECTION START -->
                <tr>
                    <td>
                        <table>
                            <tr>
                                <th class="table-inner-th"> {{visit.weight}} </th>
                            </tr>
                            <tr>
                                <th class="table-inner-th"> {{visit.height}} </th>
                            </tr>
                            <tr>
                                <th class="table-inner-th"> {{visit.bmi}} </th>
                            </tr>
                            <tr *ngIf="isPediatric">
                                <th class="table-inner-th"> {{visit.hc}} </th>
                            </tr>
                            <tr *ngIf="isPediatric">
                                <th class="table-inner-th"> {{visit.cc}} </th>
                            </tr>
                            <tr>
                                <th class="table-inner-th"> {{visit.Temp}} </th>
                            </tr>
                            <tr>
                                <th class="table-inner-th"> {{visit.spo2}} </th>
                            </tr>
                            <tr *ngIf="ageYears>=8 && gender=='Female'">
                                <th class="table-inner-th"> {{visit.lmp | date: 'dd/MM/yyyy'}} </th>
                            </tr>
                        </table>
                    </td>
                </tr>
                <!-- VITALS SECTION END -->


                <!-- BP & Heart SECTION START -->
                <tr *ngIf="showBpHeart">
                    <td style="height: 180px;">
                        <table>
                            <tr>
                                <th class="table-inner-th">{{visit.bpSys}}</th>
                            </tr>
                            <tr>
                                <th class="table-inner-th">{{visit.bpDia}}</th>
                            </tr>
                            <tr>
                                <th class="table-inner-th">{{visit.Heartrate}}</th>
                            </tr>
                            <tr>
                                <th class="table-inner-th">{{visit.Pulse}}</th>
                            </tr>
                        </table>
                    </td>
                </tr>
                <!-- BP & Heart SECTION START -->

                <!-- Blood Analysis SECTION START -->
                <tr *ngIf="showBloodAnalysis">
                    <td style="height: 180px;">
                        <table>
                            <tr>
                                <th class="table-inner-th">{{visit.Bloodhaemoglobin}}</th>
                            </tr>
                            <tr>
                                <th class="table-inner-th">{{visit.BloodSugarRandom}}</th>
                            </tr>
                            <tr>
                                <th class="table-inner-th">{{visit.Bloodsugar_F}}</th>
                            </tr>
                            <tr>
                                <th class="table-inner-th">{{visit.Bloodsugar_PP}}</th>
                            </tr>
                            <tr>
                                <th class="table-inner-th">{{visit.Blood_Hba1c}}</th>
                            </tr>
                        </table>
                    </td>
                </tr>
                <!-- Blood Analysis SECTION END -->

                <!-- Lipid Profile SECTION START -->
                <tr *ngIf="showLipidProfile">
                    <td style="height: 180px;">
                        <table>
                            <tr>
                                <th class="table-inner-th">{{visit.Chol_HDL}}</th>
                            </tr>
                            <tr>
                                <th class="table-inner-th">{{visit.Chol_LDL}}</th>
                            </tr>
                            <tr>
                                <th class="table-inner-th">{{visit.TotalCholesterol}}</th>
                            </tr>
                            <tr>
                                <th class="table-inner-th">{{visit.Triglycerides_LFT}}</th>
                            </tr>
                        </table>
                    </td>
                </tr>
                <!-- Lipid Profile SECTION END -->


            </table>
        </td>
        <!-- END of Loop  TD should be repeated-->

        </tr>
        </table>

        <div *ngIf="!showBpHeart" (click)="toggleVitals('bpHeart')"
            style="text-align: center; background-color: #B4FFE5; width: fit-content; font-weight: bold; padding: 8px; cursor: pointer;">
            Blood Pressure & Heart</div>

        <div *ngIf="!showBloodAnalysis" (click)="toggleVitals('bloodAnalysis')"
            style="text-align: center; background-color: #FCB4FF; width: fit-content; font-weight: bold; padding: 8px; cursor: pointer;">
            Blood Analysis (Sugar)</div>

        <div *ngIf="!showLipidProfile" (click)="toggleVitals('lipidProfile')"
            style="text-align: center; background-color: #FFB4B4; width: fit-content; font-weight: bold; padding: 8px; cursor: pointer;">
            Lipid Profile</div>

    </div>


    <div *ngIf="!showGraphs && false" style="height: '100%'; width: '100%';">
        <div *ngIf="isPediatric">
            <div style="border: 1px solid #e9eaea;background-color: #f1f1f1;height: 40px;">
                <div style="padding: 7px;color: #333;font-size: 18px;font-weight:500;">Birth Records</div>
            </div>
            <div (click)="onSelectVisit(birthVisit, 1);opensDialog(editVitalsModal, 'editVitalsModal');"
                style="border: 1px solid #e9eaea;background-color: #ffffff;height: 40px; display:flex;flex-direction: row;cursor: pointer;">
                <div style="padding: 7px;color: #333333;font-size: 16px;width: 50%;">
                    {{birthVisit?.formattedDate}}
                </div>
                <div style="display:flex;flex-direction: row;width: 50%;">
                    <div *ngIf="birthVisit?.updatedByName != null"
                        style="font-size: 16px; align-self: center; justify-content:flex-end; text-align:right; color:#808080;width: 60%;">
                        {{((birthVisit?.updatedByName).length > 17) ?
                        (((birthVisit?.updatedByName).substring(0, 17 - 3)) + '...') :
                        birthVisit?.updatedByName}}
                    </div>
                    <div
                        style=" font-size: 16px; align-self:center; justify-content: flex-end; text-align: right;color:#808080;width: 28%; ">
                        {{moment(birthVisit?.date).format('h:mm a')}}
                    </div>
                    <a>
                        <span>
                            <i style="color: #04C5BE;margin-left: 10px;font-size: 25px; margin-top: 5px;"
                                class="fa fa-angle-right fa-4"></i></span></a>
                </div>
            </div>
        </div>

        <div *ngIf="!visitsForVisitScreen.length" style="width: 100%;display: flex; flex-direction: column; 
        height: 80vh;
        display: flex;
       
        justify-content: center;
        align-items: center;">

            <div class="c_align">
                <img style="justify-content: center;align-items: center; width: 170px;margin-bottom: -10px"
                    src="assets/images/placeholder.svg" alt="" />

                <div style="font-size: 20px; color: #333333; font-weight: bold;">No Visits</div>
                <div style="font-size: 18px; color: grey;">No visits found for selected Patient</div>
            </div>
        </div>

        <div *ngFor="let eachMonthVisit of visitsForVisitScreen; let i = index">
            <div style="border: 1px solid #eee;background-color: #F7F7F8; height: 50px;">
                <div style="padding: 7px;color: #757575;font-size: 20px;font-weight:bold;">
                    {{eachMonthVisit.month}}
                </div>
            </div>

            <div *ngFor="let visit of eachMonthVisit.data; let j = index"
                style="border: 1px solid #e9eaea;background-color: #ffffff; display:flex;flex-direction: row;cursor: pointer; align-items: center;"
                (click)="onSelectVisit(visit, 1);">
                <div style="padding: 7px;color: #333; font-size: 18px;width: 10%;display: flex; flex-direction: row;">
                    {{visit.formattedDate}} <br />
                    {{moment(visit.date).format('h:mm a')}}
                </div>
                <div style="width: 90%;display: flex; flex-direction: row;float: left;">
                    {{ visit.strToShowNonNullValues }}
                    <!-- <span *ngIf="visit.weight && visit.weight !== '0' && visit.weight !== 'null'" style="padding: 7px;color: #333; font-size: 18px;display: flex; flex-direction: row;">
                        Wt: {{visit.weight}}
                     </span>
                     <span *ngIf="visit.height && visit.height !== '0'  && visit.height !== 'null'" style="padding: 7px;color: #333; font-size: 18px;display: flex; flex-direction: row;">
                         Ht: {{visit.height}}
                      </span>
                      <span *ngIf="visit.hc && visit.hc !== '0'  && visit.hc !== 'null'" style="padding: 7px;color: #333; font-size: 18px;display: flex; flex-direction: row;">
                        HC: {{visit.hc}}
                     </span>
                     <span *ngIf="visit.cc && visit.cc !== '0'  && visit.cc !== 'null'" style="padding: 7px;color: #333; font-size: 18px;display: flex; flex-direction: row;">
                         CC: {{visit.cc}}
                      </span>
                      <span *ngIf="visit.bpSys && visit.bpSys !== '0'  && visit.bpSys !== 'null'" style="padding: 7px;color: #333; font-size: 18px;display: flex; flex-direction: row;">
                        BP(Sys): {{visit.bpSys}}
                     </span>
                     <span *ngIf="visit.bpDia && visit.bpDia !== '0'  && visit.bpDia !== 'null'" style="padding: 7px;color: #333; font-size: 18px;display: flex; flex-direction: row;">
                         BP(Dia): {{visit.bpDia}}
                      </span>
                      <span *ngIf="visit.spo2 && visit.spo2 !== '0'  && visit.spo2 !== 'null'" style="padding: 7px;color: #333; font-size: 18px;display: flex; flex-direction: row;">
                        Spo2: {{visit.spo2}}
                      </span>
                      <span *ngIf="visit.Temp && visit.Temp !== '0'  && visit.Temp !== 'null'" style="padding: 7px;color: #333; font-size: 18px;display: flex; flex-direction: row;">
                        Temp: {{visit.Temp}}
                     </span>
                     <span *ngIf="visit.bmi && visit.bmi !== '0'  && visit.bmi !== 'null'" style="padding: 7px;color: #333; font-size: 18px;display: flex; flex-direction: row;">
                         BMI: {{visit.bmi}}
                      </span>
                      <span *ngIf="visit.Heartrate && visit.Heartrate !== '0'  && visit.Heartrate !== 'null'"style="padding: 7px;color: #333; font-size: 18px;display: flex; flex-direction: row;">
                        Heartrate: {{visit.Heartrate}}
                     </span>
                   
                     <span *ngIf="visit.Pulse && visit.Pulse !== '0'  && visit.Pulse !== 'null'" style="padding: 7px;color: #333; font-size: 18px;display: flex; flex-direction: row;">
                        Pulse: {{visit.Pulse}}
                      </span>
                      <span *ngIf="visit.Bloodhaemoglobin && visit.Bloodhaemoglobin !== '0'  && visit.Bloodhaemoglobin !== 'null'" style="padding: 7px;color: #333; font-size: 18px;display: flex; flex-direction: row;">
                        HB: {{visit.Bloodhaemoglobin}}
                     </span>
                     <span *ngIf="visit.Bloodsugar_F && visit.Bloodsugar_F !== '0'  && visit.Bloodsugar_F !== 'null'" style="padding: 7px;color: #333; font-size: 18px;display: flex; flex-direction: row;">
                         Sugar (F): {{visit.Bloodsugar_F}}
                      </span>
                      <span *ngIf="visit.BloodSugarRandom && visit.BloodSugarRandom !== '0'" style="padding: 7px;color: #333; font-size: 18px;display: flex; flex-direction: row;">
                        Sugar (Ran): {{visit.BloodSugarRandom}}
                     </span>
                     <span *ngIf="visit.Bloodsugar_PP && visit.Bloodsugar_PP !== '0'" style="padding: 7px;color: #333; font-size: 18px;display: flex; flex-direction: row;">
                         Sugar (PP): {{visit.Bloodsugar_PP}}
                      </span>
                      <span *ngIf="visit.Blood_Hba1c && visit.Blood_Hba1c !== '0'" style="padding: 7px;color: #333; font-size: 18px;display: flex; flex-direction: row;">
                        Hba1c: {{visit.Blood_Hba1c}}
                     </span>
                     <span *ngIf="visit.Chol_LDL && visit.Chol_LDL !== '0'" style="padding: 7px;color: #333; font-size: 18px;display: flex; flex-direction: row;">
                        Chol. LDL: {{visit.Chol_LDL}}
                      </span>
                      <span *ngIf="visit.Chol_HDL && visit.Chol_HDL !== '0'" style="padding: 7px;color: #333; font-size: 18px;display: flex; flex-direction: row;">
                        Chol. HDL: {{visit.Chol_HDL}}
                     </span>
                     <span *ngIf="visit.Triglycerides_LFT && visit.Triglycerides_LFT !== '0'" style="padding: 7px;color: #333; font-size: 18px;display: flex; flex-direction: row;">
                        Trigly. LFT: {{visit.Triglycerides_LFT}}
                      </span>
                      <span *ngIf="visit.TotalCholesterol && visit.TotalCholesterol !== '0'" style="padding: 7px;color: #333; font-size: 18px;display: flex; flex-direction: row;">
                        Total Chol.: {{visit.TotalCholesterol}}
                     </span> -->

                </div>
                <!-- <div style="display:flex;flex-direction: row;"> -->
                <!-- <div *ngIf="visit.updatedByName != null"
                        style="font-size: 16px; align-self: center; justify-content:flex-end; text-align:right; color:#808080;width: 60%;">
                        {{((visit.updatedByName).length > 17) ?
                        (((visit.updatedByName).substring(0, 17 - 3)) + '...') :
                        visit.updatedByName}}
                    </div> -->
                <!-- <div
                        style=" font-size: 16px; align-self:center; justify-content: flex-end; text-align: right;color:#808080;width: 28%; ">
                    </div>
                    <a>
                        <span>
                            <i style="color: #4687BF;margin-left: 10px;font-size: 25px; "
                                class="fa fa-angle-right fa-4"></i></span></a> -->
                <!-- </div> -->
            </div>
        </div>
    </div>



    <!-- ADD VITALS MODAL -->
    <ng-template #addVitalsModal let-modal>
        <div style="width: 100%;">


            <app-add-vitals #addVitalsComponent [patientResponse]="patientResponse" [isPediatric]="isPediatric"
                [addVisit]="true"></app-add-vitals>
        </div>

    </ng-template>
    <!-- ADD VITALS MODAL -->


    <!-- EDIT VITALS MODAL -->
    <ng-template #editVitalsModal let-modal>
        <div style="width: 100%;">
            <!-- <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
            (click)="closeDialog('Cross click');">
            <span style="font-size: 25px;" aria-hidden="true">&times;</span>
        </button> -->

            <app-add-vitals #addVitalsComponent [patientResponse]="patientResponse" [visitDate]="visitDate"
                [isPediatric]="isPediatric" [addVisit]="true" [containerHeight]="'75vh'"></app-add-vitals>
        </div>
    </ng-template>
    <!-- EDIT VITALS MODAL -->

</div>
<ng-template #delete_modal>
    <h4>Delete Visit?</h4>
    <div style="color: #555; font-size: 16px;">Are you sure you want to delete Visit?</div>
    <div style="padding-top: 16px; display: flex; justify-content: flex-end;">
        <button (click)="closeDialog()" class="cancelBtnUi">Cancel</button>
        <button (click)="onClickDeleteVisit()" class="deleteBtn">Delete</button>
    </div>
</ng-template>
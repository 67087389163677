<!-- <p>alertbox works!</p> -->
<div class="container" [style]="styles" 
    [ngClass]="{'container-info': type == 'Info', 'container-warning' : type == 'Warning', 'container-danger': type == 'Danger', 'container-success':type == 'Success'}">
    <div style=" width:80px; border-radius: 8px; display: flex; justify-content: center; align-items: flex-start;flex-grow: 1;">
        <img *ngIf="type=='Info'" src="assets/images/info_icon_new.svg" alt="" />
        <img *ngIf="type=='Warning'" src="assets/images/warning_icon_new.svg" alt="" />
        <img *ngIf="type=='Danger'" src="assets/images/danger_icon_new.svg" alt="" />
        <img *ngIf="type=='Success'" src="assets/images/success_icon_new.svg" alt="" />
    </div>

    <div style="margin-left: 8px;">
        <div class="title"
            [ngClass]="{'font-info': type == 'Info', 'font-warning' : type == 'Warning', 'font-danger': type == 'Danger', 'font-success':type == 'Success' }">
            {{title}}</div>
        <div class="subtitle"
            [ngClass]="{'font-info': type == 'Info', 'font-warning' : type == 'Warning', 'font-danger': type == 'Danger', 'font-success':type == 'Success' }"
            [ngStyle]="{'width': subtitle.length > 100 ? '80%' : '100%'}"
            >
            {{subtitle}}</div>
    </div>
</div>